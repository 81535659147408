import React from 'react'
import { Card, CardHeader, CardBody, Loader, Flex } from '@fluentui/react-northstar';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellServerGraph, CellColTitle, CellRecievedExpected, CellPill, CellAccessIcon, CellPillGroup, CellHealthStatus, CellServerGraphLabel, CellDateTime } from '../../../../shared/appcomponents/custom-grid-cells';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { getRouteFromLocalStorage, WindowContext } from "../../../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';

const InfraDetail = (props) => {
  const { id, type } = useParams();
  const [details, setDetails] = React.useState(null);
  const [detailTable, setDetailTable] = React.useState(null);
  const [serverState, setServerState] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const routeChange = (path) =>{
    navigate(path);
  }
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "ServerName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const updateDetailData = (response) => {
    setDetails(response);
    let _configRules = JSON.parse(getRouteFromLocalStorage('configRules'));
    response.Table.forEach((t) => {
      t.DiskUsage = t.DiskUsage != "" ? t.DiskUsage.split(' | ').map((m) => {
          let v = Number(m.split(': ')[1].slice(0, -1));
          let _state = 'healthy';
          if(v > _configRules.AzureAlert.DiskUsageLimitCritical.value * 100){
              _state = 'critical';
          }
          else if(v > _configRules.AzureAlert.DiskUsageLimitWarning.value * 100){
              _state = 'warning';
          }
  
          return {text: m, state: _state};
      }): [];
      let _cpu = Number(t.CPUUsage.slice(0,-1)), _ram =  Number(t.RAMUsage.slice(0,-1));
      t.CPUUsage = t.CPUUsage != "" ?
        _cpu > _configRules.AzureAlert.CPUUtilizationLimitCritical.value * 100 ? [{text: _cpu, state: 'critical'}] : 
        _cpu > _configRules.AzureAlert.CPUUtilizationLimitWarning.value * 100 ? [{text: _cpu, state: 'warning'}] :
        [{text: _cpu, state: 'healthy'}]
        : [];
      t.RAMUsage = t.RAMUsage != "" ?
      _ram < _configRules.AzureAlert.RAMAvailableLimitCritical.value * 100 ? [{text: (100 - _ram), state: 'critical'}] : 
      _ram < _configRules.AzureAlert.RAMAvailableLimitWarning.value * 100 ? [{text: (100 - _ram), state: 'warning'}] :
        [{text: (100 - _ram), state: 'healthy'}]
        : [];
    });
    setDetailTable(response.Table);
  }

  React.useEffect(() => {
    props.acquireToken((token) => {
      setLoaded(false);
      let _alarmType = searchParams.get('state');
      let _state = INCIDENTLEVELS[_alarmType];
      if(_state == null){
        _state = -1;
      }
      setServerState(_state);
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId : _state,
        appliedFilters: type
          ? id
            ? [`InstanceId IN (${id})`, `ServerTypeId IN (${type})`]
            : []
          : id
          ? [`InstanceId IN (${id})`]
          : [],
      };
      if (token) {
        MonitoringService.GetInfraDetail(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            updateDetailData(response);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }

          setLoaded(true);
        });
      }
    });
  }, [id, type]);

  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if(_state == null){
      _state = -1;
    }
    setServerState(_state);
  }, [searchParams]);

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setDetails(null);
    setDetailTable(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId : serverState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetInfraDetail(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            updateDetailData(response);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }

          setLoaded(true);
        });
      }
    })
  }

    const filterGridData = (value) => {
        let filter = {
            logic: 'or',
            filters: [
                { field: 'ServerName', operator: 'contains', value: value },
                { field: 'PowerState', operator: 'contains', value: value },
                { field: 'ServerType', operator: 'contains', value: value },
                { field: 'CPUUsage', operator: 'contains', value: value },
                { field: 'DiskUsage', operator: 'contains', value: value },
                { field: 'RAMUsage', operator: 'contains', value: value },
            ],
        };
        setDataState({ ...dataState, filter: filter });
    };

  const expandChange = (event) => {
    let newData = detailTable.map((item) => {
      if (item.ServerName === event.dataItem.ServerName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDetailTable(newData);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>Server Type: {dataItem.ServerType}</td>
            <td>CPU Usage (Current): {dataItem.CPUUsage}</td>
          </tr>
          <tr>
            <td>Disk Usage (Current): {dataItem.DiskUsage}</td>
            <td>RAM Usage (Current): {dataItem.RAMUsage}</td>
          </tr>
          <tr>
            <td>
              <CellAccessIcon
                {...props1}
                field={"RDPAccess"}
                text={"RDP Access:"}
              />
            </td>
            <td>
              <CellServerGraph
                {...props1}
                field={"ServerGraph"}
                text={"Server Graph:"}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };


  return (
    <WindowContext.Consumer>
      {(context) => (
        <div className='fluid w-100'>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                <CardHeader className='mb-2'>
                  <div className='d-flex'>
                    <div className='f-size-20'>Infrastructure Detail</div>
                  </div>
                </CardHeader>
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/infra/detail${id ? '/' + id : ''}${type ? '/' + type : ''}`)}
                      className={`card-border bg-white br-0 pt-1 p-0 card-action ${serverState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center'>
                        <div className='f-size-15'>Total Servers</div>
                        <div className='f-size-26'>{details != null ? details.Total : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() =>
                        routeChange(
                          `/dashboards/monitoring/infra/detail${
                            id ? '/' + id : ''
                          }${type ? '/' + type : ''}?state=healthy`
                        )
                      }
                      className={`card-border bg-success br-0 pt-1 p-0 card-action ${
                        serverState == 0 ? 'selected' : ''
                      }`} fluid ghost elevated
                    >
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Healthy</div>
                        <div className='f-size-26'>{details != null ? details.Healthy : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                    onClick={() => routeChange(`/dashboards/monitoring/infra/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=warning`)}
                    className={`card-border bg-warning br-0 pt-1 p-0 card-action ${serverState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                    <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Warning</div>
                        <div className='f-size-26'>{details != null ? details.Warning : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                    onClick={() => routeChange(`/dashboards/monitoring/infra/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=critical`)}
                    className={`card-border bg-danger br-0 pt-1 p-0 card-action ${serverState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Critical</div>
                        <div className='f-size-26'>{details != null ? details.Critical : '-'}</div>
                      </div>
                    </Card>
                  </div>
                </div>
                <CardBody>
                  <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                    <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                      defaultAppliedFilter={
                        type
                          ? [
                              {filter: "Instance", operator: "==", value: id },
                              {
                                filter: "ServerType",
                                operator: "==",
                                value: type.split(",").map((i) => Number(i)),
                              },
                            ]
                          : {filter: 'Instance', operator: '==', value: id}
                      }
                      elegibleFilters={['Instance', 'Server', 'ServerType', 'CPUUsage', 'DiskUsage', 'RAMUsage']} />
                    <Flex.Item push>
                      <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                        <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                      </div>
                    </Flex.Item>
                  </Flex>
                  <div className='w-100'>
                    {isLoaded ?(
                      <KendoGrid
                        style={{
                          height: "456px",
                        }}
                        // pageable={true}
                        pageable={{
                          buttonCount: 3
                        }}
                        sortable={true}
                        data={process(detailTable.filter(tr => {
                            if(serverState == -1){return true}
                            else if(serverState != -1 && tr.Health == serverState){return true}
                            return false;
                          }), dataState)}
                        {...dataState}
                        onDataStateChange={(e) => {
                          setDataState(e.dataState);
                        }}
                        // detail={
                        //   context.mediaCategory.sm ? DetailComponentSm : null
                        // }
                        expandField="expanded"
                        onExpandChange={expandChange}
                      >
                            <Column field="ServerName" title="Server Name" cell={CellServerGraphLabel} locked={true} width={200}/>
                            <Column field="ServerType" title="Server Type" locked={true} width={150}/>
                            <Column field="CPUUsage" cell={CellPill} title="CPU Usage (Current)" width={180}/>
                            <Column field="DiskUsage" cell={CellPillGroup} title="Disk Usage (Current)" width={250}/>
                            <Column field="RAMUsage" cell={CellPill} title="RAM Usage (Current)" width={180}/>
                            <Column field="NetworkReceivedExpected" cell={CellRecievedExpected} title={<CellColTitle title="Recieved/Expected"  />} width={170}/> 
                            <Column field="Health" title="Health" cell={CellHealthStatus}  width={120}/>
                            <Column field="DateUpdatedHub" title="Last Updated UTC" cell={CellDateTime} width={150}/>
                            <Column field="RDPAccess" title="RDP Access" cell={CellAccessIcon} filterable={false} sortable={false} width={110}/>
                            <Column field="ServerGraph" title="Server Graph" cell={CellServerGraph} filterable={false} sortable={false} width={120}/>

                        </KendoGrid>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </WindowContext.Consumer>
  )
};

export { InfraDetail }