import React from 'react'
import { Card, Loader, CardHeader, CardBody, Flex } from '@fluentui/react-northstar';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CellHealthStatus, CellCallRecievedExpected, CellDateTime, CellInstanceOverviewLabel,CellPortalGraph, CellColTitle , CellOpenIncidents, CellCustomerName, CellTenantName} from '../../../../shared/appcomponents/custom-grid-cells';
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../../../shared/contexts/Context";

const PortalDetail = (props) => {
    const { id, type } = useParams();
    const [details, setDetails] = React.useState(null);
    const [detailTable, setDetailTable] = React.useState(null);
    const [isLoaded, setLoaded] = React.useState(false);
    const [tenantState, setTenantState] = React.useState(null);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const routeChange = (path) =>{
        navigate(path);
    }

    const [dataState, setDataState] = React.useState({
        sort: [
          {
            field: "InstanceName",
            dir: "asc",
          },
        ],
        take: 10,
        skip: 0,
      });

    React.useEffect(() => {
      var x = type;
        props.acquireToken((token) => {
            setLoaded(false);
            let _alarmType = searchParams.get('state');
            let _state = INCIDENTLEVELS[_alarmType];
            if(_state == null){
                _state = -1;
            }
            setTenantState(_state);
            let _params = {
                instanceId: id ? id : -1,
                incidentLevelId : _state,
                appliedFilters: type
                  ? id ? type == '1' ? [`InstanceId IN (${id})`, `RecordingAvailable = 1`] :
                      type == '0' ? [`InstanceId IN (${id})`, ` ProductType IN (2,3,4) OR (ProductType = 10 AND AnalyticsEnabled =1) `] : []
                    : []
                  : id
                  ? [`InstanceId IN (${id})`]
                  : [],
            }
            if (token) {
                MonitoringService.GetDataDetail(token, _params).then((response) => {
                  if(response && response.status==200){
                    response=response.data;
                        setDetails(response);
                        setDetailTable(response.Table);
                      }
                      else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                        }
                        else {
                          props.viewStatus.InformChildPageStatus(500);
                        }
  
                    setLoaded(true);
                });
            }
        });
    }, [id]);

    React.useEffect(() => {
        let _alarmType = searchParams.get('state');
        let _state = INCIDENTLEVELS[_alarmType];
        if(_state == null){
            _state = -1;
        }
        setTenantState(_state);
    }, [searchParams]);

    const handleFilterUpdate = (appliedFilters) => {
        setLoaded(false);    
        setDetails(null);  
        setDetailTable(null);  
        props.acquireToken((token) => {
            let _params = {
                instanceId: id ? id : -1,
                incidentLevelId : tenantState,
                appliedFilters: appliedFilters
            }
            if (token) {
                MonitoringService.GetDataDetail(token, _params).then((response) => {
                  if(response && response.status==200){
                    response=response.data;
                        setDetails(response);
                        setDetailTable(response.Table);
                      }
                      else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                        }
                        else {
                          props.viewStatus.InformChildPageStatus(500);
                        }
  
                    setLoaded(true);
                });
            }
        })
    }

    const filterGridData = (value) => {
        let filter = {
          logic: 'or',
          filters: [
            { field: 'InstanceName', operator: 'contains', value: value },
            { field: 'TenantId', operator: 'contains', value: value },
            { field: 'TenantName', operator: 'contains', value: value },
            { field: 'Product', operator: 'contains', value: value },
            { field: 'CustomerName', operator: 'contains', value: value },
            { field: 'Reseller', operator: 'contains', value: value },
          ],
        };
        setDataState({ ...dataState, filter: filter });
      };

    const expandChange = (event) => {
      let newData = detailTable.map((item) => {
        if (
          item.InstanceName === event.dataItem.InstanceName &&
          item.TenantId === event.dataItem.TenantId
        ) {
          item.expanded = !event.dataItem.expanded;
        }
        return item;
      });
      setDetailTable(newData);
    };

    const CellOpenIncidentsCol = (prop) => {
      return (
      
          <CellOpenIncidents {...prop} acquireToken={props.acquireToken} />
      
      );
   }

    const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>TenantName: {dataItem.TenantName}</td>
            <td>Product: {dataItem.Product}</td>
          </tr>
          <tr>
            <td>CustomerName: {dataItem.CustomerName}</td>
            <td>Reseller: {dataItem.Reseller}</td>
          </tr>
          <tr>
          <td>
              <CellDateTime
                {...props1}
                field={"CustomerTime"}
                text={"CustomerTimeUTC:"}
              />
            </td>
            <td>
              <CellDateTime
                {...props1}
                field={"LastCallInserted"}
                text={"LastCallInsertedUTC:"}
              />
            </td>
            <td>
              <CellDateTime
                {...props1}
                field={"LastRecordingInserted"}
                text={"LastRecordingInsertedUTC:"}
              />
            </td>
          </tr>
          <tr>
            <td>Stuck Calls (Data_CTI): {dataItem.StuckCalls}</td>
            <td>Trend: {dataItem.Trend}</td>
          </tr>
          <tr>
            <td>Recieved/Expected: {dataItem.CallsRecievedExpected}</td>
          </tr>
        </tbody>
      </table>
    );
  };


    return (
     <WindowContext.Consumer>
        {(context) => (
    <div className='fluid w-100'>
        <div className='row mb-3'>
            <div className='col-md-12'>
                <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                    <CardHeader className='mb-2'>
                        <div className='d-flex'>
                            <div className='f-size-20'>Data Detail</div>
                        </div>
                    </CardHeader>
                    <div className='row'>
                        <div className='col-md-3 mb-3'>
                            <Card 
                            onClick={() => routeChange(`/dashboards/monitoring/portal/detail${id ? '/' + id : ''}${type ? '/' + type : ''}`)}
                            className={`card-border bg-white br-0 pt-1 p-0 card-action ${tenantState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                                <div className='w-100 text-center'>
                                    <div className='f-size-15'>Total Tenants</div>
                                    <div className='f-size-26'>{details ? details.Total : '-'}</div>
                                </div>
                            </Card>                            
                        </div>
                        <div className='col-md-3 mb-3'>
                            <Card
                            onClick={() => routeChange(`/dashboards/monitoring/portal/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=healthy`)}
                            className={`card-border bg-success br-0 pt-1 p-0 card-action ${tenantState == 0 ? 'selected' : ''}`} fluid ghost elevated>
                            <div className='w-100 text-center text-white'>
                                <div className='f-size-15'>Healthy</div>
                                <div className='f-size-26'>{details ? details.Healthy : '-'}</div>
                            </div>
                            </Card>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <Card
                            onClick={() => routeChange(`/dashboards/monitoring/portal/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=warning`)}
                            className={`card-border bg-warning br-0 pt-1 p-0 card-action ${tenantState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                            <div className='w-100 text-center text-white'>
                                <div className='f-size-15'>Warning</div>
                                <div className='f-size-26'>{details ? details.Warning : '-'}</div>
                            </div>
                            </Card>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <Card 
                            onClick={() => routeChange(`/dashboards/monitoring/portal/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=critical`)}
                            className={`card-border bg-danger br-0 pt-1 p-0 card-action ${tenantState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                            <div className='w-100 text-center text-white'>
                                <div className='f-size-15'>Critical</div>
                                <div className='f-size-26'>{details ? details.Critical : '-'}</div>
                            </div>
                            </Card>
                        </div>
                    </div>
                    <CardBody>
                    <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                                <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                                defaultAppliedFilter={
                                  type
                                    ? [
                                        {filter: "Instance", operator: "==", value: id },
                                        {
                                          filter: "TenantType",
                                          operator: "==",
                                          value: type.split(",").map((i) => Number(i)),
                                        },
                                      ]
                                    : {filter: 'Instance', operator: '==', value: id}
                                }
                                elegibleFilters={['Instance', 'Platform', 'TenantType', 'TenantRange']} />
                                <Flex.Item push>
                                    <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                                    <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                                    </div>
                                </Flex.Item>
                            </Flex>
                            <div className='w-100'>
                            {
                                isLoaded ?
                                (
                                    <KendoGrid
                                    style={{
                                    height: "456px",
                                    }}
                                    // pageable={true}
                                    pageable={{
                                      buttonCount: 3
                                    }}
                                    sortable={true}
                                    data={process(detailTable.filter(tr => {
                                        if(tenantState == -1){return true}
                                        else if(tenantState != -1 && tr.Health == tenantState){return true}
                                        return false;
                                    }), dataState)}
                                    {...dataState}
                                    onDataStateChange={(e) => {
                                      setDataState(e.dataState);
                                    }}
                                    // detail={
                                    // context.mediaCategory.sm ? DetailComponentSm : null
                                    // }
                                    expandField="expanded"
                                    onExpandChange={expandChange}
                                    >
                                        <Column field="InstanceName"  title={<CellColTitle title="Instance Name" />} cell={CellInstanceOverviewLabel} locked={true} width={160}/>
                                        <Column field="TenantId" title="Tenant ID" locked={true} width={120}/>
                                        <Column field="TenantName" title="Tenant Name" locked={true} width={150} cell={CellTenantName}/>
                                        <Column field="IndustryName" title="Industry" width={140} />
                                        <Column field="UsersCount" title="Size" width={80} />
                                        <Column field="domain" title="Domain" width={180} />
                                        <Column field="Product" title="Product" width={150}/>
                                        <Column field="CustomerName"  title={<CellColTitle title="Customer Name" />} width={150} cell={CellCustomerName}  />
                                        <Column field="Reseller" title="Reseller" width={150} />
                                        <Column field="CustomerTime"  title={<CellColTitle title="Customer Time" />} cell={CellDateTime} width={140}/>
                                        <Column field="LastCallInserted"  title={<CellColTitle title="Last Call Inserted" />} cell={CellDateTime} width={140}/>
                                        <Column field="LastRecordingInserted"  title={<CellColTitle title="Last Recording Inserted" />} cell={CellDateTime} width={140}/> 
                                        <Column field="StuckCalls"  title={<CellColTitle title="Stuck Calls (Data_CTI)" />} width={80}/> 
                                        <Column field="CallsRecievedExpected" cell={CellCallRecievedExpected} title={<CellColTitle title="Recieved/Expected"  />} width={170}/> 
                                        <Column field="Health"  title={<CellColTitle title="Health" />} cell={CellHealthStatus} width={120}/>
                                        <Column field="TenantId"  title={<CellColTitle title="Open Incidents" />} cell={CellOpenIncidentsCol} width={120} filterable={false} sortable={false} />
                                        <Column field="TenantId"  title={<CellColTitle title="Portal Graph" />} cell={CellPortalGraph} width={120} filterable={false} sortable={false} /> 
                                    </KendoGrid>
                                )
                                :
                                <Loader />
                            }
                            </div>  
                    </CardBody>
                </Card>
            </div>
        </div>
    </div>)}
    </WindowContext.Consumer>
    )

};

export { PortalDetail }

