import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, FormDropdown, FormCheckbox,
	Tooltip, InfoIcon, Datepicker, Dropdown, SplitButton,
	navigableListBehavior
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon, ChevronStartIcon, ChevronEndIcon, ExcelColorIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../../shared/contexts/Context";
import MonitoringService from "../../../services/monitoringservice";
import { process } from "@progress/kendo-data-query";
import { CellDateTime, CellDateTimeSheet, CellHoursTimeSheet, CellIsPS } from "../../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from '@progress/kendo-intl';
import { retry } from "@progress/kendo-react-upload/dist/npm/messages";
import { connectorIcon } from "@progress/kendo-svg-icons";
// import { Loader as LoaderKendo } from "@progress/kendo-react-indicators";

const CUSTOMERPRIORITYLEVELS = [
	{ key: 1, header: 'true', value: true },
	{ key: 0, header: 'false', value: false }
]
const Timesheet = (props) => {
	const [timeSheets, settimeSheets] = React.useState(null);
	const [timeSheetsDbDaily, settimeSheetsDbDaily] = React.useState(null);
	const [dailyDataUser, setdailyDataUser] = React.useState(null);

	const [isLoaded, SetLoaded] = React.useState(false);
	const [isLoadedDaily, SetisLoadedDaily] = React.useState(true);
	const [isLoadedWeekly, SetisLoadedWeekly] = React.useState(true);
	const [visible, setVisible] = React.useState(false);
	const [visibleDaily, setvisibleDaily] = React.useState(false);
	const [visibleWeekly, setvisibleWeekly] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);
	const [projects, setProjects] = React.useState(null);

	const [timesheetData, settimesheetData] = React.useState(null);
	const [isHoursValid, SetisHoursValid] = React.useState(true);
	const [isWorkTypeValid, SetisWorkTypeValid] = React.useState(true);
	const [isProjectValid, SetisProjectValid] = React.useState(true);
	const [isVersionValid, SetisVersionValid] = React.useState(true);
	const [versions, setVersions] = React.useState(null); // State to hold version options
	const [filterByRadioValue, setfilterByRadioValue] = React.useState('1');

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [selectedFilter, setselectedFilter] = React.useState(null);
	const [selectedEmployee, setselectedEmployee] = React.useState(null);
	const [selectedStatus, setselectedStatus] = React.useState(null);
	const [filterByValue, setFilterByValue] = React.useState('');
	const filterByItems = ['Date', 'Work Type', 'Project'];
	const [showDateFilter, setShowDateFilter] = useState(true);
	const [showWorkTypeFilter, setshowWorkTypeFilter] = useState(false);
	const [workTypes, setWorkTypes] = React.useState(null);
	const [SelectedWorkType, setSelectedWorkType] = React.useState(null);
	const [showProjectFilter, setshowProjectFilter] = useState(false);
	const [showEmployeeFilter, setshowEmployeeFilter] = useState(false);
	const [showStatusFilter, setshowStatusFilter] = useState(false);
	const [TokenNames, setTokenNames] = React.useState(null);
	const [selectedProject, setselectedProject] = React.useState(null);
	const [filterError, setFilterError] = React.useState(false);
	const [filterActive, setfilterActive] = React.useState(false);
	const tokenSuffix = "***************";
	const [isActive, setActive] = useState("false");
	const [logHoursError, setlogHoursError] = useState(false);
	const [missing, setMissing] = useState(false);
	const [restrictDates, setrestrictDates] = useState([]);
	const [loggedinUserEmail, setloggedinUserEmail] = useState('');
	const [submitText, setsubmitText] = useState('Submit');
	const [filterVisible, setfilterVisible] = useState(false);
	const [submitEnabled, setsubmitEnabled] = useState(true);
	const [dailyData, setdailyData] = React.useState(null);
	const [weeklyData, setweeklyData] = React.useState(null);
	const [showDailyList, setshowDailyList] = useState(false);

	const timsheetStausItems = ['Approved', 'Not Approved'];
	const [updateId, setUpdateId] = React.useState("123");
	const [timesheetDailyDate, settimesheetDailyDate] = useState(new Date());
	const [errorText, seterrorText] = useState("");
	const [submitEnabledDaily, setsubmitEnabledDaily] = useState(true);
	const [submitEnabledWeekly, setsubmitEnabledWeekly] = useState(true);
	const [employees, setemployees] = React.useState(null);
	const [weeklyDisplayDt, setweeklyDisplayDt] = useState('');
	const [weekDt, setweekDt] = useState(new Date());
	const [addEnabledDaily, setaddEnabledDaily] = useState(true);
	const [downloadingHistory, setdownloadingHistory] = React.useState(false);

	const [hoursState, sethoursState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	const [projectState, setprojectState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)
	const [projectIdState, setprojectIdState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	const [versionState, setversionState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	const [jiraIdsState, setjiraIdsState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	const [notesState, setnotesState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	const [isPsState, setisPsState] = React.useReducer(
		(state, action) => {
			return { ...state, ...action }
		},
		{},
	)

	// const employees = ['Pulkit Agrawal', 'Gaurav Bhatia', 'Vani Aggarwal', 'Gajendra Kumar', 'Zoltan Vida' ];

	const weelyDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };




	const versionRegex = /^(\d+|x|X)\.(\d+|x|X)\.(\d+|x|X)\.(\d+|x|X)(\s*-\s*[a-zA-Z0-9\s]+)?$/;

	let hoursItems = [];

	const steps = {
		hour: 1,
	};
	const defaultHours = new Date(2000, 2, 10, 1, 0);

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "alertId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	for (let i = 0; i < 9; i++) {
		hoursItems.push(i.toString())
	}

	let rdates = [];
	let d = new Date();
	d.setMonth(0);
	d.setDate(1);
	for (let i = 1; i < 365; i++) {
		d.setDate(d.getDate() + 1);
		if (d.getDay() == 0 || d.getDay() == 6) {
			rdates.push(new Date(d));
		}
	}

	function isNumeric(str) {
		if (typeof str != "string") return false
		return !isNaN(str) &&
			!isNaN(parseFloat(str))
	}


	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);

	}, []);

	const authSuccessCallback = (token) => {

		let weeklyDta = [];
		let param = {};
		let curr = new Date();
		let first = curr.getDate() - curr.getDay() + 1;
		let weekFirstDate = new Date();
		weekFirstDate.setDate(first);
		param.WeekStartDate = weekFirstDate;
		let index = 0;
		let weekDb = null;

		if (token) {
			MonitoringService.GetTimeSheets(token, param).then((response) => {
				if (response && response.status == 200) {
					response = response.data;
					settimeSheets(response.Timesheets);
					setMissing(response.MissingDays);
					setrestrictDates(rdates);
					// weekDb = response.WeekDb;

					// resetWeeklyData(weekFirstDate, weekDb);

					if (props.user?.Permissions?.some((p) => (p == "Dashboards.TimesheetReport.Read"))) {
						setfilterVisible(true);
					}

					MonitoringService.GetTimesheetsData(token).then(
						(response) => {
							if (response && response.status == 200) {
								response = response.data;

								setWorkTypes(response.WorkTypes);
								setdailyData(response.WorkTypes);
								// for (let i = 0; i < 5; i++) {
								// 	for (let j = 0; j < response?.WorkTypes.length; j++) {
								// 		let t = new Date(weekFirstDate.getTime());
								// 		let day = t.getDate() + i;
								// 		t.setDate(day);
								// 		let obj = structuredClone(response?.WorkTypes[j]);
								// 		obj.Date = t;
								// 		obj.Index = index;
								// 		weeklyDta.push(obj)
								// 		index++;
								// 	}
								// }


								let curr = new Date();
								setweekDt(curr);
								let first = curr.getDate() - curr.getDay() + 1;
								let last = first + 4;

								let l = new Date(curr.getTime());
								let r = new Date(curr.getTime());

								l.setDate(first);
								r.setDate(last);

								let ld = l.toLocaleDateString("en-US", weelyDateOptions);
								let rd = r.toLocaleDateString("en-US", weelyDateOptions);

								setweeklyDisplayDt(ld + '   -   ' + rd);


								setemployees(response.Employees?.map(e => e.DisplayName));
								setshowDailyList(true);
								setProjects(response.Projects);
								setloggedinUserEmail(response.loggedinUserEmail);

								if (response.WorkTypes) {
									setSelectedWorkType(response.WorkTypes[0].WorkTypeName);
								}

								if (response.Projects) {
									setselectedProject(response.Projects[0].ProjectName);
								}
							}
						}
					);
					MonitoringService.fetchVersions(token).then(
						(response) => {
							if (response && response.status == 200) {
								response = response.data;
								setVersions(response);
							}


						}
					)
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};



	// const getWeeklyData()

	const filterGridData = (value) => {
		let filter = {
			logic: "or",
			filters: [{ field: "EmployeeName", operator: "contains", value: value },
			{ field: "Date", operator: "contains", value: value },
			{ field: "Hours", operator: "contains", value: value },
			{ field: "WorkType", operator: "contains", value: value },
			{ field: "Project", operator: "contains", value: value },
			{ field: "JiraIds", operator: "contains", value: value },
			{ field: "Notes", operator: "contains", value: value }],

		};
		setDataState({ ...dataState, filter: filter });
	};

	const handleAddEditClickOpen = (dataItem, isEdit) => {
		SetisAlertIdValid(true);
		setVisible(true);
		SetisHoursValid(true);
		SetisWorkTypeValid(true);
		SetisProjectValid(true);
		SetisVersionValid(true);
		setlogHoursError(false);
		setsubmitText('Submit');
		setsubmitEnabled(true);

		if (isEdit === true) {
			// If editing, parse the existing date
			dataItem.Date = new Date(Date.parse(dataItem.Date));
			settimesheetData(dataItem);
			setEditMode(true);
			setIsAlertIdDisabled(true);

			if (loggedinUserEmail === dataItem.EmailId) {
				setsubmitText('Submit');
			} else {
				setsubmitText('Approve');
			}
		} else {
			// If `dataItem.Date` already exists, use it (e.g. for missing timesheets),
			// otherwise set today's date for new timesheet entries.
			settimesheetData({
				...dataItem,
				Date: dataItem.Date ? new Date(Date.parse(dataItem.Date)) : new Date() // Set date conditionally
			});
			setEditMode(false);  // Make sure to turn off edit mode
			setIsAlertIdDisabled(false);
		}
	};



	const handleAddEditClickClose = () => {
		setVisible(false);
		settimesheetData(null);
		setEditMode(false);
		setIsValid(false);
		setlogHoursError(false);
		setsubmitEnabled(true);
	};

	const ActionIconVersions = (props) => {
		return (
			<td style={{ textAlign: "center" }}>
				<Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} disabled={props.dataItem.IsLeaveDateSynced ? true : false} text onClick={() => handleAddEditClickOpen(props.dataItem, true)} />
				{/* <Button
					styles={{ height: "auto" }}
					iconOnly
					size="small"
					icon={<TrashCanIcon />}
					text
					onClick={() => {
						setDeleteAlertObj(props.dataItem);
						setDeleteConfirmationDialog(true);
					}}
				/> */}
			</td>
		);
	};

	const removeData = () => {
		if (deleteAlertObj != null) {
			props.acquireToken((token) => {
				if (token) {
					let _params = {
						RecordId: deleteAlertObj.RecordId,
					};
					MonitoringService.RemoveTimeSheet(_params, token).then(
						(response) => {
							if (response && response.status == 200) {
								response = response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}
							else if (response && response.status == 401) {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

							}
							else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						}
					);
				}
			});
		}
		setDeleteConfirmationDialog(false);
		setDeleteAlertObj(null);
	};

	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		let newdata = {
			Date: timesheetData.Date,

			DateSubmit: timesheetData.DateSubmit,

			Hours: timesheetData.Hours,

			IsEdit: timesheetData.IsEdit,

			Project: timesheetData.Project,

			ProjectId: timesheetData.ProjectId,

			Version: timesheetData.Version,

			WorkType: timesheetData.WorkType,

			WorkTypeId: timesheetData.WorkTypeId,

			JiraIds: timesheetData.JiraIds,

			Notes: timesheetData.Notes,

			IsPS: timesheetData.IsPS,

			RecordId: timesheetData.RecordId
		}
		let tsData = newdata;
		let date = new Date(tsData.Date);
		let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		tsData.DateSubmit = date_sub;
		tsData.IsEdit = editMode;
		// let hrs = tsData.Hours;
		// tsData.Hours =  hrs.getHours() + ':' + hrs.getMinutes();

		if (isValid == true) {
			props.acquireToken((token) => {
				if (token) {
					setsubmitEnabled(false);
					MonitoringService.CheckTimeSheet(tsData, token).then((resCheck) => {
						if (resCheck && resCheck.data == '0') {
							setlogHoursError(true);
							setsubmitEnabled(true);
							// props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}
						else if (resCheck && resCheck.data == '1') {
							if (editMode) {
								MonitoringService.EditTimeSheet(tsData, token).then((response) => {
									if (response && response.data == '-1') {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									else if (response && response.status == 200) {
										response = response.data;
										props.acquireToken(authSuccessCallback);
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
									} else if (response && response.status == 401) {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
									}
									else {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									setsubmitEnabled(true);
								});
							} else {
								MonitoringService.AddTimeSheet(tsData, token).then((response) => {
									if (response && response.data == '-1') {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									if (response && response.status == 200) {
										response = response.data;
										props.acquireToken(authSuccessCallback);
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
									} else if (response && response.status == 401) {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
									}
									else {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									setsubmitEnabled(true);
								});
							}
							setVisible(false);
							setEditMode(false);
							setIsValid(false);
							setlogHoursError(false);
						}
					});
				}
			});
		}

	};


	const addApproveClickHandler = () => {

		let tsData = timesheetData;
		timesheetData.Approved = 'Approved';
		tsData.ApprovedSubmit = true;
		props.acquireToken((token) => {
			if (token) {
				MonitoringService.UpdateTimesheetApprove(tsData, token).then((response) => {
					if (response && response.data == '-1') {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
					else if (response && response.status == 200) {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
					} else if (response && response.status == 401) {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					}
					else {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
					setVisible(false);
					setEditMode(false);
					setIsValid(false);
					setlogHoursError(false);
				});
			}
		});
	};

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>
					<Text weight="bold" content="Cancel ID:" />
					<Text content={dataItem.cancelID} />
					<Text weight="bold" content="Service Name:" />
					<Text content={dataItem.serviceName} />
					<Text weight="bold" content="Details:" />
					<Text content={dataItem.details} />
					<Text weight="bold" content="Steps:" />
					<Text content={dataItem.Steps} />
					<Text weight="bold" content="Tenanted Alert:" />
					{
						props1.dataItem.isTenanted ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}
					<Text weight="bold" content="Call Data Alert:" />
					{
						props1.dataItem.isCallDataAlert ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}

				</FluentGrid>
			</section>
		);
	};

	const CheckForm = () => {
		let isValid = true;
		let isValidHours = true;
		let isValidWorkType = true;
		let isValidProject = true;
		let isVersionValid = true;


		if (timesheetData?.Hours && timesheetData?.Hours.toString().length > 0) {
			isValidHours = true;
		}
		else {
			isValidHours = false;
			SetisHoursValid(false);
		}

		if (timesheetData?.Project && timesheetData?.Project.toString().length > 0) {
			isValidProject = true;
		}
		else {
			isValidProject = false;
			SetisProjectValid(false);
		}

		if (timesheetData?.WorkType && timesheetData?.WorkType.toString().length > 0) {
			isValidWorkType = true;
		}
		else {
			isValidWorkType = false;
			SetisWorkTypeValid(false);
		}

		if ((timesheetData?.Hours == '0' || timesheetData.ProjectId == 9 || timesheetData.ProjectId == 11) && (timesheetData.Version == undefined || timesheetData.Version.length == 0)) {
			// blank is ok , if version is provided it should be in correct format
			isVersionValid = true;
		}
		else {
			if (timesheetData?.Version && versionRegex.test(timesheetData?.Version.trim()) == true) {
				isVersionValid = true;
			}
			else {
				isVersionValid = false;
				SetisVersionValid(false);
			}
		}



		// Check the Version field based on the Project and Hours
		if ((timesheetData.Project === "R&D Management" || timesheetData.Project === "Delivery" || timesheetData.Hours === "0") &&
			(!timesheetData.Version || timesheetData.Version.length === 0)) {
			// Version is not mandatory in these cases, skip validation
			isVersionValid = true;
		} else if (timesheetData?.Version && versionRegex.test(timesheetData.Version.trim())) {
			isVersionValid = true;
		} else {
			isVersionValid = false;
			SetisVersionValid(false); // Show the error
		}

		if (isValidHours && isValidProject && isValidWorkType && isVersionValid) {
			isValid = true;
		}
		else {
			isValid = false;
		}



		return isValid;

	};

	const expandChange = (event) => {
		let newData = timeSheets.map((item) => {
			if (item.RecordId === event.dataItem.RecordId) {
				item.expanded = !event.dataItem.expanded;
			}

			return item;
		});
		settimeSheets(newData);
	};

	const handleWorkTypeChange = (alwaysNull, data) => {

		if (data.value.header && data.value.header.length > 0) {
			SetisWorkTypeValid(true);
		}
		else {
			SetisWorkTypeValid(false);
		}

		settimesheetData({
			...timesheetData,
			WorkType: data.value.header,
			WorkTypeId: data.value.value,
		})
	};

	const handleProjectChange = (alwaysNull, data) => {

		if ((data.value.value == 9 || data.value.value == 11 || timesheetData.Hours == '0') && (timesheetData.Version == undefined || timesheetData.Version.length == 0)) {
			SetisVersionValid(true);

			if (timesheetData.Version == undefined || timesheetData.Version.length == 0) {
				timesheetData.Version = 'x.x.x.x';
			}
		}


		if (data.value.header && data.value.header.length > 0) {
			SetisProjectValid(true);
		}
		else {
			SetisProjectValid(false);
		}

		settimesheetData({
			...timesheetData,
			Project: data.value.header,
			ProjectId: data.value.value,
		})
	};

	const handleVersionChange = (alwaysNull, data) => {


		if (data.value.header && data.value.header.length > 0) {
			SetisVersionValid(true);
		}
		else {
			SetisVersionValid(false);
		}

		settimesheetData({
			...timesheetData,
			Version: data.value.header,
		})
	};

	const handleHoursChange = (alwaysNull, data) => {

		// debugger
		// console.log(timesheetData.Version);
		// console.log(timesheetData.Version?.length);
		// if(timesheetData.Version == undefined){
		// 	console.log('und')
		// }

		// if(timesheetData.Version == null){
		// 	console.log('nll')
		// }

		// if(timesheetData.Version?.length == 0){
		// 	console.log('ln0')
		// }

		if ((data.value.header == '0' || timesheetData.ProjectId == 9) && (timesheetData.Version == undefined || timesheetData.Version.length == 0)) {
			SetisVersionValid(true);

			if (timesheetData.Version == undefined || timesheetData.Version.length == 0) {
				timesheetData.Version = 'x.x.x.x';
			}
		}

		if (data.value.header && data.value.header.length > 0) {
			SetisHoursValid(true);
		}
		else {
			SetisHoursValid(false);
		}

		settimesheetData({
			...timesheetData,
			Hours: data.value.header,
		})
	};

	const handleFilterDdlChange = (_selectedType) => {

		setFilterError(false);
		if (filterByRadioValue == '1') {
			setShowDateFilter(false);
			setshowWorkTypeFilter(false);
			setshowProjectFilter(false);
			setshowEmployeeFilter(false);
			setshowStatusFilter(false);

			if (_selectedType == 'Date') {
				setShowDateFilter(true);
			}
			else if (_selectedType == 'Work Type') {
				setshowWorkTypeFilter(true);
			}
			else if (_selectedType == 'Project') {
				setshowProjectFilter(true);
			}
			else if (_selectedType == 'Employee') {
				setshowEmployeeFilter(true);
			}
			else if (_selectedType == 'Timesheet Status') {
				setshowStatusFilter(true);
			}
		}
		setselectedFilter(_selectedType);
		setFilterByValue('');
	}

	const handleEmployeeChange = (_selectedType) => {
		setFilterError(false);
		setselectedEmployee(_selectedType);
	}

	const handleStatusChange = (_selectedType) => {
		setFilterError(false);
		setselectedStatus(_selectedType);
	}

	const handleEndDateChange = (event) => {
		setSelectedEndDate(event.value);
	};

	const handleStartDateChange = (event) => {
		setSelectedStartDate(event.value);
	};

	const handleFilterByTextChange = (e, data) => {
		setFilterByValue(data.value);
	}

	const handleWorkTypeChangeFilter = (alwaysNull, data) => {
		setSelectedWorkType(data.value);
	};

	const handleTokenNameChange = (alwaysNull, data) => {
		setselectedProject(data.value);
	};

	const applyFilter = () => {
		// if (filterByRadioValue == "1" && selectedFilter === null) {
		// 	setFilterError(true);
		// 	return;
		// }

		if (selectedFilter === null && selectedEmployee === null && selectedStatus === null) {
			setFilterError(true);
			return;
		}

		SetLoaded(false);
		setActive("false");
		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() + 1) + '/' + sd.getDate() + ' ' + sd.getHours() + ':' + sd.getMinutes();
		let ed = new Date(selectedEndDate);
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() + 1) + '/' + ed.getDate() + ' ' + ed.getHours() + ':' + ed.getMinutes();
		let filterByValueData = filterByValue;

		let filterByField = 'filter';
		switch (selectedFilter) {
			case 'Date': filterByField = 'date'; break;
			case 'Work Type': filterByField = 'workType'; filterByValueData = SelectedWorkType; break;
			case 'Project': filterByField = 'project'; filterByValueData = selectedProject; break;
			// case 'Employee': filterByField = 'employee'; filterByValueData = selectedEmployee; break;
			// case 'Timesheet Status': filterByField = 'status'; filterByValueData = selectedStatus; break;
		}
		props.acquireToken((token) => {
			if (token) {
				let _params = {
					filterByRadioValue: filterByRadioValue,
					filterByField: filterByField,
					filterByValue: filterByValueData,
					startDate: startDate,
					endDate: endDate,
					employeeName: selectedEmployee,
					status: selectedStatus
				};
				MonitoringService.GetTimeSheets(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						console.log(response.data);
						settimeSheets(response.Timesheets);
						setMissing(response.MissingDays);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
					setfilterActive(true);
				});
			}
		});
	}

	const reset = () => {
		setselectedFilter(null);
		setselectedEmployee(null);
		setselectedStatus(null);
		setShowDateFilter(true);
		SetLoaded(false);
		setActive("false");
		// setfilterKey(filterKey + 1);
		props.acquireToken((token) => {
			if (token) {
				let _params = {};
				MonitoringService.GetTimeSheets(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						settimeSheets(response.Timesheets);
						setMissing(response.MissingDays);
						if (workTypes && workTypes.length > 0) {
							setSelectedWorkType(workTypes[0].WorkTypeName);
						}

						if (projects && projects.length > 0) {
							setselectedProject(projects[0].ProjectName);
						}

					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
					setfilterActive(false);
					setActive("false");
					setUpdateId(Math.random().toString());
					// setselectedFilter('Date');
				});
			}
		});
	}


	const ToggleClass = () => {
		setActive(!isActive);
	};

	const handleAddDailyClickClose = () => {
		setvisibleDaily(false);
		setIsValid(false);
		seterrorText('');
		setsubmitEnabledDaily(true);
	};

	const handleAddWeeklyClickClose = () => {
		setvisibleWeekly(false);
		setIsValid(false);
		seterrorText('');
		setsubmitEnabledWeekly(true);
	};

	const handleWeeklyPrev = (isprev) => {

		seterrorText('');
		// weeklyData = null;
		let index = 0;
		let currDay = weekDt.getDate();
		let day = 0;
		if (isprev === true) {
			day = currDay - 7;
		}
		else {
			day = currDay + 7;
		}

		let temp = new Date(weekDt.getTime());
		// n.setMonth(weekDt.getMonth());
		// n.setFullYear(weekDt.getFullYear());
		temp.setDate(day);
		// n.setDate(day);
		setweekDt(temp);

		let l = new Date(temp.getTime());
		let r = new Date(temp.getTime());

		let first = l.getDate() - l.getDay() + 1;
		let last = first + 4;

		l.setDate(first);
		r.setDate(last);

		let ld = l.toLocaleDateString("en-US", weelyDateOptions);
		let rd = r.toLocaleDateString("en-US", weelyDateOptions);

		setweeklyDisplayDt(ld + '   -   ' + rd);

		weeklyDateData(l);

		// let weeklyDta = [];
		// // weeklyData = [];

		// // let weekFirstDate =  new Date(l.getTime());


		// for (let i = 0; i < 5; i++) {
		// 	let t = new Date(l.getTime());
		// 	let day = t.getDate() + i;
		// 	t.setDate(day);
		// 	let obj = {};
		// 	obj.Date = t;
		// 	obj.Index = index;
		// 	obj.Hours = '';
		// 	obj.Project = '';
		// 	obj.ProjectName = '';
		// 	obj.ProjectId = '';
		// 	obj.Version = '';
		// 	obj.Notes = '';
		// 	obj.JiraIds = '';
		// 	obj.HoursClass = '';
		// 	obj.ProjectClass = '';
		// 	obj.VersionClass = '';
		// 	obj.WorkTypeName = '';
		// 	obj.WorkTypeId = '';

		// 	weeklyDta.push(obj)
		// 	index++;
		// }

		// setweeklyData(weeklyDta);


		// for(let i = 0; i < weeklyData.length; i++){

		// 		let t = new Date(l.getTime());
		// 		let day =  t.getDate() + Math.floor(i / 2);
		// 		t.setDate(day);
		// 		let obj = structuredClone(weeklyData[i]);
		// 		obj.Date = t;
		// 		weeklyDta.push(obj)
		// }




	}

	// const handleDailyDateChange = (e, data) => {

	// 	dailyDateData(data.value);
	// 	setdailyDataUser(null);
	// 	seterrorText('');
	// 	setsubmitEnabledDaily(true);
	// }

	const weeklyDateData = (date) => {
		let addrow = false;
		let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		let _params = {
			date: date_sub
		};
		let totalHours = 0;

		// setaddEnabledDaily(true);

		props.acquireToken((token) => {
			if (token) {
				SetisLoadedDaily(false);
				MonitoringService.GetTimesheetsDataWeekly(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						// settimeSheetsDbDaily(response.Timesheets);

						// if (response.WeekDb && response.WeekDb.length > 0) {

						resetWeeklyData(date, response.WeekDb)
						// addrow = false;


						// else {
						// 	addrow = true;
						// }

						// if (addrow === true) {
						// 	let row = {
						// 		index: 0
						// 	}
						// 	let dt = [];
						// 	dt.push(row);
						// 	setdailyDataUser(dt);
						// }
						// else {
						// 	setdailyDataUser(null);
						// }

					}
					SetisLoadedDaily(true);

				});

			}
		});
		// settimesheetDailyDate(date);
	}

	const resetWeeklyData = (date, weeklyDb) => {
		let weeklyDta = [];
		let obj = {};
		let n = {};

		for (let i = 0; i < 5; i++) {
			let t = new Date(date.getTime());
			let day = t.getDate() + i;
			let totalHoursDay = 0;
			t.setDate(day);

			obj = {};
			obj.TableDate = t;
			obj.db = [];
			obj.userData = [];
			obj.Index = i;
			obj.addDisabled = false;

			if (weeklyDb && weeklyDb.length > 0) {
				weeklyDb.forEach(function (it, j) {
					let dt = new Date(it.Date);
					if (dt.getDate() === t.getDate() && dt.getMonth() === t.getMonth() && dt.getYear() === t.getYear()) {
						n = structuredClone(it);
						obj.db.push(n);
					}
				});
			}

			obj.db.forEach(function (it, j) {
				totalHoursDay += +it.Hours;
			});

			if (totalHoursDay >= 8) {
				obj.addDisabled = true;
			}

			weeklyDta.push(obj);

		}
		setweeklyData(weeklyDta);
	}

	const addBtnClick = (clkDt) => {
		let n = {};
		let weeklyDta = [];
		let obj = {};
		let added = false;

		weeklyData.forEach(function (it, j) {
			//debugger;
			obj = structuredClone(it);
			weeklyDta.push(obj);
			let dt = it.TableDate;
			if (dt.getDate() === clkDt.getDate() && dt.getMonth() === clkDt.getMonth() && dt.getYear() === clkDt.getYear()) {
				if ((obj.userData.length + obj.db.length) < 8) {
					n = {};
					n.Date = obj.TableDate;
					n.Hours = '';
					n.Project = '';
					n.ProjectName = '';
					n.ProjectId = '';
					n.Version = '';
					n.Notes = '';
					n.JiraIds = '';
					n.HoursClass = '';
					n.ProjectClass = '';
					n.VersionClass = '';
					n.WorkTypeName = '';
					n.WorkTypeId = '';
					n.Index = obj.userData.length;
					obj.userData.push(n)
				}
			}
		});
		//debugger;
		setweeklyData(weeklyDta);
	}

	const handleMenuClickOpen = (e, p) => {

		if (p.index === 1) {
			setvisibleWeekly(true);
			seterrorText('');
			setsubmitEnabledWeekly(true);

			let index = 0;
			let curr = new Date();
			setweekDt(curr);
			let first = curr.getDate() - curr.getDay() + 1;
			let last = first + 4;

			let l = new Date(curr.getTime());
			let r = new Date(curr.getTime());
			// n.setMonth(weekDt.getMonth());
			// n.setFullYear(weekDt.getFullYear());
			l.setDate(first);
			r.setDate(last);

			let ld = l.toLocaleDateString("en-US", weelyDateOptions);
			let rd = r.toLocaleDateString("en-US", weelyDateOptions);

			setweeklyDisplayDt(ld + '   -   ' + rd);

			weeklyDateData(l);

			// let weeklyDta = [];

			// // let weekFirstDate =  new Date(l.getTime());


			// for (let i = 0; i < 5; i++) {

			// 	let t = new Date(l.getTime());
			// 	let day = t.getDate() + i;
			// 	t.setDate(day);
			// 	let obj = {};
			// 	obj.Date = t;
			// 	obj.Index = index;

			// 	obj.Hours = '';
			// 	obj.Project = '';
			// 	obj.ProjectName = '';
			// 	obj.ProjectId = '';
			// 	obj.Version = '';
			// 	obj.Notes = '';
			// 	obj.JiraIds = '';
			// 	obj.HoursClass = '';
			// 	obj.ProjectClass = '';
			// 	obj.VersionClass = '';
			// 	obj.WorkTypeName = '';
			// 	obj.WorkTypeId = '';

			// 	weeklyDta.push(obj);
			// 	index++;

			// }



			// // for(let i = 0; i < weeklyData.length; i++){
			// // 		let t = new Date(l.getTime());
			// // 		let day =  t.getDate() + Math.floor(i / 2);
			// // 		t.setDate(day);
			// // 		let obj = structuredClone(weeklyData[i]);
			// // 		obj.Date = t;
			// // 		weeklyDta.push(obj)
			// // }

			// setweeklyData(weeklyDta);


		}
		else {
			let addrow = false;
			setvisibleDaily(true);
			seterrorText('');
			setsubmitEnabledDaily(true);
			dailyDateData(new Date());
			settimesheetDailyDate(new Date())



			// for (let i = 0; i < dailyData.length; i++) {
			// 	let k = dailyData[i].WorkTypeId;
			// 	sethoursState({
			// 		[k]: null,
			// 	});
			// 	setprojectState({
			// 		[k]: null,
			// 	});
			// 	setprojectIdState({
			// 		[k]: null,
			// 	});
			// 	setversionState({
			// 		[k]: null,
			// 	});
			// 	setjiraIdsState({
			// 		[k]: null,
			// 	});
			// 	setnotesState({
			// 		[k]: null,
			// 	});
			// 	setisPsState({
			// 		[k]: null,
			// 	});
			// }
		}

		// SetisHoursValid(true);
		// SetisWorkTypeValid(true);
		// SetisProjectValid(true);
		// SetisVersionValid(true);
		// setlogHoursError(false);

		// dailyData.forEach(function (ts) {
		// 	ts.Hours = null;
		// });

		// setdailyData(workTypes);
		// dt.Hours = defaultHours;
		// for(let i = 0 ; i < dailyData.length; i++){
		// 	let dt = dailyData.find(x => x.WorkTypeId == dailyData[i].WorkTypeId);
		// 	delete dt.Hours ;
		// 	delete dt.Project ;
		// 	delete dt.Version ;
		// 	delete dt.JiraIds ;
		// 	delete dt.Notes ;

		// }

		// setdailyData(dailyData);

		// let dt = dailyData.find(x => x.WorkTypeId === 1);
		// delete dt.Hours ;
		// setdailyData(dailyData);

		// dailyData.find(x => x.WorkTypeId == 2).Hours = null;
		// setdailyData(dailyData);

		// dailyData.find(x => x.WorkTypeId == 1).Hours = null;
		// setdailyData(dailyData);


	};

	const handleWeeklyWorkTypeChange = (data, mainIndex) => {

		let index = data.value.index;
		let weeklyDta = [];

		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].WorkType = data.value.header;
					obj.userData[j].WorkTypeId = data.value.value;
					if (data.value.header && data.value.header.length > 0) {
						obj.userData[j].WorkTypeError = false;
					}
				}
			}

			weeklyDta.push(obj)
		}

		setweeklyData(weeklyDta);
	};

	const handleWeeklyHoursChange = (data, mainIndex) => {

		let index = data.value.index;

		let weeklyDta = [];
		// let weekFirstDate =  new Date(l.getTime());

		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].Hours = data.value.header;
					if (data.value.header && data.value.header >= 0) {
						obj.userData[j].HoursError = false;
					}
				}
			}

			weeklyDta.push(obj)
		}

		setweeklyData(weeklyDta);
	};

	const handleWeeklyProjectChange = (data, mainIndex) => {

		let index = data.value.index;

		let weeklyDta = [];
		// let weekFirstDate =  new Date(l.getTime());
		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].Project = data.value.header;
					obj.userData[j].ProjectId = data.value.value;
					if (data.value.header && data.value.header.length > 0) {
						obj.userData[j].ProjectError = false;
					}
				}
			}

			weeklyDta.push(obj)
		}

		setweeklyData(weeklyDta);
	};

	const handleWeeklyVersionChange = (versionValue, mainIndex, userIndex) => {
		setweeklyData(prevWeeklyData =>
			prevWeeklyData.map((item) => {
				if (item.Index === mainIndex) {
					let updatedUserData = item.userData.map((userItem) => {
						if (userItem.Index === userIndex) {
							return {
								...userItem,
								Version: versionValue,  // Update the version with selected value
								VersionError: versionValue.value && versionValue.value.length > 0 ? false : true
							};
						}
						return userItem;
					});
					return { ...item, userData: updatedUserData };
				}
				return item;
			})
		);
	};




	const handleWeeklyJiraIdsChange = (data, mainIndex, i) => {
		let index = i;
		let weeklyDta = [];


		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].JiraIds = data.target.value;
				}
			}
			weeklyDta.push(obj)
		}


		setweeklyData(weeklyDta);
	};

	const handleWeeklyNotesChange = (data, mainIndex, i) => {
		let index = i;
		let weeklyDta = [];

		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].Notes = data.target.value;
				}
			}
			weeklyDta.push(obj)
		}

		setweeklyData(weeklyDta);
	};

	const handleWeeklyIsPSChange = (e, isChecked, mainIndex, i) => {
		let index = i;
		let weeklyDta = [];


		for (let i = 0; i < weeklyData.length; i++) {
			let obj = structuredClone(weeklyData[i]);

			for (let j = 0; j < obj.userData.length; j++) {
				if (obj.userData[j].Index == index && obj.Index == mainIndex) {
					obj.userData[j].IsPS = isChecked.checked;
				}
			}
			weeklyDta.push(obj)
		}

		setweeklyData(weeklyDta);

	};


	const addEditSubmitWeeklyClickHandler = () => {
		let tsData = [];

		let isValid = CheckFormWeekly(tsData);

		let date = new Date(timesheetDailyDate);
		let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		tsData.forEach(function (ts) {
			ts.DateSubmit = ts.Date.getFullYear() + '/' + (ts.Date.getMonth() + 1) + '/' + ts.Date.getDate();
		});
		// tsData.DateSubmit = date_sub;
		// tsData.IsEdit = editMode;
		// let hrs = tsData.Hours;
		// tsData.Hours =  hrs.getHours() + ':' + hrs.getMinutes();

		if (isValid == true) {
			props.acquireToken((token) => {
				if (token) {
					setsubmitEnabledDaily(false);
					MonitoringService.CheckTimeSheetWeekly(tsData, token).then((resCheck) => {
						if (resCheck && resCheck.data == '0') {
							seterrorText('You cannot log more than 8 hrs in one day');
							setsubmitEnabledDaily(true);
							// props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}
						else if (resCheck && resCheck.data == '1') {
							MonitoringService.AddTimeSheetWeekly(tsData, token).then((response) => {
								if (response && response.data == '-1') {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
								}
								if (response && response.status == 200) {
									response = response.data;
									props.acquireToken(authSuccessCallback);
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
								} else if (response && response.status == 401) {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
								}
								else {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
								}
								setsubmitEnabledDaily(true);
							});
							setvisibleWeekly(false);
							setIsValid(false);
							seterrorText('');
						}
					});
				}
			});
		}

	};

	const CheckFormWeekly = (wkDt) => {
		let isValid = true; // Assume form is valid
		let errorMessage = '';
		let userRowsCountAllDates = 0; // Initialize user rows count

		// Debugging: Log the weekly data before validation

		// Loop through weeklyData
		weeklyData.forEach((week, weekIndex) => {
			let dbHours = 0;

			// Calculate hours from the database for this week
			if (week.db && week.db.length > 0) {
				week.db.forEach((it) => {
					dbHours += +it.Hours;
				});
			}

			// Validate each entry in userData
			week.userData.forEach((entry, entryIndex) => {
				let isValidHours = false;
				let isValidWorkType = false;
				let isValidProject = false;
				let isVersionValid = false;

				// Extract the `value` from the Version object, or default to empty
				let version = entry.Version?.value || '';

				// Skip validation if the row is completely empty
				if (!entry.WorkType && !entry.Hours && !entry.Project && !version) {
					return; // Skip this entry since it's empty
				}

				// Validate WorkType
				if (entry.WorkType && entry.WorkType.length > 0) {
					isValidWorkType = true;
					entry.WorkTypeError = false;
				} else {
					isValidWorkType = false;
					entry.WorkTypeError = true;
				}

				// Validate Hours
				if (entry.Hours && entry.Hours.toString().length > 0) {
					isValidHours = true;
					entry.HoursError = false;
				} else {
					isValidHours = false;
					entry.HoursError = true;
				}

				// Validate Project
				if (entry.Project && entry.Project.toString().length > 0) {
					isValidProject = true;
					entry.ProjectError = false;
				} else {
					isValidProject = false;
					entry.ProjectError = true;
				}

				// Validate Version from dropdown only if required (based on hours or project ID)
				entry.VersionError = false;
				if (entry.Hours === '0' || entry.ProjectId === 9 || entry.ProjectId === 11) {
					// Skip version validation for these cases
					isVersionValid = true;
				} else {
					// Check if the version `value` exists and is not empty
					if (version.length > 0) {
						isVersionValid = true;
					} else {
						isVersionValid = false;
						entry.VersionError = true;
						// Mark version as invalid if not selected
					}
				}

				// Log the results of validation for each field
				console.log(`Validation result for entry ${entryIndex}:`, {
					isValidWorkType,
					isValidHours,
					isValidProject,
					isVersionValid,
				});

				// Combine all validations for this row
				if (!isValidWorkType || !isValidHours || !isValidProject || !isVersionValid) {
					isValid = false;
					errorMessage = 'Please fill work type, hours, project, and version.';
					entry.VersionError = !isVersionValid; // Ensure version error is marked
					entry.WorkTypeError = !isValidWorkType;
					entry.HoursError = !isValidHours;
					entry.ProjectError = !isValidProject;

					// Log the invalid entry and continue to validate the rest

				} else {
					// If the row is valid, add it to `wkDt`
					let clonedEntry = structuredClone(entry);

					// Replace the Version object with just the value (string)
					clonedEntry.Version = version;  // Ensure only the string is sent

					wkDt.push(clonedEntry); // This is where tsData is populated
					userRowsCountAllDates++;
				}
			});
		});

		// Update the state with any validation errors
		setweeklyData(structuredClone(weeklyData));

		// If no rows are filled, set an error (only if all rows are empty)
		if (userRowsCountAllDates === 0) {
			errorMessage = 'Please fill work type, hours, project, and version.';
			seterrorText(errorMessage);
			isValid = false;
		}

		// Set the error message only at the end
		if (!isValid) {
			seterrorText(errorMessage);
		}

		return isValid;
	};











	const handleDailyDateChange = (e, data) => {

		dailyDateData(data.value);
		setdailyDataUser(null);
		seterrorText('');
		setsubmitEnabledDaily(true);
	}

	const dailyDateData = (date) => {
		let addrow = false;
		let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		let _params = {
			date: date_sub
		};
		let totalHours = 0;
		setaddEnabledDaily(true);
		props.acquireToken((token) => {
			if (token) {
				SetisLoadedDaily(false);
				MonitoringService.GetTimesheetsDataDaily(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						settimeSheetsDbDaily(response.Timesheets);

						if (response.Timesheets && response.Timesheets.length > 0) {
							addrow = false;
							response.Timesheets.forEach(function (item) {
								if (item.Hours && isNumeric(item.Hours)) {
									totalHours += +item.Hours;
								}
								if (totalHours >= 8) {
									setaddEnabledDaily(false);
								}
							});
						}
						else {
							addrow = true;
						}

						if (addrow === true) {
							let row = {
								index: 0
							}
							let dt = [];
							dt.push(row);
							setdailyDataUser(dt);
						}
						else {
							setdailyDataUser(null);
						}

					}
					SetisLoadedDaily(true);

				});

			}
		});
		settimesheetDailyDate(date);
	}

	const dailyAdd = () => {

		let dt = [];
		let dbCount = 0;
		let count = 0;
		if (dailyDataUser) {
			dt = structuredClone(dailyDataUser);
			count = dailyDataUser.length;
		}

		if (timeSheetsDbDaily && timeSheetsDbDaily.length > 0) {
			dbCount = timeSheetsDbDaily.length
		}

		if ((count + dbCount) < 8) {
			let row = {
				index: count
			};

			dt.push(row);
			setdailyDataUser(dt);
		}

	}

	const handleDailyWorkTypeChange = (alwaysNull, data) => {

		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === data.value.index).WorkType = data.value.header;
		dt.find(x => x.index === data.value.index).WorkTypeId = data.value.value;

		if (data.value.header && data.value.header.length > 0) {
			dt.find(x => x.index === data.value.index).WorkTypeError = false;
		}
		setdailyDataUser(dt)

	};

	const handleDailyHoursChange = (alwaysNull, data) => {

		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === data.value.index).Hours = data.value.value;

		if (data.value.value && data.value.value >= 0) {
			dt.find(x => x.index === data.value.index).HoursError = false;
		}

		setdailyDataUser(dt)

		// let k = data.value.workTypeId;

		// if (data.value.header == '0' && (versionState[k] == undefined || versionState[k]?.length == 0)) {
		// 	versionState[k] = 'x.x.x.x';
		// }

		// sethoursState({
		// 	[k]: data.value.header,
		// })

		// dailyData.find(x => x.WorkTypeId === data.value.workTypeId).Hours = data.value.header;
		// setdailyData(dailyData);
	};

	const handleDailyProjectChange = (cc, data) => {
		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === data.value.index).Project = data.value.header;
		dt.find(x => x.index === data.value.index).ProjectId = data.value.value;

		if (data.value.header && data.value.header.length > 0) {
			dt.find(x => x.index === data.value.index).ProjectError = false;
		}

		setdailyDataUser(dt)

		// let k = data.value.workTypeId;

		// if (data.value.value == 9 && (versionState[k] == undefined || versionState[k]?.length == 0)) {
		// 	versionState[k] = 'x.x.x.x';
		// }

		// setprojectState({
		// 	[k]: data.value.header,
		// })
		// setprojectIdState({
		// 	[k]: data.value.value,
		// })

		// dailyData.find(x => x.WorkTypeId === data.value.workTypeId).Project = data.value.header;
		// dailyData.find(x => x.WorkTypeId === data.value.workTypeId).ProjectId =  data.value.value;
		// setdailyData(dailyData);
	};

	const handleDailyVersionChange = (isnull, data, index) => {

		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === index).Version = data.value.value;

		// if(data.target.value && data.target.value.length > 0 && versionRegex.test(data.target.value.trim()) == true){
		// 	dt.find(x => x.index === data.value.index).VersionError = false;
		// }

		setdailyDataUser(dt)
	};


	const handleDailyJiraIdChange = (data, index) => {
		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === index).JiraIds = data.target.value;
		setdailyDataUser(dt)
	};

	const handleDailyNotesChange = (data, index) => {
		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === index).Notes = data.target.value;
		setdailyDataUser(dt)
	};

	const handleDailyPsChange = (event, isChecked, index) => {
		let dt = structuredClone(dailyDataUser);
		dt.find(x => x.index === index).IsPS = isChecked.checked;
		setdailyDataUser(dt)
	};

	const addEditSubmitDailyClickHandler = () => {

		let tsData = [];

		let isValid = CheckFormDaily(tsData);

		// let date = new Date(timesheetDailyDate);
		// let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		// tsData.forEach(function (ts) {
		// 	ts.DateSubmit = ts.Date.getFullYear() + '/' + (ts.Date.getMonth() + 1) + '/' + ts.Date.getDate();
		// });


		// let isValid = CheckFormDaily();

		// tsData.DateSubmit = date_sub;
		// tsData.IsEdit = editMode;
		// let hrs = tsData.Hours;
		// tsData.Hours =  hrs.getHours() + ':' + hrs.getMinutes();

		if (isValid == true) {

			// let tsData = dailyDataUser;
			let date = new Date(timesheetDailyDate);
			let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
			tsData.forEach(function (ts) {
				ts.DateSubmit = date_sub;
			});

			props.acquireToken((token) => {
				if (token) {
					setsubmitEnabledDaily(false);
					MonitoringService.CheckTimeSheetDaily(tsData, token).then((resCheck) => {
						if (resCheck && resCheck.data == '0') {
							seterrorText('You cannot log more than 8 hrs in one day.');
							setsubmitEnabledDaily(true);
							// props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}
						else if (resCheck && resCheck.data == '1') {
							MonitoringService.AddTimeSheetDaily(tsData, token).then((response) => {
								if (response && response.data == '-1') {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
								}
								if (response && response.status == 200) {
									response = response.data;
									props.acquireToken(authSuccessCallback);
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
								} else if (response && response.status == 401) {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
								}
								else {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
								}
								setsubmitEnabledDaily(true);
							});
							setvisibleDaily(false);
							setIsValid(false);
							seterrorText('');
						}
					});
				}
			});
		}

	};

	const CheckFormDaily = (dailyDt) => {
		let isValid = true; // Assume form is valid initially
		let errorMessage = '';
		let hasRows = false;
		let dbHours = 0;

		// Calculate total hours from the database
		if (timeSheetsDbDaily && timeSheetsDbDaily.length > 0) {
			timeSheetsDbDaily.forEach(function (it) {
				dbHours += +it.Hours;
			});
		}

		// Check if there are any rows to validate
		if (dailyDataUser && dailyDataUser.length > 0) {
			hasRows = true;
		} else {
			seterrorText('Please add new row.');
			return false;
		}

		let count = dailyDataUser.length;
		for (let i = 0; i < count; i++) {
			let isValidHours = false;
			let isValidWorkType = false;
			let isValidProject = false;
			let isVersionValid = false;
			let d = dailyDataUser[i];
			let version = d.Version?.trim() || '';

			// Validate WorkType
			if (d.WorkType && d.WorkType.length > 0) {
				isValidWorkType = true;
				d.WorkTypeError = false;
			} else {
				isValidWorkType = false;
				d.WorkTypeError = true;
			}

			// Validate Hours
			if (d.Hours && d.Hours.length > 0) {
				isValidHours = true;
				d.HoursError = false;
			} else {
				isValidHours = false;
				d.HoursError = true;
			}

			// Validate Project
			if (d.Project && d.Project.length > 0) {
				isValidProject = true;
				d.ProjectError = false;
			} else {
				isValidProject = false;
				d.ProjectError = true;
			}

			// Validate Version only if required (based on hours or project ID)
			d.VersionError = false;
			if (d.Hours === '0' || d.ProjectId === 9 || d.ProjectId === 11) {
				// Skip version validation for these cases
				isVersionValid = true;
			} else {
				if (version.length > 0 && versionRegex.test(version)) {
					isVersionValid = true;
				} else {
					isVersionValid = false;
					d.VersionError = true;

				}
			}

			// Combine all validation checks
			if (!isValidWorkType || !isValidHours || !isValidProject || !isVersionValid) {
				isValid = false;
				errorMessage = 'Please fill work type, hours, product, and version.';
				break;
			}

			// If the row is valid, add it to `dailyDt`
			let clone = structuredClone(d);
			dailyDt.push(clone);
		}

		// Update the state with any validation errors
		setdailyDataUser(structuredClone(dailyDataUser));

		// Set the error message if form is invalid
		if (!isValid) {
			seterrorText(errorMessage);
		}

		return isValid;
	};

	const downloadCsvHandler = () => {
		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() + 1) + '/' + sd.getDate() + ' ' + sd.getHours() + ':' + sd.getMinutes();

		let ed = new Date(selectedEndDate);
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() + 1) + '/' + ed.getDate() + ' ' + ed.getHours() + ':' + ed.getMinutes();

		let filterByValueData = filterByValue;
		let filterByField = 'filter';

		switch (selectedFilter) {
			case 'Date':
				filterByField = 'date';
				break;
			case 'Work Type':
				filterByField = 'workType';
				filterByValueData = SelectedWorkType;
				break;
			case 'Project':
				filterByField = 'project';
				filterByValueData = selectedProject;
				break;
		}

		props.acquireToken((token) => {
			if (token) {
				let _params = {
					filterByRadioValue: filterByRadioValue,
					filterByField: filterByField,
					filterByValue: filterByValueData,
					startDate: startDate,
					endDate: endDate,
					employeeName: selectedEmployee,
					status: selectedStatus,
					userEmail: loggedinUserEmail // Include user email if necessary
				};
				MonitoringService.DownloadTimsheetHistoryCsv(token, _params).then((response) => {
					if (response && response.status === 200) {
						const blob = new Blob([response.data], { type: 'text/csv' });
						const href = URL.createObjectURL(blob);

						// Create "a" HTML element with href to file & click
						const link = document.createElement('a');
						link.href = href;
						link.setAttribute('download', 'timesheet_history.csv'); // Provide a default file name
						document.body.appendChild(link);
						link.click();

						// Clean up "a" element & remove ObjectURL
						document.body.removeChild(link);
						URL.revokeObjectURL(href);
					} else if (response && response.status === 401) {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					} else {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
					setdownloadingHistory(false);
				}).catch(err => {

					props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					setdownloadingHistory(false);
				});
			}
		});
	};



	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100" style={{ marginTop: "-9px" }}>
						<div className={isLoadedDaily ? "overlay" : "overlay d-block"}>
							<div className="overlay-text">
								<Loader></Loader>
							</div>
						</div>
						<div className="col-md-12" style={{ height: "125%", paddingBottom: '10px' }}>
							<Card className="card-border bg-white h-100" fluid style={{ border: '1px solid #e1e1e1' }}>
								<CardHeader className="mb-2">
									<div className="d-flex justify-content-between">
										<div className="f-size-18">Timesheets</div>

										{/* Wrap Download CSV and Log Time buttons in one container */}
										<div className="d-flex align-items-center">
											{
												downloadingHistory ? <Loader size="smallest" />
													: <Button
														title='Download CSV'
														icon={<ExcelColorIcon outline rotate={0} size="large" />}
														iconOnly
														onClick={downloadCsvHandler}
														style={{ marginRight: '10px' }}  // Add margin here
													/>
											}

											<SplitButton
												primary
												menu={[
													{
														key: 'daily',
														content: <><AddIcon style={{ marginRight: '5px' }} /> Log Time Daily </>,
													},
													{
														key: 'weekly',
														content: <><AddIcon style={{ marginRight: '5px' }} /> Log Time Weekly </>,
													}
												]}
												button={{
													content: <><AddIcon style={{ marginRight: '5px' }} /> Log Time </>,
													'aria-roledescription': 'splitbutton',
													'aria-describedby': 'instruction-message-primary-button',
												}}
												toggleButton={{
													'aria-label': 'more options',
												}}
												onMainButtonClick={handleAddEditClickOpen}
												onMenuItemClick={handleMenuClickOpen}
											/>
										</div>
									</div>

									<div>
										{visible && (
											<Dialog
												className="k-dialog-md dialog-timesheets"
												title={editMode ? "Edit Log Time" : "Add Log Time"}
												onClose={handleAddEditClickClose}
											>
												<Form
												// onSubmit={submitHandler}
												>
													{/* <FormInput
														label={isAlertIdValid == true ? "Alert ID* " : <Text color="red" content="Alert ID *Required" />}
														id="alertId-inline"
														fluid
														disabled={isAlertIdDisabled}
														value={timesheetData?.alertId}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
														onChange={(value) => {
															if (value.target.value && value.target.value.length > 0) {
																SetisAlertIdValid(true);
															}
															else {
																SetisAlertIdValid(false);
															}
															settimesheetData({
																...timesheetData,
																alertId: value.target.value,
															})
														}
														}
													/> */}
													{/* <Text color="red" content="*Required" /> */}

													<div className="d-flex" style={{ marginBottom: "0" }} >
														<Text content="Date*" size="medium" weight="regular" style={{ marginBottom: "5px" }} />
														{
															logHoursError ?

																<Text className="text-danger" style={{ marginBottom: "0", marginLeft: '5px' }}  >You cannot log more than 8 hrs in one day</Text>

																: ""
														}
													</div>

													<div className="datepicker-timesheet">
														<Datepicker allowManualInput={false} width={400}
															selectedDate={timesheetData?.Date}
															restrictedDates={restrictDates}

															onDateChange={(e, data) => {
																// setSelectedStartDate(data.value);
																settimesheetData({
																	...timesheetData,
																	Date: data.value,
																})
															}} />
													</div>


													{/* <Text content="Hours*" size="medium" weight="regular" style={{ marginBottom: "5px" }} /> */}

													<FormDropdown
														label={isHoursValid == true ? "Hours* " : <Text color="red" content="Hours *Required" />}
														items={hoursItems?.map((obj) => {
															return {
																header: obj,
																value: obj,
															};
														})}
														aria-labelledby={"hours-inline"}
														placeholder="Select Hours"
														autosize="true"
														value={timesheetData?.Hours}
														onChange={handleHoursChange}
													/>

													{/* <TimePicker format="HH" steps={steps} defaultValue={defaultHours}  width={180}
														value={timesheetData?.Hours} 
														onChange={(value) => {
															settimesheetData({
																...timesheetData,
																Hours: value.target.value,
															})
														}
														} /> */}

													<FormDropdown
														label={isWorkTypeValid == true ? "Work Type* " : <Text color="red" content="Work Type *Required" />}
														items={workTypes?.map((obj) => {
															return {
																header: obj.WorkTypeName,
																value: obj.WorkTypeId,
															};
														})}
														aria-labelledby={"worktype-inline"}
														placeholder="Select Work Type"
														autosize="true"
														value={timesheetData?.WorkType}
														onChange={handleWorkTypeChange}
														allowFreeform
													/>

													<FormDropdown
														label={isProjectValid == true ? "Product* " : <Text color="red" content="Product *Required" />}
														items={projects?.map((obj) => {
															return {
																header: obj.ProjectName,
																value: obj.ProjectId,
															};
														})}
														aria-labelledby={"project-inline"}
														placeholder="Select Product"
														autosize="true"
														value={timesheetData?.Project}
														onChange={handleProjectChange}
													/>

													<FormDropdown
														search
														label={isVersionValid == true ? "Version* " : <Text color="red" content="Version *Required" />}
														items={versions?.map((obj) => {
															return {
																header: obj.header,
																value: obj.header,
															};
														})}
														aria-labelledby={"version-inline"}
														placeholder="Select Version"
														autosize="true"
														value={timesheetData?.Version}
														onChange={handleVersionChange}
													/>


													<FormCheckbox toggle labelPosition="start"
														label={'Professional Service'}
														name="IsPS" id="IsPS-inline" fluid checked={timesheetData?.IsPS}
														className="timesheet-IsPS"
														onClick={(event, isChecked) => {
															settimesheetData({
																...timesheetData,
																IsPS: isChecked.checked,
															})
														}}
													/>

													<FormInput
														label="Jira Ids"
														name="JiraIds"
														id="jiraIds-inline"
														fluid
														value={timesheetData?.JiraIds}
														onChange={(value) =>
															settimesheetData({
																...timesheetData,
																JiraIds: value.target.value,
															})
														}
													/>

													<FormInput className="notes-input" label="Notes" name="notes" id="notes-inline" fluid
														value={timesheetData?.Notes}
														onChange={(value) =>
															settimesheetData({
																...timesheetData,
																Notes: value.target.value,
															})
														}
													/>



													<DialogActionsBar>

														{
															submitText == 'Submit' ?
																<button disabled={!submitEnabled}
																	className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet"
																	onClick={addEditSubmitClickHandler}
																// disabled={isValid === false}
																>
																	{" "}
																	Submit
																</button>
																:
																<button
																	className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet"
																	onClick={addApproveClickHandler}
																// disabled={isValid === false}
																>
																	{" "}
																	Approve
																</button>
														}

														<button
															className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
															onClick={handleAddEditClickClose}
														>
															{" "}
															Close
														</button>
													</DialogActionsBar>


												</Form>

											</Dialog>
										)}

										{visibleDaily && (
											<Dialog
												className="k-dialog-md timesheets-daily"
												title={"Log Time Daily"}
												onClose={handleAddDailyClickClose}
											>

												<Form>
													<div className="d-flex align-items-center" style={{ marginBottom: "12px" }} >
														<Text content="Date*" size="medium" weight="regular" />
														<div style={{ marginLeft: '8px' }}>
															<Datepicker allowManualInput={false} width={200} fluid
																selectedDate={timesheetDailyDate}
																restrictedDates={restrictDates}
																onDateChange={handleDailyDateChange} />
															{/* onDateChange={(e, data) => {
																	SetisLoadedDaily(false);
																	// setSelectedStartDate(data.value);
																	settimesheetDailyDate(data.value)
																}} /> */}
														</div>
														<div>
															<Text className="text-danger" style={{ marginLeft: '15px' }}>{errorText}</Text>
														</div>
													</div>

													{
														showDailyList == true ?
															<>
																<div className="daily-wrap">
																	<table style={{ width: "500px" }} className="daily-list">
																		<tr>
																			<th style={{ width: "50px" }}> Work Type </th>
																			<th style={{ width: "50px" }}> Hours* </th>
																			<th style={{ width: "100px" }}> Product* </th>
																			<th style={{ width: "120px" }}> Version* </th>
																			<th style={{ width: "40px" }}> Professional Service </th>
																			<th style={{ width: "80px" }}> Jira Ids </th>
																			<th style={{ width: "60px" }}> Notes </th>
																		</tr>
																		{
																			timeSheetsDbDaily?.map(d => {
																				return <tr>
																					<td style={{ width: "50px" }} className="daily-cell">
																						<div style={{ width: "155px" }}>
																							<FormDropdown className="daily-db" fluid value={d.WorkType ? d.WorkType : ''} disabled />
																						</div>
																					</td>

																					<td style={{ width: "50px" }} className="daily-cell">
																						<div style={{ width: "155px" }}>
																							<FormDropdown className="daily-db" fluid value={d.Hours ? d.Hours : ''} disabled />
																						</div>
																					</td>

																					<td style={{ width: "100px" }} className="daily-cell">
																						<div style={{ width: "185px" }}>
																							<FormDropdown className="daily-db" fluid value={d.Project ? d.Project : ''} disabled />
																						</div>
																					</td>

																					<td style={{ width: "120px" }} className="daily-cell">
																						<div style={{ width: "100px" }}>
																							<FormDropdown search className="daily-db" fluid value={d.Version ? d.Version : ''} disabled />
																						</div>
																					</td>

																					<td style={{ width: "40px" }} className="daily-cell">
																						<div style={{ width: "80px" }}>
																							<FormCheckbox name="IsPS" className="isPS-input" fluid toggle disabled
																								checked={d.IsPS ? d.IsPS : false}
																							/>
																						</div>
																					</td>

																					<td style={{ width: "80px" }} className="daily-cell">
																						<div style={{ width: "100px" }}>
																							<FormInput className="daily-db" fluid value={d.JiraIds ? d.JiraIds : ''} disabled />
																						</div>
																					</td>

																					<td style={{ width: "60px" }} className="daily-cell">
																						<div style={{ width: "100px" }}>
																							<FormInput className="daily-db" fluid value={d.Notes ? d.Notes : ''} disabled />
																						</div>
																					</td>

																					{/* <td>
																						<div style={{ width: "155px" }}>
																							<FormDropdown
																								items={hoursItems?.map((obj) => {
																									return {
																										header: obj,
																										value: obj,
																										workTypeId: d.WorkTypeId
																									};
																								})}
																								className="daily-hours"
																								placeholder="Select Hours"
																								value={hoursState ? hoursState[d.WorkTypeId] : ''}
																								onChange={handleDailyHoursChange}
																							/>
																						</div>
																					</td> */}

																					{/* <td>
																						<div style={{ width: "185px" }}>
																							<FormDropdown
																								items={projects?.map((obj) => {
																									return {
																										header: obj.ProjectName,
																										value: obj.ProjectId,
																										workTypeId: d.WorkTypeId
																									};
																								})}
																								className="daily-project"
																								aria-labelledby={"project-inline"}
																								placeholder="Select Product"
																								value={projectState ? projectState[d.WorkTypeId] : ''}
																								onChange={handleDailyProjectChange}
																							/>
																						</div>
																					</td>

																					<td>
																						<FormInput
																							name="version" id="version-inline" fluid value={versionState ? versionState[d.WorkTypeId] : ''}
																							placeholder="x.x.x.x"
																							onChange={(value) => {
																								let k = d.WorkTypeId;
																								setversionState({
																									[k]: value.target.value.trim(),
																								})
																								// dailyData.find(x => x.WorkTypeId === d.WorkTypeId).Version = value.target.value.trim();
																								// setdailyData(dailyData);
																							}
																							}
																						/>
																					</td>

																					<td >
																						<FormCheckbox name="IsPS" className="isPS-input" id="notes-inline" fluid toggle
																							checked={isPsState ? isPsState[d.WorkTypeId] : false}
																							onClick={(event, isChecked) => {
																								let k = d.WorkTypeId;
																								setisPsState({
																									[k]: isChecked.checked,
																								})
																							}}
																						/>
																					</td>

																					<td>
																						<FormInput
																							name="JiraIds"
																							id="jiraIds-inline"
																							fluid
																							value={jiraIdsState ? jiraIdsState[d.WorkTypeId] : ''}
																							onChange={(value) => {
																								let k = d.WorkTypeId;
																								setjiraIdsState({
																									[k]: value.target.value,
																								})
																								// dailyData.find(x => x.WorkTypeId === d.WorkTypeId).JiraIds = value.target.value.trim();
																								// setdailyData(dailyData);
																							}
																							}
																						/>
																					</td>

																					<td>
																						<FormInput className="notes-input" id="notes-inline" fluid
																							value={notesState ? notesState[d.WorkTypeId] : ''}
																							onChange={(value) => {
																								let k = d.WorkTypeId;
																								setnotesState({
																									[k]: value.target.value,
																								})
																								// dailyData.find(x => x.WorkTypeId === d.WorkTypeId).Notes = value.target.value.trim();
																								// setdailyData(dailyData);
																							}
																							}
																						/>
																					</td> */}

																				</tr>;
																			})

																		}

																		{
																			dailyDataUser?.map(d => {
																				return <tr>
																					<td className="daily-cell">
																						<div style={{ width: "155px" }}>
																							<FormDropdown className={d.WorkTypeError === true ? "daily-hours error" : "daily-hours"} placeholder="Select Work Type" value={d.WorkType} fluid
																								items={workTypes?.map((obj) => {
																									return {
																										header: obj.WorkTypeName,
																										value: obj.WorkTypeId,
																										index: d.index
																									};
																								})}
																								onChange={handleDailyWorkTypeChange}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "155px" }}>
																							<FormDropdown className={d.HoursError === true ? "daily-hours error" : "daily-hours"} placeholder="Select Hours" value={d.Hours}
																								items={hoursItems?.map((obj) => {
																									return {
																										header: obj,
																										value: obj,
																										index: d.index
																									};
																								})}
																								onChange={handleDailyHoursChange}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "185px" }}>
																							<FormDropdown className={d.ProjectError === true ? "daily-project error" : "daily-project"} placeholder="Select Product" value={d.Project}
																								items={projects?.map((obj) => {
																									return {
																										header: obj.ProjectName,
																										value: obj.ProjectId,
																										index: d.index
																									};
																								})}
																								onChange={handleDailyProjectChange}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "145px" }}>
																							<FormDropdown search fluid value={d.Version} placeholder="Select" className={d.VersionError === true ? "daily-version error" : "daily-version"}
																								items={versions?.map((obj) => {
																									return {
																										header: obj.header,
																										value: obj.header,
																										index: d.index
																									};
																								})}
																								onChange={(isnull, data) => handleDailyVersionChange(isnull, data, d.index)}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "80px" }}>
																							<FormCheckbox fluid toggle checked={d.IsPS}
																								onClick={(event, isChecked) => handleDailyPsChange(event, isChecked, d.index)}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "100px" }}>
																							<FormInput id="version-inline" fluid value={d.JiraIds}
																								onChange={(data) => handleDailyJiraIdChange(data, d.index)}
																							/>
																						</div>
																					</td>

																					<td className="daily-cell">
																						<div style={{ width: "100px" }}>
																							<FormInput id="version-inline" fluid value={d.Notes}
																								onChange={(data) => handleDailyNotesChange(data, d.index)}
																							/>
																						</div>
																					</td>

																				</tr>;
																			})
																		}
																	</table>
																	<Tooltip iconOnly content="Add New Row"
																		trigger={
																			<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet"
																				onClick={dailyAdd} style={{ width: '67px', marginBottom: '0', marginTop: '10px' }} disabled={!addEnabledDaily} >
																				Add
																			</button>
																		}
																	/>
																</div>
															</>
															: ""
													}

													<DialogActionsBar >
														<div className="d-flex justify-content-end">
															<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
																onClick={handleAddDailyClickClose}>
																Close
															</button>
															<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet"
																onClick={addEditSubmitDailyClickHandler} style={{ marginLeft: "5px" }} disabled={!submitEnabledDaily} >
																Submit
															</button>
														</div>
													</DialogActionsBar>
												</Form>



											</Dialog>
										)}

										{visibleWeekly && (
											<Dialog
												className="k-dialog-md timesheets-daily timesheets-weekly"
												title={"Log Time Weekly"}
												onClose={handleAddWeeklyClickClose}
											>
												<Form>

													<div className="d-flex align-items-center" style={{ marginBottom: "10px" }} >
														<Tooltip iconOnly trigger={<Button className="prev-icon" icon={<ChevronStartIcon />} text
															onClick={() => { handleWeeklyPrev(true) }} />} content="Previous Week" />
														<div style={{ width: '245px' }}   >
															<Input fluid value={weeklyDisplayDt} disabled style={{ color: '#000' }} className="weelyDt"
															/>
														</div>
														<Tooltip iconOnly trigger={<Button className="next-icon" icon={<ChevronEndIcon />} text
															onClick={() => { handleWeeklyPrev(false) }} />} content="Next Week" />
														<div>
															<Text className="text-danger" style={{ marginLeft: '15px' }}>{errorText}</Text>
														</div>
													</div>

													{
														showDailyList == true ?
															<> <div className="weekly-wrap">

																<table className="daily-list weekly-list">
																	<tr>
																		<th className="dt-h" style={{ borderLeft: 'none', width: "100px" }} > Date </th>
																		<th className="wt-h" style={{ width: "100px" }}> Work Type* </th>
																		<th className="hr-h" > Hours* </th>
																		<th className="pr-h" style={{ width: "100px" }}> Product* </th>
																		<th className="vr-h" style={{ width: "150px" }}> Version* </th>
																		<th className="ps-h" style={{ width: "100px" }}> Professional Service </th>
																		<th className="jr-h" style={{ width: "100px" }}> Jira Ids </th>
																		<th className="no-h" style={{ borderRight: 'none', width: "100px" }}> Notes </th>
																	</tr>
																	{
																		weeklyData?.map(d => {
																			return <tr>
																				<td className="weekly-cell"  >
																					<div className="weekly-cell" >
																						<Text content={(d.TableDate).toLocaleDateString("en-US", weelyDateOptions)} size="medium" weight="regular" />
																					</div>
																				</td>

																				<td className="weekly-cell" colSpan={7} style={{ padding: '0' }}>
																					<table className="db-table">
																						{
																							d.db.map(r => {
																								return <tr>
																									<td className="cell wt-db" style={{ width: "100px" }}>
																										<div className="cell-content">
																											{r.WorkType}
																										</div>
																									</td>
																									<td className="cell hr-db" >
																										<div className="cell-content">
																											{r.Hours}
																										</div>
																									</td>
																									<td className="cell pr-db" style={{ width: "100px" }}>
																										<div className="cell-content">
																											{r.Project}
																										</div>
																									</td>
																									<td className="cell vr-db" style={{ width: "150px" }}>
																										<div className="cell-content">
																											{r.Version}
																										</div>
																									</td>
																									<td className="cell ps-db" style={{ width: "100px" }}>
																										<div className="cell-content">
																											{r.IsPS ? 'Yes' : 'No'}
																										</div>
																									</td>
																									<td className="cell jr-db" style={{ width: "100px" }}>
																										<div className="cell-content">
																											{r.JiraIds}
																										</div>
																									</td>
																									<td className="cell no-db" style={{ width: "100px" }}>
																										<div className="cell-content">
																											{r.Notes}
																										</div>
																									</td>
																								</tr>
																							})
																						}
																					</table>

																					<table className="user-table">
																						{
																							d.userData.map(r => {
																								return <tr>
																									<td className="cell wt-u" >
																										<div className="cell-content" >
																											<FormDropdown
																												items={workTypes?.map((obj) => {
																													return {
																														header: obj.WorkTypeName,
																														value: obj.WorkTypeId,
																														index: r.Index
																													};
																												})}
																												className={r.WorkTypeError === true ? "daily-workType error" : "daily-workType"}
																												placeholder="Select Work Type"
																												value={r.WorkType}
																												onChange={(e, data) => handleWeeklyWorkTypeChange(data, d.Index)} fluid
																											/>

																										</div>
																									</td>
																									<td className="cell hr-u" >
																										<div className="cell-content">
																											<FormDropdown
																												items={hoursItems?.map((obj) => {
																													return {
																														header: obj,
																														value: obj,
																														index: r.Index
																													};
																												})}
																												className={r.HoursError === true ? "daily-hours error" : "daily-hours"}
																												placeholder="Select Hours"
																												value={r.Hours}
																												onChange={(e, data) => handleWeeklyHoursChange(data, d.Index)}
																											/>
																										</div>
																									</td>
																									<td className="cell pr-u" style={{ width: "100px" }}>
																										<div className="cell-content">
																											<FormDropdown
																												items={projects?.map((obj) => {
																													return {
																														header: obj.ProjectName,
																														value: obj.ProjectId,
																														index: r.Index
																													};
																												})}
																												className={r.ProjectError === true ? "daily-project error" : "daily-project"}
																												aria-labelledby={"project-inline"}
																												placeholder="Select Product"
																												value={r.Project}
																												onChange={(e, data) => handleWeeklyProjectChange(data, d.Index)}
																											/>
																										</div>
																									</td>
																									<td className="cell vr-u" style={{ width: "150px" }}>
																										<div className="cell-content">
																											<FormDropdown
																												search
																												fluid
																												items={versions?.map((obj) => ({
																													header: obj.header,
																													value: obj.header, // Set the value to the version header
																												}))}
																												placeholder="Select"
																												onChange={(e, { value }) => handleWeeklyVersionChange(value, d.Index, r.Index)}
																												className={r.VersionError === true ? "daily-version error" : "daily-version"}
																											/>

																										</div>
																									</td>
																									<td className="cell ps-u" style={{ width: "100px" }}>
																										<div className="cell-content">
																											<FormCheckbox name="IsPS" className="isPS-input" id="notes-inline" fluid toggle
																												checked={r.IsPS ? r.IsPS : false}
																												onClick={(e, isChecked) => handleWeeklyIsPSChange(e, isChecked, d.Index, r.Index)}
																											/>
																										</div>
																									</td>
																									<td className="cell jr-u" style={{ width: "100px" }}>
																										<div className="cell-content">
																											<FormInput fluid value={r.JiraIds}
																												onChange={(dt) => handleWeeklyJiraIdsChange(dt, d.Index, r.Index)}
																											/>
																										</div>
																									</td>
																									<td className="cell no-u" style={{ width: "100px" }}>
																										<div className="cell-content">
																											<FormInput className="notes-input" id="notes-inline" fluid
																												value={r.Notes}
																												onChange={(dt) => handleWeeklyNotesChange(dt, d.Index, r.Index)}
																											/>
																										</div>
																									</td>
																								</tr>
																							})
																						}
																					</table>

																					<div>
																						<Tooltip size="small" iconOnly trigger={<Button disabled={d.addDisabled} icon={<AddIcon />} className="add-icon submit-timesheet k-button" primary iconOnly size="small" onClick={() => addBtnClick(d.TableDate)} />} content="Add New Row" />
																					</div>

																				</td>

																				{/* {
																					d.DataRow === true ? <>
																					<td>
																						<div style={{ width: "155px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormDropdown className="daily-db" fluid value={d.WorkType ? d.WorkType : ''} disabled /> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "155px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormDropdown className="daily-db" fluid value={d.Hours ? d.Hours : ''} disabled /> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "185px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormDropdown className="daily-db" fluid value={d.Project ? d.Project : ''} disabled /> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "100px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormInput className="daily-db" fluid value={d.Version ? d.Version : ''} disabled /> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "80px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormCheckbox name="IsPS" className="isPS-input" fluid toggle disabled
																									checked={d.IsPS ? d.IsPS : false}
																								/> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "100px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormInput className="daily-db" fluid value={d.JiraIds ? d.JiraIds : ''} disabled /> : ""
																							}
																						</div>
																					</td>

																					<td>
																						<div style={{ width: "100px" }}>
																							{
																								d.WorkType && d.WorkType.length > 0 ?
																								<FormInput className="daily-db" fluid value={d.Notes ? d.Notes : ''} disabled /> : ""
																							}
																						</div>
																					</td>
																					</>
																					: 
																					""
																				}
																				
																				{
																					d.UserRow === true ?
																					<>
																						<td colSpan={7}>
																							<div>
																								new
																							</div> 
																						</td>
																					</> : ""
																				}

																				{
																					d.AddButtonRow === true ?
																					<>
																						<td colSpan={7}>
																							<div>
																								<Button icon={<AddIcon />}   primary iconOnly size="small" onClick={() => addBtnClick(d.TableDate)}  />
																							</div> 
																						</td>
																					</> : ""
																				} */}




																				{/* <td style={{ width: "155px" }}> 
																					<div style={{ width: "155px" }}>
																						<FormDropdown
																							items={workTypes?.map((obj) => {
																								return {
																									header: obj.WorkTypeName,
																									value: obj.WorkTypeId,
																									index: d.Index
																								};
																							})}
																							className={d.HoursClass == 'error' ? "daily-hours error" : "daily-hours"}
																							placeholder="Select Work Type"
																							value={d.WorkTypeName}
																							onChange={handleWeeklyWorkTypeChange}
																						/>
																					</div>
																				</td>

																				<td>
																					<div style={{ width: "155px" }}>
																						<FormDropdown
																							items={hoursItems?.map((obj) => {
																								return {
																									header: obj,
																									value: obj,
																									index: d.Index
																								};
																							})}
																							className={d.HoursClass == 'error' ? "daily-hours error" : "daily-hours"}
																							placeholder="Select Hours"
																							value={d.Hours}
																							onChange={handleWeeklyHoursChange}
																						/>
																					</div>
																				</td>

																				<td>
																					<div style={{ width: "185px" }}>
																						<FormDropdown
																							items={projects?.map((obj) => {
																								return {
																									header: obj.ProjectName,
																									value: obj.ProjectId,
																									index: d.Index
																								};
																							})}
																							className={d.ProjectClass == 'error' ? "daily-project error" : "daily-project"}
																							aria-labelledby={"project-inline"}
																							placeholder="Select Product"
																							value={d.Project}
																							onChange={handleWeeklyProjectChange}
																						/>
																					</div>
																				</td>

																				<td>
																					<FormInput
																						name="version" id="version-inline" fluid value={d.Version}
																						placeholder="x.x.x.x"
																						onChange={(dt) => handleWeeklyVersionChange(dt, d.Index)}
																						className={d.VersionClass == 'error' ? "error" : ""}
																					/>
																				</td>

																				<td style={{ width: "70px" }}>
																					<FormCheckbox name="IsPS" className="isPS-input" id="notes-inline" fluid toggle
																						checked={d.IsPS ? d.IsPS : false}
																						onClick={(e, isChecked) => handleWeeklyIsPSChange(e, isChecked, d.Index)}
																					/>
																				</td>

																				<td>
																					<FormInput
																						name="JiraIds"
																						id="jiraIds-inline"
																						fluid
																						value={d.JiraIds}
																						onChange={(dt) => handleWeeklyJiraIdsChange(dt, d.Index)}
																					/>
																				</td>

																				<td className="notes-cell" >
																					<FormInput className="notes-input" id="notes-inline" fluid
																						value={d.Notes}
																						onChange={(dt) => handleWeeklyNotesChange(dt, d.Index)}
																					/>
																				</td> */}

																			</tr>;
																		})
																	}
																</table>
															</div></>
															: ""
													}

													<DialogActionsBar>
														<div className="d-flex justify-content-end">
															<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
																onClick={handleAddWeeklyClickClose}>
																Close
															</button>
															<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet ef"
																onClick={addEditSubmitWeeklyClickHandler} style={{ marginLeft: "5px" }} disabled={!submitEnabledWeekly} >
																Submit
															</button>
														</div>
													</DialogActionsBar>
												</Form>
											</Dialog>
										)}

									</div>
								</CardHeader>
								<CardBody>
									<div className="d-flex justify-content-between missing-container" >
										<div className="missing-ts">
											{missing && missing.length > 0 ? (
												<>
													<Text content="Missing Timesheets: " className="text-danger"></Text>
													<div className="missing-wrap">
														{missing.map((t, index) => {
															let date = formatDate(new Date(t), "dd-MMM-yy");
															return (
																<span key={date}>
																	<Text
																		className="missing-date"
																		content={date}
																		onClick={() => handleAddEditClickOpen({ Date: new Date(t) }, false)}
																		style={{ cursor: 'pointer', color: 'rgb(72, 70, 68)', textDecoration: 'none' }}
																	/>
																	{index < missing.length - 1 && ', '}
																	{index < missing.length - 1 && <span>&nbsp;</span>}
																</span>
															);
														})}
													</div>
												</>
											) : (
												""
											)}
										</div>
										<div className="tbl-search float-right text-right mb-2 position-relative timesheet-filter">
											<Input
												onChange={(e, { name, value }) => {
													filterGridData(value);
												}}
												inverted
												icon={<SearchIcon />}
												placeholder="Search..."
												className="timesheet-input"
											/>
											{
												filterVisible ?
													<Button styles={{ padding: '0 12px', marginLeft: "10px" }}
														icon={<FilterIcon />} content={"Filters"}
														title="Filters" className="filetr-icon" circular
														onClick={ToggleClass} />
													: ""
											}



											<Flex gap="gap.small" className={isActive ? "email-log-filter email-service" : "active email-log-filter email-service"} style={{ width: '400px', textAlign: 'left' }}>

												<div className="radio-container" style={{ position: 'relative' }}>
													<div className="ddl-container d-flex justify-content-between" >
														<div style={{ marginLeft: "7px" }}>
															<Text styles={{ verticalAlign: '-webkit-baseline-middle' }}>Filter By: </Text>
														</div>
														<Dropdown fluid items={filterByItems} value={selectedFilter} className="filter-by-items"
															placeholder="Select Filter"
															onChange={(e, selectedItem) => {
																handleFilterDdlChange(selectedItem.value);
															}}
															disabled={filterByRadioValue == "0" ? true : false}
															styles={{ marginLeft: "0" }}
														// key={updateId}
														/>
													</div>
													{
														filterError ?
															<div className="error-message" style={{ position: 'absolute', top: '-14px', left: '4px', fontSize: '12px' }}>
																<Text className="text-danger">*Select Filter</Text>
															</div>
															: ""
													}

												</div>
												<div style={{ marginLeft: '7px', marginTop: '8px' }}>
													{
														showDateFilter ?
															<div>
																<div style={{ marginTop: 'px', marginBottom: '5px', marginLeft: '1px', marginRight: '2px', height: '70px' }}>
																	<div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '5px' }}>
																		<Text content="Start Date" size="medium" weight="regular"
																			disabled={filterByRadioValue == "0" || selectedFilter != 'Date' ? true : false} />

																		<div>
																			<Datepicker allowManualInput={false}
																				disabled={filterByRadioValue == "0" || selectedFilter != 'Date' ? true : false}
																				selectedDate={selectedStartDate}
																				onDateChange={(e, data) => {
																					setSelectedStartDate(data.value);
																				}} />


																		</div>

																	</div>
																	<div className="d-flex justify-content-between align-items-center">
																		<Text content="End Date" size="medium" weight="regular"
																			disabled={filterByRadioValue == "0" || selectedFilter != 'Date' ? true : false} />

																		<Datepicker allowManualInput={false} fluid
																			disabled={filterByRadioValue == "0" || selectedFilter != 'Date' ? true : false} width={210}
																			selectedDate={selectedEndDate}
																			onDateChange={(e, data) => {
																				setSelectedEndDate(data.value);
																			}} />

																	</div>
																</div>
															</div> :
															showWorkTypeFilter ?
																<div  >
																	<div className="d-flex justify-content-between align-items-center" style={{ height: '70px' }} >
																		<Text content="Select Work Type" size="medium" weight="regular"
																			disabled={filterByRadioValue == "0" ? true : false} />
																		<FormDropdown
																			style={{ textAlign: 'left', width: '161px', marginRight: '2px' }}
																			items={workTypes?.map(item => item.WorkTypeName)}
																			fluid required value={SelectedWorkType} defaultValue={SelectedWorkType}
																			onChange={handleWorkTypeChangeFilter} width={150}
																			disabled={filterByRadioValue == "0" ? true : false} />
																	</div>
																</div>
																:
																showProjectFilter ?
																	<div >
																		<div className="d-flex justify-content-between align-items-center" style={{ height: '70px' }} >
																			<Text content="Select Project" size="medium" weight="regular"
																				disabled={filterByRadioValue == "0" ? true : false} />
																			<FormDropdown
																				style={{ textAlign: 'left', width: '161px', marginRight: '2px' }}
																				items={projects?.map(item => item.ProjectName)} fluid required value={selectedProject} defaultValue={selectedProject}
																				onChange={handleTokenNameChange} width={150}
																				disabled={filterByRadioValue == "0" ? true : false} />
																		</div>
																	</div>
																	:

																	<div >
																		<Text content="Enter " className="date-filter-text" size="medium" weight="regular"
																			disabled={filterByRadioValue == "0" ? true : false} />
																		<Text content={selectedFilter} className="date-filter-text" size="medium" weight="regular"
																			disabled={filterByRadioValue == "0" ? true : false} />
																		<Input className="border-filter-input" fluid value={filterByValue} style={{ marginBottom: '17px' }}
																			disabled={filterByRadioValue == "0" ? true : false} onChange={handleFilterByTextChange} />
																	</div>
													}

												</div>

												<div className="radio-container" style={{ position: 'relative', marginTop: '3px' }}>
													<div className="ddl-container d-flex justify-content-between" >
														<div style={{ marginLeft: "7px" }}>
															<Text styles={{ verticalAlign: '-webkit-baseline-middle' }}>Employee: </Text>
														</div>
														<Dropdown className="filter-by-items"
															items={employees} fluid value={selectedEmployee}
															onChange={(e, selectedItem) => {
																handleEmployeeChange(selectedItem.value);
															}}
															placeholder="Select"
															styles={{ marginLeft: "0" }}
														/>
													</div>
												</div>

												<div className="radio-container" style={{ position: 'relative', marginTop: '10px' }}>
													<div className="ddl-container d-flex justify-content-between" >
														<div style={{ marginLeft: "7px" }}>
															<Text styles={{ verticalAlign: '-webkit-baseline-middle' }}>Timesheet Status: </Text>
														</div>
														<Dropdown className="filter-by-items"
															items={timsheetStausItems} fluid value={selectedStatus}
															onChange={(e, selectedItem) => {
																handleStatusChange(selectedItem.value);
															}}
															placeholder="Select"
															styles={{ marginLeft: "0" }}
														/>
													</div>
												</div>


												<Flex gap="gap.small" className="mt-2" style={{ marginLeft: '9px' }}>
													<Button content="Apply"
														onClick={applyFilter} />
													<Button content="Cancel" className="aws-applyFilter"
														onClick={() => setActive("false")} />

													{filterActive && (
														<Button styles={{ padding: '0 12px', marginRight: "10px" }}
															content={"Clear Filters"} title="Clear" className="filetr-icon" circular
															onClick={reset} />
													)}
												</Flex>

											</Flex>


										</div>
									</div>
									<div className='grid-with-search'>
										<Grid

											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(timeSheets, dataState)}
											{...dataState}
											onDataStateChange={(e) => {
												setDataState(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>

											<Column field="EmployeeName" title="Employee Name" width={170} />
											<Column field="DateDisplay" title="Date" width={120} cell={CellDateTimeSheet} />

											{!context.mediaCategory.sm && (
												<Column field="HoursDisplay" title="Hours" width={60} cell={CellHoursTimeSheet} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="WorkType" title="Work Type" width={150} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Project" title="Project" width={100} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Version" title="Version" width={100} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="IsPS" title="Professional Service" width={100} cell={CellIsPS} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="JiraIds" title="Jira Ids" width={150} />
											)}
											{/* {!context.mediaCategory.sm && (
												<Column field="Notes" title="Notes" width={100} />
											)} */}

											{!context.mediaCategory.sm && (
												<Column field="Approved" title="Status" width={100} />
											)}

											<Column
												field=""
												title="Actions"
												style={{ textAlign: "center" }}
												width={100}
												cell={ActionIconVersions}
											/>
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>
						<FluentDialog
							cancelButton="Cancel"
							confirmButton="Confirm"
							onCancel={() => {
								setDeleteConfirmationDialog(false);
								setDeleteAlertObj(null);
							}}
							onConfirm={() => removeData()}
							open={openDeleteConfirmationDialog}
							backdrop
							header="Action confirmation"
							headerAction={{
								icon: <CloseIcon />,
								title: "Close",
								onClick: () => {
									setDeleteConfirmationDialog(false);
									setDeleteAlertObj(null);
								},
							}}
							content={`Are you sure you want to delete log?`}
						/>
					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { Timesheet };
