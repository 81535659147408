import React from 'react'
import { Dialog as FluentDialog, Card, CardHeader, CardBody, Pill, Button, Segment, Text, Flex, Form, FormTextArea } from '@fluentui/react-northstar';
import { AddIcon, ArrowDownIcon, ArrowUpIcon, EditIcon, EyeIcon, TrashCanIcon } from '@fluentui/react-icons-northstar';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import RevenueService from "../../../services/revenueservice";
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
import { PieChart } from '../../../shared/appcomponents/custom-charts';

const TopKPI = (props) => {
    const [insights, setInsights] = React.useState(props.businessInsights);
    const [disalogVisible, setDialogVisible] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [formData, setFormData] = React.useState(null);
    const [newBillingView, setNewBillingView] = React.useState({ newSPByProduct: true, existingSPByProduct: true });
    const handleInsightAddEditClickClose = () => {
        setDialogVisible(false);
        setFormData(null);
        setEditMode(false);
    };
    const handleInsightAddEditClickOpen = (dataItem, isEdit) => {
        setDialogVisible(true);
        if (isEdit === true) {
            setFormData(dataItem);
            setEditMode(true);
        }
        else {
            let fyYear = String(new Date().getUTCFullYear() - 1 - props.year);
            setFormData({ FinancialYear: fyYear });
            setEditMode(false);
        }
    };
    const addUpdateInsightSubmitClickHandler = () => {
        if (formData?.Insight?.length > 0) {
            props.acquireToken((token) => {
                if (token) {
                    if (editMode) {
                        RevenueService.UpdateBusinessInsight(token, formData).then((response) => {
                            if (response && response.data != null) {
                                response = response.data;
                                insights.forEach(function (item, i) {
                                    if (item.Id == response.Id) {
                                        insights[i].Insight = response.Insight;
                                        insights[i].UpdatedBy = response.UpdatedBy;
                                        insights[i].UpdatedAt = response.UpdatedAt;
                                    }
                                });
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                            } else {
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                            }
                        });
                    }
                    else {
                        RevenueService.AddBusinessInsight(token, formData).then((response) => {
                            if (response && response.data != null) {
                                response = response.data;
                                setInsights([response, ...insights]);
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                            } else {
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                            }
                        });
                    }
                }
                setDialogVisible(false);
                setEditMode(false);
            });
        }
    };
    const handleInsightDeleteConfirm = (dataItem) => {
        if (dataItem && dataItem.Id > 0) {
            let params = {
                insightId: dataItem.Id
            }
            props.acquireToken((token) => {
                if (token) {
                    RevenueService.DeleteBusinessInsight(token, params).then((response) => {
                        if (response && response.data != null) {
                            response = response.data;
                            if (response == true) {
                                setInsights(insights?.filter((f) => f.Id != dataItem.Id));
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                            }
                            else {
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                            }
                        }
                        else {
                            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                        }
                    });
                }
            });
        }
        setFormData(dataItem);
    };
    return (<>
        <div className='row d-flex align-items-stretch top-kpi'>
            {
                props.isRevenue == "1" ?
                    <div className='col-12 col-lg-5 pr-lg-1 mb-4'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader>

                                <div className='row'>
                                    <div className='col-12 col-sm-8 mb-1 mb-sm-0'>
                                        <h6>Billing This Year</h6>
                                        <h1>£ {(props.data?.TotalRevenue?.Total.toLocaleString('en'))}
                                            {
                                                props.data?.TotalRevenue?.Total_Growth < 0 ?
                                                    <Pill className='critical'>{props.data?.TotalRevenue?.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                    <Pill className='healthy'>{props.data?.TotalRevenue?.Total_Growth}%<ArrowUpIcon /></Pill>
                                            }
                                        </h1>
                                    </div>
                                    <div className='col-12 col-sm-4'>
                                        <h6>Billing This Month</h6>
                                        <h1>{props.data?.TotalRevenue.ThisMonth.toLocaleString('en')}</h1>
                                    </div>
                                </div>



                            </CardHeader>
                            <CardBody>
                                <div className='row mx-0'>
                                    <div className='col border-bottom border-right'>
                                        <h5 className='rr-top-heading'>Subscription Billing</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total</h6>
                                                <h2>£ {props.data?.SR.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.SR.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.SR.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.SR.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>£ {props.data?.SR.ThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                            <div className='col'>
                                                <h6>ARR</h6>
                                                <h2 style={{ fontSize: "20px" }}>£ {props.data?.SR.Annualized.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col border-bottom'>
                                        <h5 className='rr-top-heading' >Recurring Billing</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total</h6>
                                                <h2>£ {props.data?.RR.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.RR.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.RR.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.RR.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>£ {props.data?.RR.ThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mx-0 mt-2'>
                                    <div className='col border-right'>
                                        <h5>Licence Billing</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total</h6>
                                                <h2>£ {props.data?.LR.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.LR.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.LR.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.LR.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>£ {props.data?.LR.ThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h5>Recognised Support Billing</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total</h6>
                                                <h2>£ {props.data?.SPR.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.SPR.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.SPR.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.SPR.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>£ {props.data?.SPR.ThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    :
                    <div className='col-12 col-lg-5 pr-lg-1 mb-4'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader>
                                <Flex space='between' vAlign='center' gap="gap.small">
                                    <h6>Business Insight</h6>
                                    {
                                        props.user?.Permissions?.some((p) => (p == "Dashboards.ReadWrite" || p == "Dashboards.BusinessPerformance.ReadWrite"))
                                            ?
                                            <Flex.Item push>
                                                <Button circular primary title="Add Business Insight" icon={<AddIcon />} onClick={() => handleInsightAddEditClickOpen(null, false)} />
                                            </Flex.Item>
                                            : <></>
                                    }

                                </Flex>
                            </CardHeader>
                            <CardBody>
                                {
                                    insights ?
                                        <div className='bussiness-insights-container'>
                                            {
                                                insights.map((item) => {
                                                    return <Segment className='bussiness-insight' content={<><Flex space='between' vAlign='center' gap="gap.small">
                                                        <Text content={item.Insight} />
                                                        {
                                                            props.user?.Permissions?.some((p) => (p == "Dashboards.ReadWrite" || p == "Dashboards.BusinessPerformance.ReadWrite"))
                                                                ?
                                                                <><Flex.Item push>
                                                                    <Button iconOnly icon={<EditIcon />} onClick={() => handleInsightAddEditClickOpen(item, true)} />
                                                                </Flex.Item>
                                                                    <FluentDialog
                                                                        cancelButton="Cancel"
                                                                        confirmButton="Confirm"
                                                                        onConfirm={() => handleInsightDeleteConfirm(item)}
                                                                        content="Are you sure you want to delete this business insight?"
                                                                        header="Confirm Delete"
                                                                        trigger={<Button iconOnly icon={<TrashCanIcon />} />}
                                                                    /></>
                                                                : <></>
                                                        }

                                                    </Flex>
                                                        <div className='creation-details'>
                                                            <Text content={item.UpdatedBy && item.UpdatedBy != "" ? item.UpdatedBy : item.CreatedBy} size='small' />
                                                        </div>
                                                    </>} />
                                                })
                                            }
                                        </div>
                                        : <></>
                                }
                            </CardBody>
                        </Card>
                    </div>
            }

            <div className='col-12 col-lg-7 pl-lg-1 mb-4'>
                <div className='row pb-1'>
                    <div className='col'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-12 col-sm-5 mb-2 mb-sm-0'>
                                        <h6>Total Tenants
                                            {props.data?.Tenants.IsSecondaryText ? "" : <span>-excluding Protect</span>}
                                        </h6>
                                        <h1> {props.data?.Tenants.Total.toLocaleString('en')}
                                            {
                                                props.data?.Tenants.Total_Growth < 0 ?
                                                    <Pill className='critical'>{props.data?.Tenants.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                    <Pill className='healthy'>{props.data?.Tenants.Total_Growth}%<ArrowUpIcon /></Pill>
                                            }
                                        </h1>
                                    </div>
                                    <div className='col'>
                                        <h6>Added This Month</h6>
                                        <h1>{props.data?.Tenants.AddedThisMonth.toLocaleString('en')} </h1>
                                    </div>
                                    <div className='col'>
                                        <h6>Removed This Month</h6>
                                        <h1>{props.data?.Tenants.RemovedThisMonth.toLocaleString('en')}</h1>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='row mx-0'>
                                    <div className='col border-right'>
                                        <h5>Users</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total Users</h6>
                                                <h2>{props.data?.Users.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.Users.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.Users.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.Users.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-lg-6'>
                                                <h6>Added This Month</h6>
                                                <h2>{props.data?.Users.AddedThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                <h6>Removed This Month</h6>
                                                <h2>{props.data?.Users.RemovedThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h5>End Points</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>Total End Points</h6>
                                                <h2>{props.data?.EndPoints.Total.toLocaleString('en')}
                                                    {
                                                        props.data?.EndPoints.Total_Growth < 0 ?
                                                            <Pill className='critical'>{props.data?.EndPoints.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                            <Pill className='healthy'>{props.data?.EndPoints.Total_Growth}%<ArrowUpIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-lg-6'>
                                                <h6>Added This Month</h6>
                                                <h2>{props.data?.EndPoints.AddedThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                <h6>Removed This Month</h6>
                                                <h2>{props.data?.EndPoints.RemovedThisMonth.toLocaleString('en')} </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='row pt-1'>
                    <div className='col-12 col-lg-4 pr-lg-1 mb-2 mb-lg-0'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader>
                                <h6>Total Churn</h6>
                                <h1>£ {props.data?.Churn.Total.toLocaleString('en')}
                                    {/* {
                                        props.data?.Churn.Total_Growth <= 0 ?
                                        <Pill className='healthy'>{props.data?.Churn.Total_Growth}%<ArrowDownIcon /></Pill> :  
                                        <Pill className='critical'>{props.data?.Churn.Total_Growth}%<ArrowUpIcon /></Pill>
                                    } */}
                                </h1>
                            </CardHeader>
                            <CardBody>
                                <div className='row mx-0'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>£ {props.data?.Churn.ThisMonth.toLocaleString('en')}
                                                    {
                                                        props.data?.Churn.Total_Growth_IsIncrease > 0 ?
                                                            <Pill className='critical'>{props.data?.Churn.Total_Growth}%<ArrowUpIcon /></Pill> :
                                                            <Pill className='critical'>{props.data?.Churn.Total_Growth}%<ArrowDownIcon /></Pill>
                                                    }
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-12 col-lg-8 pl-lg-1'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-12 col-sm-7 mb-2 mb-sm-0'>
                                        <h6>Total Recordings</h6>
                                        <h1>{props.data?.Recordings.Total.toLocaleString('en')}
                                            {
                                                props.data?.Recordings.Total_Growth < 0 ?
                                                    <Pill className='critical'>{props.data?.Recordings.Total_Growth}%<ArrowDownIcon /></Pill> :
                                                    <Pill className='healthy'>{props.data?.Recordings.Total_Growth}%<ArrowUpIcon /></Pill>
                                            }
                                        </h1>
                                    </div>
                                    <div className='col-12 col-sm-5'>
                                        <h6>This Month</h6>
                                        <h1>{props.data?.Recordings.AddedThisMonth.toLocaleString('en')}</h1>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='row mx-0'>
                                    <div className='col border-right'>
                                        <h5>Call Recording Hours</h5>
                                        <div className='row'>
                                            <div className='col-12 col-xl-7'>
                                                <h6>Total</h6>
                                                <h2>{props.data?.RecordingHours.Total.toLocaleString('en')}</h2>
                                            </div>
                                            <div className='col-12 col-xl-5'>
                                                <h6>This Month</h6>
                                                <h2>{props.data?.RecordingHours.AddedThisMonth.toLocaleString('en')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h5>Recording Storage (TB)</h5>
                                        <div className='row'>
                                            <div className='col'>
                                                <h6>This Month</h6>
                                                <h2>{props.data?.RecordingStorage.AddedThisMonth.toLocaleString('en')}</h2>
                                                {/* <h6>Total</h6>
                                                <h2>{props.data?.RecordingStorage.Total.toLocaleString('en')}</h2> */}
                                            </div>
                                            {/* <div className='col-5'>
                                                <h6>This Month</h6>
                                                <h2>{props.data?.RecordingStorage.AddedThisMonth.toLocaleString('en')}</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            {
                props.isRevenue == "1" && props.year == 0 ?
                    <>
                    <div className='col-6 col-lg-6 pr-lg-1 mb-4'>
                <div className='row pb-1'>
                    <div className='col'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader className='ch-new-billing'>
                                <h6>New Billing with New Service Providers</h6>
                            </CardHeader>
                            <CardBody className='row mx-0'>
                                <div className='col-12 col-lg-12'>
                                    <Flex>
                                        <Flex.Item push>
                                            <Button icon={<EyeIcon />} content={newBillingView?.newSPByProduct ? "By Customer" : "By Product"} onClick={() => setNewBillingView({ ...newBillingView, newSPByProduct: !newBillingView.newSPByProduct })} />
                                        </Flex.Item>
                                    </Flex>
                                    <div className='row'>
                                        <div className='col-4 col-lg-4'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <h6>Total</h6>
                                                    <h2>£ {props.data?.NewBillingNewSP?.Total.toLocaleString('en')}</h2>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <h6>This Month</h6>
                                                    <h2>£ {props.data?.NewBillingNewSP?.ThisMonth.toLocaleString('en')}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-8 col-lg-8'>
                                            <div className='col'>
                                                {
                                                    newBillingView?.newSPByProduct ?
                                                        <PieChart title="By Product" categoryField="ProductCode" field="TotalValue" data={props.data?.BillingData?.filter(f => f.IsNewSPNewBilling == true)} />
                                                        :
                                                        <PieChart title="By Customer" categoryField="CustomerName" field="TotalValue" data={props.data?.BillingData?.filter(f => f.IsNewSPNewBilling == true)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            <div className='col-6 col-lg-6 mb-4'>
                <div className='row pb-1'>
                    <div className='col'>
                        <Card className='card-border bg-white rev-chart'>
                            <CardHeader className='ch-new-billing'>
                                <h6>New Billing with Existing Service Providers</h6>
                            </CardHeader>
                            <CardBody className='row mx-0'>
                                <div className='col-12 col-lg-12'>
                                    <Flex>
                                        <Flex.Item push>
                                            <Button icon={<EyeIcon />} content={newBillingView?.existingSPByProduct ? "By Customer" : "By Product"} onClick={() => setNewBillingView({ ...newBillingView, existingSPByProduct: !newBillingView.existingSPByProduct })} />
                                        </Flex.Item>
                                    </Flex>
                                    <div className='row'>
                                        <div className='col-4 col-lg-4'>
                                            <div className='row'>
                                                <div className='col'>
                                                    <h6>Total</h6>
                                                    <h2>£ {props.data?.NewBillingExistingSP?.Total.toLocaleString('en')}</h2>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <h6>This Month</h6>
                                                    <h2>£ {props.data?.NewBillingExistingSP?.ThisMonth.toLocaleString('en')} </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-8 col-lg-8'>
                                            <div className='col'>
                                                {
                                                    newBillingView?.existingSPByProduct ?
                                                        <PieChart title="By Product" categoryField="ProductCode" field="TotalValue" data={props.data?.BillingData?.filter(f => f.IsExistingSPNewBilling == true)} />
                                                        :
                                                        <PieChart title="By Customer" categoryField="CustomerName" field="TotalValue" data={props.data?.BillingData?.filter(f => f.IsExistingSPNewBilling == true)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div></>
                    :
                    <>
                    </>
            }
            


            {/* <div className='col-12 col-lg-7 pl-lg-1 mb-4'>
                <div className='row pb-1'>
                    <div className='col'>
                        <Card className='card-border bg-white'>
                           
                        </Card>
                    </div>
                </div>
            </div> */}


            {/* <div className='col-md-3 pl-1'>
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div className='h-50 mb-1'>
                    </div>
                    <div className='h-50 mt-1'>
                        
                    </div>
                </div>
            </div> */}
        </div>
        {disalogVisible && (
            <Dialog
                className="k-dialog-md"
                title={editMode ? "Edit Business Insight" : "Add Business Insight"}
                onClose={handleInsightAddEditClickClose}
            >
                <Form>
                    <FormTextArea
                        label={"Insight Detail*"}
                        id="alertId-inline"
                        fluid
                        value={formData?.Insight}
                        onChange={(value) => {
                            setFormData({
                                ...formData,
                                Insight: value.target.value,
                            })
                        }
                        }
                    />

                    <DialogActionsBar>
                        <Button content="Submit" primary
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addUpdateInsightSubmitClickHandler}
                            disabled={formData?.Insight?.length > 0 ? false : true}
                        />
                        <Button content="Cancel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleInsightAddEditClickClose}
                        />
                    </DialogActionsBar>
                </Form>
            </Dialog>
        )}
    </>
    )
}

export default TopKPI;