import * as React from 'react';
import { DiskChartContainer, MetricChartContainer } from '../../../../shared/appcomponents/custom-charts';
import { Card, Grid, Divider,Pill, PillGroup, Dropdown, CardBody, Text, Flex, Button, Header, Loader, Datepicker as FluentDatePicker, Dialog, Popup,FormDropdown,FlexItem,Checkbox } from '@fluentui/react-northstar';
import { FilterIcon } from '@fluentui/react-northstar';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import MonitoringService from '../../../../services/monitoringservice';
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';
import _ from 'lodash';
import { NativeDatePicker, TeamsDatePicker, GETOFFSETDATE } from '../../../../shared/appcomponents/custom-datetime-inputs';
import { WindowContext } from '../../../../shared/contexts/Context';
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';
import { ComboBox } from "@progress/kendo-react-dropdowns";

const periodFilterItemList = [
    { header: 'Today', value: 0, daystoreduce: 0 },
    { header: 'Last 7 days', value: 1, daystoreduce: 6 },
    { header: 'This Week', value: 2, daystoreduce: -1 },
    { header: 'This Month', value: 3, daystoreduce: -1 },
    { header: 'Last Month', value: 4, daystoreduce: -1 },
    { header: 'Custom Date Range', value: 5, daystoreduce: -1 }
];

const BASEUNITS = ['fit', 'minutes', 'hours', 'days', 'weeks', 'months'];

const PortalGraph = (props) => {
    let navigate = useNavigate(); 
    const routeChange = (path) =>{
        navigate(path);
    }
    const { id } = useParams(); // -1 : vm, -2 : storage account, else [Servers] Table
    const { InstanceId } = useParams();
    const { TenantId } = useParams();
    const [searchParams] = useSearchParams();
    const[isLoaded, setLoaded] = React.useState(false);
    const[serverName, setServerName] = React.useState("");
    const[incidentServerId, setIncidentServerId] = React.useState(null);
    const [portalOpen, setPortalOpen] = React.useState(false);
    const [servers, setServers] = React.useState(null);
    const [selectedServer, setSelectedServer] = React.useState(null);
    const [selectedPeriodId, setSelectedPeriodId] = React.useState(0);
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(GETOFFSETDATE(new Date(), 0));
    const [endDatePlaceholder, setEndDatePlaceholder] = React.useState(new Date());
    const [startDatePlaceholder, setStartDatePlaceholder] = React.useState(GETOFFSETDATE(new Date(), 0));
    const [metricData, setMetricData] = React.useState(null);
    const [baseUnit, setBaseUnit] = React.useState("hours");
    const [baseUnits, setBaseUnits] = React.useState(BASEUNITS);
    const [isDiskView, setDiskView] = React.useState(false);

    const [instances, setInstances] = React.useState(null);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const[instanceName, setInstanceName] = React.useState("");
    const [tenants, setTenants] = React.useState(null);
    const [selectedTenant, setSelectedTenant] = React.useState(null);
    const [days7Chk, setdays7Chk] = React.useState(false);

    React.useEffect(() => {   
        props.acquireToken((token) => {
            let _instanceId = searchParams.get('InstanceId');

            let params = {
              InstanceId: _instanceId
            };

            MonitoringService.GetPortalGraphOptions(token, params).then((response) => {
                // console.log(response);
                if(response && response.status==200){
                    response=response.data;
                    setInstances(response.Instances);
                    setTenants(response.Tenants);
                }
                else if(response && response.status==401){
                    props.viewStatus.InformChildPageStatus(response.status);
                  }
                  else {
                    props.viewStatus.InformChildPageStatus(500);
                  }

        
            })
        });        
    }, [selectedInstance]);

    React.useEffect(() => {
        setLoaded(false);
        props.acquireToken(authSuccessCallback);
    }, [searchParams])

    const authSuccessCallback = (token) => {             
        setLoaded(false);

        // let _selectedInstance = {};
        let _instanceId = searchParams.get('InstanceId');
        let _instanceName = searchParams.get('InstanceName');
        let _tenantId = searchParams.get('TenantId');
        let _tenantName = searchParams.get('TenantName');
        let _last7Days = searchParams.get('Last7Days');
        
        if(_instanceId && _instanceName){
            setSelectedInstance({
                value:_instanceId, header:_instanceName, key: _instanceId
            });
        }

        if(_tenantId && _tenantName){
            setSelectedTenant({
                value:_tenantId, header:_tenantName, key: _tenantId
            });
        }
       
        
        if (token) {      
            let params = {
                instanceId: _instanceId,
                tenantId : _tenantId,
                last7Days : _last7Days
            };
           
            MonitoringService.GetPortalGraph(token, params).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    formatMetricData(response);              
                }
                else if(response && response.status==401){
                    props.viewStatus.InformChildPageStatus(response.status);
                  }
                  else {
                    props.viewStatus.InformChildPageStatus(500);
                  }

        
                setLoaded(true);
            });
        }
    };

   

    const handleInstanceChange = (option) => {
        let _tenantName = "ALL";
        let _tenantId = 0;
        let _last7Days = searchParams.get('Last7Days');
        // if (serverName != option.header) {
        setSelectedInstance(option);
        setSelectedTenant({
            value:_tenantId, header:_tenantName, key: _tenantId
        });
        //   let path = `/dashboards/monitoring/portal/metrics/${option.value}?id=${option.incidentserverid}&name=${option.header}`;
        let path = `/dashboards/monitoring/portal/metrics/0?InstanceId=${option.value}&TenantId=${_tenantId}&InstanceName=${option.header}&TenantName=${_tenantName}&Last7Days=${_last7Days}`;
          
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
        // }
    };

    const handleTenantChange = (option) => {
        let _instanceName = selectedInstance.header;
        let _instanceId = selectedInstance.value;
        let _last7Days = searchParams.get('Last7Days');
        // if (serverName != option.header) {
        setSelectedTenant(option);
        //   let path = `/dashboards/monitoring/portal/metrics/${option.value}?id=${option.incidentserverid}&name=${option.header}`;
        let path = `/dashboards/monitoring/portal/metrics/0?InstanceId=${_instanceId}&TenantId=${option.value}&InstanceName=${_instanceName}&TenantName=${option.header}&Last7Days=${_last7Days}`;
          
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
        // }
    };


    const formatMetricData = (response) => {
        let _last7Days = searchParams.get('Last7Days');
        
        if(response){
            if(_last7Days == '1'){
                setMetricData({
                    PortalMetrics: response.PortalMetrics?.map((p) => {
                        p.Date = new Date(p.DatePeriod);
                        return p;
                    }),
                    CtiMetrics: response.CtiMetrics?.map((p) => {
                        p.Date = p.DateAdded;
                        return p;
                    })
                });
            }
            else{
                setMetricData({
                    PortalMetrics: response.PortalMetrics,
                    CtiMetrics: response.CtiMetrics?.map((p) => {
                        p.Date = p.DateAdded;
                        return p;
                    })
                });
            }
           
           
        }  
    };

    const checkChange = (e, data) => {
		setdays7Chk(data.checked);
        
        let _instanceName = selectedInstance.header;
        let _instanceId = selectedInstance.value;

        let _tenantId = selectedTenant.value;
        let _tenantName = selectedTenant.header;
        let _last7Days = data.checked  == true ? "1" : "0";
        let path = `/dashboards/monitoring/portal/metrics/0?InstanceId=${_instanceId}&TenantId=${_tenantId}&InstanceName=${_instanceName}&TenantName=${_tenantName}&Last7Days=${_last7Days}`;
          
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
	};


    return (
        <WindowContext.Consumer>
        {(context) =>
        <Card  className="card-border bg-white" fluid>
            <Card.Header>
                <div className='text-right'>
                    <Button onClick={() => navigate(-1)} content="Back" />  
                </div>
               
                <Flex vAlign="center" className="mr-2">
                    <div>
                        <Text as='h4' className="m-0" content="Instances: " />
                        {/* <Dropdown
                            className=""
                            items={instances ? instances : []}
                            // placeholder={instanceName}
                            // defaultValue={
                            //     instances  ? instances.find((d) => d.value == InstanceId) : {}
                            // }
                            value={selectedInstance}
                            onChange={(e, selectedOption) => {
                                setMetricData(null);
                                setLoaded(false);
                                handleInstanceChange(selectedOption.value);
                            }}
                        /> */}
                         <ComboBox
       
                            data={instances ? instances : []}
                            clearButton={false}
                            textField="header"
                            dataItemKey="value"
                            value={selectedInstance}
                            onChange={(e, selectedOption) => {
                                setMetricData(null);
                                setLoaded(false);
                                if ( e.target.value!=null ) {
                                    handleInstanceChange(e.target.value);
                                } else
                                {
                                    setSelectedInstance([]);
                                    setSelectedTenant([]);
                                }
                            }}
                        />

                    </div>
                    <div className="ml-2">
                        <Text as='h4'  className="m-0"  content="Tenants: " />
                        {/* <Dropdown
                            className=""
                            search
                            items={tenants ? tenants : []}
                            // placeholder={instanceName}
                            // defaultValue={
                            //     servers && serverName != "" ? servers.find((d) => d.header == serverName) : {}
                            // }
                            value={selectedTenant}
                            onChange={(e, selectedOption) => {
                                setMetricData(null);
                                setLoaded(false);
                                handleTenantChange(selectedOption.value);
                            }}
                        /> */}
                        <ComboBox
                            data={tenants ? tenants : []}
                            clearButton={false}

                            textField="header"
                            dataItemKey="value"
                            value={selectedTenant}
                            onChange={(e, selectedOption) => {
                                setMetricData(null);
                                setLoaded(false);
                                //if(selectedOption.value.len)
                                        
                            if ( e.target.value!=null ) {
                                handleTenantChange(e.target.value);
                            } else
                            {
                                setSelectedTenant([]);
                            }
                            }}
                        />
                    </div>
                    <FlexItem push>
                        <Checkbox 
                            onChange={checkChange} 
                            checked={days7Chk} 
                            label="Last 7 days"
                            className="permission-checkbox" style={ { marginRight: "-10px" } }>
                        </Checkbox>
                    </FlexItem>
                </Flex>  
            </Card.Header>
            <Card.Body>
                {
                <>
                {
                     <div className='row mb-3'>
                     <div className='col-sm-12'>
                         <Card className='card-border bg-white card-hov' fluid ghost elevated>
                             <CardBody>
                                 {
                                     isLoaded && metricData ?
                                     <MetricChartContainer categoryField={days7Chk ? "":  "PeriodHours"}  title="Call Count" yaxesLabel="Call Count" baseUnit="hours" data={metricData.PortalMetrics} series={[
                                             {
                                                 name: "Call Count",
                                                 field: "CallCount",
                                                 aggregate: "avg",
                                             },
                                             {
                                                 name: "Call Trend",
                                                 field: "CallTrend",
                                                 aggregate: "avg",
                                             }
                                         ]} />
                                     : <Loader /> 
                                 }                                
                             </CardBody>
                         </Card>
                     </div>
                     <div className='col-sm-12'>
                         <Card className='card-border bg-white card-hov' fluid ghost elevated>
                             <CardBody>
                                 {
                                     isLoaded && metricData ?
                                     <MetricChartContainer categoryField={days7Chk ? "":  "PeriodHours"}   title="Recording Count" yaxesLabel="Recording Count" baseUnit="hours" data={metricData.PortalMetrics} series={[
                                             {
                                                 name: "Recording Count",
                                                 field: "RecordingCount",
                                                 aggregate: "avg",
                                             },
                                             {
                                                 name: "Recording Trend",
                                                 field: "RecordingTrend",
                                                 aggregate: "avg",
                                             }
                                         ]} />
                                     : <Loader /> 
                                 }                                
                             </CardBody>
                         </Card>
                     </div>
                     <div className='col-sm-12'>
                         <Card className='card-border bg-white card-hov' fluid ghost elevated>
                             <CardBody>
                                 {
                                     isLoaded && metricData ?
                                     <MetricChartContainer  title="Stuck Calls" yaxesLabel="Stuck Calls Count" baseUnit="days" data={metricData.CtiMetrics} series={[
                                             {
                                                 name: "Stuck Calls Count",
                                                 field: "Count",
                                                 aggregate: "avg",
                                             }
                                             
                                         ]} />
                                     : <Loader /> 
                                 }                                
                             </CardBody>
                         </Card>
                     </div>
                 </div>
                }
                </>
                
                }
            </Card.Body>
        </Card>
        }
        </WindowContext.Consumer>
    )
};

export default PortalGraph