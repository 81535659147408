import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text,FormDropdown, FormCheckbox , Datepicker} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellConfigurationState } from "../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { DateInput } from "@progress/kendo-react-dateinputs";
const CUSTOMERPRIORITYLEVELS = [
    {key: 1, header: 'true', value: true},
    {key: 0, header: 'false', value: false}
]
const SettingsHoliday = (props) => {
	const [holidays, setholidays] = React.useState(null);
	const [holidayData, setholidayData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteHolidayObj, setDeleteHolidayObj] = useState(null);

	const [isHolidayIdValid, SetisHolidayIdValid] = React.useState(true);
	const [isHolidayIdDisabled, setIsHolidayIdDisabled] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "Id",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetHolidays(token).then((response) => {
        if(response && response.status==200){
            response=response.data;
          setholidays(response);
		}
		else if(response && response.status==401){
		props.viewStatus.InformChildPageStatus(response.status);
		}
		else {
		props.viewStatus.InformChildPageStatus(500);
		}  
		SetLoaded(true);    
      });
    }
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "Id", operator: "contains", value: value },
								{ field: "Name", operator: "contains", value: value },
								{ field: "Date", operator: "contains", value: value }
							],
    };
    setDataState({ ...dataState, filter: filter });
  };

const handleAddEditClickOpen = (dataItem, isEdit) => {
	SetisHolidayIdValid(true);
    setVisible(true);
		

    if (isEdit === true) {
			if(dataItem.Date && dataItem.Date.length > 0){
				let arr = dataItem.Date.split('-');
				if(arr && arr.length > 2){
					dataItem.CalenderDate = new Date(arr[1]+ '-' + arr[0] + '-' +  arr[2])
				}
			}
			
      setholidayData(dataItem);
      setEditMode(true);
			setIsHolidayIdDisabled(true);
      // checkIsValid();
    }
	else{
		setholidayData(null) ;
		setIsHolidayIdDisabled(false);
	}
};

const handleAddEditClickClose = () => {
    setVisible(false);
    setholidayData(null);
    setEditMode(false);
    setIsValid(false);
  };

	const ActionIconVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteHolidayObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteHolidayObj != null) {
      props.acquireToken((token) => {
        if (token) {
          let _params = {
            Id: deleteHolidayObj.Id,
          };
           SettingsService.RemoveHoliday(_params, token).then(
             (response) => {
                if(response && response.status==200){
				response=response.data;
                 props.acquireToken(authSuccessCallback);
                 props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
               }
			   else if(response && response.status==401){
				debugger;
				props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
	  
			  }
			  else {
				props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
			  }
             }
           );
        }
      });
    }
    setDeleteConfirmationDialog(false);
    setDeleteHolidayObj(null);
  };

	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		if(isValid == true){
			let sd = new Date(holidayData.CalenderDate);
			let startDate = sd.getFullYear() + '/' + (sd.getMonth() +1) + '/' + sd.getDate();
			holidayData.CalenderDate = startDate;
			props.acquireToken((token) => {
				if (token) {
					if (editMode) {
						SettingsService.EditHoliday(holidayData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							} 
							else if(response && response.status==200){
								response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}  else if(response && response.status==401){
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					  
							  }
							  else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							  }
						});
					} else {
						SettingsService.AddHoliday(holidayData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							} 
							if (response && response.status == 200) {
								response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}  else if(response && response.status==401){
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					  
							  }
							  else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							  }
						});
					}
				}      
				setVisible(false);
				setEditMode(false);
				setIsValid(false);
			});
		}
   
  };

	const CheckForm = () => {
    let isValid = true;
	
		if(holidayData?.Name && holidayData?.Name.toString().length > 0){
			isValid = true;
		}
		else{
			isValid = false;
			SetisHolidayIdValid(false);
		}
	
		return isValid;
		
  };


	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between">
								<div className="f-size-18">Holidays</div>
								{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
								
								<Button
									icon={<AddIcon />}
									iconPosition="before"
									content="Add Holiday"
									primary
									title="Add Holiday"
									onClick={handleAddEditClickOpen} className="ml-auto"
								/>}
							</div>
							<div>
								{visible && (
									<Dialog
									className="k-dialog-md"
										title={editMode ? "Edit Holiday" : "Add Holiday"}
										onClose={handleAddEditClickClose}
									>
										<Form 
										// onSubmit={submitHandler}
										>
											<FormInput
												label= {isHolidayIdValid == true ? "Name* " : <Text color="red" content="Name *Required" />}
												id="alertId-inline"
												fluid
												value={holidayData?.Name}
												onChange={(value) =>
													{
														if(value.target.value && value.target.value.length > 0){
															SetisHolidayIdValid(true);
														}
														else{
															SetisHolidayIdValid(false);
														}
														setholidayData({
															...holidayData,
															Name: value.target.value,
														})
													}
												}
											/>

											<Datepicker allowManualInput={false}  className="date-filter" 
												// selectedDate={holidayData ? new Date(holidayData?.Date): new Date()} 
												selectedDate={holidayData?.CalenderDate } 
												onDateChange={ (e, data) => {
													setholidayData({
															...holidayData,
															CalenderDate: data.value,
														})
												}}
											/>
											
											<DialogActionsBar>
												<button
													className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
													onClick={addEditSubmitClickHandler}
													// disabled={isValid === false}
												>
													{" "}
													Submit
												</button>
												<button
													className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
													onClick={handleAddEditClickClose}
												>
													{" "}
													Close
												</button>
											</DialogActionsBar>
										</Form>
									</Dialog>
								)}
							</div>
						</CardHeader>
						<CardBody>
							<div className="tbl-search float-right text-right mb-2">
								<Input
									onChange={(e, { name, value }) => {
										filterGridData(value);
									}}
									inverted
									icon={<SearchIcon />}
									placeholder="Search..."
									className="border-gray"
								/>
							</div>
							<div className='grid-with-search'>
								<Grid
									style={{
										height: "100%",
									}}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
									data={process(holidays, dataState)}
									{...dataState}
									onDataStateChange={(e) => {
										setDataState(e.dataState);
									}}
									//  detail={context.mediaCategory.sm ? DetailComponentSm : null}
									//  expandField="expanded"
									//  onExpandChange={expandChange}
								>
									<Column field="Name" title="Holiday" width={context.mediaCategory.sm ? 70: 100} />
									<Column field="Date" title="Date" width={context.mediaCategory.sm ? 70: 100} />
									
									{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
										<Column
											field=""
											title="Actions"
											style={{ textAlign: "center" }}
											width={40}
											cell={ActionIconVersions}
										/>
									}
								</Grid>
							</div>
						</CardBody>
					</Card>
				</div>
				<FluentDialog
					cancelButton="Cancel"
					confirmButton="Confirm"
					onCancel={() => {
						setDeleteConfirmationDialog(false);
						setDeleteHolidayObj(null);
					}}
					onConfirm={() => removeData()}
					open={openDeleteConfirmationDialog}
					backdrop
					header="Action confirmation"
					headerAction={{
						icon: <CloseIcon />,
						title: "Close",
						onClick: () => {
							setDeleteConfirmationDialog(false);
							setDeleteHolidayObj(null);
						},
					}}
					 content={`Are you sure to delete holiday: ${deleteHolidayObj?.Name}?`}
				/>
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { SettingsHoliday };
