import React from 'react'
import { Link } from 'react-router-dom';

const Error403 = () => {
    return (
        <div className="error">
            <div className="notfound">
                <div className="notfound-section">
                    <h1>Oops!</h1>
                    <h2>We are Sorry!
                        <br />The page you're trying to access has restricted access.
                    </h2>
                </div>
                <Link to="/" className="k-button k-primary py-3 px-5">Contact Administrator</Link>
            </div>
        </div>
    )
}

export { Error403 }