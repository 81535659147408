import * as React from 'react';
import { TextEditor } from './../serversm/texteditor';
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status , Text} from '@fluentui/react-northstar';
import SettingServerSM from "../../services/settingServerSM";
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon, FilesFlashIcon, DownloadIcon } from '@fluentui/react-icons-northstar'
import {getName, getFolderPath, getFileExtension , removeHomePrefix} from './../serversm/helper';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';


export const FileEditDialog = (props) => {

    const [data, setData] = React.useState(null);
    const [isLoaded, setLoaded] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const addEditSubmitClickHandler = () => {
      props.acquireToken((token) => {
        let _params = {
          Path: removeHomePrefix(getFolderPath(props?.fileObj?.path)),
          FileName : getName(props?.fileObj?.path),
          FileData : data,
          IPAddress : props?.serverObj?.IPAddress,
          Port : props?.serverObj?.Port
        };

        SettingServerSM.UpdateFile(token, _params).then((response) => {
          // console.log(response);
          if(response && response.status==200){
            response=response?.data;

            setData(response);
            setLoaded(true);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else{
            props.viewStatus.InformChildPageStatus(500);
          }
        });
        setLoaded(false);
      })
    };

    const handleAddEditFileClickClose = () => {
      setOpen(false);
    };

    const onChangeEdit = (changedData) => {
      setData(changedData);
    };

    let LoadData = () => {
      debugger;
        props.acquireToken((token) => {
          let _params = {
            Path: removeHomePrefix(getFolderPath(props?.fileObj?.path)),
            FileName : getName(props?.fileObj?.path),
            IPAddress : props?.serverObj?.IPAddress,
            Port : props?.serverObj?.Port
          };

          SettingServerSM.ReadFile(token, _params).then((response) => {
            // console.log(response);
            if(response && response.status==200){
              response=response?.data;
              debugger;
              setData(response);
              setLoaded(true);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
            }
            else{
              props.viewStatus.InformChildPageStatus(500);
            }
          });
          setLoaded(false);
        })
      }


      return (
   <FluentDialog
    styles={{ width: '80%', textAlign: "left" }}
    backdrop={true}
    closeOnOutsideClick={false}
    header="File"
    headerAction={{
      icon: <CloseIcon />,
      title: 'Close',
      onClick: () => setOpen(false),
    }}

    content={
      <>
        {data ? 
        <>      
        <Text size="medium" as="p" content={getName(props?.fileObj?.path)}/>
        <TextEditor data = {data} fileExtension = {getFileExtension(props?.fileObj?.path)} onChange = {onChangeEdit}></TextEditor>        
          </>
        :
        <Loader /> 
        }
          <DialogActionsBar>
                            <Button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditSubmitClickHandler}
                              content="Submit"
                              disabled={!data}
                            />
                            <Button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              content="Close"
                              onClick={handleAddEditFileClickClose}/>
                          </DialogActionsBar>
       
      </>
    }
    open={open}
    onOpen={() => { setOpen(true); LoadData(); }}
    trigger={
      <Button iconOnly size="small" icon={<FilesFlashIcon />} text title="Log Access" />
    }
    onCancel={() => setOpen(false)}
  />
      )
  };