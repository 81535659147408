import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, RadioGroup, Label, Datepicker, Checkbox
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellEmailLogs, CellEmail, CellDateEmailService, CellDateEmailServiceDt, CellEmailToken } from '../../shared/appcomponents/custom-grid-cells';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsAPITokens = (props) => {

	const [APITokens, setAPITokens] = React.useState(null);
	// const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "EmailSentDate",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});

	const emailDaysItems = [
		{
			name: '7 days',
			key: '7days',
			label: '7 days',
			value: '7',
		},
		{
			name: 'Select Date Range',
			key: 'all',
			label: 'Select Date Range:',
			value: '-1',
		},
	];

	const [emailDaysCheckedValue, setEmailDaysCheckedValue] = React.useState('7');

	const emailDaysChange = (e, data) => {

		setEmailDaysCheckedValue(data.value);

	};

	const applyDateFilter = () => {
		SetLoaded(false);
		setActive("false");

		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() + 1) + '/' + sd.getDate();
		let ed = addDays(new Date(selectedEndDate), 1);
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() + 1) + '/' + ed.getDate();

		props.acquireToken((token) => {
			if (token) {
				let _params = {
					EmailDays: emailDaysCheckedValue,
					startDate: startDate,
					endDate: endDate
				};
				SettingsService.GetAPITokens(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						setAPITokens(response);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
				});
			}
		});
	}

	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);
	}, []);

	const authSuccessCallback = (token) => {
		if (token) {
			let _params = {
				EmailDays: emailDaysCheckedValue,
			};
			SettingsService.GetAPITokens(token, _params).then((response) => {
				if (response && response.status == 200) {
					response = response.data;
					setAPITokens(response);
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};

	const filterGridData = (value) => {
		let filter = {
			logic: "or",
			filters: [{ field: "Token", operator: "contains", value: value },
			{ field: "CreatedDate", operator: "contains", value: value },
			{ field: "ExpiryDate", operator: "contains", value: value },
			],
		};
		setDataState({ ...dataState, filter: filter });
	};

	const ActionIconVersions = (props) => {
		return (
			<td style={{ textAlign: "center" }}>
				{/* <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/> */}
				<Button
					styles={{ height: "auto" }}
					iconOnly
					size="small"
					icon={<TrashCanIcon />}
					text
					onClick={() => {
						// setDeleteAlertObj(props.dataItem);
						// setDeleteConfirmationDialog(true);
					}}
				/>
			</td>
		);
	};

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>

				</FluentGrid>
			</section>
		);
	};

	const expandChange = (event) => {
		let newData = APITokens.map((item) => {
			if (item.alertId === event.dataItem.alertId) {
				item.expanded = !event.dataItem.expanded;
			}
			return item;
		});
		setAPITokens(newData);
	};

	const addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	const CellEmailCol = (prop) => {
		return (
			<CellEmail {...prop} acquireToken={props.acquireToken} />
		);
	}

	const [isActive, setActive] = useState("false");
	const ToggleClass = () => {
		setActive(!isActive);
	};

	const addNewTokenHandler = () => {
		let data = {};
		props.acquireToken((token) => {
			if (token) {
				SettingsService.AddAPIToken(data, token).then((response) => {
					if (response && response.data == '-1') {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
					if (response && response.status == 200) {
						response = response.data;
						props.acquireToken(authSuccessCallback);
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
					} else if (response && response.status == 401) {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					}
					else {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
				});
			}
		});


	};

	const CellCheckboxEnabledDisabled = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<td style={{ textAlign: "center" }}>
				<Checkbox
					toggle
					checked={dataItem.IsActive}
					onClick={(event, isChecked) => {
						dataItem.IsActive =  isChecked.checked;
						props.acquireToken((token) =>
							authSuccessUpdateTogglesCallback(token, dataItem)
						);
					}}
				/>
			</td>
		);
	};

	const authSuccessUpdateTogglesCallback = (token, data) => {
    if (token) {
      SettingsService.UpdateAPIToken(token, data).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else if (response && response.status == 401) {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    }
  };

	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100">
						<div className="col-md-12 h-100">
							<Card className="card-border bg-white h-100" fluid>
								<CardHeader className="mb-2">
									<div className="d-flex justify-content-between position-relative">
										<div className="f-size-18">API Tokens</div>
										<Button
											icon={<AddIcon />} iconPosition="before" content="Add New Token" primary title="New Token" className="ml-auto"
											onClick={addNewTokenHandler}
										/>
									</div>

								</CardHeader>
								<CardBody>
									<div className="tbl-search float-right text-right mb-2">
										<Input
											onChange={(e, { name, value }) => {
												filterGridData(value);
											}}
											inverted
											icon={<SearchIcon />}
											placeholder="Search..."
											className="border-gray"
										/>
									</div>
									<div className='grid-with-search'>
										<Grid
											style={{
												height: "100%",
											}}
											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(APITokens, dataState)}
											{...dataState}
											onDataStateChange={(e) => {
												setDataState(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>
											<Column field="Token" title="Token" cell={CellEmailToken} width={320} />
											<Column field="CreatedDate" title="Created On"  	cell={CellDateEmailService} width={120} />
											<Column field="ExpiryDate" title="Expiry Date" cell={CellDateEmailServiceDt} width={120} />
											<Column field="IsActive" title="Enabled" cell={CellCheckboxEnabledDisabled} width={80}  
												filterable={false} sortable={false} />
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { SettingsAPITokens };
