import React, { useState } from 'react'
import { Flex, Box, Loader } from '@fluentui/react-northstar'

const Accessdenied = (props) => {
    return (
    <>
      <Box className="settings pt-3 pb-1">
        <Flex fill className="error" hAlign="center" vAlign="center">
            <div className="notfound">
              <div className="notfound-section">
                <h1>Oops!</h1>
                <h2>Access Denied!</h2>
              </div>
            </div>
          </Flex>
      </Box>
    </>
  )
};

export { Accessdenied }