import axios from 'axios'
import * as constants from '../../Constants'

class SettingServerSM {
  static AuthenticatedApi = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static GetAllServersAndVMs(token) {
    return this.AuthenticatedApi.get("/ServerSM/GetAllServersAndVMs/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetAllEntries(token) {
    return this.AuthenticatedApi.get("/ServerSM/GetAllEntries/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetAllLogs(token, logObj) {
    return this.AuthenticatedApi.post("/ServerSM/GetLogs/", logObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetProcessDetail(token, processObj) {
    return this.AuthenticatedApi.post("/ServerSM/GetProcessDetail/", processObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }


  static ReadFile(token, fileObj) {
    return this.AuthenticatedApi.post("/ServerSM/ReadFile/", fileObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static UploadFile(token, fileObj) {
    return this.AuthenticatedApi.post("/ServerSM/UploadFile/", fileObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static AddNewFolder(token, folderObj) {
    return this.AuthenticatedApi.post("/ServerSM/AddFolder/", folderObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static UpdateFile(token, fileObj) {
    return this.AuthenticatedApi.post("/ServerSM/UpdateFile/", fileObj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }
 
  static GetConnectionStatus(server, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetConnectionStatus/", server, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static AddServer(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/InsertEntry/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetPowershellScripts(token) {
    debugger;
    return this.AuthenticatedApi.get("/ServerSM/GetPowershellScripts/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
    return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  }
  
  static UpdatePowershellScriptData(token, requestData) {
    debugger;
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
  
      return this.AuthenticatedApi.post("/ServerSM/UpdatePowershellScriptData/", requestData, {
        headers: headers,
      })
        .then((response) => {
          return response.data; 
        })
        .catch((error) => {
          if (error.response) {
          } else if (error.request) {

          } else {

          }
          throw error; 
        });
    } catch (error) {
      throw error;
    }
  }
  
  static GetScriptFiles(token) {
    debugger;
    return this.AuthenticatedApi.get("/ServerSM/GetScriptFiles/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetPowershellFolders(token) {
    debugger;
    return this.AuthenticatedApi.get("/ServerSM/GetPowershellFolders/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetPowershellFilesWithContent(token) {
    debugger;
    return this.AuthenticatedApi.get("/ServerSM/GetPowershellFilesWithContent/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static AddSite(siteSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/InsertSite/", siteSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static AddBinding(bindingSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/InsertBindings/", bindingSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetServerOverview(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetServerOverview/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }


  static GetServerServices(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetServerServices/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static StartService(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/StartService/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static StopService(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/StopService/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetServerProcesses(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetServerProcesses/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetServerFolders(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetFolders/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }


  static AddFolder(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/AddFolder/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static KillProcess(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/KillProcess/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetIISProcesses(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/GetIISList/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static GetEvents(serverSMData, token) {
    return this.AuthenticatedApi.post("/ServerEvent/GetEvents/", serverSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }

  static RemoveSite(siteSMData, token) {
    return this.AuthenticatedApi.post("/ServerSM/DeleteSite/", siteSMData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response;
})
.catch((error) => {
  return error.response.data;
});
  }
}

export default SettingServerSM;