import { useState, useEffect } from "react";
import {
  isMobileOnly,
  isTablet,
  isDesktop,
  withOrientationChange,
} from "react-device-detect";

const getMediaCategory = (windowWidth) => {
  let mediaCategory = { xlg: false, lg: false, md: false, sm: false };
  if (windowWidth) {
    if (windowWidth < 799) {
      mediaCategory.sm = true;
    } else if (windowWidth < 959) {
      mediaCategory.md = true;
    } else if (windowWidth < 1279) {
      mediaCategory.lg = true;
    } else {
      mediaCategory.xlg = true;
    }
  } else {
    return undefined;
  }
  return mediaCategory;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth });
  const [mediaCategory, setMediaCategory] = useState(
    getMediaCategory(window.innerWidth)
  );

  //const { isLandscape, isPortrait } = props;
  const listener = () => {
    let d = {
      m: isMobileOnly,
      t: isTablet,
      d: isDesktop,
      //op: isPortrait,
      //ol: isLandscape
    };
    setMediaCategory(getMediaCategory(window.innerWidth));
    setWindowSize({ width: window.innerWidth });
  };

  useEffect(() => {
    let d = {
      m: isMobileOnly,
      t: isTablet,
      d: isDesktop,
      //op: isPortrait,
      //ol: isLandscape
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return [{ windowSize, mediaCategory }, {}];
};
