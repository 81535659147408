import React from 'react'
import { Card, Flex, Text, Button, Tooltip, Toolbar } from '@fluentui/react-northstar'
import { PlugsIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../shared/contexts/Context';

const ServiceDashboard = (props) => {
    const [viewArea, setViewArea] = React.useState("");
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const routeChange = (path, varea) => {
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
    }
    React.useEffect(() => {
        let varea = searchParams.get('type');
        if (varea) {
            setViewArea(varea);
        }
        else {
            if (window.location.pathname.includes('history')) {
                setViewArea('history');
            }
            else {
                setViewArea("overview");
            }
        }
    }, []);
    return (
        <>
            <div className='dashboard-top'>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12 mt-2 mb-3'>
                        <Card className='card card-service' elevated >
                            <Card.Body>
                                <Toolbar
                                    items={[
                                        {
                                            key: 'custom-text',
                                            content: <Text content="Service Alarms" />,
                                            kind: 'custom',
                                            className: 'heading',
                                            fitted: 'horizontally',
                                        },
                                        {
                                            key: 'custom-focusable-text',
                                            content: <Button icon={10} content="Critical Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=critical', 'critical')} />,
                                            focusable: true,
                                            kind: 'custom',
                                            active: true,
                                            className: 'btn critical',
                                            fitted: 'horizontally',
                                        },
                                        {
                                            key: 'custom-button',
                                            kind: 'custom',
                                            content: <Button icon={7} content="Warning Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=warning', 'warning')} />,
                                            fitted: 'horizontally',
                                            className: 'btn warning',
                                        },
                                        {
                                            key: 'custom-split-button',
                                            kind: 'custom',
                                            content: <Button icon={83} content="Healthy Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=healthy', 'healthy')} />,
                                            fitted: 'horizontally',
                                            className: 'btn healthy',
                                        },
                                        {
                                            key: 'custom-critical-servers',
                                            kind: 'custom',
                                            content: <Tooltip pointing trigger={<Button className={`${viewArea == "overview" ? 'selected' : ''}`} icon={<PlugsIcon />} text iconOnly onClick={() => routeChange('/dashboards/service/overview', 'overview')} />} content="Service (Nlog) Overview" />,
                                            fitted: 'horizontally',
                                            className: 'card-actions',
                                        },
                                        {
                                            key: 'custom-healthy-servers',
                                            kind: 'custom',
                                            content: <Tooltip pointing trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text iconOnly onClick={() => routeChange('/dashboards/service/history', 'history')} />} content="Historical Alarms" />,
                                            fitted: 'horizontally',
                                            className: 'card-actions',
                                        },
                                    ]}
                                >
                                </Toolbar>
                                <PlugsIcon outline rotate={0} size="largest" />
                                {/* <div className='row mt-3'>
                                <div className='col-sm-4 pr-1'>
                                    <div className={`d-flex btn critical ${viewArea == "critical" ? 'selected' : ''}`}>
                                        <Button icon={10} content="Critical Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=critical', 'critical')} />
                                    </div>
                                </div>
                                <div className='col-sm-4 pr-1 pl-1'>
                                    <div className={`d-flex btn warning ${viewArea == "warning" ? 'selected' : ''}`}>
                                        <Button icon={7} content="Warning Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=warning', 'warning')} />
                                    </div>
                                </div>
                                <div className='col-sm-4 pl-1'>
                                    <div className={`d-flex btn healthy ${viewArea == "healthy" ? 'selected' : ''}`}>
                                        <Button icon={83} content="Healthy Services" iconPosition="after" onClick={() => routeChange('/dashboards/service/alarms?type=healthy', 'healthy')} />
                                    </div>
                                </div>
                            </div> */}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='dashboard-details mb-3'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export const ServiceMonitoring = (props) => {

    return (
        <Outlet />
    )
}

export default ServiceDashboard;