import React , { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize, useTeams } from '../customhooks';
import * as constants from "../../../Constants";

export const setRouteInLocalStorage = (key, path) => {
    if (global.localStorage) {
        global.localStorage.setItem(
          key,
          path
        );
      }
}

export const getRouteFromLocalStorage = (key) => {
    let url = '';
    if (global.localStorage) {
        try {
            url = global.localStorage.getItem(key) || '';
        } catch (e) {
            /*Ignore*/
        }
    }

    if (url === '') {
        if (global.localStorage) {
            global.localStorage.setItem(
                key,
                '/' + key.split('_')[1]
            );
        }
        url = '/' + key.split('_')[1];
    }
    return url;
}

export const WindowContext = React.createContext();

export const WindowProvider = ({ children }) => {
    let navigate = useNavigate();
    const [{windowSize, mediaCategory}, {}] = useWindowSize();
    const options = {
        initialTheme: "default",
        contentUrl: getRouteFromLocalStorage('contentUrl_' + (window.location.pathname.includes('settings') ? 'settings' : 'dashboards'))
    };
    const [{ inTeams, fullScreen, theme, themeString, hostClientType }, { setTheme }] = useTeams({ options });
    const [operationStatus, DisplayOperationStatus] = useState(null);
    const [pageStatus, setPageStatus] = useState(null);
    const [childpageStatus, InformChildPageStatus] = useState(null);
    useEffect(() => {
        if (!constants.ISLOCAL) { navigate(options.contentUrl); }
    }, []);

    return (
        <WindowContext.Provider
            value={{
                windowSize: windowSize,
                mediaCategory: mediaCategory,
                teams:{
                    inTeams: inTeams,
                    fullScreen: fullScreen,
                    theme: theme,
                    themeString: themeString,
                    hostClientType: hostClientType,
                    setTheme: setTheme
                },
                constants: constants,
                viewStatus:{
                    operationStatus: operationStatus,
                    pageStatus: pageStatus,
                    childpageStatus: childpageStatus,
                    DisplayOperationStatus: DisplayOperationStatus,
                    setPageStatus: setPageStatus,
                    InformChildPageStatus: InformChildPageStatus
                }
            }}
        >
            {children}
        </WindowContext.Provider>
    );
}
