import React from 'react'
import { Card, Loader, Text, Button, Tooltip, Toolbar } from '@fluentui/react-northstar'
import { AppsIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';
import { PortalAlarm } from './recent';
import { PortalAlarmHistorical } from './historical';
import PortalAvailability from './availability';
import { INCIDENTLEVELS ,OPERATIONSTATUS} from '../../../../shared/appcomponents/enums';
import MonitoringService from '../../../../services/monitoringservice';

const PortalIncident = (props) => {
    const { id } = useParams();
    const [viewArea, setViewArea] = React.useState("");
    const [incidents, setIncidents] = React.useState(null);
    const [isLoaded, setLoaded] = React.useState(false);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const routeChange = (path, varea, refresh) =>{
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        if(refresh == 1){
            // navigate(path + '&rf=' + Math.floor((Math.random() * 100)));
            props.updateUser();
        }
        else{
            navigate(path);
        }
    }

    const instanceId =  id ? id :  -1 ;

    React.useEffect(() => {
        setLoaded(false);
        let _alarmType = searchParams.get('type');
        if(_alarmType){
            setViewArea(_alarmType);
        }
        else{
            _alarmType = '_alarmType';
            setViewArea("critical");
        }
        props.acquireToken((token) => {
            if(token){                
                let _params = {
                    instanceId: id ? id : -1,
                    incidentLevelId : _alarmType != 'history' ? INCIDENTLEVELS[_alarmType] : -1,
                    appliedFilters:  id
                                    ? [`InstanceId IN (${id})`]
                                    : [],
                };
                MonitoringService.GetDataIncidents(token, _params).then((response) => {
                    if(response && response.status==200){
                        response=response.data;
                      setIncidents(response);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                      }
                      else {
                        props.viewStatus.InformChildPageStatus(500);
                      }

                    setLoaded(true);
                });
            }
        });
    }, [searchParams]);

    return(
        <Card fluid className='card card-portal bg-white' elevated >
            <Card.Header>
                <Toolbar
                items={[
                    {
                        key: 'custom-text',
                        content: <Text className='f-size-20' content={`Data Alarms ${viewArea != '' ? ': ' + viewArea.toUpperCase() : ''}`} />,
                        kind: 'custom',
                        className: 'heading',
                        fitted: 'horizontally',
                    },
                    {
                        key: 'custom-portal-critical',
                        content: <Button icon={incidents ? incidents.Critical : '-'} content="Critical" className={`${viewArea == "critical" ? 'selected' : ''}`} onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId != -1 ? instanceId: ''}?type=critical`, 'critical')} iconPosition="after" />,
                        focusable: true,
                        kind: 'custom',
                        active: true,
                        className: 'btn critical',
                        fitted: 'horizontally',
                    },
                    {
                        key: 'custom-portal-warning',
                        kind: 'custom',
                        content: <Button icon={incidents ? incidents.Warning : '-'} content="Warning" className={`${viewArea == "warning" ? 'selected' : ''}`} onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId != -1 ? instanceId: ''}?type=warning`, 'warning')} iconPosition="after" />,
                        fitted: 'horizontally',
                        className: 'btn warning',
                    },
                    {
                        key: 'custom-portal-down',
                        kind: 'custom',
                        content: <Button icon={incidents ? incidents.Down : '-'} content="Instances Down" className={`${viewArea == "down" ? 'selected' : ''}`} onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId != -1 ? instanceId: ''}?type=down`, 'down')} iconPosition="after" />,
                        fitted: 'horizontally',
                        className: 'btn down',
                    },
                    {
                        key: 'custom-portal-history',
                        kind: 'custom',
                        content: <Tooltip pointing trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text iconOnly onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId != -1 ? instanceId: ''}?type=history`, 'history')} />} content="Historical Alarms" />,
                        fitted: 'horizontally',
                        className: 'card-actions',
                    },
                    ]}
                >
                </Toolbar>
            </Card.Header>
            <Card.Body>
                {
                    viewArea != "" && isLoaded ?
                    (
                        viewArea != 'history' ?
                        <PortalAlarm {...props} incidents={incidents} alarmType={viewArea} routeChange={routeChange} />
                        :
                        <PortalAlarmHistorical {...props} incidents={incidents} alarmType={viewArea} routeChange={routeChange} />
                    )
                    :
                    <Loader />
                }
            </Card.Body>
        </Card>
    )
}

export default PortalIncident;