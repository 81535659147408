import React from 'react'
import { Card, Flex, Text, Button, Tooltip, Toolbar, Loader } from '@fluentui/react-northstar'
import { BroadcastIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams,useParams, useOutletContext } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';
import { InfraAlarmHistorical } from './historical';
import { InfraAlarm } from './recent';
import { INCIDENTLEVELS } from '../../../../shared/appcomponents/enums';
import MonitoringService from '../../../../services/monitoringservice';
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';

const InfraIncident = (props) => {
    const { id } = useParams();
    const [viewArea, setViewArea] = React.useState("");
    const [incidents, setIncidents] = React.useState(null);
    const [isLoaded, setLoaded] = React.useState(false);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const routeChange = (path, varea, refresh) =>{
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        if(refresh == 1){
            props.updateUser();
        }
        else{
            navigate(path);
        }
    }

    const instanceId =  id ? id :  -1 ;
   
    React.useEffect(() => {
        setLoaded(false);
        let _alarmType = searchParams.get('type');
        if(_alarmType){
            setViewArea(_alarmType);
        }
        else{
            _alarmType = 'critical';
            setViewArea("critical");
        }
        props.acquireToken((token) => {
            if(token){                
                let _params = {
                    instanceId: id ? id : -1,
                    incidentLevelId : _alarmType != 'history' ? INCIDENTLEVELS[_alarmType] : -1,
                    appliedFilters:  id
                    ? [`InstanceId IN (${id})`]
                    : [],
                };
                MonitoringService.GetInfraIncidents(token, _params).then((response) => {
                    if(response && response.status==200){
                      response=response.data;
                      setIncidents(response);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                      }
                      else {
                        props.viewStatus.InformChildPageStatus(500);
                      }
            
                    setLoaded(true);
                });
            }
        });
    }, [searchParams]);

    return(
        <>
        <Card fluid className='card card-infra bg-white' elevated >
            <Card.Header>
            <Toolbar
                items={[
                    {
                        key: 'custom-text',
                        content: <Text className='f-size-20' content={`Infrastructure Alarms ${viewArea != '' ? ': ' + viewArea.toUpperCase() : ''}`} />,
                        kind: 'custom',
                        className: 'heading',
                        fitted: 'horizontally',
                    },
                    {
                        key: 'custom-focusable-text',
                        content: <Button icon={incidents ? incidents.Critical : '-'} className={`${viewArea == "critical" ? 'selected' : ''}`} content="Critical" iconPosition="after"  onClick={() => routeChange(`/dashboards/monitoring/infra/alarms/${instanceId != -1 ? instanceId: ''}?type=critical`, 'critical')} />,
                        focusable: true,
                        kind: 'custom',
                        active: true,
                        className: 'btn critical',
                        fitted: 'horizontally',
                    },
                    {
                        key: 'custom-button',
                        kind: 'custom',
                        content: <Button icon={incidents ? incidents.Warning : '-'} className={`${viewArea == "warning" ? 'selected' : ''}`} content="Warning" iconPosition="after"  onClick={() => routeChange(`/dashboards/monitoring/infra/alarms/${instanceId != -1 ? instanceId: ''}?type=warning`, 'warning')} />,
                        fitted: 'horizontally',
                        className: 'btn warning',
                    },
                    {
                        key: 'custom-infra-history',
                        kind: 'custom',
                        content: <Tooltip pointing iconOnly trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text onClick={() => routeChange(`/dashboards/monitoring/infra/alarms/${instanceId != -1 ? instanceId: ''}?type=history`, 'history')} />} content="Historical Alarms" />,
                        fitted: 'horizontally',
                        className: 'card-actions',
                    },
                    ]}
                >
                </Toolbar>
            </Card.Header>
            <Card.Body>                            
                {
                    viewArea != "" && isLoaded ?
                    (
                        viewArea != 'history' ?
                        <InfraAlarm {...props} 
                        incidents={incidents} alarmType={viewArea} routeChange={routeChange} />
                        :
                        <InfraAlarmHistorical {...props}
                        incidents={incidents} alarmType={viewArea} routeChange={routeChange}/>
                    )
                    :
                    <Loader />
                }
            </Card.Body>
        </Card>
        </>
    )
}

export default InfraIncident;