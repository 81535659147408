import * as React from 'react';
import { Chart, ChartBreadcrumb, exportVisual, ChartSeriesLabels, ChartLegend, ChartValueAxisItem, ChartValueAxis, ChartTitle, ChartSeries, ChartTooltip, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartSeriesItemTooltip } from '@progress/kendo-react-charts';
import 'hammerjs';
import { useFullscreenStatus } from '../customhooks';
import { Button, Loader, Text, Dropdown } from '@fluentui/react-northstar';
import { ExpandIcon, CollapseIcon, FilesImageIcon, FilterIcon } from '@fluentui/react-northstar';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { formatDate } from '@progress/kendo-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import RevenueService from "../../services/revenueservice";
import { useCallback } from 'react';
import ReactFlow, {
  Handle, Position,
  NodeResizer,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge, MarkerType,
  useStore, getBezierPath
} from 'reactflow';


const getDateFormat = (baseUnit) => {

  let format = '';
  switch (baseUnit) {
    case 'minutes':
      format = "MMM dd yyyy HH:mm";
      break;

    case 'hours':
      format = "MMM dd yyyy HH:mm"
      break;

    case 'fit':
      format = "MMM dd yyyy HH:mm"
      break;

    case 'days':
      format = "MMM dd yyyy"
      break;
    case 'weeks':
      format = "MMM dd yyyy"
      break;
    case 'months':
      format = "MMM yyyy"
      break;
    case 'years':
      format = "yyyy"
      break;
    default:
      format = "MMM dd yyyy HH:mm"
      break;

  }
  return format;


}

export const MetricChartContainer = (props) => {

  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };
  const renderTooltip = ({ point }) => {
    return (
      <span>
        {formatDate(new Date(point.dataItem.MetricTimeStamp), getDateFormat(props.baseUnit))}
        : {point.value}
      </span>
    )
  };


  const renderTooltip1 = ({ point }) => {
    return (<span>
      {`Size: ${point.dataItem.Size} GB, Used: ${point.dataItem.UsedSpace} GB, Free: ${point.dataItem.FreeSpace} GB`}
    </span>
    )
  };

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        props.data ?
          <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom"> </ChartLegend>
            <ChartTooltip render={renderTooltip} />
            <ChartSeries>
              {props.series?.map((s) => (
                <ChartSeriesItem aggregate={s.aggregate} markers={{
                  visible: false,
                }} type="line" field={s.field} categoryField={props.categoryField ? props.categoryField : "Date"} name={s.name} data={props.data} key={s.name} autoFit="true">
                  <ChartSeriesItemTooltip format={`${s.name}: {0}`} />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartCategoryAxis>
              {
                props.categoryField == "PeriodHours" ?
                  <ChartCategoryAxisItem
                    maxDivisions={30}
                    labels={{
                      rotation: "-45",
                    }}
                  />
                  :
                  <ChartCategoryAxisItem
                    maxDivisions={30}
                    baseUnit={props.baseUnit} labels={{
                      rotation: "auto",
                      dateFormats: {
                        minutes: "MMM dd yyyy HH:mm",
                        hours: "MMM dd yyyy HH:mm",
                        days: "MMM dd yyyy",
                        weeks: "MMM dd yyyy",
                        months: "MMM yyyy",
                        years: "yyyy"
                      }
                    }}
                  />
              }
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{
                  text: props.yaxesLabel,
                }}
              />
            </ChartValueAxis>
          </Chart>
          : <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom"> </ChartLegend>
            <ChartTooltip render={renderTooltip1} />
            <ChartSeries>
              <ChartSeriesItem
                name="FreeSpace"
                data={props.series}
                type="column"
                field="FreeSpace"
                stack={{
                  type: "100%",
                }}
                categoryField="MetricKey"
              />
              <ChartSeriesItem
                name="UsedSpace"
                data={props.series}
                type="column"
                field="UsedSpace"
                stack={{
                  type: "100%",
                }}
                categoryField="MetricKey"
              />
              <ChartSeriesItem
                name="Size"
                data={props.series}
                type="column"
                field="Size"
                stack={{
                  type: "100%",
                }}
                categoryField="MetricKey"
              />
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem labels={{
                rotation: 'auto'
              }} />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{
                  text: props.yaxesLabel,
                }}
              />
            </ChartValueAxis>
          </Chart>
      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
};

export const DiskChartContainer = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };
  const renderTooltip = ({ point }) => {
    return (<span>
      {`Size: ${point.dataItem.Size} GB, Used: ${point.dataItem.UsedSpace} GB, Free: ${point.dataItem.FreeSpace} GB`}
    </span>
    )
  };

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        props.series ?
          <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom"> </ChartLegend>
            <ChartTooltip render={renderTooltip} />
            <ChartSeries>
              <ChartSeriesItem
                name="FreeSpace"
                aggregate={props.aggregate}
                data={props.series}
                type="column"
                field="FreeSpace"
                stack={{
                  type: "100%",
                }}
                categoryField="Date"
              />
              <ChartSeriesItem
                name="UsedSpace"
                aggregate={props.aggregate}
                data={props.series}
                type="column"
                field="UsedSpace"
                stack={{
                  type: "100%",
                }}
                categoryField="Date"
              />
              <ChartSeriesItem
                name="Size"
                aggregate={props.aggregate}
                data={props.series}
                type="column"
                field="Size"
                categoryField="Date"
                stack={{
                  type: "100%",
                }}
              />
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem maxDivisions={30}
                baseUnit={props.baseUnit} labels={{
                  rotation: 'auto',
                  dateFormats: {
                    minutes: "MMM dd yyyy HH:mm",
                    hours: "MMM dd yyyy HH:mm",
                    days: "MMM dd yyyy",
                    months: "MMM yyyy",
                    years: "yyyy"
                  }
                }} />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{
                  text: props.yaxesLabel,
                }}
              />
            </ChartValueAxis>
          </Chart> : <></>
      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
};

export const AreaChartContainer = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };

  const renderTooltip = ({ point }) => {
    return (<span>
      {`Total: ${point.dataItem.TotalTenants}, Growth: ${point.dataItem.TotalGrowth}, Churn: ${point.dataItem.TotalChurn}`}
    </span>
    )
  };

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        props.series ?
          <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom"> </ChartLegend>
            <ChartTooltip render={renderTooltip} />
            <ChartSeries>
              <ChartSeriesItem
                name="TotalTenants"
                data={props.series}
                type="area"
                field="TotalTenants"
                categoryField="Date"
              />
              <ChartSeriesItem
                name="TotalGrowth"
                data={props.series}
                type="area"
                field="TotalGrowth"
                categoryField="Date"
              />
              <ChartSeriesItem
                name="TotalChurn"
                data={props.series}
                type="area"
                field="TotalChurn"
                categoryField="Date"
              />
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                maxDivisions={30}
                baseUnit={"months"} labels={{
                  rotation: 'auto',
                  dateFormats: {
                    minutes: "MMM dd yyyy HH:mm",
                    hours: "MMM dd yyyy HH:mm",
                    days: "MMM dd yyyy",
                    weeks: "MMM dd yyyy",
                    months: "MMM yyyy",
                    years: "yyyy"
                  }
                }} />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                title={{
                  text: props.yaxesLabel,
                }}
              />
            </ChartValueAxis>
          </Chart>
          : <></>
      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
};

const predict = (data, newX) => {
  const round = (n) => Math.round(n * 100) / 100;

  const sum = data.reduce(
    (acc, pair) => {
      const x = pair[0];
      const y = pair[1];

      if (y !== null) {
        acc.x += x;
        acc.y += y;
        acc.squareX += x * x;
        acc.product += x * y;
        acc.len += 1;
      }

      return acc;
    },
    { x: 0, y: 0, squareX: 0, product: 0, len: 0 }
  );

  const run = sum.len * sum.squareX - sum.x * sum.x;
  const rise = sum.len * sum.product - sum.x * sum.y;
  const gradient = run === 0 ? 0 : round(rise / run);
  const intercept = round(sum.y / sum.len - (gradient * sum.x) / sum.len);

  return round(gradient * newX + intercept);
};

const getForecastMonths = (_date) => {
  var months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  var starting_month = 3;

  let _currentMonth = _date.getMonth();
  let _currentYear = _date.getFullYear();
  let _startYear = _currentMonth < 4 ? _currentYear - 1 : _currentYear;
  let _endYear = _currentMonth < 4 ? _currentYear : _currentYear + 1;
  let count = 0;
  let financial_months = [];
  var index = months.indexOf(starting_month);
  let _dateYear = _startYear;
  while (count < 12) {
    financial_months.push(new Date(_dateYear, months[index], 1));
    index++;

    if (index === months.length) {
      index = 0;
      _dateYear = _endYear;
    }
    count++;
  }
  return financial_months.filter(f => f.getFullYear() > _startYear || (f.getFullYear() == _startYear && f.getMonth() >= _currentMonth)).map(m => ({ Date: m }));
}

export const ForecastChart = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const [historyData, setHistoryData] = React.useState([]);
  const [forecastData, setForecastData] = React.useState([]);

  React.useEffect(() => {
    var forecast = getForecastMonths(new Date());
    props.data?.map((t) => {
      t.Date = new Date(t[props.xField]);
      t.ForecastValue = t[props.yField];
    });
    if (props.data) {
      let history = props.data;
      setHistoryData(history);
      if (props.isForecast == true) {
        var historyIndex = history.map((d, i) => [i, d.ForecastValue]);
        forecast = forecast.map((d, i) => {
          return {
            Date: new Date(d.Date),
            ForecastValue: predict(historyIndex, historyIndex.length - 1 + i),
          };
        });
        forecast.unshift(history);
        setForecastData(forecast);
      }
    }
  }, [props.data]);

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };
  const renderTooltip = ({ point }) => {
    return (<span>
      {point.value}
    </span>
    )
  };

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        props.data != null ?
          historyData != null ?
            <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
              pannable={{
                lock: "y",
              }}
              zoomable={{
                mousewheel: {
                  lock: "y",
                },
                selection: {
                  lock: "y",
                },
              }}>
              <ChartTitle text={props.title} />
              <ChartLegend position="bottom"> </ChartLegend>
              <ChartTooltip render={renderTooltip} />
              <ChartSeries>
                <ChartSeriesItem markers={{
                  visible: false,
                }} data={historyData} field="ForecastValue" categoryField="Date" type="column" />
                {
                  forecastData && forecastData.length > 0 ?
                    <ChartSeriesItem markers={{
                      visible: false,
                    }} dashType="dash" data={forecastData} field="ForecastValue" categoryField="Date" type="column" />
                    : <></>
                }

              </ChartSeries>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem maxDivisions={30}
                  baseUnit={props.baseUnit} labels={{
                    rotation: 'auto',
                    dateFormats: {
                      minutes: "MMM dd yyyy HH:mm",
                      hours: "MMM dd yyyy HH:mm",
                      days: "MMM dd yyyy",
                      months: "MMM yyyy",
                      years: "yyyy"
                    }
                  }} />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem
                  title={{
                    text: props.yaxesLabel,
                  }}
                />
              </ChartValueAxis>
            </Chart> : <><Text>No Data</Text></>
          : <><Text>No Data</Text></>
      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
};
const chartDefaultV4Colors = [
  "#4472C4",
  "#ED7D31",
  "#78d237",
  "#28b4c8",
  "#2d73f5",
  "#aa46be",
];

export const LineChart = (props) => {
  const maximizableElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const handleExitFullscreen = () => document.exitFullscreen();

  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>

      {
        props.series ?
          <Chart style={{ height: '100%', minHeight: '400px' }} seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom"> </ChartLegend>
            <ChartTooltip format="{0}" />
            <ChartSeries>
              {props.series?.map((s) => (
                <ChartSeriesItem name={s.name} data={s.data} type="line" key={s.name}>
                  <ChartSeriesItemTooltip format={`${s.name} value: {0}`} />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem categories={props.categories} labels={{
                rotation: 'auto'
              }} />
            </ChartCategoryAxis>
          </Chart>
          : <></>
      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
    </div>
  )
};

export const StackedBarChart = (props) => (
  <Chart seriesColors={props.seriesColors ? props.seriesColors : chartDefaultV4Colors}>
    <ChartTitle text={props.title} />
    <ChartLegend position="top"> </ChartLegend>
    <ChartSeries>
      {props.series.map((s) => (
        <ChartSeriesItem name={s.name} data={s.data} type="column" stack={{ type: '100%' }} key={s.name} />
      ))}
    </ChartSeries>
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={props.categories} />
    </ChartCategoryAxis>
  </Chart>
);


export const BillingDashChart = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };
  

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }}
          pannable={{
            lock: "y",
          }}
          zoomable={{
            mousewheel: {
              lock: "y",
            },
            selection: {
              lock: "y",
            },
          }}>
        
          <ChartLegend position="top" orientation='horizontal' />
          <ChartSeries>
            {props.series?.map((s) => {
              return (
                s.axis ?
                  <ChartSeriesItem tooltip={{ visible: true }} markers={{
                    visible: true,
                  }} type={s.type} gap={s.gap} axis={s.axis} color={s.color} name={s.name} data={s.data} key={s.name} autoFit="true">
                  </ChartSeriesItem>
                  :
                  <ChartSeriesItem tooltip={{ visible: true }} markers={{
                    visible: true,
                  }} type={s.type} gap={s.gap} color={s.color} name={s.name} data={s.data} key={s.name} autoFit="true">
                  </ChartSeriesItem>
              )
            })}
          </ChartSeries>
          <ChartCategoryAxis>
            {
              props.categories?.map((s) => {
                return (s.axisCrossingValue ?
                  <ChartCategoryAxisItem
                    labels={{
                      rotation: s.labelRotation,
                    }}
                    title={{
                      text: s.title,
                    }}
                    categories={s.data}
                    churnAxisCrossingValue={s.axisCrossingValue}
                  />
                  : <ChartCategoryAxisItem
                    labels={{
                      rotation: s.labelRotation,
                      font: s.font,
                    }}
                    title={{
                      text: s.title,
                    }}
                    categories={s.data}
                  />
                )
              })
            }
          </ChartCategoryAxis>
          {
            props.values ?
              <ChartValueAxis>
                {
                  props.values?.map((s) => (
                    <ChartValueAxisItem
                      name={s.name}
                      position={s.position ? s.position : "left"}
                    />
                  ))
                }
              </ChartValueAxis>
              : <></>
          }
          {
            props.valueAxis ?
              <ChartValueAxis >
                <ChartValueAxisItem narrowRange={true} min={props.valueAxis?.min} title={{ text: props.valueAxis?.title, position: "center" }} />
              </ChartValueAxis>
              : <></>
          }
        </Chart>

      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
}


export const PieChart = (props) => {
  const [chartData, setChartData] = React.useState([]);
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };

  const handleExitFullscreen = () => document.exitFullscreen();
  const labelContent = (props) => {
    let formatedNumber = Number(props.dataItem.value).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return `${props.dataItem.category} : ${formatedNumber}`;
  };

  React.useEffect(() => {
    let hashMap = {};
    let totalValue = 0;
    props.data?.forEach((v, i) => {
      if (hashMap[v[props.categoryField]]) {
        hashMap[v[props.categoryField]] += v[props.field];
      }
      else {
        hashMap[v[props.categoryField]] = v[props.field]
      }
      totalValue += v[props.field];
    });
    let mapArr = Object.keys(hashMap).map((m) => ({ category: m, value: hashMap[m] / totalValue }));
    setChartData(mapArr);
  }, [props.data]);

  return (
    chartData?.length > 0 ?
      <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
        {
          <Chart className='new-billing-chart' ref={chartElement} style={{ height: '100%', minHeight: '400px' }}>
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={chartData}
                categoryField="category"
                field="value"
                labels={{
                  visible: true,
                  content: labelContent,
                }} />
            </ChartSeries>
          </Chart>
        }
        {
          errorMessage ? <></> :
            (isFullscreen ? (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<CollapseIcon />}
                iconOnly
                text
                title="Exit Fullscreen"
                onClick={handleExitFullscreen}
              />
            ) : (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<ExpandIcon />}
                iconOnly
                text
                title="Fullscreen"
                onClick={setIsFullscreen}
              />
            ))
        }
        <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
      </div>
      : <></>
  )
};

export const IndustryDrillDown = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  const maximizableElementAll = React.useRef(null);
  const [visible, setVisible] = React.useState(false);
  const [allvisible, setallvisible] = React.useState(false);
  const [isLoadedDaily, SetisLoadedDaily] = React.useState(true);
  const [allData, setallData] = React.useState(null);
  const [allFilterData, setallFilterData] = React.useState(null);
  const [width, setwidth] = React.useState("100%");
  const [noOfUsers, setnoOfUsers] = React.useState(null);
  const [industries, setindustries] = React.useState(null);
  const [selectedIndustries, setselectedIndustries] = React.useState(null);
  const [uniqueId, setuniqueId] = React.useState(1);
  const [industryChartId, setindustryChartId] = React.useState(100);


  React.useEffect(() => {
    document.addEventListener("fullscreenchange", (event) => { console.log('onfullscreenchange'); });
  }, []);


  const onfullscreenchange = (event) => {
    console.log('onfullscreenchange');
  };

  let isFullscreen, setIsFullscreen;
  let errorMessage;

  let isFullscreenAll, setIsFullscreenAll;
  let errorMessageAll;

  try {
    [isFullscreenAll, setIsFullscreenAll] = useFullscreenStatus(maximizableElementAll);
  } catch (e) {
    errorMessageAll = "Fullscreen not supported";
    isFullscreenAll = false;
    setIsFullscreenAll = undefined;
  }

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };

  const handleExitFullscreen = () => document.exitFullscreen();

  const handleExitFullscreenAll = () => {
    SetisLoadedDaily(false);

    setallData(null);
    setallFilterData(null);
    setindustries(null);

    let id = uniqueId + 1;
    setindustryChartId(id);

    document.exitFullscreen();

    // setTimeout(
    //   () => {  document.exitFullscreen(); }, 
    //   7000);


    // SetisLoadedDaily(true);
  }

  const handleIndustryChange = (_selectedType) => {
    setselectedIndustries(_selectedType);
    // debugger;
    let data = [];
    // for(let i =0; i< _selectedType.length; i++){
    //   let c = allData;
    // }

    if (_selectedType && _selectedType.length > 0) {
      data = allData.filter(ob => _selectedType.findIndex(nm => ob.Name.toLowerCase() == nm.toLowerCase()) >= 0);
      setallFilterData(structuredClone(data));
      if (data.length > 50) {
        let w = data * 20;
        setwidth(w + "px")
      }
      else if (data.length > 10) {
        setwidth("100%")
      }
      else {
        setwidth("25%")
      }

    }
    else {
      setallFilterData(allData);

      if (allData.length > 50) {
        let w = allData.length * 20;
        setwidth(w + "px")
      }
      else {
        setwidth("100%")
      }


    }

  }

  const onViewAllClick = () => {
    // console.log(drilldownState);
    // setnoOfUsers(drilldownState?.steps[0]?.drilldownValue);
    // setVisible(true);
    weeklyDateData();

  };

  const onViewAllClickClose = () => {
    setVisible(false);

  };


  const weeklyDateData = () => {
    // debugger
    let _params = {
    };
    props.acquireToken((token) => {
      if (token) {
        SetisLoadedDaily(false);

        RevenueService.GetRevenueAllGroupAllIndustries(token, _params).then((response) => {
          if (response && response.status == 200) {
            response = response.data;
            setallData(response.IndustryCustomerCount);
            setallFilterData(response.IndustryCustomerCount);
            setindustries(response.Industries);

            if (response.IndustryCustomerCount.length > 50) {
              let w = response.IndustryCustomerCount.length * 20;
              setwidth(w + "px")
            }
            else {
              setwidth("100%")
            }
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }

          SetisLoadedDaily(true);
          // setTimeout(
          //   () => { SetisLoadedDaily(true); }, 
          //   7000);


        });


        // MonitoringService.GetTimesheetsDataWeekly(token, _params).then((response) => {
        // 	if (response && response.status == 200) {
        // 		response = response.data;
        // 		resetWeeklyData(date, response.WeekDb)
        // 	}
        // 	SetisLoadedDaily(true);
        // });
      }
    });

    // settimesheetDailyDate(date);
  }

  const [drilldownState, setDrilldownState] = React.useState();
  const handleDrilldown = (e) => {
    setDrilldownState(e.nextState);
  };

  const chartRender = () => {
    // debugger;
    console.log("re");
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
        {
          <>
            <ChartBreadcrumb
              drilldownState={drilldownState}
              onDrilldownStateChange={handleDrilldown}
              style={{ color: '#222' }}
            />
            <Chart ref={chartElement} style={{ height: '100%', minHeight: '340px' }}
              pannable={{
                lock: "y",
              }}
              zoomable={{
                mousewheel: {
                  lock: "y",
                },
                selection: {
                  lock: "y",
                },
              }}
              drilldownState={drilldownState} onDrilldown={handleDrilldown}
              render={chartRender}
            >


              <ChartSeries>

                {/* <ChartSeriesItem tooltip={{ visible: true }} markers={{
                    visible: true,
                  }} type={props.type} gap={props.gap} color={props.color} name={props.name} data={s.data} key={s.name} autoFit="true">
        </ChartSeriesItem> */}

                <ChartSeriesItem type="column" tooltip={{ visible: true }} autoFit="true" color="#004B95" valueAxis={{ title: "No. of Customers" }}
                  name="Customer Count by Industry"
                  data={props?.MainData}
                  field="Size"
                  categoryField="DisplayName"
                  drilldownField="Name"
                  // drilldownSeriesFactory={ (p) => {DrilldownByCompany(p, props.SecondData)}}
                  drilldownSeriesFactory={props.DrilldownByCompany}
                />
              </ChartSeries>

              <ChartValueAxis >
                <ChartValueAxisItem
                  // labels={{
                  //   step: "2",
                  // }}
                  title={{ text: "No. of Customers", position: "center" }} />
              </ChartValueAxis>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    rotation: "270",
                    color: "rgba(31,31,31,0.9999)",
                    // font: "400 10.5px Poppins, sans-serif",
                  }}
                // title={{
                //   text: s.title,
                // }}
                // categories={s.data}
                />
              </ChartCategoryAxis>

              {/* <ChartCategoryAxis>
            <ChartCategoryAxisItem
                    // labels={{
                    //   rotation: s.labelRotation,
                    // }}
                    title={{
                      text: "Users",
                    }}
                    // categories={s.data}
                  />
          </ChartCategoryAxis> */}

              <ChartLegend position="bottom" />
            </Chart>
          </>
        }
        {
          errorMessage ? <></> :
            (isFullscreen ? (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<CollapseIcon />}
                iconOnly
                text
                title="Exit Fullscreen"
                onClick={handleExitFullscreen}
              />
            ) : (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<ExpandIcon />}
                iconOnly
                text
                title="Fullscreen"
                onClick={setIsFullscreen}
              />
            ))
        }
        <Button style={{ position: 'absolute', top: '8px', right: '44px', display: 'none' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
        {!isFullscreen ? <Button style={{ position: 'absolute', top: '8px', right: '10px' }} text title="View All Industries"
          //  onClick={onViewAllClick} 
          onClick={() => {
            setIsFullscreenAll();
            onViewAllClick();
          }}
          content="All" visible="False" /> : ""}


      </div>

      <div ref={maximizableElementAll} className={isFullscreenAll === true ? "allFull-container" : "d-none allFull-container"}  >

        <div>
          <Button
            style={{ position: 'absolute', top: '8px', right: '8px' }}
            icon={<CollapseIcon />}
            iconOnly
            text
            title="Exit Fullscreen"
            onClick={handleExitFullscreenAll}
          />
        </div>

        {isLoadedDaily ?
          <div style={{ margin: '20px', marginTop: '60px' }} >
            <div >
              <div style={{ marginBottom: '20px', fontSize: '20px' }}>
                {`Customer Count by Industry`}
              </div>
              <div className='d-flex align-items-center' style={{ minHeight: "84px", marginTop: '-10px' }}>

                <div>
                  <FilterIcon /> Filter by Industry:
                </div>
                <div style={{ width: "30%", marginLeft: '7px' }}>
                  <Dropdown className="filter-by-industry" key={uniqueId}
                    search
                    multiple
                    items={industries} fluid
                    onChange={(e, selectedItem) => {
                      handleIndustryChange(selectedItem.value);
                    }}
                    placeholder="Start typing a name"
                  />
                </div>

              </div>

            </div>

            <div>

              <div className='clear-container'
                onClick={() => {
                  let id = uniqueId + 1;
                  setuniqueId(id);
                  setallFilterData(allData);

                  if (allData.length > 50) {
                    let w = allData.length * 20;
                    setwidth(w + "px")
                  }
                  else {
                    setwidth("100%")
                  }

                }}
              >
                <div><FilterIcon /></div>
                <div className='clear-back'>\</div>
                <div style={{ paddingLeft: '2px' }}>Clear</div>
              </div>

              <div class="revenue-chart-wrapper" >

                <Chart style={{ width: width }} key={industryChartId}>
                  <ChartLegend position="bottom" visible={false} />
                  <ChartSeries>
                    <ChartSeriesItem type="column"
                      tooltip={{ visible: true }}

                      name={`Customer Distribution by Industry (User Count: ${noOfUsers})`}
                      data={allFilterData}
                      field="Size"
                      categoryField="DisplayName" color="#004B95"
                      labels={{
                        rotation: "270",
                      }}
                      {...props}
                    />
                  </ChartSeries>

                  <ChartValueAxis >
                    <ChartValueAxisItem
                      // labels={{
                      //   step: "2",
                      // }}
                      title={{ text: "No. of Customers", position: "center" }} />
                  </ChartValueAxis>

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{
                        rotation: "270",
                      }}

                    />
                  </ChartCategoryAxis>
                </Chart>

              </div>

            </div>


          </div> :
          <div className="d-flex justify-content-center" style={{ height: "100%" }}>
            <Loader />
          </div>
        }
      </div>

    </>
  );
};

export const UserGroupsDrillDown = (props) => {
  const maximizableElement = React.useRef(null);
  const maximizableElementAll = React.useRef(null);
  const chartElement = React.useRef(null);
  const [visible, setVisible] = React.useState(false);
  const [allvisible, setallvisible] = React.useState(false);
  const [isLoadedDaily, SetisLoadedDaily] = React.useState(true);
  const [allData, setallData] = React.useState(null);
  const [allFilterData, setallFilterData] = React.useState(null);
  const [width, setwidth] = React.useState("100%");
  const [noOfUsers, setnoOfUsers] = React.useState(null);
  const [industries, setindustries] = React.useState(null);
  const [selectedIndustries, setselectedIndustries] = React.useState(null);
  const [uniqueId, setuniqueId] = React.useState(1);


  let isFullscreen, setIsFullscreen;
  let errorMessage;

  let isFullscreenAll, setIsFullscreenAll;
  let errorMessageAll;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  try {
    [isFullscreenAll, setIsFullscreenAll] = useFullscreenStatus(maximizableElementAll);
  } catch (e) {
    errorMessageAll = "Fullscreen not supported";
    isFullscreenAll = false;
    setIsFullscreenAll = undefined;
  }

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };

  const handleIndustryChange = (_selectedType) => {
    setselectedIndustries(_selectedType);
    // debugger;
    let data = [];
    // for(let i =0; i< _selectedType.length; i++){
    //   let c = allData;
    // }

    if (_selectedType && _selectedType.length > 0) {
      data = allData.filter(ob => _selectedType.findIndex(nm => ob.Name.toLowerCase() == nm.toLowerCase()) >= 0);
      setallFilterData(structuredClone(data));
      if (data.length > 50) {
        let w = data * 20;
        setwidth(w + "px")
      }
      else if (data.length > 10) {
        setwidth("100%")
      }
      else {
        setwidth("25%")
      }

    }
    else {
      setallFilterData(allData);

      if (allData.length > 50) {
        let w = allData.length * 20;
        setwidth(w + "px")
      }
      else {
        setwidth("100%")
      }


    }

  }

  const onViewAllClick = () => {
    // console.log(drilldownState);
    setnoOfUsers(drilldownState?.steps[0]?.drilldownValue);
    // setVisible(true);
    weeklyDateData();

  };

  const onViewAllClickClose = () => {
    setVisible(false);

  };

  const handleExitFullscreen = () => document.exitFullscreen();

  const [drilldownState, setDrilldownState] = React.useState();
  const handleDrilldown = (e) => {
    if (e.nextState && e.nextState.steps.length > 0) {
      setallvisible(true);
    }
    else {
      setallvisible(false);
    }
    setDrilldownState(e.nextState);
  };

  const weeklyDateData = () => {

    let group = drilldownState?.steps[0]?.drilldownValue;
    let low = '';
    let high = '';

    if (group && group.length > 0) {

      if (group.includes('501')) {
        low = "501";
        high = "5000000";
      }
      else {
        let arr = group.split('-');
        low = arr[0];
        high = arr[1];
      }


      let _params = {
        low: low,
        high: high
      };
      props.acquireToken((token) => {
        if (token) {
          SetisLoadedDaily(false);

          RevenueService.GetRevenueDashboardAllIndustries(token, _params).then((response) => {
            if (response && response.status == 200) {
              response = response.data;
              setallData(response.IndustryCustomerCount);
              setallFilterData(response.IndustryCustomerCount);
              setindustries(response.Industries);

              if (response.IndustryCustomerCount.length > 50) {
                let w = response.IndustryCustomerCount.length * 20;
                setwidth(w + "px")
              }
              else {
                setwidth("100%")
              }
            }
            else if (response && response.status == 401) {
              props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }
            SetisLoadedDaily(true);
          });


          // MonitoringService.GetTimesheetsDataWeekly(token, _params).then((response) => {
          // 	if (response && response.status == 200) {
          // 		response = response.data;
          // 		resetWeeklyData(date, response.WeekDb)
          // 	}
          // 	SetisLoadedDaily(true);
          // });
        }
      });
    }

    // settimesheetDailyDate(date);
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
        {
          <>
            {/* <div className={isLoadedDaily ? "overlay" : "overlay d-block"}>
                <div className="overlay-text">
                  <Loader></Loader>
                </div>
              </div> */}
            <ChartBreadcrumb
              drilldownState={drilldownState}
              onDrilldownStateChange={handleDrilldown}
              style={{ color: '#222' }}
            />
            <Chart ref={chartElement} style={{ height: '100%', minHeight: '340px' }}
              pannable={{
                lock: "y",
              }}
              zoomable={{
                mousewheel: {
                  lock: "y",
                },
                selection: {
                  lock: "y",
                },
              }}
              drilldownState={drilldownState} onDrilldown={handleDrilldown} >


              <ChartSeries>

                {/* <ChartSeriesItem tooltip={{ visible: true }} markers={{
                      visible: true,
                    }} type={props.type} gap={props.gap} color={props.color} name={props.name} data={s.data} key={s.name} autoFit="true">
          </ChartSeriesItem> */}

                <ChartSeriesItem type="column" tooltip={{ visible: true }} autoFit="true" color="#004B95" valueAxis={{ title: "No. of Customers" }}
                  name="Customer Count by End Points"
                  data={props?.MainData}
                  field="Size"
                  categoryField="Name"
                  drilldownField="Name"
                  // drilldownSeriesFactory={ (p) => {DrilldownByCompany(p, props.SecondData)}}
                  drilldownSeriesFactory={props.DrilldownByCompany}
                />
              </ChartSeries>

              <ChartValueAxis >
                <ChartValueAxisItem
                  // labels={{
                  //   step: "2",
                  // }}
                  title={{ text: "No. of Customers", position: "center" }} />
              </ChartValueAxis>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    rotation: "270",
                    // font: "400 10.5px Poppins, sans-serif",
                  }}
                // title={{
                //   text: s.title,
                // }}
                // categories={s.data}
                />
              </ChartCategoryAxis>

              {/* <ChartCategoryAxis>
              <ChartCategoryAxisItem
                      // labels={{
                      //   rotation: s.labelRotation,
                      // }}
                      title={{
                        text: "Users",
                      }}
                      // categories={s.data}
                    />
            </ChartCategoryAxis> */}

              <ChartLegend position="bottom" />
            </Chart>
          </>
        }
        {
          errorMessage ? <></> :
            (isFullscreen ? (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<CollapseIcon />}
                iconOnly
                text
                title="Exit Fullscreen"
                onClick={handleExitFullscreen}
              />
            ) : (
              <Button
                style={{ position: 'absolute', top: '8px', right: '8px' }}
                icon={<ExpandIcon />}
                iconOnly
                text
                title="Fullscreen"
                onClick={setIsFullscreen}
              />
            ))
        }
        <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />

        {allvisible && !isFullscreen ? <Button style={{ position: 'absolute', top: '8px', right: '54px' }} text title="View All Industries"
          //  onClick={onViewAllClick} 
          onClick={() => {
            setIsFullscreenAll();
            onViewAllClick();
          }}
          content="All" visible="False" /> : ""}

      </div>

      <div ref={maximizableElementAll} className={isFullscreenAll === true ? "allFull-container" : "d-none allFull-container"}  >

        <div>
          <Button
            style={{ position: 'absolute', top: '8px', right: '8px' }}
            icon={<CollapseIcon />}
            iconOnly
            text
            title="Exit Fullscreen"
            onClick={handleExitFullscreen}
          />
        </div>

        {isLoadedDaily ?
          <div style={{ margin: '20px', marginTop: '60px' }} >
            <div >
              <div style={{ marginBottom: '20px', fontSize: '20px' }}>
                {`Customer Distribution by Industry (User Count: ${noOfUsers})`}
              </div>
              <div className='d-flex align-items-center' style={{ minHeight: "84px", marginTop: '-10px' }}>

                <div>
                  <FilterIcon /> Filter by Industry:
                </div>
                <div style={{ width: "30%", marginLeft: '7px' }}>
                  <Dropdown className="filter-by-industry" key={uniqueId}
                    search
                    multiple
                    items={industries} fluid
                    onChange={(e, selectedItem) => {
                      handleIndustryChange(selectedItem.value);
                    }}
                    placeholder="Start typing a name"
                  />
                </div>

              </div>

            </div>

            <div>

              <div className='clear-container'
                onClick={() => {
                  let id = uniqueId + 1;
                  setuniqueId(id);
                  setallFilterData(allData);

                  if (allData.length > 50) {
                    let w = allData.length * 20;
                    setwidth(w + "px")
                  }
                  else {
                    setwidth("100%")
                  }

                }}
              >
                <div><FilterIcon /></div>
                <div className='clear-back'>\</div>
                <div style={{ paddingLeft: '2px' }}>Clear</div>
              </div>

              <div class="revenue-chart-wrapper" >

                <Chart style={{ width: width }} >
                  <ChartLegend position="bottom" visible={false} />
                  <ChartSeries>
                    <ChartSeriesItem
                      tooltip={{ visible: true }}

                      name={`Customer Distribution by Industry (User Count: ${noOfUsers})`}
                      data={allFilterData}
                      field="Size"
                      categoryField="DisplayName" color="#004B95"
                      labels={{
                        rotation: "270",
                      }}
                      {...props}
                    />
                  </ChartSeries>

                  <ChartValueAxis >
                    <ChartValueAxisItem
                      // labels={{
                      //   step: "2",
                      // }}
                      title={{ text: "No. of Customers", position: "center" }} />
                  </ChartValueAxis>

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{
                        rotation: "270",
                      }}

                    />
                  </ChartCategoryAxis>
                </Chart>

              </div>

            </div>


          </div> :
          <div className="d-flex justify-content-center" style={{ height: "100%" }}>
            <Loader />
          </div>
        }
      </div>

      {/* <div >
        test full
      </div> */}

      <div>
        {visible && (
          <Dialog className="viewAll-dialog"
            title={`Customer Distribution by Industry (User Count: ${noOfUsers})`}
            onClose={onViewAllClickClose}
            style={{ width: "100%", height: "98%" }}
          >


            {isLoadedDaily ? <div></div>
              // <div >
              //   <div >
              //     <div className='d-flex align-items-center' style={{ minHeight : "84px", marginTop:'-10px'}}>
              //       <div>
              //         <FilterIcon /> Filter by Industry:
              //       </div>
              //       <div style={{ width : "30%", marginLeft: '7px'}}>
              //         <Dropdown className="filter-by-industry" key={uniqueId}
              //             search
              //             multiple
              //             items={industries} fluid 
              //             onChange={(e, selectedItem) => {
              //               handleIndustryChange(selectedItem.value);
              //             }}
              //             placeholder="Start typing a name"
              //           />
              //       </div>

              //     </div>

              //   </div>

              //   <div>

              //   <div className='clear-container' 
              //       onClick={() => {
              //         let id = uniqueId + 1;
              //         setuniqueId(id);
              //         setallFilterData(allData);

              //         if(allData.length > 50){
              //           let w = allData.length * 20;
              //           setwidth(w + "px")
              //         }
              //         else{
              //           setwidth("100%")
              //         }

              //       }}
              //   >
              //       <div><FilterIcon /></div>
              //       <div className='clear-back'>\</div>
              //       <div style={{paddingLeft:'2px'}}>Clear</div>
              //   </div>

              //   <div class="revenue-chart-wrapper" >

              //   <Chart  style={{ width: width}} >
              //     <ChartLegend  position="bottom" visible={false} />
              //     <ChartSeries>
              //       <ChartSeriesItem
              //           tooltip={{ visible: true }}

              //           name={`Customer Distribution by Industry (User Count: ${noOfUsers})`}
              //           data={allFilterData}
              //           field="Size"
              //           categoryField="DisplayName" color="#004B95"
              //           labels={{
              //             rotation: "270",
              //           }}
              //           {...props}
              //         />
              //     </ChartSeries>

              //     <ChartValueAxis >
              //         <ChartValueAxisItem 

              //         title={{ text: "No. of Customers", position: "center" }} />
              //     </ChartValueAxis>

              //     <ChartCategoryAxis>
              //       <ChartCategoryAxisItem
              //           labels={{
              //             rotation: "270",
              //           }}

              //       />
              //     </ChartCategoryAxis>
              //   </Chart>

              //   </div>

              //   </div>


              // </div> 
              :
              <div className="d-flex justify-content-center" style={{ height: "100%" }}>
                <Loader />
              </div>
            }
            <DialogActionsBar>
              <div className="d-flex justify-content-end">
                <Button style={{ marginRight: '8px' }} type="button" onClick={onViewAllClickClose} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                  Close
                </Button>
              </div>

            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};


export const TopCustomersChart = (props) => {
  const maximizableElement = React.useRef(null);
  const chartElement = React.useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = "Fullscreen not supported";
    isFullscreen = false;
    setIsFullscreen = undefined;
  }
  const onImageExportClick = () => {
    const chartVisual = exportVisual(chartElement.current);
    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };
  const defaultTooltipRender = (e) => {
    console.log(e);
    let index = e.point.categoryIndex;
    let instance = e.point?.series?.instances[index];
    return <>
      <div className='customer-chart-tooltip '>
        <div>
          Customer: {e.point.category}
        </div>
        <div className='mt-1'>
          End Points: {e.point.value}
        </div>
        <div className='mt-1'>
          Instance: {instance}
        </div>
      </div>
    </>;
  }

  const handleExitFullscreen = () => document.exitFullscreen();
  return (
    <div style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
      {
        <Chart ref={chartElement} style={{ height: '100%', minHeight: '400px' }}
          pannable={{
            lock: "y",
          }}
          zoomable={{
            mousewheel: {
              lock: "y",
            },
            selection: {
              lock: "y",
            },
          }}>
          <ChartTooltip render={defaultTooltipRender} />
          <ChartLegend position="top" orientation='horizontal' />
          <ChartSeries>
            {props.series?.map((s) => {
              return (
                s.axis ?
                  <ChartSeriesItem tooltip={{ visible: true }} markers={{
                    visible: true,
                  }} type={s.type} gap={s.gap} axis={s.axis} color={s.color} name={s.name} data={s.data} key={s.name} autoFit="true">
                  </ChartSeriesItem>
                  :
                  <ChartSeriesItem tooltip={{ visible: true }} markers={{
                     visible: true,
                    }} 
                    type={s.type} gap={s.gap} color={s.color} name={s.name} data={s.data} key={s.name} autoFit="true" instances={s.instances}>

                    {/* <ChartSeriesItemTooltip render={nestedTooltipRender} /> */}

                  </ChartSeriesItem>
              )
            })}
          </ChartSeries>
          <ChartCategoryAxis>
            {
              props.categories?.map((s) => {
                return (s.axisCrossingValue ?
                  <ChartCategoryAxisItem
                    labels={{
                      rotation: s.labelRotation,
                    }}
                    title={{
                      text: s.title,
                    }}
                    categories={s.data}
                    churnAxisCrossingValue={s.axisCrossingValue}
                  />
                  : <ChartCategoryAxisItem
                    labels={{
                      rotation: s.labelRotation,
                      font: s.font,
                    }}
                    title={{
                      text: s.title,
                    }}
                    categories={s.data}
                  />
                )
              })
            }
          </ChartCategoryAxis>
          {
            props.values ?
              <ChartValueAxis>
                {
                  props.values?.map((s) => (
                    <ChartValueAxisItem
                      name={s.name}
                      position={s.position ? s.position : "left"}
                    />
                  ))
                }
              </ChartValueAxis>
              : <></>
          }
          {
            props.valueAxis ?
              <ChartValueAxis >
                <ChartValueAxisItem narrowRange={true} min={props.valueAxis?.min} title={{ text: props.valueAxis?.title, position: "center" }} />
              </ChartValueAxis>
              : <></>
          }
        </Chart>

      }
      {
        errorMessage ? <></> :
          (isFullscreen ? (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<CollapseIcon />}
              iconOnly
              text
              title="Exit Fullscreen"
              onClick={handleExitFullscreen}
            />
          ) : (
            <Button
              style={{ position: 'absolute', top: '8px', right: '8px' }}
              icon={<ExpandIcon />}
              iconOnly
              text
              title="Fullscreen"
              onClick={setIsFullscreen}
            />
          ))
      }
      <Button style={{ position: 'absolute', top: '8px', right: '44px' }} iconOnly text icon={<FilesImageIcon size="large" />} title="Export as Image" onClick={onImageExportClick} />
    </div>
  )
}
