import React from 'react';
import _ from 'lodash';
import { Text, Input, Header, Dropdown, Grid, Pill, PillGroup, Popup, Button, Flex, Loader } from '@fluentui/react-northstar';
import { FilterIcon } from '@fluentui/react-northstar';
import MonitoringService from '../../services/monitoringservice';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import RevenueService from '../../services/revenueservice';

export const FILTERPROPERTIES = [
    {key: 0, header: 'Instance', value: 0, propertyname: 'InstanceId', alternatepropertyname: 'InstanceName', propertytype: 'select'},
    {key: 1, header: 'Server', value: 1, propertyname: 'ServerId', alternatepropertyname: 'ServerName', propertytype: 'select'},
    {key: 2, header: 'ServerType', value: 2, propertyname: 'ServerTypeId', alternatepropertyname: 'ServerTypeName', propertytype: 'select'},
    {key: 3, header: 'CPUUsage', value: 3, propertyname: 'PercentageCPU', alternatepropertyname: 'PercentageCPU', propertytype: 'number'},
    {key: 4, header: 'DiskUsage', value: 4, propertyname: 'DiskUsage', alternatepropertyname: 'DiskUsage', propertytype: 'number'},
    {key: 5, header: 'RAMUsage', value: 5, propertyname: 'PercentageAvailableMemory', alternatepropertyname: 'PercentageAvailableMemory', propertytype: 'number'},
    {key: 6, header: 'Platform', value: 6, propertyname: 'PlatformId', alternatepropertyname: 'PlatformName', propertytype: 'select'},
    {key: 7, header: 'Reseller', value: 7, propertyname: 'MasterResellerId', alternatepropertyname: 'MasterResellerName', propertytype: 'select'},
    {key: 8, header: 'Region', value: 8, propertyname: 'RegionId', alternatepropertyname: 'RegionName', propertytype: 'select'},
    {key: 9, header: 'Owner', value: 9, propertyname: 'AssignedUserId', alternatepropertyname: 'AssignedUserId', propertytype: 'select'},
    {key: 10, header: 'IncidentStatus', value: 10, propertyname: 'IncidentStatusId', alternatepropertyname: 'IncidentStatus', propertytype: 'select'},
    {key: 11, header: 'TenantType', value: 11, propertyname: 'RecordingAvailable', alternatepropertyname: 'RecordingAvailable', propertytype: 'checkbox'},
    {key: 12, header: 'ProductStream', value: 12, propertyname: 'ProductStreamId', alternatepropertyname: 'ProductStreamName', propertytype: 'select'},
    {key: 13, header: 'Product', value: 13, propertyname: 'ProductCode', alternatepropertyname: 'ProductName', propertytype: 'select'},
    {key: 14, header: 'TenantRange', value: 14, propertyname: 'TenantId', alternatepropertyname: 'TenantId', propertytype: 'range'},
    {key: 15, header: 'ServiceType', value: 15, propertyname: 'ServiceTypeId', alternatepropertyname: 'ServiceTypeName', propertytype: 'select'},
];
export const FILTEROPERATORS = [
    {header: '==', value: 'IN', key: 1, applicablepropertytypes: ['select', 'text', 'number', 'checkbox']},
    {header: '>=', value: '>=', key: 2, applicablepropertytypes: ['number']},
    {header: '<=', value: '<=', key: 3, applicablepropertytypes: ['number']},
    {header: 'contains', value: 'LIKE', key: 4, applicablepropertytypes: ['select', 'text']},
    {header: 'startswith', value: 'LIKE', key: 5, applicablepropertytypes: ['select', 'text']},
    {header: 'endswith', value: 'LIKE', key: 6, applicablepropertytypes: ['select', 'text']},
    {header: 'range', value: 'range', key: 7, applicablepropertytypes: ['range']}
];
export const FILTEROPTIONS = {
  CPUUsage:20,
  DiskUsage:20,
  RAMUsage:20,
  TenantType: [{key: 0, header: 'Reporting', value: 0}, {key: 1, header: 'Recording', value: 1}],
  TenantRange: [1, 200]
};

export const FILTERPROPERTYOPERATORMATRIX = () => {
    let operatorObj = FILTEROPERATORS.reduce((json, value, key) => { json[value.header] = []; return json; }, {});
    let filterOperatorMatrix = FILTERPROPERTIES.reduce((json, value, key) => { json[value.header] = _.cloneDeep(operatorObj); return json; }, {});
    return filterOperatorMatrix;
}
export const FILTERPROPERTYMAP = FILTERPROPERTIES.reduce((json, value, key) => { json[value.header] = value; return json; }, {});
export const FILTEROPERATORMAP = FILTEROPERATORS.reduce((json, value, key) => { json[value.header] = value; return json; }, {});



const CustomQueryFilter = (props) => {
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [portalOpen, setPortalOpen] = React.useState(false);
  const [isLoaded, setLoaded] = React.useState(false);
  const [filterSelections, setFilterSelections] = React.useState({
    Filter: null,
    Operator:null,
    Value: []
  });
  const [filterOptions, setFilterOptions] = React.useState(null);
  const [allSelectedFilters, setAllSelectedFilters] = React.useState(FILTERPROPERTYOPERATORMATRIX());

  React.useEffect(() => {
    props.acquireToken((token) => {
      if (token){
        let _params = {};
          MonitoringService.GetMasterFilterData(token, _params).then((response) => {
            if(response && response.status==200){
              response=response.data;
              let _allOptions = {...FILTEROPTIONS, ...response};
              setFilterOptions(_allOptions);
              if(props.defaultAppliedFilter && Array.isArray(props.defaultAppliedFilter)) { 
                let _defaultSelectedValues;
                props.defaultAppliedFilter.map((item) => {
                  if (Array.isArray(item.value)) {
                    _defaultSelectedValues = _allOptions[item.filter].filter(
                      (p) => item.value.includes(p.value)
                    );
                  } else {
                    _defaultSelectedValues = _allOptions[item.filter].filter(
                      (p) => p.value == item.value
                    );
                  }
                  allSelectedFilters[item.filter][item.operator] =
                    _defaultSelectedValues;
                });
                
                let formattedFilters = formatAppliedFilter();
                setAppliedFilters(formattedFilters.filters);
              } 
              else if (
                props.defaultAppliedFilter &&
                props.defaultAppliedFilter.value
              ){
                let _defaultSelectedValues = _allOptions[
                  props.defaultAppliedFilter.filter
                ].filter((p) => p.value == props.defaultAppliedFilter.value);
                setFilterSelections({
                  Filter: FILTERPROPERTYMAP[props.defaultAppliedFilter.filter],
                  Operator:FILTEROPERATORMAP[props.defaultAppliedFilter.operator],
                  Value: _defaultSelectedValues
                });

                allSelectedFilters[props.defaultAppliedFilter.filter][props.defaultAppliedFilter.operator]
                  = _defaultSelectedValues;
                let formattedFilters = formatAppliedFilter();
                setAppliedFilters(formattedFilters.filters);
              }
              setLoaded(true);

            }
            // else if(response && response.status==401){
            //   props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

            // }
            // else{
            //   props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

            // }
          })
      }
    });
  }, []);

  const formatAppliedFilter = () => {
    let _appliedFilters = [], _appliedFilterString = [];
    for (const filter in allSelectedFilters) {
      for (const operator in allSelectedFilters[filter]) {
        if(operator === 'range' && allSelectedFilters[filter][operator] && allSelectedFilters[filter][operator].length > 0 && allSelectedFilters[filter][operator][0].to && allSelectedFilters[filter][operator][0].from){
          _appliedFilters.push({
            Filter: FILTERPROPERTYMAP[filter],
            Operator:FILTEROPERATORMAP[operator],
            Value: allSelectedFilters[filter][operator][0]
          });
          _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].propertyname} >= ${allSelectedFilters[filter][operator][0].from.value} AND ${FILTERPROPERTYMAP[filter].propertyname} <= ${allSelectedFilters[filter][operator][0].to.value}`);
        }
        else if(allSelectedFilters[filter][operator] && allSelectedFilters[filter][operator].length > 0)
        {
          _appliedFilters.push({
            Filter: FILTERPROPERTYMAP[filter],
            Operator:FILTEROPERATORMAP[operator],
            Value: allSelectedFilters[filter][operator]
          });

            if(FILTERPROPERTYMAP[filter].key == 9 && FILTEROPERATORMAP[operator].key == 1){
                _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].propertyname} ${FILTEROPERATORMAP[operator].value} (${allSelectedFilters[filter][operator].map(m => "'" + m.value + "'").join(',')})`);
            }
            else{
                if(FILTEROPERATORMAP[operator].key == 1 || FILTEROPERATORMAP[operator].key == 15){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].propertyname} ${FILTEROPERATORMAP[operator].value} (${allSelectedFilters[filter][operator].map(m => m.value).join(',')})`);
                }
                else if(FILTEROPERATORMAP[operator].key == 2){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].propertyname} ${FILTEROPERATORMAP[operator].value} ${allSelectedFilters[filter][operator].map(m => m.value).join(',')}`);
                }
                else if(FILTEROPERATORMAP[operator].key == 3){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].propertyname} ${FILTEROPERATORMAP[operator].value} ${allSelectedFilters[filter][operator].map(m => m.value).join(',')}`);
                }
                else if(FILTEROPERATORMAP[operator].key == 4){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].alternatepropertyname} ${FILTEROPERATORMAP[operator].value} '%${allSelectedFilters[filter][operator].map(m => m.value).join(',')}%'`);
                }
                else if(FILTEROPERATORMAP[operator].key == 5){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].alternatepropertyname} ${FILTEROPERATORMAP[operator].value} '${allSelectedFilters[filter][operator].map(m => m.value).join(',')}%'`);
                }
                else if(FILTEROPERATORMAP[operator].key == 6){
                    _appliedFilterString.push(`${FILTERPROPERTYMAP[filter].alternatepropertyname} ${FILTEROPERATORMAP[operator].value} '%${allSelectedFilters[filter][operator].map(m => m.value).join(',')}'`);
                }
          }
        }
      }
    }
    return { filters: _appliedFilters, filterString: _appliedFilterString };
  };

  const handleFilterUpdate = () => {
    setLoaded(false);
    let formattedFilters = formatAppliedFilter();
    setAppliedFilters(formattedFilters.filters);
    setFilterSelections({
      Filter: null,
      Operator:null,
      Value: []
    });
    props.handleFilterUpdate(formattedFilters.filterString);
    setLoaded(true);
  }
  return(
    <Flex vAlign='center'>
      <Text className='mr-2 ml-2' content="Applied Filter:" />
          <PillGroup aria-label="filter group">
              {
                  appliedFilters?.map((f, index) => {
                    if(f.Operator.value === 'range'){
                      return <Pill appearance="outline"
                      actionable
                      onDismiss={(e, data) => {
                          allSelectedFilters[f.Filter.header][f.Operator.header] = [];
                          appliedFilters.splice(index, 1);
                          setAppliedFilters(appliedFilters);
                          handleFilterUpdate();
                      }}>{`${f.Filter?.header} IN ${f.Value?.from.value}-${f.Value?.to.value}`}</Pill>
                    }
                    else{
                      return <Pill appearance="outline"
                          actionable
                          onDismiss={(e, data) => {
                              allSelectedFilters[f.Filter.header][f.Operator.header] = [];
                              appliedFilters.splice(index, 1);
                              setAppliedFilters(appliedFilters);
                              handleFilterUpdate();
                          }}>{`${f.Filter?.header} ${f.Operator?.header} ${f.Value?.map((v) => v.header).join(',')}`}</Pill>
                    }
                  })
              }
          </PillGroup>
      <Flex.Item>
        <div className='mr-2'>
          <Popup
            position= 'below'
            align= 'start'
            pointing
            open={portalOpen}
            onOpenChange={(e, { open }) => {
              setFilterSelections({
                Filter: null,
                Operator:null,
                Value: []
              });
              setPortalOpen(open);
            }}
            trapFocus={{
              // When 'false', all clicks outside the Portal will be caught and not handled.
              // 'true' by default.
              isClickableOutsideFocusTrap: false,
              // Indicates whether to focus element which triggered FTZ on outside click
              // 'false' by default
              // note: if isClickableOutsideFocusTrap === 'false', focusTriggerOnOutsideClick will not be taken into account.
              focusTriggerOnOutsideClick: false,
              // Allows to pass element which you want to be focused after Portal is closed.
              // 'null' by default, so the trigger element would be focused on close.
              elementToFocusOnDismiss: null,
              // Indicates whether to force focus inside a Portal, if the 'focus' event was invoked at any place.
              // 'false' by default.
              forceFocusInsideTrapOnOutsideFocus: false,
              // Ignore focusing element which activated Portal after it was closed.
              // 'false' by default.
              ignoreExternalFocusing: false,
              // Do not focus first focusable element of Portal when opened.
              // 'false' by default.
              disableFirstFocus: false,
              // Indicates an element to focus after Portal has opened.
              // 'null' by default. The first focusable element of Portal will be focused.
              firstFocusableSelector: null,
            }}
            content={
              <Flex column gap="gap.small" style={{minWidth: '465px'}}>
                    <Header className="mt-0" as="h4">Add Filter </Header>
                    <Grid columns={2} style={{gap: '8px', gridTemplateColumns: '100px 1fr', alignItems: 'center'}}>
                  <Text content="Filter"/>
                  <Dropdown
                                        items={FILTERPROPERTIES.filter(fp => props.elegibleFilters.includes(fp.header))}
                                        onChange={(e, selectedOption) =>{
                                            if(filterSelections.Operator != null){
                                                allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [];
                                            }
                                            setFilterSelections({Filter: selectedOption.value})}
                                        }
                                    />
                                    <Text content="Operator"/>
                                    <Dropdown
                                        items={filterSelections?.Filter ? FILTEROPERATORS.filter(fo => fo.applicablepropertytypes.includes(filterSelections.Filter.propertytype)) : FILTEROPERATORS}
                                        disabled={filterSelections.Filter == null}
                                        value={filterSelections?.Operator ? filterSelections.Operator : null}
                                        onChange={(e, selectedOption) =>
                                            setFilterSelections({...filterSelections, Operator: selectedOption.value})
                                        }
                                    />
                                    <Text content="Value"/>
                                    <div className='w-100'>
                                        {
                                            filterOptions != null ?
                                                (
                                                    filterSelections.Filter?.propertytype == 'number' ?
                                                        <Input type="number" fluid
                                                            disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                            onChange={(e, input) =>{
                                                                allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{header: input.value, value: input.value}];
                                                                setFilterSelections({...filterSelections, Value: [{header: input.value, value: input.value}]})
                                                            }}
                                                        />
                                                        : filterSelections.Filter?.propertytype == 'range' ?
                                                        <Grid columns={2} style={{gridGap: '15px'}}>
                                                        <Input type="number" fluid
                                                        label="From"
                                                        disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                        onChange={(e, input) =>{
                                                            allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{from: {header: input.value, value: input.value}, to: (allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0] ? allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0].to: {})}];
                                                            setFilterSelections({...filterSelections, Value: [{from: {header: input.value, value: input.value}, to: filterSelections.Value ? filterSelections.Value[0].to : {}}]})
                                                        }}
                                                        />
                                                        <Input type="number" fluid
                                                        label="To"
                                                        disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                        onChange={(e, input) =>{
                                                          allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{to: {header: input.value, value: input.value}, from: (allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0] ? allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0].from : {})}];
                                                          setFilterSelections({...filterSelections, Value: [{to: {header: input.value, value: input.value}, from: filterSelections.Value ? filterSelections.Value[0].from : {}}]})
                                                        }}
                                                        />
                                                        </Grid>
                                                        : ((filterSelections.Operator?.key == 4 || filterSelections.Operator?.key == 5 || filterSelections.Operator?.key == 6)
                                                            ?
                                                            <Input type="text" fluid
                                                                disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                                onChange={(e, input) =>{
                                                                    allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{header: input.value, value: input.value}];
                                                                    setFilterSelections({...filterSelections, Value: [{header: input.value, value: input.value}]})
                                                                }}
                                                            />
                                                            :
                                                            <Dropdown
                                                                checkable
                                                                multiple
                                                                search
                                                                disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                                items={filterOptions[filterSelections.Filter?.header]}
                                                                value={filterSelections.Filter && filterSelections.Operator ? allSelectedFilters[filterSelections.Filter?.header][filterSelections.Operator?.header]: []}
                                                                onChange={(e, selectedOption) =>{
                                                                    allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [...selectedOption.value];
                                                                    setFilterSelections({...filterSelections, Value: selectedOption.value})
                                                                }
                                                                }
                                                            />
                                                        )
                                                )
                                                :
                                                <Loader />
                                        }
                                    </div>
                                </Grid>
                                <Flex.Item push>
                                    <Flex gap="gap.small" className="mt-2">
                                        <Button
                                            disabled={filterSelections?.value?.length == 0}
                                            primary content="Apply" onClick={() => {setPortalOpen(false); handleFilterUpdate();}} />
                                        <Button content="Cancel" onClick={() => setPortalOpen(false)} />
                                    </Flex>
                                </Flex.Item>
                            </Flex>
                        }
                        trigger={<Button disabled={!isLoaded} className="pl-3 pr-3" circular icon={<FilterIcon />} content={'Add Filter'} onClick={() => {
                            // setEndDate(new Date());
                            // setStartDate(GETOFFSETDATE(new Date(), -7));
                            setPortalOpen(!portalOpen);
                        }} />}
                    />
                </div>
            </Flex.Item>
        </Flex>
    )
}



export const REVENUEFILTERPROPERTIES = [
  {key: 0, header: 'Instance', value: 0, propertyname: 'Instance', alternatepropertyname: 'InstanceName', propertytype: 'select'},
  {key: 6, header: 'Platform', value: 6, propertyname: 'Platform', alternatepropertyname: 'PlatformName', propertytype: 'select'},
  {key: 7, header: 'Reseller', value: 7, propertyname: 'MasterReseller', alternatepropertyname: 'MasterResellerName', propertytype: 'select'},
  {key: 8, header: 'Region', value: 8, propertyname: 'Region', alternatepropertyname: 'RegionName', propertytype: 'select'},
  {key: 12, header: 'ProductStream', value: 12, propertyname: 'ProductStream', alternatepropertyname: 'ProductStreamName', propertytype: 'select'},
  {key: 13, header: 'Product', value: 13, propertyname: 'Product', alternatepropertyname: 'ProductName', propertytype: 'select'},
  {key: 16, header: 'Customer', value: 16, propertyname: 'Customer', alternatepropertyname: 'FinanceCustomerName', propertytype: 'select'},
];
export const REVENUEFILTEROPERATORS = [
  {header: '==', value: 'IN', key: 1, applicablepropertytypes: ['select']}
];
export const REVENUEFILTEROPTIONS = {};

export const REVENUEFILTERPROPERTYOPERATORMATRIX = () => {
  let operatorObj = REVENUEFILTEROPERATORS.reduce((json, value, key) => { json[value.header] = []; return json; }, {});
  let filterOperatorMatrix = REVENUEFILTERPROPERTIES.reduce((json, value, key) => { json[value.header] = _.cloneDeep(operatorObj); return json; }, {});
  return filterOperatorMatrix;
}
export const REVENUEFILTERPROPERTYMAP = REVENUEFILTERPROPERTIES.reduce((json, value, key) => { json[value.header] = value; return json; }, {});
export const REVENUEFILTEROPERATORMAP = REVENUEFILTEROPERATORS.reduce((json, value, key) => { json[value.header] = value; return json; }, {});

export const RevenueCustomQueryFilter = (props) => {
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [portalOpen, setPortalOpen] = React.useState(false);
  const [isLoaded, setLoaded] = React.useState(false);
  const [filterSelections, setFilterSelections] = React.useState({
    Filter: null,
    Operator:null,
    Value: []
  });
  const [filterOptions, setFilterOptions] = React.useState(null);
  const [allSelectedFilters, setAllSelectedFilters] = React.useState(REVENUEFILTERPROPERTYOPERATORMATRIX());

  React.useEffect(() => {
    if (props.downloadExcel) {
      let formattedFilters = formatAppliedFilter();
      props.downloadExcelHandler(formattedFilters.filterString);
      // setLoaded(true);

      // console.log('parent');
    }
  }, [props.downloadExcel]);

  React.useEffect(() => {
    props.acquireToken((token) => {
      if (token){
        let _params = {
          year: 0,
          appliedFilters: null,
          isRevenue: props.isRevenue,
          salesDDLSelected:props.salesDDLSelected
        };
          RevenueService.GetMasterRevenueData(token, _params).then((response) => {
            if(response && response.status==200){
              response=response.data;
              let _allOptions = {...REVENUEFILTEROPTIONS, ...response};
              setFilterOptions(_allOptions);
              if(props.defaultAppliedFilter && Array.isArray(props.defaultAppliedFilter)) { 
                let _defaultSelectedValues;
                props.defaultAppliedFilter.map((item) => {
                  if (Array.isArray(item.value)) {
                    _defaultSelectedValues = _allOptions[item.filter].filter(
                      (p) => item.value.includes(p.value)
                    );
                  } else {
                    _defaultSelectedValues = _allOptions[item.filter].filter(
                      (p) => p.value == item.value
                    );
                  }
                  allSelectedFilters[item.filter][item.operator] =
                    _defaultSelectedValues;
                });
                
                let formattedFilters = formatAppliedFilter();
                setAppliedFilters(formattedFilters.filters);

              } 
              else if (
                props.defaultAppliedFilter &&
                props.defaultAppliedFilter.value
              ){
                let _defaultSelectedValues = _allOptions[
                  props.defaultAppliedFilter.filter
                ].filter((p) => p.value == props.defaultAppliedFilter.value);
                setFilterSelections({
                  Filter: REVENUEFILTERPROPERTYMAP[props.defaultAppliedFilter.filter],
                  Operator:REVENUEFILTEROPERATORMAP[props.defaultAppliedFilter.operator],
                  Value: _defaultSelectedValues
                });

                allSelectedFilters[props.defaultAppliedFilter.filter][props.defaultAppliedFilter.operator]
                  = _defaultSelectedValues;
                let formattedFilters = formatAppliedFilter();
                setAppliedFilters(formattedFilters.filters);
              }
            }
            // else if(response && response.status==401){
            //   props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

            // }
            // else{
            //   props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

            // }            
            setLoaded(true);
          })
      }
    });
  }, []);

  const formatAppliedFilter = () => {
    let _appliedFilters = [], _appliedFilterString = {};
    for (const filter in allSelectedFilters) {
      for (const operator in allSelectedFilters[filter]) {
        if(allSelectedFilters[filter][operator] && allSelectedFilters[filter][operator].length > 0)
        {
          _appliedFilters.push({
            Filter: REVENUEFILTERPROPERTYMAP[filter],
            Operator:REVENUEFILTEROPERATORMAP[operator],
            Value: allSelectedFilters[filter][operator]
          });
          if(REVENUEFILTERPROPERTYMAP[filter].key == 13 || REVENUEFILTERPROPERTYMAP[filter].key == 16){
            _appliedFilterString[`${REVENUEFILTERPROPERTYMAP[filter].propertyname}`] = `${allSelectedFilters[filter][operator].map(m => `'${m.value}'`).join(',')}`;
          }
          else if(REVENUEFILTEROPERATORMAP[operator].key == 1){
            _appliedFilterString[`${REVENUEFILTERPROPERTYMAP[filter].propertyname}`] = `${allSelectedFilters[filter][operator].map(m => m.value).join(',')}`;
          }
        }
      }
    }
    return { filters: _appliedFilters, filterString: _appliedFilterString };
  };

  const handleFilterUpdate = () => {
    setLoaded(false);
    let formattedFilters = formatAppliedFilter();
    setAppliedFilters(formattedFilters.filters);
    setFilterSelections({
      Filter: null,
      Operator:null,
      Value: []
    });
    props.handleFilterUpdate(formattedFilters.filterString);
    setLoaded(true);
  }
  return(
    <Flex vAlign='center'>
      <Text className='mr-2' content="Applied Filter:" style={{marginLeft: '2px'}} />
          <PillGroup aria-label="filter group">
              {
                  appliedFilters?.map((f, index) => {
                    debugger
                    if(f.Operator.value === 'range'){
                      return <Pill appearance="outline"
                      actionable
                      onDismiss={(e, data) => {
                          allSelectedFilters[f.Filter.header][f.Operator.header] = [];
                          appliedFilters.splice(index, 1);
                          setAppliedFilters(appliedFilters);
                          handleFilterUpdate();
                      }}>{`${f.Filter?.header} IN ${f.Value?.from.value}-${f.Value?.to.value}`}</Pill>
                    }
                    else{
                      return <Pill appearance="outline"
                          actionable = {props.restrictedAccess && props.isRevenue == '1' && f.Filter?.propertyname == 'Customer' ? false : true}
                          onDismiss={(e, data) => {
                              allSelectedFilters[f.Filter.header][f.Operator.header] = [];
                              appliedFilters.splice(index, 1);
                              setAppliedFilters(appliedFilters);
                              handleFilterUpdate();
                          }}>{`${f.Filter?.header} ${f.Operator?.header} ${f.Value?.map((v) => v.header).join(',')}`}</Pill>
                    }
                  })
              }
          </PillGroup>
      <Flex.Item>
        <div className='mr-2'>
          <Popup
            position= 'below'
            align= 'start'
            pointing
            open={portalOpen}
            onOpenChange={(e, { open }) => {
              setFilterSelections({
                Filter: null,
                Operator:null,
                Value: []
              });
              setPortalOpen(open);
            }}
            trapFocus={{
              // When 'false', all clicks outside the Portal will be caught and not handled.
              // 'true' by default.
              isClickableOutsideFocusTrap: false,
              // Indicates whether to focus element which triggered FTZ on outside click
              // 'false' by default
              // note: if isClickableOutsideFocusTrap === 'false', focusTriggerOnOutsideClick will not be taken into account.
              focusTriggerOnOutsideClick: false,
              // Allows to pass element which you want to be focused after Portal is closed.
              // 'null' by default, so the trigger element would be focused on close.
              elementToFocusOnDismiss: null,
              // Indicates whether to force focus inside a Portal, if the 'focus' event was invoked at any place.
              // 'false' by default.
              forceFocusInsideTrapOnOutsideFocus: false,
              // Ignore focusing element which activated Portal after it was closed.
              // 'false' by default.
              ignoreExternalFocusing: false,
              // Do not focus first focusable element of Portal when opened.
              // 'false' by default.
              disableFirstFocus: false,
              // Indicates an element to focus after Portal has opened.
              // 'null' by default. The first focusable element of Portal will be focused.
              firstFocusableSelector: null,
            }}
            content={
              <Flex column gap="gap.small" style={{minWidth: '465px'}}>
                    <Header className="mt-0" as="h4">Add Filter</Header>
                    <Grid columns={2} style={{gap: '8px', gridTemplateColumns: '100px 1fr', alignItems: 'center'}}>
                  <Text content="Filter"/>
                  <Dropdown
                                        items={REVENUEFILTERPROPERTIES.filter(fp => props.elegibleFilters.includes(fp.header))}
                                        onChange={(e, selectedOption) =>{
                                            if(filterSelections.Operator != null){
                                                allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [];
                                            }
                                            setFilterSelections({Filter: selectedOption.value})}
                                        }
                                    />
                                    <Text content="Operator"/>
                                    <Dropdown
                                        items={filterSelections?.Filter ? REVENUEFILTEROPERATORS.filter(fo => fo.applicablepropertytypes.includes(filterSelections.Filter.propertytype)) : REVENUEFILTEROPERATORS}
                                        disabled={filterSelections.Filter == null}
                                        value={filterSelections?.Operator ? filterSelections.Operator : null}
                                        onChange={(e, selectedOption) =>
                                            setFilterSelections({...filterSelections, Operator: selectedOption.value})
                                        }
                                    />
                                    <Text content="Value"/>
                                    <div>
                                        {
                                            filterOptions != null ?
                                                (
                                                    filterSelections.Filter?.propertytype == 'number' ?
                                                        <Input type="number" fluid
                                                            disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                            onChange={(e, input) =>{
                                                                allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{header: input.value, value: input.value}];
                                                                setFilterSelections({...filterSelections, Value: [{header: input.value, value: input.value}]})
                                                            }}
                                                        />
                                                        : filterSelections.Filter?.propertytype == 'range' ?
                                                        <Grid columns={2} style={{gridGap: '15px'}}>
                                                        <Input type="number" fluid
                                                        label="From"
                                                        disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                        onChange={(e, input) =>{
                                                            allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{from: {header: input.value, value: input.value}, to: (allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0] ? allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0].to: {})}];
                                                            setFilterSelections({...filterSelections, Value: [{from: {header: input.value, value: input.value}, to: filterSelections.Value ? filterSelections.Value[0].to : {}}]})
                                                        }}
                                                        />
                                                        <Input type="number" fluid
                                                        label="To"
                                                        disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                        onChange={(e, input) =>{
                                                          allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{to: {header: input.value, value: input.value}, from: (allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0] ? allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header][0].from : {})}];
                                                          setFilterSelections({...filterSelections, Value: [{to: {header: input.value, value: input.value}, from: filterSelections.Value ? filterSelections.Value[0].from : {}}]})
                                                        }}
                                                        />
                                                        </Grid>
                                                        : ((filterSelections.Operator?.key == 4 || filterSelections.Operator?.key == 5 || filterSelections.Operator?.key == 6)
                                                            ?
                                                            <Input type="text" fluid
                                                                disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                                onChange={(e, input) =>{
                                                                    allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [{header: input.value, value: input.value}];
                                                                    setFilterSelections({...filterSelections, Value: [{header: input.value, value: input.value}]})
                                                                }}
                                                            />
                                                            :
                                                            <Dropdown
                                                                checkable
                                                                multiple
                                                                search
                                                                disabled={filterSelections.Filter == null || filterSelections.Operator == null}
                                                                items={filterOptions[filterSelections.Filter?.header]}
                                                                value={filterSelections.Filter && filterSelections.Operator ? allSelectedFilters[filterSelections.Filter?.header][filterSelections.Operator?.header]: []}
                                                                onChange={(e, selectedOption) =>{
                                                                    allSelectedFilters[filterSelections.Filter.header][filterSelections.Operator.header] = [...selectedOption.value];
                                                                    setFilterSelections({...filterSelections, Value: selectedOption.value})
                                                                }
                                                                }
                                                            />
                                                        )
                                                )
                                                :
                                                <Loader />
                                        }
                                    </div>
                                </Grid>
                                <Flex.Item push>
                                    <Flex gap="gap.small" className="mt-2">
                                        <Button
                                            disabled={filterSelections?.Value != null && filterSelections?.Value?.length > 0 ? false : true}
                                            primary content="Apply" onClick={() => {setPortalOpen(false); handleFilterUpdate();}} />
                                        <Button content="Cancel" onClick={() => setPortalOpen(false)} />
                                    </Flex>
                                </Flex.Item>
                            </Flex>
                        }
                        trigger={<Button style={{ boxShadow: 'none'}} disabled={!isLoaded} className="pl-3 pr-3 filter-btn" circular icon={<FilterIcon />} content={'Add Filter'} onClick={() => {
                            // setEndDate(new Date());
                            // setStartDate(GETOFFSETDATE(new Date(), -7));
                            setPortalOpen(!portalOpen);
                        }} />}
                    />
                </div>
            </Flex.Item>
        </Flex>
    )
}

export default CustomQueryFilter;