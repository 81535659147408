import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text,FormDropdown, FormCheckbox , Checkbox, Dropdown, Label} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellConfigurationState } from "../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { NativeTimePicker, TeamsDatePicker, TeamsTimePicker, TimeList } from '../../shared/appcomponents/custom-datetime-inputs';
import { stubFalse } from "lodash";

const CUSTOMERPRIORITYLEVELS = [
    {key: 1, header: 'true', value: true},
    {key: 0, header: 'false', value: false}
]
const SettingsBusinessHours = (props) => {
const [businessHours, setBusinessHours] = React.useState(null);
const [businessHourProfiles, setBusinessHourProfiles] = React.useState(null);
const [formData, setformData] = useState(null);
const [profileData, SetProfileData] = useState(null);
const [profileDetailsData, SetProfileDetailsData] = useState(null);
const [isLoaded, SetLoaded] = React.useState(false);
const [isLoadedDetails, SetLoadedDetails] = React.useState(false);
const [visible, setVisible] = React.useState(false);
const [editMode, setEditMode] = React.useState(false);
const [isValid, setIsValid] = useState(false);
const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
const [deleteAlertObj, setDeleteAlertObj] = useState(null);
const [updatedName, setUpdatedName] = useState("");
const [isNameValid, SetisNameValid] = React.useState(true);
const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

const [dataState, setDataState] = React.useState({
	sort: [
		{
			field: "alertId",
			dir: "asc",
		},
	],
	take: 10,
	skip: 0,
});

React.useEffect(() => {
	props.acquireToken(authSuccessCallback);
}, []);
React.useEffect(() => {
	if(profileData){
		setUpdatedName(profileData.header);
	}
}, [profileData]);
const authSuccessCallback = (token) => {
	if (token) {
		SetLoaded(false);
		SettingsService.GetBusinessHourProfiles(token).then((response) => {
			if(response && response.status==200){
					response=response.data;
					let _profiles = response?.map(m => ({header: m.Value, value: m.Key}));

					setBusinessHourProfiles(_profiles);
				if(_profiles && _profiles.length > 0){
					
						let selectedProfileDef = _profiles[0];
						SetProfileData(selectedProfileDef);
						getProfileDetailData(selectedProfileDef.value);
				}
				// setBusinessHourProfiles(response?.map((m) => ({header: m.Value, value: m.Key})));
				// props.acquireToken(authSuccessCallbackBusinessHours);
			}
			else if(response && response.status==401){
			props.viewStatus.InformChildPageStatus(response.status);
			}
			else {
			props.viewStatus.InformChildPageStatus(500);
			}  
			SetLoaded(true);    
		});
	}
};

const getProfileDetailData = (profileId) => {
	props.acquireToken((token) => {
		if (token) {
				let _params = {
					profileId: profileId,
				};

				SetLoadedDetails(false);
				SettingsService.GetBusinessHours(token, _params).then((res) => {
						if(res && res.status==200){
							SetProfileDetailsData(res.data);
						}
						SetLoadedDetails(true);
				});
		}
	});
}

const updateData = () => {
	props.acquireToken((token) => {
		if (token) {
				let _params = {
					profileName: updatedName,
					businessHourTiming: profileDetailsData
				};

				SetLoaded(false);
				SettingsService.EditBusinessHours(_params, token).then((res) => {
					if(res && res.status==200){
						let indexUpdated = 0;
						businessHourProfiles.forEach(function(item, i) { if (item.value == profileData.value) {businessHourProfiles[i].header = updatedName; indexUpdated = i;}});
						SetProfileData(businessHourProfiles[indexUpdated]);
						getProfileDetailData(profileData.value);
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
					}
					SetLoaded(true);
				});
		}
	});

}

const deleteData = () => {
	props.acquireToken((token) => {
		if (token) {
			let _params = {
				businessHrProfileId: Number(profileData.value),
			}
			SetLoaded(false);
			SettingsService.DeleteBusinessHours(_params, token).then((res) => {
					if(res && res.status==200){
						let _remainingProfiles = businessHourProfiles.filter((f) => f.value != profileData.value);
						setBusinessHourProfiles(_remainingProfiles);
						let selectedProfileDef = _remainingProfiles[0];
						SetProfileData(selectedProfileDef);
						getProfileDetailData(Number(selectedProfileDef.value));
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
					}
					SetLoaded(true);
			});
		}
	});

}

	
const handleAddEditClickOpen = (dataItem, isEdit) => {
	SetisNameValid(true);
    setVisible(true);
		

    if (isEdit === true) {
      setformData(dataItem);
      setEditMode(true);
			setIsAlertIdDisabled(true);
      // checkIsValid();
    }
	else{
		setformData(null) ;
		setIsAlertIdDisabled(false);
	}
};

const handleAddEditClickClose = () => {
    setVisible(false);
    setformData(null);
    setEditMode(false);
    setIsValid(false);
  };


	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		if(isValid == true){
			props.acquireToken((token) => {
				if (token) {
					SettingsService.AddBusinessHoursProfile(formData, token).then((response) => {
						if (response && response.data != '-1') {
							props.acquireToken(authSuccessCallback);
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
						} 
					});
				}      
				setVisible(false);
				setEditMode(false);
				setIsValid(false);
			});
		}
   
  };

	const CheckForm = () => {
    let isValid = true;
	
		if(formData?.Name && formData?.Name.toString().length > 0){
			isValid = true;
		}
		else{
			isValid = false;
			SetisNameValid(false);
		}
	
		return isValid;
		
  };

    

	const handleBusinessProfileChange = (alwaysNull, data) => {
		SetProfileData(data.value);
		getProfileDetailData(data.value.value);
	};

	const handleOpeningTimeChange = (e, data) => {
		updateItem(data.weekday, { OpeningHours: data.value})
	};

	const handleClosingTimeChange = (e, data) => {
		updateItem(data.weekday, { ClosingHours: data.value})
	};

	const handleIsClosedChange = (e, data) => {
		// console.log(data);
		updateItem(data.weekday, { IsClosed: data.checked})
	};

	const updateItem = (id, itemAttributes) => {
		let index = profileDetailsData.findIndex(x=> x.Weekday == id);
		let arr = [];
		

		if (index > -1){

			for(let i = 0; i< profileDetailsData.length; i++){
				if(i == index){
					arr.push(Object.assign({}, profileDetailsData[index], itemAttributes));
				}
				else{
					arr.push(profileDetailsData[i]);
				}
			}

			SetProfileDetailsData(arr);
		}
	}


	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between">
								<div className="f-size-18">Business Hours</div>
								{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
								
								<Button
									icon={<AddIcon />}
									iconPosition="before"
									content="Add Business Hours Profile"
									primary
									title="Add Business Hours Profile"
									onClick={handleAddEditClickOpen} className="ml-auto"
								/>}
							</div>

							<div>
								{visible && (
									<Dialog
									className="k-dialog-md"
										title={editMode ? "Add Business Hours Profile" : "Add Business Hours Profile"}
										onClose={handleAddEditClickClose}
									>
										<Form 
										// onSubmit={submitHandler}
										>
											<FormInput
												label= {isNameValid == true ? "Name* " : <Text color="red" content="Name *Required" />}
												id="alertId-inline"
												fluid
												value={formData?.Name}
												onChange={(value) =>
													{
														if(value.target.value && value.target.value.length > 0){
															SetisNameValid(true);
														}
														else{
															SetisNameValid(false);
														}
														setformData({
															...formData,
															Name: value.target.value,
														})
													}
												}
											/>
											
											<DialogActionsBar>
												<button
													className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
													onClick={addEditSubmitClickHandler}
													// disabled={isValid === false}
												>
													{" "}
													Submit
												</button>
												<button
													className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
													onClick={handleAddEditClickClose}
												>
													{" "}
													Close
												</button>
											</DialogActionsBar>
										</Form>
									</Dialog>
								)}
							</div>
						
						</CardHeader>
						<CardBody>
						<Flex gap="gap.small" vAlign="center" className="mb-4 mt-3">
							<Text content="Business Hours Profile"></Text>
							<Dropdown
								label={{
									content: `Business Profile`,
									id: "business-inline",
								}}
								items={businessHourProfiles}
								aria-labelledby={"business-inline"}
								placeholder="Select Business Hours Profile"
								onChange={handleBusinessProfileChange}
								value={profileData}
							/>
						</Flex>

						{
							isLoadedDetails ?
							<div>
								<Form>
								{
									profileData.value != '1' && <div>
										<FormInput
											label= {isNameValid == true ? "Name* " : <Text color="red" content="Name *Required" />}
											id="alertId-inline"
											fluid
											value={updatedName}
											onChange={(value) =>
												{
													if(value.target.value && value.target.value.length > 0){
														SetisNameValid(true);
													}
													else{
														SetisNameValid(false);
													}
													setUpdatedName(value.target.value);
												}
											}
										/>
									</div>
								}
								<table className="business-hours">
									<thead>
										<tr>
											<th></th>
											<th>Opening Hours</th>
											<th>Closing Hours</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td> Monday </td>
											<td> 
												<div  className="time-ddl-container">
													<Dropdown 
														weekday="2"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 2)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 2)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="2"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 2)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 2)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													weekday="2"
													className="form-checkbox"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 2)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Tuesday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="3"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 3)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 3)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="3"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 3)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 3)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="3"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 3)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Wednesday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="4"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 4)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 4)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="4"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 4)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 4)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="4"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 4)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Thursday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="5"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 5)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 5)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="5"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 5)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 5)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="5"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 5)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Friday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="6"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 6)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 6)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="6"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 6)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 6)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="6"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 6)?.IsClosed: false} /> 
											</td>
										</tr>
										
										<tr>
											<td> Saturday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="7"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 7)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 7)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="7"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 7)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 7)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="7"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 7)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Sunday </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="1"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 1)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 1)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="1"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 1)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 1)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="1"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == 1)?.IsClosed: false} /> 
											</td>
										</tr>

										<tr>
											<td> Public Holidays </td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="-1"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleOpeningTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == -1)?.OpeningHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == -1)?.IsClosed: false}
													/>
												</div>
											</td>
											<td> 
												<div className="time-ddl-container">
													<Dropdown 
														weekday="-1"
														items={TimeList? TimeList.map(m => m.header) : null}
														onChange={handleClosingTimeChange}
														className="time-ddl"
														value={profileDetailsData ? profileDetailsData.find(i => i.Weekday == -1)?.ClosingHours:"00:00"}
														disabled={profileDetailsData ? profileDetailsData.find(i => i.Weekday == -1)?.IsClosed: false}
													/>
												</div>	
											</td>
											<td> 
												<Checkbox 
													className="form-checkbox"
													weekday="-1"
													label="Closed?"
													onChange={handleIsClosedChange}
													checked={profileDetailsData ? profileDetailsData.find(i => i.Weekday == -1)?.IsClosed: false} /> 
											</td>
										</tr>

									</tbody>
								</table>
								
								</Form>
								<div>
									<Button tinted  content="Update" className="mt-5" onClick={updateData}></Button>
									{
										profileData.value != '1' && <Button content="Delete" className="mt-5 ml-3 text-danger" onClick={deleteData}></Button>
									}
								</div>
							</div>
							:
							<Loader />
						}
						
						</CardBody>
					</Card>
				</div>
				
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { SettingsBusinessHours };
