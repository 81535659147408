import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Card, CardHeader, CardBody, Button, Toolbar, Input,
	Loader,
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, RetryIcon, SyncIcon, SurveyIcon } from "@fluentui/react-icons-northstar";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { CellDateTime, CellColTitle, CellDateTimeUpdated, CellDateTimeRelative } from "../../shared/appcomponents/custom-grid-cells";

const SettingsLogs = (props) => {
	const [alerTypeStateForTitle, setAlerTypeStateForTitle] = useState(1);

	const [sdlogs, setSDLogs] = React.useState(null);

	const [isLoaded, SetLoaded] = React.useState(false);

	let alertType = '1';
	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "DateAddedHub",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});
	let LoadData = () => {
		props.acquireToken(authSuccessCallback);
	}

	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);
	}, []);

	const authSuccessCallback = (token) => {
		let _params = {
			alertType: alertType
		}
		if (token) {
			SettingsService.GetLogs(token, _params).then((response) => {
				if (response && response.status == 200) {
					response = response.data;

					setSDLogs(response);
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};

	const filterGridData = (value) => {
		let filter = {
			logic: "or",
			filters: [
				// { field: "Id", operator: "contains", value: value },
				// { field: "InstanceName", operator: "contains", value: value },
				{ field: "DateAddedHub", operator: "contains", value: value },
				{ field: "DateUpdatedHub", operator: "contains", value: value }
			],
		};
		setDataState({ ...dataState, filter: filter });
	};

	const impactLevel = (props) => {
		const Level = props.dataItem.ImpactLevel;
		return (
			<td style={{ textAlign: "center" }}>
				{Level === 0 && (
					<div className="d-flex align-item-center">
						<div className="stausDot mr-1 bg-success"></div>
						<span>Healthy</span>
					</div>
				)}
				{Level === 1 && (
					<div className="d-flex align-item-center">
						<div className="stausDot mr-1 bg-warning"></div>
						<span>Warning</span>
					</div>
				)}
				{Level === 2 && (
					<div className="d-flex align-item-center">
						<div className="stausDot mr-1 bg-danger"></div>
						<span>Critical</span>
					</div>
				)}
			</td>
		);
	};

	const impactArea = (props) => {
		const Area = props.dataItem.Impact;
		return (
			<td style={{ textAlign: "center" }}>
				{Area.includes('PROCESS') && (
					<div className="d-flex align-item-center">
						<SurveyIcon /><span class="ml-1">{Area}</span>
					</div>
				)}
				{Area.includes('DATASYNC') && (
					<div className="d-flex align-item-center">
						<SyncIcon /><span class="ml-1">{Area}</span>
					</div>
				)}
				{Area.includes('CACHESYNC') && (
					<div className="d-flex align-item-center">
						<RetryIcon /><span class="ml-1">{Area}</span>
					</div>
				)}
			</td>
		);
	}





	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100">
						<div className="col-md-12 h-100">
							<Card className="card-border bg-white h-100" fluid>
								<CardHeader className="mb-2">
									<div className="d-flex justify-content-between">
										<div className="f-size-18">Application Diagnostics</div>
										{props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) &&
											<Toolbar
												items={[
													{
														key: 'custom-button',
														kind: 'custom',
														content: <Button onClick={() => { alertType = '1'; LoadData();  setAlerTypeStateForTitle(1);}} content="Open Alerts" />,
														fitted: 'horizontally',
														className: 'btn mr-2',
													}, {
														key: 'custom-button',
														kind: 'custom',
														content: <Button onClick={() => { alertType = '-1'; LoadData(); setAlerTypeStateForTitle(-1); }} content="Historical Alerts" />,
														fitted: 'horizontally',
														className: 'btn',
													},

												]}
											/>


										}
									</div>

								</CardHeader>
								<CardBody>
									<div className="tbl-search float-right text-right mb-2">
										<Input
											onChange={(e, { name, value }) => {
												filterGridData(value);
											}}
											inverted
											icon={<SearchIcon />}
											placeholder="Search..."
											className="border-gray"
										/>
									</div>
									<div className='grid-with-search'>
										<Grid
											style={{
												height: "100%",
											}}
											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											//data={sdlogs}
											data={process(sdlogs, dataState)}
											{...dataState}
											onDataStateChange={(e) => {
												setDataState(e.dataState);
											}}
										//  detail={context.mediaCategory.sm ? DetailComponentSm : null}
										//  expandField="expanded"
										//  onExpandChange={expandChange}
										>
											<Column field="DateAddedHub" title={<CellColTitle title="Genrated At" />} cell={CellDateTimeRelative} width={100} />
											<Column field="InstanceName" title="Instance" width={100} />
											<Column field="Impact" title="Impact Area" width={150} cell={impactArea} />
											<Column field="ImpactLevel" title="Impact Level" cell={impactLevel} width={100} /> 
											
											<Column field="DateUpdatedHub" title={<CellColTitle title={ alerTypeStateForTitle == "-1" ?  "Cleared At": "Last Updated" }/>}    cell={CellDateTimeUpdated} width={100} />
											
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>

					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { SettingsLogs };
