import axios from 'axios'
import * as constants from '../../Constants'

class SettingsService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static AuthenticatedApi_download = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    responseType: 'blob',

  });

  static GetVMData(token) {
    return this.AuthenticatedApi.get("/Settings/GetVMData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetPortalData(token) {
    return this.AuthenticatedApi.get("/Settings/GetPortalData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInstanceServerMapping(token) {
    return this.AuthenticatedApi.get("/Settings/GetInstanceServerMapping/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServerNames(token) {
    return this.AuthenticatedApi.get("/Settings/GetServerNames/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInstanceNames(token) {
    return this.AuthenticatedApi.get("/Settings/GetInstanceNames/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetMasterInstanceServerData(token) {
    return this.AuthenticatedApi.get("/Settings/GetMasterInstanceServerData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetPlatforms(token) {
    return this.AuthenticatedApi.get("/Settings/GetPlatforms/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // static GetVersions(token) {
  //   return this.AuthenticatedApi.get("/Settings/GetVersions/", {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data) return response.data;
  //       else return null;
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "SettingsService.GetVersions ()  - An error has occurred calling the web api: " +
  //           error
  //       );
  //     });
  // }

  static GetDbRepoVersions(token) {
    return this.AuthenticatedApi.get("/Settings/GetDbRepoVersions/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetDbRepoVersionQueries(token, dbRepoVersionNumber) {
    return this.AuthenticatedApi.get("/Settings/GetDbRepoVersionQueries/", {
      params: { dbRepoVersionNumber: dbRepoVersionNumber },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServerRules(token) {
    return this.AuthenticatedApi.get("/Settings/GetServerRules/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateServerRule(token, serverRule) {
    return this.AuthenticatedApi.post("/Settings/UpdateServerRule/", serverRule, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetRegions(token) {
    return this.AuthenticatedApi.get("/Settings/GetRegions/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTimezones(token) {
    return this.AuthenticatedApi.get("/Settings/GetTimezones/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetCountries(token) {
    return this.AuthenticatedApi.get("/Settings/GetCountries/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetUserData(token) {
    return this.AuthenticatedApi.get("/Settings/GetUserData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetStorageAccountNames(token) {
    return this.AuthenticatedApi.get("/Settings/GetStorageAccountNames/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddServer(serverData, token) {
    return this.AuthenticatedApi.put("/Settings/AddServer/", serverData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateServer(token, serverData) {
    return this.AuthenticatedApi.post("/Settings/UpdateServer/", serverData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveServer(token, _params) {
    return this.AuthenticatedApi.delete("/Settings/RemoveServer/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateUser(token, userData) {
    return this.AuthenticatedApi.post("/Settings/UpdateUser/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveUser(token, _params) {
    return this.AuthenticatedApi.delete("/Settings/RemoveUser/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddInstanceServerMapping(userData, token) {
    return this.AuthenticatedApi.put(
      "/Settings/AddInstanceServerMapping/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateInstanceServerMapping(token, instanceServerData) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateInstanceServerMapping/",
      instanceServerData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveInstanceServerMapping(token, _params) {
    return this.AuthenticatedApi.delete(
      "/Settings/RemoveInstanceServerMapping/",
      {
        params: _params,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddInstance(instanceData, token) {
    return this.AuthenticatedApi.put("/Settings/AddInstance/", instanceData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateInstance(token, instanceData) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateInstance/",
      instanceData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static UpdateInstanceConnection(token, data) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateInstanceConnection/",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static RemoveInstance(token, _params) {
    return this.AuthenticatedApi.delete("/Settings/RemoveInstance/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetMasterResellerPage(token) {
    return this.AuthenticatedApi.get("/Settings/GetMasterResellerPage/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddMasterReseller(token, serverData) {
    return this.AuthenticatedApi.post(
      "/Settings/AddMasterReseller/",
      serverData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateMasterReseller(token, serverData) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateMasterReseller/",
      serverData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DeleteMasterReseller(token, serverData) {
    return this.AuthenticatedApi.post(
      "/Settings/DeleteMasterReseller/",
      serverData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // static AddVersion(userData, token) {
  //   return this.AuthenticatedApi.put("/Settings/AddVersion/", userData, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => {
  //       return "Success";
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "SettingsService.AddVersion() - An error has occurred calling the web api: " +
  //           error
  //       );
  //       return null;
  //     });
  // }

  // static UpdateVersion(token, version) {
  //   return this.AuthenticatedApi.post("/Settings/UpdateVersion/", version, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => {
  //       return "Success";
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "SettingsService.UpdateVersion() - An error has occurred calling the web api: " +
  //           error
  //       );
  //       return null;
  //     });
  // }

  // static RemoveVersion(token, _params) {
  //   return this.AuthenticatedApi.delete("/Settings/RemoveVersion/", {
  //     params: _params,
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   })
  //     .then((response) => {
  //       return "Success";
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "SettingsService.RemoveVersion() - An error has occurred calling the web api: " +
  //           error
  //       );
  //       return null;
  //     });
  // }

  static AddDbRepoVersion(userData, token) {
    return this.AuthenticatedApi.post("/Settings/AddDbRepoVersion/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateDbRepoVersion(token, version) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateDbRepoVersion/",
      version,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveDbRepoVersion(token, _params) {
    return this.AuthenticatedApi.delete("/Settings/RemoveDbRepoVersion/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServiceAlerts(token) {
    return this.AuthenticatedApi.get("/Settings/GetServiceAlerts/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddServiceAlert(userData, token) {
    return this.AuthenticatedApi.put("/Settings/AddServiceAlert/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditServiceAlert(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateServiceAlert/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveServiceAlert(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/RemoveServiceAlert/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServices(token) {
    return this.AuthenticatedApi.get("/Settings/GetServices/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddService(userData, token) {
    return this.AuthenticatedApi.put("/Settings/InsertService/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditService(userData, token) {
    return this.AuthenticatedApi.post("/Settings/UpdateService/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveService(userData, token) {
    return this.AuthenticatedApi.post("/Settings/RemoveService/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetOnCallPlannerPage(token) {
    return this.AuthenticatedApi.get("/Settings/GetOnCallPlannerPage/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static UpdateOnCallSupervisor(token, _params) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateOnCallSupervisor",
      _params,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static AddOnCallMember(token, _params) {
    return this.AuthenticatedApi.post("/Settings/AddOnCallMember", _params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static RemoveOnCallMember(token, _params) {
    return this.AuthenticatedApi.delete("/Settings/RemoveOnCallMember/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetEmailLogs(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetEmailLogs/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetAWSNotifications(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetAWSNotifications/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DownloadAWSNotification(token, _params) {
    return this.AuthenticatedApi_download.post('/Settings/GetAWSNotificationDetail',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetUserPermissions(token) {
    return this.AuthenticatedApi.get("/Settings/GetUserPermissions/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddUserPermission(userData, token) {
    return this.AuthenticatedApi.post("/Settings/AddUserPermission/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditUserPermission(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateUserPermission/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveUserPermission(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/RemoveUserPermission/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetPriorityCustomers(token) {
    return this.AuthenticatedApi.get("/Settings/GetPriorityCustomers/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static AddUpdatePriorityCustomer(token, priorityCustomerData) {
    return this.AuthenticatedApi.post(
      "/Settings/AddUpdatePriorityCustomer/",
      priorityCustomerData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemovePriorityCustomer(token, _params) {
    return this.AuthenticatedApi.delete(
      "/Settings/RemovePriorityCustomer/",
      {
        params: _params,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetEmail(token, _params) {
    return this.AuthenticatedApi.get('/Settings/GetEmail', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateVNetType(token, VNetType) {
    return this.AuthenticatedApi.post("/Settings/UpdateVNetType/", VNetType, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetSPAccessData(token, _params) {
    return this.AuthenticatedApi.get('/Settings/GetSPAccessData', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static SaveSpAccessData(token, _params) {
    return this.AuthenticatedApi.post('/Settings/SaveSpAccessData', _params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetBusinessHourProfiles(token) {
    return this.AuthenticatedApi.get("/Settings/GetBusinessHourProfiles/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetBusinessHours(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetBusinessHours/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddBusinessHoursProfile(userData, token) {
    return this.AuthenticatedApi.post("/Settings/AddBusinessHoursProfile/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditBusinessHours(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/EditBusinessHours/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static DeleteBusinessHours(_params, token) {
    return this.AuthenticatedApi.delete(
      "/Settings/DeleteBusinessHours/",
      {
        params: _params,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static GetHolidays(token) {
    return this.AuthenticatedApi.get("/Settings/GetHolidays/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddHoliday(userData, token) {
    return this.AuthenticatedApi.put("/Settings/AddHoliday/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditHoliday(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateHoliday/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveHoliday(userData, token) {
    return this.AuthenticatedApi.post(
      "/Settings/RemoveHoliday/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetLogs(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetLogs/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static getInfraDocDetails(token, InstanceId, VNetResourceUri, StorageAccountName) {
    return this.AuthenticatedApi.get("/Settings/getInfraDocDetails/", {
      params: {
        InstanceId: InstanceId, VNetResourceUri: VNetResourceUri,
        StorageAccountName: StorageAccountName
      },

      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetAPITokens(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetAPITokens/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddAPIToken(data, token) {
    return this.AuthenticatedApi.put("/Settings/AddAPIToken/", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetEmailAnalysis(token, _params) {
    return this.AuthenticatedApi.get("/Settings/GetEmailAnalysis/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static AddBrand(token, brand) {
    return this.AuthenticatedApi.post("/Settings/AddBrand", brand, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateBrand(token, brand) {
    return this.AuthenticatedApi.post("/Settings/UpdateBrand", brand, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetBrandsAnalysis(token) {
    return this.AuthenticatedApi.get("/Settings/GetBrandsAnalysis", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DeleteBrand(token, brandId) {
    return this.AuthenticatedApi.delete(`/Settings/DeleteBrand/${brandId}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetCertificates(token) {
    return this.AuthenticatedApi.get('/Settings/GetCertificates', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response)
    .catch(error => error.response.data);
  }

  // Add a new certificate (using PUT as per backend)
  static AddCertificate(certificate, token) {
    return this.AuthenticatedApi.put('/Settings/AddCertificate', certificate, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response)
    .catch(error => error.response.data);
  }

  // Update an existing certificate
  static UpdateCertificate(certificate, token) {
    return this.AuthenticatedApi.post('/Settings/UpdateCertificate', certificate, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response)
    .catch(error => error.response.data);
  }

  // Delete a certificate
  static DeleteCertificate(id, token) {
    return this.AuthenticatedApi.post('/Settings/DeleteCertificate', id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response)
    .catch(error => error.response.data);
  }

  static UpdateAPIToken(token, data) {
    return this.AuthenticatedApi.post(
      "/Settings/UpdateAPIToken/",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetEmailFilterData(token) {
    return this.AuthenticatedApi.get("/Settings/GetEmailFilterData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });

  }


  

}


export default SettingsService;