import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status , Text} from '@fluentui/react-northstar';
import { Grid, GridColumn as Column, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon, FilesFlashIcon, DownloadIcon } from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import SettingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { getter } from '@progress/kendo-react-common';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ServerIIS = (props) => {
  const [vmData, setVMData] = useState([]);
  const [isLoaded, SetLoaded] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [siteData, SetSiteData] = useState(null);
  const [bindingData, SetBindingData] = useState(null);
  const [visibleBinding, setVisibleBinding] = React.useState(false);
  const [isValidBinding, setIsValidBinding] = useState(false);

  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);  
  const [deleteInstanceServerObj, setDeleteInstanceServerObj] = useState(null);

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "Name",
          dir: "asc",
        },
      ]
    });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "Name", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };

    React.useEffect(() => {
      props.acquireToken(authSuccessCallback);
    }, []);

    useEffect(() => checkIsValid(), [siteData]);

    
    const checkIsValid = () => {
      if (
        siteData?.SiteName !== undefined &&
        siteData?.SiteName?.length > 0 &&
        siteData?.SitePort !== undefined  &&
        siteData?.SitePort?.length > 0 && 
        siteData?.SitePath !== undefined  &&
        siteData?.SitePath?.length > 0    && 
        siteData?.SiteHost !== undefined  &&
        siteData?.SiteHost?.length > 0        
      ) 
      {
        setIsValid(true);
      } else setIsValid(false);
    };


    
    useEffect(() => checkIsValidBinding(), [bindingData]);

    
    const checkIsValidBinding = () => {
      if (
        bindingData?.BindingPort !== undefined  &&
        bindingData?.BindingPort?.length > 0 && 
        bindingData?.BindingHost !== undefined  &&
        bindingData?.BindingHost?.length > 0        
      ) 
      {
        setIsValidBinding(true);
      } else setIsValidBinding(false);
    };

    
    const authSuccessRemoveInstanceServerCallback = (token, siteId) => {
      if (token) {
        let _params = {
          SiteId: siteId,
          IPAddress : props?.serverObj?.IPAddress,
          Port : props?.serverObj?.Port
        };

        SettingServerSM.RemoveSite(_params, token).then(
          (response) => {
            if(response && response.status==200){
              response=response.data;
              props.acquireToken(authSuccessCallback);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }  else if(response && response.status==401){
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);  
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          }
        );
      }
    };

    const removeData = () => {
      if (deleteInstanceServerObj != null) {
        console.log(deleteInstanceServerObj);
        props.acquireToken((token) =>
          authSuccessRemoveInstanceServerCallback(token, deleteInstanceServerObj.Id)
        );
      }

      setDeleteConfirmationDialog(false);
      setDeleteInstanceServerObj(null);
    };

    const authSuccessCallback = (token) => {
      var data = props.serverObj;
      let serverObj = { IPAddress: data.IPAddress, Port: data.Port };

      if (token) {
        SettingServerSM.GetIISProcesses(serverObj, token).then((response) => {
          if (response && response.status == 200) {
            let servicesFound = response.data;
            setVMData(response.data);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }
          SetLoaded(true);
        });
      }
    };

    const DetailComponentSm = (props1) => {
      const dataItem = props1.dataItem?.Bindings;
      return (
        <td style={{ margin : 10}}>
                          <div className="d-flex justify-content-between">
                    <div className="f-size-18">Site Details</div>
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "ServerVM.Site.ReadWrite" 
                    ) && (
                      <Button
                        icon={<AddIcon />}
                        iconPosition="before"
                        content="Add Binding"
                        primary
                        title="Add Binding"
                        onClick={handleAddEditBindingClickOpen}
                        className="ml-auto"
                      />
                    )}
                  </div>
        <Grid
        style={{
          height: "100%",
        }}
        sortable={true}
        data={dataItem}
        {...dataState}
        onDataStateChange={(e) => {
          setDataState(e.dataState);
        }}
      >
        <Column
          field="Host"
          title="Host"
          width={140}
        />

        <Column
          field="Protocol"
          title="Protocol"
          width={140}
        />
        <Column
          field="Port"
          title="Port"
          width={140}
        />
        
        <Column
          field=""
          cell={CertificateDetailCell}
          title="Certificate"
          width={150}
        />
      </Grid> 
      </td>
         );
    };

    const expandChange = (event) => {
      debugger;
      let alteredData = (vmData).map((item) => {
          item.expanded = false;
        return item;
      });

      let newData = (alteredData).map((item) => {
        if (item.Id === event.dataItem.Id) {
          item.expanded = event.syntheticEvent.target.classList.contains('k-i-minus') ? false : true;
        }
  
        return item;
      });
      setVMData(newData);
    };

    const CertificateDetailCell = (props) => {
      const certificate = props?.dataItem?.Certificate;
      const certificateDate = certificate != null ? new Date(certificate?.ExpiryDate) : new Date();
      const currentDate = new Date();
      const weekBeforeDate = (new Date()).setDate(currentDate.getDate() - 7);

      return (certificate != null &&
        <td>
          <div style={{
    position: "relative",
    textAlign: "center",
    justify:  "center",
    border:  "1px solid #ccc",
    width: "fit-content",
    padding: "0px 30px"
          }}>
          {certificate != null && certificateDate > currentDate && (
            <span className="staus-bookmark mr-1 bg-success"></span>
          )}
          {certificate != null && certificateDate < weekBeforeDate && (
            <span className="staus-bookmark mr-1 bg-warning"></span>
          )}
          {(certificate != null && 
          certificateDate >= weekBeforeDate && 
          certificateDate < currentDate) && (
            <span className="staus-bookmark mr-1 bg-danger"></span>
          )}
          <Text
            size="medium"
            as="p"
            content={<span> {certificate?.Issuer}</span>}
            weight="semibold"
            className="text-primary cursor-pointer"
            title={"Certificate Expiry : " + certificateDate}
          />
          </div>
        </td>
      );
    };

    const handleAddEditSiteClickOpen = (dataItem, isEdit) => {
      setVisible(true);
      if (isEdit === true) {
        SetSiteData(dataItem);
      }
    };

    const handleAddEditSiteClickClose = () => {
      setVisible(false);
      SetSiteData(null);
      setIsValid(false);

      setDeleteConfirmationDialog(false);
      setDeleteInstanceServerObj(null);
    };

    const addEditSubmitClickHandler = () => {
      props.acquireToken(authSuccessAddEditServerCallback);
      setVisible(false);
      setEditMode(false);
      SetSiteData(null);
    };

    const submitHandler = e => {
      e.preventDefault();
    }

    const authSuccessAddEditServerCallback = (token) => {
      siteData.IPAddress = props?.serverObj?.IPAddress;
      siteData.Port = props?.serverObj?.Port;

      if (token) {
        SettingServerSM.AddSite(siteData, token).then((response) => {
          if(response && response.status==200){
            response=response.data;          
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else{
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
          }
    };



    const handleAddEditBindingClickOpen = (dataItem, isEdit) => {
      setVisibleBinding(true);
      if (isEdit === true) {
        SetBindingData(dataItem);
      }
    };

    const handleAddEditBindingClickClose = () => {
      setVisibleBinding(false);
      SetBindingData(null);
      setIsValidBinding(false);

      setDeleteConfirmationDialog(false);
      setDeleteInstanceServerObj(null);
    };

    const addEditBindingSubmitClickHandler = () => {
      props.acquireToken(authSuccessAddEditBindingCallback);
      setVisibleBinding(false);
      setEditMode(false);
      SetBindingData(null);
    };

    const authSuccessAddEditBindingCallback = (token) => {

      let _params = {
        IPAddress : props?.serverObj?.IPAddress,
        Port : props?.serverObj?.Port,
        BindingPort : bindingData.BindingPort,
        BindingHost : bindingData.BindingHost
      };

      let newData = (vmData.data).map((item) => {
        if (item.expanded === true) {
          _params = {..._params, SiteId : item.Id};
    }});


      if (token) {
        SettingServerSM.AddBinding(_params, token).then((response) => {
          if(response && response.status==200){
            response=response.data;          
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else{
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
          }
    };


    const ActionIconVM = (props) => {
      console.log(props.dataItem);
      let serverObj = {IPAddress : '22.33.55.77', Port : 5050};

    return (
        <td 
        style={{ textAlign: "center" }}>          
          <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteInstanceServerObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }
          }
        />
        </td>
    );
    }


    const ActionDownloadVM = (props) => {

    return (
        <td 
        style={{ textAlign: "center" }}>          
          <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<DownloadIcon />}
          text
          onClick={() => {
            setDeleteInstanceServerObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }
          }
        />
        </td>
    );
    }

    const CellLogAccess = (props1) => {
 
      const [data, setData] = useState(null);
      const [isLoaded, setLoaded] = useState(false);
      const siteId = props1?.dataItem?.Id;
      const siteName = props1?.dataItem?.Name;

      const [open, setOpen] = useState(false);
    
      let incidentLevelId = 2;
    
      //console.log('load CellIncidenttenants');
    
      let LoadData = () => {
        // console.log('load data');
        props1.acquireToken((token) => {
          let _params = {
            SiteId: siteId,
            IPAddress : props.serverObj.IPAddress,
            Port : props.serverObj.Port
          };

          SettingServerSM.GetAllLogs(token, _params).then((response) => {
            // console.log(response);
            if(response && response.status==200){
              response=response.data;

              setData(response);
              setLoaded(true);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
            }
            else{
              props.viewStatus.InformChildPageStatus(500);
            }
          });
          setLoaded(false);
        })
      }
    
      return (
        <td style={{ textAlign: "center" }} className='historyCell'>
          <FluentDialog
            styles={{ width: '50%', textAlign: "left", maxHeight: "90%" }}
            backdrop={true}
            header="Logs"
            headerAction={{
              icon: <CloseIcon />,
              title: 'Close',
              onClick: () => setOpen(false),
            }}
    
            content={
              <>
                <Text size="medium" as="p" content={`Site Name: ${siteName}`} />
                <Grid
                      style={{
                        height: "100%",
                      }}
                      sortable={true}
                      data={data}
                    >
                      <Column
                        field="LogFile"
                        title="Name"
                        width={140}
                      />
                        <Column
                          field=""
                          title="Actions"
                          cell={ActionDownloadVM}
                          width={100}
                        />
                    </Grid>
    
              </>
            }
            open={open}
            onOpen={() => { setOpen(true); LoadData(); }}
            trigger={
              <Button iconOnly size="small" icon={<FilesFlashIcon />} text title="Log Access" />
            }
            // cancelButton="Close"
            onCancel={() => setOpen(false)}
          />
        </td>
      )
    };
    
  const CellLogCol = (prop) => {
    return (
      <CellLogAccess {...prop} acquireToken={props.acquireToken} />
    );
  }

    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                    <div className="f-size-18">Site Details</div>
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "Settings.ReadWrite" ||
                        p == "Settings.Configuration.ReadWrite"
                    ) && (
                      <Button
                        icon={<AddIcon />}
                        iconPosition="before"
                        content="Add Site"
                        primary
                        title="Add Site"
                        onClick={handleAddEditSiteClickOpen}
                        className="ml-auto"
                      />
                    )}
                  </div>
                  <div>
                    {visibleBinding && (
                      <Dialog
                        title="Add Binding"
                        onClose={handleAddEditBindingClickClose}
                      >
                        <Form onSubmit={submitHandler}>
                          <FormInput
                            label="Binding Host"
                            name="BindingHost"
                            id="BindingHost-inline"
                            fluid
                            required
                            maxLength={40}
                            value={bindingData?.BindingHost}
                            onChange={(value) =>
                              SetBindingData({
                                ...bindingData,
                                BindingHost: value.target.value,
                              })
                            }
                          />
                          <FormInput
                            label="Binding Port"
                            name="BindingPort"
                            id="BindingPort-inline"
                            fluid
                            required
                            maxLength={40}
                            value={bindingData?.BindingPort}
                            onChange={(value) =>
                              SetBindingData({
                                ...bindingData,
                                BindingPort: value.target.value,
                              })
                            }
                          />
                          <DialogActionsBar>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditBindingSubmitClickHandler}
                              disabled={isValidBinding === false}
                            >
                              {" "}
                              Submit
                            </button>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              onClick={handleAddEditBindingClickClose}
                            >
                              {" "}
                              Close
                            </button>
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>
                  <div>
                    {visible && (
                      <Dialog
                        title="Add Site"
                        onClose={handleAddEditSiteClickClose}
                      >
                        <Form onSubmit={submitHandler}>
                          <FormInput
                            label="Site Name"
                            name="SiteName"
                            id="SiteName-inline"
                            fluid
                            required
                            maxLength={40}
                            value={siteData?.SiteName}
                            onChange={(value) =>
                              SetSiteData({
                                ...siteData,
                                SiteName: value.target.value,
                              })
                            }
                          />
                          <FormInput
                            label="Site Host"
                            name="SiteHost"
                            id="SiteHost-inline"
                            fluid
                            required
                            maxLength={40}
                            value={siteData?.SiteHost}
                            onChange={(value) =>
                              SetSiteData({
                                ...siteData,
                                SiteHost: value.target.value,
                              })
                            }
                          />
                            <FormInput
                            label="Site Path"
                            name="SitePath"
                            id="SitePath-inline"
                            fluid
                            required
                            maxLength={40}
                            value={siteData?.SitePath}
                            onChange={(value) =>
                              SetSiteData({
                                ...siteData,
                                SitePath: value.target.value,
                              })
                            }
                          />
                            <FormInput
                            label="Site Port"
                            name="SitePort"
                            id="SitePort-inline"
                            fluid
                            required
                            maxLength={4}
                            value={siteData?.SitePort}
                            onChange={(value) =>
                              SetSiteData({
                                ...siteData,
                                SitePort: value.target.value,
                              })
                            }
                          />
                          <DialogActionsBar>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditSubmitClickHandler}
                              disabled={isValid === false}
                            >
                              {" "}
                              Submit
                            </button>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              onClick={handleAddEditSiteClickClose}
                            >
                              {" "}
                              Close
                            </button>
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      sortable={true}
                      data={process(vmData, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                      detail={ DetailComponentSm }
                      expandField="expanded"
                      onExpandChange={expandChange}
                    >
                      <Column
                        field="Name"
                        title="Name"
                        width={140}
                      />
                      <Column
                        field="StateStr"
                        title="State"
                        width={150}
                      />
                      <Column
                        field=""
                        title="Log Files"
                        cell={CellLogCol}
                        width={100} filterable={false} sortable={false}
                      />
                      {props.user?.Permissions?.some(
                          (p) =>
                            p == "ServerVM.Site.ReadWrite" 
                        ) &&
                        <Column
                          field=""
                          title="Actions"
                          cell={ActionIconVM}
                          width={100}
                        />
                      }
                    </Grid>
                  </div>
                  <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteInstanceServerObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteInstanceServerObj(null);
              },
            }}
            content={
              "Are you sure you want to delete site :  " +
              deleteInstanceServerObj?.Name
            }
          />
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
  }


  export { ServerIIS };