import React from 'react'
import { Card, Flex, Text, Button, Tooltip, Toolbar } from '@fluentui/react-northstar'
import { AppsIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../shared/contexts/Context';

const PortalDashboard = (props) => {
    const [viewArea, setViewArea] = React.useState("");
    const [searchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const routeChange = (path, varea) =>{
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
    }
    React.useEffect(() => {
        let varea = searchParams.get('type');
        if(varea){
            setViewArea(varea);
        }
        else{
            if(window.location.pathname.includes('history')){
                setViewArea('history');
            }
            else{
                setViewArea("overview");
            }
        }
    }, []);
    return(
        <>
        <div className='dashboard-top'>
            <div className='row'>
                <div className='col-sm-12 col-xs-12 mt-2 mb-3'>
                <Card className='card card-portal' elevated >
                        {/* <Card.Header>
                            <Flex gap="gap.small" space="between" className='w-100'>
                                <Text content="Instance Alarms" />
                                <Flex gap="gap.small" className="card-actions">
                                    <Tooltip pointing trigger={<Button className={`${viewArea == "overview" ? 'selected' : ''}`} icon={<AppsIcon />} text iconOnly onClick={() => routeChange('/dashboards/portal/overview', 'overview')} />} content="Instance Overview" />
                                    <Tooltip pointing trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text iconOnly onClick={() => routeChange('/dashboards/portal/history', 'history')} />} content="Historical Alarms" />
                                </Flex>
                            </Flex>
                        </Card.Header> */}
                        <Card.Body>
                        <Toolbar
                            items={[
                                {
                                  key: 'custom-text',
                                  content: <Text content="Instance Alarms" />,
                                  kind: 'custom',
                                  className: 'heading',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-focusable-text',
                                  content: <Button icon={10} content="Critical Tenants" onClick={() => routeChange('/dashboards/portal/alarms?type=critical', 'critical')} iconPosition="after" />,
                                  focusable: true,
                                  kind: 'custom',
                                  active: true,
                                  className: 'btn critical',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-button',
                                  kind: 'custom',
                                  content: <Button icon={7} content="Warning" onClick={() => routeChange('/dashboards/portal/alarms?type=warning', 'warning')} iconPosition="after" />,
                                  fitted: 'horizontally',
                                  className: 'btn warning',
                                },
                                {
                                  key: 'custom-down-server',
                                  kind: 'custom',
                                  content: <Button icon={4} content="Instances Down" onClick={() => routeChange('/dashboards/portal/availability?type=down', 'down')} iconPosition="after" />,
                                  fitted: 'horizontally',
                                  className: 'btn down',
                                },
                                {
                                  key: 'custom-critical-servers',
                                  kind: 'custom',
                                  content:  <Tooltip pointing trigger={<Button className={`${viewArea == "overview" ? 'selected' : ''}`} icon={<AppsIcon />} text iconOnly onClick={() => routeChange('/dashboards/portal/overview', 'overview')} />} content="Instance Overview" />,
                                  fitted: 'horizontally',
                                  className: 'card-actions',
                                },
                                {
                                  key: 'custom-healthy-servers',
                                  kind: 'custom',
                                  content: <Tooltip pointing trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text iconOnly onClick={() => routeChange('/dashboards/portal/history', 'history')} />} content="Historical Alarms" />,
                                  fitted: 'horizontally',
                                  className: 'card-actions',
                                },
                              ]}
                            >
                            </Toolbar>
                            <AppsIcon outline rotate={0} size="largest" />
                            {/* <div className='row'>
                                <div className='col-sm-4 pr-1'>
                                    <div className={`d-flex btn critical ${viewArea == "critical" ? 'selected' : ''}`}>
                                        <Button icon={10} content="Critical Tenants" onClick={() => routeChange('/dashboards/portal/alarms?type=critical', 'critical')} iconPosition="after" />
                                    </div>
                                </div>
                                <div className='col-sm-4 pr-1 pl-1'>
                                    <div className={`d-flex btn warning ${viewArea == "warning" ? 'selected' : ''}`}>
                                        <Button icon={7} content="Warning" onClick={() => routeChange('/dashboards/portal/alarms?type=warning', 'warning')} iconPosition="after" />
                                    </div>
                                </div>
                                <div className='col-sm-4 pl-1'>
                                    <div className={`d-flex btn healthy ${viewArea == "healthy" ? 'selected' : ''}`}>
                                        <Button icon={83} content="Healthy" onClick={() => routeChange('/dashboards/portal/alarms?type=healthy', 'healthy')} iconPosition="after" />
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className='row mt-2'>
                                <div className='col-sm-6 pr-1'>
                                    <div className={`d-flex btn up ${viewArea == "up" ? 'selected' : ''}`}>
                                        <Button icon={4} content="Instances Up" onClick={() => routeChange('/dashboards/portal/availability?type=up', 'up')} iconPosition="after" />
                                    </div>
                                </div>
                                <div className='col-sm-6 pl-1'>
                                    <div className={`d-flex btn down ${viewArea == "down" ? 'selected' : ''}`}>
                                        <Button icon={4} content="Instances Down" onClick={() => routeChange('/dashboards/portal/availability?type=down', 'down')} iconPosition="after" />
                                    </div>
                                </div>
                            </div> */}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        <div className='dashboard-details mb-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    )
}

export const PortalMonitoring = (props) => {
    return(        
        <Outlet />
    )
}

export default PortalDashboard;