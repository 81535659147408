import axios from 'axios';
import * as constants from '../../Constants';

class AccountService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.apiConfig.resourceUri,
        // will inject auth header on-demand later as needed.
        headers: {
            'Content-Type': 'application/json'
        }
    });

    static GetAccountPermissions(token) {
        return this.AuthenticatedApi.get('/Account/GetAccountPermissions/', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data)
                    return response.data;
                else
                    return null;
            })
            .catch((error) => {
                console.log('AccountService.GetAccountPermission() - An error has occurred calling the web api: ' + error);
                return null;
            });
    }

    static GetUserDetails(token) {
        return this.AuthenticatedApi.get('/Account/GetUserDetails/', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data)
                    return response.data;
                else
                    return null;
            })
            .catch((error) => {
                console.log('AccountService.GetUserDetails() - An error has occurred calling the web api: ' + error);
                return null;
            });
    }

}

export default AccountService;