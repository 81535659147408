import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Label, Text, Dialog as FluentDialog } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon} from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const SettingsMasterResellers = (props) => {
    const [isLoaded, SetLoaded] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [regions, setRegions] = React.useState(null);
    const [countries, setCountries] = React.useState(null);
    const [currentMasterResellerData, setCurrentMasterResellerData] = useState(null);
    const [masterResellers, setMasterResellers] = useState([]);
    const [resellers, setResellers] = useState([]);
    const [IsResellerNameBlank, setIsResellerNameBlank] = useState(false);
    const [IsResellersDDLBlank, setIsResellersDDLBlank] = useState(false);
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteMasterResellerObj, setDeleteMasterResellerObj] = useState(null);

    const addEditSubmitClickHandler = () => {
        
      let isFormValid = validateForm();
     
      if(isFormValid == true){
        props.acquireToken((token) => {
        if (token) {
          if(editMode){
            SettingsService.UpdateMasterReseller(token, currentMasterResellerData).then((response) => {
              if(response && response.status==200){
                response=response.data;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
              refreshGrid();
            } else if(response && response.status==401){
              debugger;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
    
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          });
          }
          else{
            SettingsService.AddMasterReseller(token, currentMasterResellerData).then((response) => {
              if(response && response.status==200){
                response=response.data;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
              refreshGrid();
            }
            else if(response && response.status==401){
              debugger;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
    
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          });
          }
        }});
        setVisible(false);
        setEditMode(false);
       
      }
      
      // currentMasterResellerData(null);
    };

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "MasterResellerName",
          dir: "asc",
        },
      ],
      take: 10,
      skip: 0,
    });

     const filterGridData = (value) => {
       let filter = {
         logic: "or",
         filters: [
           { field: "MasterResellerName", operator: "contains", value: value },
           { field: "Description", operator: "contains", value: value },
           { field: "CountryName", operator: "contains", value: value },
           { field: "Region", operator: "contains", value: value },
         ],
       };
       setDataState({ ...dataState, filter: filter });
     };

      const expandChange = (event) => {
        let newData = masterResellers.map((item) => {
          if (item.MasterResellerId === event.dataItem.MasterResellerId) {
            item.expanded = !event.dataItem.expanded;
          }

          return item;
        });
        setMasterResellers(newData);
      };

    const DetailComponentSm = (props1) => {
      const dataItem = props1.dataItem;
      return (
        <section>
          <p>
            Description: <strong>{dataItem.Description}</strong>
          </p>
          <p>
            Region: <strong>{dataItem.Region}</strong>
          </p>
          <p>
            Country: <strong>{dataItem.CountryName}</strong>
          </p>
        </section>
      );
    };
  
    
    const validateForm = () => {
      let name = currentMasterResellerData?.MasterResellerName;
      let list = currentMasterResellerData?.ResellersList;
      let isValid = true;

      if(name && name.length > 0){
        setIsResellerNameBlank(false);
      }
      else{
        setIsResellerNameBlank(true);
        isValid = false;
      }

      if(list && list.length > 0){
        setIsResellersDDLBlank(false);
      }
      else{
        setIsResellersDDLBlank(true);
        isValid = false;
      }

      return isValid;

    };

    const refreshGrid = () => {
      SetLoaded(false);
      props.acquireToken((token) => {
        if(token){
            SettingsService.GetMasterResellerPage(token).then((response) => {
              if(response && response.status==200){
                response=response.data;
                    let resellerDDL =response.Resellers?.map((m) => ({key: m.ResellerKey, header: m.ResellerName, content: m.InstanceName, value: m.ResellerKey}));
                    setResellers(resellerDDL);
                    setMasterResellers(response.MasterResellers);
                }
                else if(response && response.status==401){
                  props.viewStatus.InformChildPageStatus(response.status);
                }
                else {
                  props.viewStatus.InformChildPageStatus(500);
                }        
                SetLoaded(true);           
            });
        }
      });
    }
    

    const submitHandler = e => {
      e.preventDefault();
    }

    const handleCountryChange = (alwaysNull, data) => {
        setCurrentMasterResellerData({ ...currentMasterResellerData, CountryId: data.value.value });
    };

    const handleRegionChange = (alwaysNull, data) => {
        setCurrentMasterResellerData({ ...currentMasterResellerData, RegionId: data.value.value });
    };

    const handleResellerChange = (alwaysNull, data) => {
        setCurrentMasterResellerData({ ...currentMasterResellerData, ResellersList: data.value.map(d => d.value) });
        let list = currentMasterResellerData?.ResellersList;
        if(list && list.length > 0){
          setIsResellersDDLBlank(false);
        }
    };

    React.useEffect(() => {
        props.acquireToken((token) => {
            if(token){
                SettingsService.GetMasterResellerPage(token).then((response) => {
                  if(response && response.status==200){
                    response=response.data;
                        let resellerDDL =response.Resellers?.map((m) => ({key: m.ResellerKey, header: m.ResellerName, content: m.InstanceName, value: m.ResellerKey}));
                        setResellers(resellerDDL);
                        setMasterResellers(response.MasterResellers);

                        SettingsService.GetRegions(token).then((response) => {
                          if(response && response.status==200){
                            response=response.data;
                              setRegions(response.map((obj) =>({
                                  header: obj.RegionName,
                                  value: obj.Id,
                                  key: obj.Id,
                                })));
                              }
                        });
                         
                        SettingsService.GetCountries(token).then((response) => {
                          if(response && response.status==200){
                            response=response.data;
                            setCountries(response.map((obj) => {
                                return {
                                  key: obj.CountryId,
                                  header: obj.CountryName,
                                  value: obj.CountryId,
                                };
                              }));                     
                            }
                        });
                    }
                    else if(response && response.status==401){
                      props.viewStatus.InformChildPageStatus(response.status);
                    }
                    else {
                      props.viewStatus.InformChildPageStatus(500);
                    }        
                    SetLoaded(true);            
                });
            }
          });
    }, [isLoaded]);

    const ActionIconVM = (props) => {
    return (
        <td style={{ textAlign: "center" }}>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<TrashCanIcon />} text onClick={() => {
              setDeleteMasterResellerObj(props.dataItem);
              setDeleteConfirmationDialog(true);
            }}/>
        </td>
    );
    }

    const removeData = () => {
      if (deleteMasterResellerObj != null) {
       props.acquireToken((token) => {
        let params = {
          MasterResellerId: deleteMasterResellerObj.MasterResellerId,
        };
         SettingsService.DeleteMasterReseller(token, params).then(
           (response) => {
            if(response && response.status==200){
              response=response.data;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
               refreshGrid();
             }
             else if(response && response.status==401){
              debugger;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
    
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
           });
         setCurrentMasterResellerData(null);
       });
      }
      setDeleteConfirmationDialog(false);
      setDeleteMasterResellerObj(null);
    };

    const handleAddEditClickOpen = (dataItem, isEdit) => {
      if (isEdit === true) {
        setCurrentMasterResellerData(dataItem);
        setEditMode(true);
      }
      else{
        setCurrentMasterResellerData(null);
      }
      setVisible(true);
    };

    const handleAddEditClickClose = () => {
      setVisible(false);
      setCurrentMasterResellerData(null);
      setEditMode(false);
      setIsValid(false);
    };

    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2 card-header-resellers">
                  <div className="d-flex justify-content-between">
                    <div className="f-size-18">MASTER RESELLERS</div>
                    
                    { props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
                    <Button
                      icon={<AddIcon />}
                      iconPosition="before"
                      content="Add Reseller"
                      primary
                      title="Add Reseller"
                      onClick={handleAddEditClickOpen} className="ml-auto"
                    />}
                  </div>
                  <div >
                    {visible  && (
                      <Dialog
                        title={
                           editMode ? "Edit Master Reseller" : "Add New Master Reseller"
                        }
                        onClose={handleAddEditClickClose}
                        width="40%"
                      >
                        <Form onSubmit={submitHandler}>
                          <FormDropdown
                            label={{
                              content: `Reselers`,
                              id: "Reselers-inline",
                            }}
                            items={resellers}
                            key={resellers.ResellerKey}
                            value= {resellers.filter((f) => currentMasterResellerData?.ResellersList?.includes(f.value))}
                            aria-labelledby={"Reselers-inline"}
                            onChange={handleResellerChange}
                            multiple
                            search={true}
                            placeholder="Choose Reselers"
                            required
                            autosize="true"
                            fluid
                          />
                          {IsResellersDDLBlank ? <Text  error content="*Required" size="small" weight="regular"  /> : ""}
                          <FormInput
                            label="Master Reseller Name*"
                            name="MasterResellerName"
                            id="MasterResellerName-inline"
                            fluid
                            value={
                              currentMasterResellerData?.MasterResellerName
                            }
                            onChange={(e) => {
                              if(e.target.value && e.target.value.length > 0){
                                setIsResellerNameBlank(false);
                              }

                              setCurrentMasterResellerData({
                                ...currentMasterResellerData,
                                MasterResellerName: e.target.value
                              })}

                            }
                          />
                          {IsResellerNameBlank ? <Text  error content="*Required" size="small" weight="regular"  /> : ""}
                          <FormInput
                            label="Description"
                            name="Description"
                            id="Description-inline"
                            fluid
                            value={currentMasterResellerData?.Description}
                            onChange={(value) =>
                              setCurrentMasterResellerData({
                                ...currentMasterResellerData,
                                Description: value.target.value,
                              })}
                          />
                          <FormDropdown
                            label={{
                              content: `Region`,
                              id: "Region-inline",
                            }}
                            items={regions}
                            aria-labelledby={"Region-inline"}
                            placeholder="Choose Region"
                            required
                            autosize="true"
                            value={regions?.find(d => d.value == currentMasterResellerData?.RegionId)}
                            onChange={handleRegionChange}
                          />
                          <FormDropdown
                            label={{
                              content: `Country`,
                              id: "Country-inline",
                            }}
                            items={countries}
                            value={countries?.find(d => d.value == currentMasterResellerData?.CountryId)}
                            aria-labelledby={"CountryName-inline"}
                            placeholder="Choose Country"
                            required
                            autosize="true"
                            onChange={handleCountryChange}
                          />
                          <DialogActionsBar>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditSubmitClickHandler}
                              // disabled={isValid === false}
                            >
                              {" "}
                              Submit
                            </button>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              onClick={handleAddEditClickClose}
                            >
                              {" "}
                              Close
                            </button>
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3
                      }}
                      sortable={true}
                      data={process(masterResellers, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                      detail={
                        context.mediaCategory.sm ? DetailComponentSm : null
                      }
                      expandField="expanded"
                      onExpandChange={expandChange}
                    >
                      <Column
                        field="MasterResellerName"
                        title="Master Reseller" width={300}
                      />
                      <Column field="Description" title="Description" width={500}  />
                      <Column field="Region" title="Region" width={150} />
                      <Column field="CountryName" title="Country" width={150} />
                      { props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                      <Column field="" title="Actions"  width={100} cell={ActionIconVM} />}
                    </Grid>
                  </div>
                </CardBody>
              </Card>
            </div>
            <FluentDialog
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setDeleteConfirmationDialog(false);
                setDeleteMasterResellerObj(null);
              }}
              onConfirm={() => removeData()}
              open={openDeleteConfirmationDialog}
              backdrop
              header="Action confirmation"
              headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => {
                  setDeleteConfirmationDialog(false);
                  setDeleteMasterResellerObj(null);
                },
              }}
              content={`Are you sure to delete MasterReseller: ${deleteMasterResellerObj?.MasterResellerName}?`}
            />
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
}

export { SettingsMasterResellers };