import { useState, useEffect } from "react";
import { Alert, CheckmarkCircleIcon, ExclamationTriangleIcon, Flex } from "@fluentui/react-northstar";
export const INCIDENTLEVELS = {
    "healthy" : 0,
    "warning" : 1,
    "critical" : 2
}
export const GETINCIDENTLEVEL = (incidentLevelId) => {
    return INCIDENTLEVELS[incidentLevelId];
} 

export const FORMATBYTES = (a,b=2) => {if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}

export const OPERATIONSTATUS = Object.freeze({
    Success: 'success',
    Failed: 'failed',
    Unauthorized: 'unauthorized',
    None: 'none',
});
export const OPERATIONSTATUSMESSAGE = {
    "success" : "Operation executed successfully!",
    "failed" : "Operation failed, Please try again!",
    "unauthorized" : "Unauthorized, Operation failed. Please contact your admin!",
    "none":""
}
export const OperationStatusIndicator = ({status, revertStatusToNull}) => {
    const [alertVisible, setAlertVisible] = useState(false);
    
    useEffect(() => {
        if(status && !(status == "" || status == OPERATIONSTATUS.None)){
           setAlertVisible(true); 
           setTimeout(() => {
                setAlertVisible(false);
                revertStatusToNull();
            }, 3000);
        }
    }, [status])
    return (
        <div style={{position:'relative'}}>
        <Flex hAlign="center" styles={{marginTop: '8px', position:'absolute', width:'100%'}}>
            <Flex.Item>
            <Alert
                styles={{padding: '8px 15px', zIndex: '1'}}
                fitted
                icon={status == OPERATIONSTATUS.Success ? <CheckmarkCircleIcon /> : <ExclamationTriangleIcon />}
                variables={{
                    urgent: status == OPERATIONSTATUS.Success ? false : true,
                }}
                success={status == OPERATIONSTATUS.Success}
                content={OPERATIONSTATUSMESSAGE[status]}
                visible={alertVisible}
            />
            </Flex.Item>
        </Flex>
        </div>
    )
}