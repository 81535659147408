import './App.css';
import './assets/styles/main.scss';
import React, { useState, useEffect } from 'react';
import withAuthProvider from './components/auth/AuthProvider';
import { Layout } from './components/Layout';
import RoutesStore from './Routes';
import { Provider } from '@fluentui/react-northstar';
import { BrowserRouter } from 'react-router-dom';
import { WindowProvider, WindowContext } from './components/shared/contexts/Context';
import { useTeams } from './components/shared/customhooks';
import AccountService from './components/services/accountservice';

const App = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [DefaultCustomers, SetDefaultCustomers] = useState({});
  const [loggedinPermissionGroupName, SetLoggedinPermissionGroupName] = useState("");
  const [restrictedAccess, SetRestrictedAccess] = useState("0");

  useEffect(() => {
    if (global.localStorage) {
      global.localStorage.removeItem('contentUrl_dashboards');
    }
    props.acquireToken((token) => {
      AccountService.GetAccountPermissions(token).then((response) => {
        if(response){
          props.updateUser(response);
         
        }
        setLoaded(true);
      });
    });
  }, []);

  useEffect(() => {
    if(!props.isAuthenticated){
      props.updateUser(null);
    }
  }, [props.isAuthenticated]);

  useEffect(() => {
    props.acquireToken((token) => {
      AccountService.GetUserDetails(token).then((response) => {
        if(response){
          SetDefaultCustomers(response.RestrictedCustomers);
          SetLoggedinPermissionGroupName(response.PermissionGroupName);
          SetRestrictedAccess(response.RestrictedAccess)
        }
        setLoaded(true);
      });
    });
  }, []);

  return (
    <BrowserRouter>
      <WindowProvider>
        <WindowContext.Consumer>
          {(context) =>
            <Provider theme={context.teams.theme} className='main-container'>   
                <Layout {...props} inTeams={context.teams.inTeams} isLocal={context.constants.ISLOCAL}
                 themeHandler={context.teams.setTheme} themeString={context.teams.themeString} viewStatus={context.viewStatus}>
                { 
                  loaded && props.user ? 
                  <RoutesStore {...props} viewStatus={context.viewStatus} isLocal={context.constants.ISLOCAL} 
                   defaultCustomers={DefaultCustomers} loggedinPermissionGroupName={loggedinPermissionGroupName} 
                   restrictedAccess={restrictedAccess} /> :
                  <p>Loading...</p>
                }
                </Layout>
            </Provider>
          }
        </WindowContext.Consumer>
      </WindowProvider>   
    </BrowserRouter>  
  );
}

export default withAuthProvider(App);
