import React from 'react';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, CardHeader, CardBody, Flex } from '@fluentui/react-northstar';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellInstanceOverview, CellInfraDetail, CellCountGroup, CellHealthStatus, CellInstanceOverviewLabel, CellIISStatus, CellDBStatus, CellDCStatus, CellCountGroupClickable, CellDateTime, CellColTitle } from '../../../../shared/appcomponents/custom-grid-cells';
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';

const InfraSummary = (props) => {
  const { id } = useParams();
  const [details, setDetails] = React.useState(null);
  const [detailTable, setDetailTable] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [instanceState, setInstanceState] = React.useState(null);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InstanceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  React.useEffect(() => {
    props.acquireToken((token) => {
      setLoaded(false);
      let _alarmType = searchParams.get('state');
      let _state = INCIDENTLEVELS[_alarmType];
      if (_state == null) {
        _state = -1;
      }
      setInstanceState(_state);
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: _state,
        appliedFilters: id ? [`InstanceId IN (${id})`] : []
      }
      if (token) {
        MonitoringService.GetInfraSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setDetails(response);
            setDetailTable(response.Table);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }
          setLoaded(true);
        });
      }
    });
  }, [id]);

  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if (_state == null) {
      _state = -1;
    }
    setInstanceState(_state);
  }, [searchParams]);

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setDetails(null);
    setDetailTable(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: instanceState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetInfraSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setDetails(response);
            setDetailTable(response.Table);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }
          setLoaded(true);
        });
      }
    })
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'RegionName', operator: 'contains', value: value }
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const expandChange = (event) => {
    let newData = detailTable.map((item) => {
      if (item.InstanceId === event.dataItem.InstanceId) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDetailTable(newData);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>Region: {dataItem.RegionName}</td>
            <td>
              <CellCountGroupClickable {...props1} field={"IIS"} text={"IIS:"} pass={"1,4"} routesection={"infra"} />
            </td>
          </tr>
          <tr>
            <td>
              <CellCountGroupClickable {...props1} field={"DC"} text={"DC:"} pass={"3,6"} routesection={"infra"} />
            </td>
            <td>
              <CellCountGroupClickable {...props1} field={"DB"} text={"DB:"} pass={"2,5"} routesection={"infra"} />
            </td>
          </tr>
          <tr>
            <td>
              <CellHealthStatus
                {...props1}
                field={"BlobStatus"}
                text={"BlobStatus:"}
              />
            </td>
            <td>
              <CellInfraDetail {...props1} text={"InfraDetail:"} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <WindowContext.Consumer>
      {(context) => (
        <div className='fluid w-100'>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                <CardHeader className='mb-2'>
                  <div className='d-flex'>
                    <div className='f-size-20'>Infrastructure Summary</div>
                  </div>
                </CardHeader>
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/infra/summary${id ? '/' + id : ''}`)}
                      className={`card-border bg-white br-0 pt-1 p-0 card-action ${instanceState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center'>
                        <div className='f-size-15'>Total Instances</div>
                        <div className='f-size-26'>{details ? details.Total : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/infra/summary${id ? '/' + id : ''}?state=healthy`)}
                      className={`card-border bg-success br-0 pt-1 p-0 card-action ${instanceState == 0 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Healthy</div>
                        <div className='f-size-26'>{details ? details.Healthy : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/infra/summary${id ? '/' + id : ''}?state=warning`)}
                      className={`card-border bg-warning br-0 pt-1 p-0 card-action ${instanceState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Warning</div>
                        <div className='f-size-26'>{details ? details.Warning : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/infra/summary${id ? '/' + id : ''}?state=critical`)}
                      className={`card-border bg-danger br-0 pt-1 p-0 card-action ${instanceState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Critical</div>
                        <div className='f-size-26'>{details ? details.Critical : '-'}</div>
                      </div>
                    </Card>
                  </div>
                </div>
                <CardBody>
                  <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                    <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                      defaultAppliedFilter={{ filter: 'Instance', operator: '==', value: id }}
                      elegibleFilters={['Instance', 'Region', 'Platform']} />
                    <Flex.Item push>
                      <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                        <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                      </div>
                    </Flex.Item>
                  </Flex>
                  <div className='w-100'>
                    {
                      isLoaded ?
                        (
                          <KendoGrid
                            style={{
                              height: "456px",
                            }}
                            // pageable={true}
                            pageable={{
                              buttonCount: 3
                            }}
                            sortable={true}
                            data={process(detailTable.filter(tr => {
                              if (instanceState == -1) { return true }
                              else if (instanceState != -1 && tr.Health == instanceState) { return true }
                              return false;
                            }), dataState)}
                            {...dataState}
                            onDataStateChange={(e) => {
                              setDataState(e.dataState);
                            }}
                            // detail={
                            //   context.mediaCategory.sm ? DetailComponentSm : null
                            // }
                            expandField="expanded"
                            onExpandChange={expandChange}
                          >
                            <Column field="InstanceName" title="Instance" cell={CellInstanceOverviewLabel} locked={true} width={150} />
                            <Column field="RegionName" title="Region" locked={true} width={150} />
                            <Column field="IIS" title="IIS" cell={CellIISStatus} filterable={false} sortable={false} width={150} />
                            <Column field="DC" title="DC" cell={CellDCStatus} filterable={false} sortable={false} width={150} />
                            <Column field="DB" title="DB" cell={CellDBStatus} filterable={false} sortable={false} width={150} />
                            <Column field="BlobStatus" title={<CellColTitle title="Blob Status" />} cell={CellHealthStatus} width={120} />
                            <Column field="DateUpdatedHub" title={<CellColTitle title="Last Updated UTC" />} cell={CellDateTime} width={150} />
                            <Column field="InfraDetail" title={<CellColTitle title="Infra Detailed View" />} cell={CellInfraDetail} filterable={false} sortable={false} width={170} />
                            <Column field="InstanceOverview" title={<CellColTitle title="Instance Overview" />} cell={CellInstanceOverview} filterable={false} sortable={false} width={160} />
                          </KendoGrid>
                        ) : (
                          <Loader />
                        )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </WindowContext.Consumer>
  );
};

export { InfraSummary };
