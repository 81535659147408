import axios from 'axios';
import * as constants from '../../Constants';

class ToolsService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.apiConfig.resourceUri,
        // will inject auth header on-demand later as needed.
        headers: { 
            'Content-Type': 'application/json'
        }
    });

    static GetBusinessPriorities(token) {
        return this.AuthenticatedApi.get('/Tools/GetBusinessPriorities/', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (response.data)
                return response.data;
            else
                return null;
        })
        .catch((error) => {
            console.log('ToolsService.GetBusinessPriorities() - An error has occurred calling the web api: ' + error);
            return null;
        });
    }
    static AddBusinessPriority(token, _businessPriority) {
        return this.AuthenticatedApi.post('/Tools/AddBusinessPriority/', _businessPriority, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (response.data)
                return response.data;
            else
                return null;
        })
        .catch((error) => {
            console.log('ToolsService.AddBusinessPriority() - An error has occurred calling the web api: ' + error);
            return null;
        });
    }
    static DeleteBusinessPriority(token, _businessPriorityId) {
        return this.AuthenticatedApi.delete('/Tools/DeleteBusinessPriority/', {
            params: _businessPriorityId,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (response.data)
                return response.data;
            else
                return null;
        })
        .catch((error) => {
            console.log('ToolsService.AddBusinessPriority() - An error has occurred calling the web api: ' + error);
            return null;
        });
    }
}

export default ToolsService;