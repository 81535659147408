import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon } from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import SettingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { getter } from '@progress/kendo-react-common';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ServerServices = (props) => {
  const [vmData, setVMData] = useState([]);
  const [isLoaded, SetLoaded] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "ServiceName",
          dir: "asc",
        },
      ]
    });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "ServiceName", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };

    const submitHandler = e => {
      e.preventDefault();
    }



    React.useEffect(() => {
      props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
      var data = props.serverObj;
      let serverObj = { IPAddress: data.IPAddress, Port: data.Port };

      if (token) {
        SettingServerSM.GetServerServices(serverObj, token).then((response) => {
          if (response && response.status == 200) {
            let servicesFound = response.data;
            setVMData(response.data);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }
          SetLoaded(true);
        });
      }
    };

    
    const handleStopService = (props1) => {

      let serverObj = {IPAddress : props?.serverObj?.IPAddress, Port : props?.serverObj?.Port, ServiceName : props1?.dataItem?.ServiceName};

      props.acquireToken((token) =>
      {
        SettingServerSM.StopService(serverObj,token).then((response) => {
          if(response && response.status==200){
            response=response.data;
              props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });

      }
      );
    }

    const handleStartService = (props1) => {
      let serverObj = {IPAddress : props?.serverObj?.IPAddress, Port : props?.serverObj?.Port, ServiceName : props1?.dataItem?.ServiceName};

      props.acquireToken((token) =>
      {
        SettingServerSM.StartService(serverObj,token).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });

      }
      );
    }


    const StartStopServiceCell = (props) => {
      return (
        <td>
          {(
            props?.dataItem?.Status == 1 ?
              <Button size="small" className="sp-access-button" content="Start" onClick = {() => handleStartService(props, true)} />
              : props?.dataItem?.Status == 4 ?
                <Button size="small" className="sp-access-button" content="Stop"  onClick = {() => handleStopService(props, true)} /> :
                <></>
          )}
        </td>
      );
    }


    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2">
                </CardHeader>
                <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    {vmData && 
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      sortable={true}
                      data={process(vmData, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                    >
                      <Column
                        field="ServiceName"
                        title="Service Name"
                        width={140}
                      />

                      <Column
                        field="StatusStr"
                        title="Status"
                        width={140}
                      />
                      <Column
                        field="StartTypeStr"
                        title="Start Type"
                        width={150}
                      />
                      {
                        props.user?.Permissions?.some(
                          (p) =>
                            p == "ServerVM.Services.ReadWrite" 
                        ) && 
                      <Column
                        field=""
                        title="Action"
                        cell={StartStopServiceCell}
                        width={150}
                      />
                      }
                    </Grid>
                    }
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
  }


  export { ServerServices };