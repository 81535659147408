import React, { useState } from 'react';
import { Card, Dialog, Dropdown, CardHeader, CardBody, Text, Grid as FluentGrid, Button, Flex, Loader, Toolbar , Label} from '@fluentui/react-northstar'
import { AppsIcon, LinkIcon,PopupIcon, CloseIcon, PlugsIcon, SearchIcon, EmailIcon, ContentViewGalleryIcon, TranscriptIcon, BroadcastIcon } from '@fluentui/react-icons-northstar';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Grid,
    GridColumn as Column
  } from "@progress/kendo-react-grid";

import { CellIncidentLevel, CellOverviewTenantReporting, CellOverviewTenantRecording ,CellOverviewIIS, CellOverviewDB, CellOverviewDC, CellOverviewInfraIncidents, CellOverviewPortalIncidents, MinutesFormat, CellOverviewReportingServices, CellOverviewRecordingServices, CellOverviewServiceIncidents} from "../../../../shared/appcomponents/custom-grid-cells";
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import MonitoringService from '../../../../services/monitoringservice';
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';

import sunImage from '../../../../../assets/images/icon-sun.svg'; 
import moonImage from '../../../../../assets/images/icon-moon.svg';
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums'; 

// const PortalOverviewTable = () => {
//     const createState = (skip, take) => {
//       let pagerSettings = {
//         buttonCount: 3,
//         info: true,
//         type: "numeric",
//         pageSizes: true,
//         previousNext: true,
//       };
//       return {
//         items: products.slice(skip, skip + take),
//         total: products.length,
//         skip: skip,
//         pageSize: take,
//         pageable: pagerSettings,
//       };
//     };
//     const [state, setState] = React.useState(createState(0, 10));
//     const [searchInput, setSearchInput] = React.useState("");

//     const [hide, setHide] = React.useState(false);
  
//     const pageChange = (event) => {
//       setState(createState(event.page.skip, event.page.take));
//     };
  
//     const updatePagerState = (key, value) => {
//       const newPageableState = Object.assign({}, state.pageable, {
//         [key]: value,
//       });
//       setState(
//         Object.assign({}, state, {
//           pageable: newPageableState,
//         })
//       );
//     };

    

//     return (
//       <>
//           <div className='row mb-2'>
//                     <div className='col-md-12'>
//                     <div className='f-size-20 float-left'><Text content={`Instance: $`} /></div>
//                         <div className='tbl-search float-right'>
//                         <GridGlobalSearch setSearchInput={(input) => setSearchInput(input.toLowerCase())} />
//                         </div>
//                     </div>
//                 </div>
//                 <div className='w-100'>
//                 <Grid
//         style={{
//           height: "456px",
//         }}
//         data={state.items != null
//             ? state.items.filter((row) =>
//                 Object.values(row).some(d => typeof d == "string" && d.toLowerCase().indexOf(searchInput) > -1)
//               )
//             : null
//         }
//         onPageChange={pageChange}
//         total={state.total}
//         skip={state.skip}
//         pageable={state.pageable}
//         pageSize={state.pageSize}
//       >               
//         <Column field="TenantName" title="Instance" />
//         <Column field="ResellerName" title="Reseller" />
//         <Column field="TenantName" title="Product" />
//         <Column field="CustomerName" title="Customer" />
//         <Column field="LastCallIn" title="Last Call Inserted"  />
//         <Column field="LastRecodringIn" title="Last Recording Inserted" />
//         <Column field="CallTrend" title="Call Trend" />
//         <Column field="RecordingTrend" title="Recording Trend" />
//         <Column field="StuckCalls" title="Stuck Calls" />
//         <Column field="IncidentLevel" title="Health" cell={CellIncidentLevel} width={120}/>        
//       </Grid>
//         </div>
        
//       </>
//     );
//   };

const PortalOverview = (props) => {
    const {id} = useParams();
    const [spDialogOpen, setSPDialogOpen] = React.useState(false);
    const [spDialogDataItem, setSPDialogDataItem] = React.useState(null);
    const [isLoaded, SetLoaded] = React.useState(false);
    const [overview, setOverview] = React.useState(null);
    const [instances, setInstances] = React.useState(null);
    let navigate = useNavigate(); 
    const routeChange = (path) =>{
        navigate(path);
    }
    let viewArea = 'critical';
    React.useEffect(() => {
        props.acquireToken((token) => 
            MonitoringService.GetInstances(token).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    setInstances(response);
                }
                else if(response && response.status==401){
                    debugger;
                  }
                  else{
                    debugger;
                  }
        
            })
        );
    }, []);

    React.useEffect(() => {
        props.acquireToken(authSuccessCallback);
    }, [id]);

    const authSuccessCallback = (token) => {
        SetLoaded(false);
        let _params = {
            instanceId: id
        }
        if (token) {
            MonitoringService.GetInstanceOverview(token, _params).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    setOverview(response);
                }       
                else if(response && response.status==401){
                    props.viewStatus.InformChildPageStatus(response.status);
                  }
                  else{
                    props.viewStatus.InformChildPageStatus(500);
                  }
                  SetLoaded(true);
                 
            });
        }
    };

    const handleInstanceChange = (option) => {
        if (id !== option.value) {
          let path = `/dashboards/monitoring/portal/overview/${option.value}`;
          setRouteInLocalStorage('contentUrl_dashboards', path);
          navigate(path);
        }
    };

    const getSPUrl = (token, portalUrl) => {

        let dataItem = {AccessSPPortal: portalUrl}
        let _host = dataItem.AccessSPPortal.split('/')[2];
        let _url = "http://" + _host + "/serviceprovider/token.aspx";
        if (_host.toString().lastIndexOf('call-analytics.co.uk') > 0)
            _url = "https://" + _host + "/serviceprovider/token.aspx";

        MonitoringService.GetServiceProviderToken(token, {host: _host}).then((response)=>{
            if(response && response.status==200){
                response=response.data;
                let t = JSON.stringify(response);
                t = t.replace('"', '');
                t = t.replace('"', '');
                if (response.length > 5 && response.length < 50){
                    dataItem.SPPortalLoginUrl = _url + "?token=" + t;
                }
                else{
                    dataItem.SPPortalLoginUrl = null;
                }
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
              }
              
            else{
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                dataItem.SPPortalLoginUrl = null;
            }   

            setSPDialogDataItem(dataItem); 
        });        
    }

    return(

        isLoaded ?
        (
            <>
        <div className='fluid w-100 mt-3'>
        <div className='row mb-3'>
            <div className='col-md-12'>
                <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                    <CardHeader>
                    <div className='text-right'>
                    <Button onClick={() => navigate(-1)} content="Back" />  
                </div>
                        <Flex vAlign="center"> 
                            <div>                   
                                <Text as='h3' className="m-0" content="Instance Overview: " />
                                <Dropdown
                                    className="ml-0"
                                    items={instances ? instances : []}
                                    placeholder="Select Instance"
                                    defaultValue={
                                        instances?.find((d) => d.value == id)
                                    }
                                    onChange={(e, selectedOption) =>
                                        handleInstanceChange(selectedOption.value)
                                    }
                                />
                            </div>

                            {/* <Flex.Item push>
                                <div>                   
                                    <Text as='h3' className="m-0" content="&nbsp;" />
                                    <Label className='overview-incident-counts mr-0' color="white" content={ <div className='incident-counts'> Incidents <span className='critical'> {overview?.IncidentsCountCritical} </span><span className='warning'>{overview?.IncidentsCountWarning}  </span></div>}  iconPosition="after"  />
                                </div>
                            </Flex.Item> */}
                        </Flex>
                        <div className='row mb-2 mt-2'>
                            <div className='col-md-12'>
                                <Card fluid elevated ghost>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-3'>
                                            <div>Portal URL</div>
                                                <div className='font-weight-bold'>
                                                <Text content={overview?.PortalURL} weight="bold" />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3'>
                                            <div>Zoho Link</div>
                                            <div className='font-weight-bold'>
                                                <Button icon={<LinkIcon />} iconOnly text onClick={() => window.open(overview?.ZohoURL, '_blank')} />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3' id="sp-access">
                                            <div >Service Provider Access</div>
                                            <div className='font-weight-bold' >
                                                {
                                                    overview?.SPAccess?.map((sp) => {
                                                            return (
                                                                <Button content={sp.PortalType} size="small" className="sp-access-button"
                                                                onClick={() => {props.acquireToken((token) => {
                                                                    setSPDialogOpen(true); 
                                                                    getSPUrl(token, sp.Host);
                                                                })}}
                                                                />
                                                            )
                                                    } )
                                                }
                                                {/* <Button icon={<SearchIcon />} iconOnly text onClick={() => {props.acquireToken((token) => {
                                                    setSPDialogOpen(true); 
                                                    getSPUrl(token, overview?.PortalURL);
                                                })}}/> */}
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-3'>
                                            <div>Send Notification</div>
                                            <div className='font-weight-bold'><Button icon={<EmailIcon />} iconOnly text /></div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>                    
                    </CardHeader>
                    <CardBody>
                    <div className='row mb-4 detailed-cards'>
                        <div className='col-lg-4 col-md-6'>
                        <Card fluid elevated ghost styles={{backgroundColor: 'rgba(255, 248, 221, 0.5)'}}>
                            <CardHeader><Text content='Infrastructure Summary' /></CardHeader>
                            <CardBody className='h-100'>
                                <div className='row h-100'>
                                    <div className='col-md-12'>
                                        <div className='listC h-100 border-gray border-radius-6 p-3'>
                                            <div className='listMain'>
                                            <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Instance</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>{overview?.InstanceName}</div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Region</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>{overview?.Region}</div>
                                                </div>

                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Incidents</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                    <CellOverviewInfraIncidents criticalCount={overview?.IncidentsInfraCritical} 
                                                        warningCount={overview?.IncidentsInfraWarning}  instanceId={overview?.InstanceId} />
                                                    </div>
                                                </div>

                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>IIS</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                        <CellOverviewIIS data={overview?.IIS} instanceId={overview?.InstanceId} />
                                                        {/* <span className='mr-2'>{overview?.IIS.Total}</span>
                                                        <span className='text-warning mr-2'>({overview?.IIS.Warning})</span>
                                                        <span className='text-danger'>({overview?.IIS.Critical})</span> */}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>DC</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                        <CellOverviewDC data={overview?.DC} instanceId={overview?.InstanceId} />
                                                        {/* <span className='mr-2'>{overview?.DC.Total}</span>
                                                        <span className='text-warning mr-2'>({overview?.DC.Warning})</span>
                                                        <span className='text-danger'>({overview?.DC.Critical})</span> */}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>DB</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                        <CellOverviewDB data={overview?.DB} instanceId={overview?.InstanceId} />
                                                        {/* <span className='mr-2'>{overview?.DB.Total}</span>
                                                        <span className='text-warning mr-2'>({overview?.DB.Warning})</span>
                                                        <span className='text-danger'>({overview?.DB.Critical})</span> */}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Blob Status</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                        <div className='d-flex align-item-center'>
                                                            <div className='stausDot mr-1 bg-success'></div>
                                                            <span>Healthy</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Detailed View</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                        <Button className='justify-content-start' primary onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${overview?.InstanceId}`)} iconOnly size="small" icon={<BroadcastIcon />} text title="Detail" />
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Summary</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                        <Button className='justify-content-start' primary onClick={() => routeChange(`/dashboards/monitoring/infra/summary/${overview?.InstanceId}`)} iconOnly icon={<TranscriptIcon />} text title="Summary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                        <Card fluid elevated ghost styles={{backgroundColor: 'rgba(241, 250, 255, 0.8)'}}>
                            <CardHeader><Text content='Data Summary' /></CardHeader>
                            <CardBody className='h-100'>
                            <div className='row h-100'>
                                <div className='col-md-12'>
                                    <div className='listC h-100 border-gray border-radius-6 p-3'>
                                        <div className='listMain'>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Instance</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>
                                                {/* overview?.InstanceName */}
                                                    {<> <span> {overview?.InstanceName }</span>  {overview?.IsDay == '0' ? <img className="dayNight-icon" src={moonImage} /> : <img className="dayNight-icon" src={sunImage} /> } </>}
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Platform</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>{overview?.Platform}</div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Time Zone </div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold' > {overview?.TimezoneIdentifier} </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Last Call Inserted </div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold' > 
                                                    <OverviewDateTime date={overview?.LastCallIn}></OverviewDateTime> 
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100 overview-minutes'>
                                                <div className='listVtrk float-left'> </div>
                                                <div className='listVtrk float-left font-weight-bold'></div>
                                                <div className='listVtrk font-weight-bold' > 
                                                    {overview?.LastCallTimeDiff ?  ( <> (<MinutesFormat minutes={overview?.LastCallTimeDiff}></MinutesFormat> <span> ago</span>)</> ) : ""}
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Last Recording Inserted</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'> <OverviewDateTime date={overview?.LastRecordingIn}></OverviewDateTime>  </div>
                                            </div>
                                            <div className='listV float-left w-100 overview-minutes'>
                                                <div className='listVtrk float-left'> </div>
                                                <div className='listVtrk float-left font-weight-bold'></div>
                                                <div className='listVtrk font-weight-bold' > 
                                                    {overview?.LastRecTimeDiff ?  ( <> (<MinutesFormat minutes={overview?.LastRecTimeDiff}></MinutesFormat> <span> ago</span>)</> ) : ""}
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Stuck Calls (Data_CTI)</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold text-warning'>{overview?.StuckCalls}</div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Incidents</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>
                                                    <CellOverviewPortalIncidents criticalCount={overview?.IncidentsPortalCritical} 
                                                        warningCount={overview?.IncidentsPortalWarning}  instanceId={overview?.InstanceId} />
                                                </div>
                                            </div>

                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Total Tenants</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>{overview?.TotalTenants}</div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Reporting Tenants</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>

                                                    <CellOverviewTenantReporting data={overview?.ReportingTenants} instanceId={overview?.InstanceId} />
                                                   
                                                    {/* <span className='mr-2'>{overview?.ReportingTenants.Total}</span>
                                                    <span className='text-danger mr-2'>({overview?.ReportingTenants.Critical})</span>
                                                    <span className='text-warning mr-2'>({overview?.ReportingTenants.Warning})</span>                                                
                                                    <span className='text-success'>({overview?.ReportingTenants.Healthy})</span> */}
                                                </div>
                                            </div>
                                            <div className='listV  float-left w-100'>
                                                <div className='listVtrk float-left'>Recording Tenants</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>

                                                    <CellOverviewTenantRecording data={overview?.RecordingTenants} instanceId={overview?.InstanceId} />

                                                    {/* <span className='mr-2'>{overview?.RecordingTenants.Total}</span>
                                                    <span className='text-danger mr-2'>({overview?.RecordingTenants.Critical})</span>
                                                    <span className='text-warning mr-2'>({overview?.RecordingTenants.Warning})</span>                                                
                                                    <span className='text-success'>({overview?.RecordingTenants.Healthy})</span> */}
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Detailed Page</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>
                                                    <Button className='justify-content-start' primary iconOnly onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${overview?.InstanceId}`)} size="small" icon={<AppsIcon />} text title="Detail" />
                                                </div>
                                            </div>
                                            <div className='listV float-left w-100'>
                                                <div className='listVtrk float-left'>Summary</div>
                                                <div className='listVtrk float-left font-weight-bold'>:</div>
                                                <div className='listVtrk font-weight-bold'>
                                                    <Button className='justify-content-start' primary iconOnly onClick={() => routeChange(`/dashboards/monitoring/portal/summary/${overview?.InstanceId}`)} icon={<TranscriptIcon />} text title="Summary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                        <Card fluid elevated ghost styles={{backgroundColor: 'rgba(255, 245, 248, 0.8)'}}>
                            <CardHeader><Text content='Service Summary' /></CardHeader>
                            <CardBody className='h-100'>
                            <div className='row h-100'>
                                <div className='col-md-12'>
                                    <div className='listC h-100 border-gray border-radius-6 p-3'>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Instance</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>{overview?.InstanceName}</div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Platform</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>{overview?.Platform}</div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Incidents</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>
                                                <CellOverviewServiceIncidents criticalCount={overview?.IncidentsServiceCritical} 
                                                    warningCount={overview?.IncidentsServiceWarning}  instanceId={overview?.InstanceId} />
                                            </div>
                                        </div>

                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Total Services</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>{overview?.TotalServices}</div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Reporting Services Status</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>
                                                <CellOverviewReportingServices data={overview?.ReportingServices} instanceId={overview?.InstanceId} />
                                            </div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Recording Services Status</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>
                                            <CellOverviewRecordingServices data={overview?.RecordingServices} instanceId={overview?.InstanceId} />
                                            </div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Portal Last Ping Status</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'><OverviewDateTime date={overview?.PortalLastPing}></OverviewDateTime></div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>DB Last Ping Status</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'><OverviewDateTime date={overview?.DBLastPing}></OverviewDateTime></div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Detailed Page</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>
                                                <Button primary iconOnly onClick={() => routeChange(`/dashboards/monitoring/service/detail/${overview?.InstanceId}`)} icon={<PlugsIcon />} text title="Detail" />
                                            </div>
                                        </div>
                                        <div className='listV float-left w-100'>
                                            <div className='listVtrk float-left'>Summary</div>
                                            <div className='listVtrk float-left font-weight-bold'>:</div>
                                            <div className='listVtrk font-weight-bold'>
                                                <Button primary onClick={() => routeChange(`/dashboards/monitoring/service/summary/${overview?.InstanceId}`)} iconOnly icon={<TranscriptIcon />} text title="Summary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </div>
                    </div>
                    </CardBody>
                </Card>
            </div>
        </div>
        </div>
        <Dialog 
            open={spDialogOpen}
            onCancel={() => {setSPDialogOpen(false); setSPDialogDataItem(null);}}
            onConfirm={() => {window.open(spDialogDataItem?.SPPortalLoginUrl, '_blank'); setSPDialogDataItem(null); }}
            confirmButton={spDialogDataItem && spDialogDataItem.SPPortalLoginUrl ? "Visit Page": null}
            cancelButton="Close"
            content={<Text content={ spDialogDataItem ? (spDialogDataItem.SPPortalLoginUrl ? `Please click on Visit button to access ${overview?.InstanceName}` : `Could not retrieve token for ${overview?.InstanceName}`) : "Retrieveing token..."} />}
            header="Service Provider Access"
            onClose={() => {setSPDialogDataItem(null);}}
            headerAction={{
              icon: <CloseIcon />,
              title: 'Close',
              onClick: () => {setSPDialogOpen(false); setSPDialogDataItem(null);},
            }}/>
        </>
        )
        :
        <Loader />
    );

   

};

export { PortalOverview }

const OverviewDateTime = (props) => { 

    const data = props.date;
    let date = '';
    if(data && data.length > 0){
        date = new Date(data);
        date = date.toLocaleString();
    }
    return (
        <span>{date}</span>
    );

};

