import React from 'react';
import { Card, CardHeader, Checkbox, CardBody, Loader, Text, Button, Dropdown, Flex, Status } from '@fluentui/react-northstar';
import { FilesPdfIcon, ExcelColorIcon, FilesImageIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import { RevenueCustomQueryFilter } from '../../../shared/appcomponents/custom-query-filter';
import { BillingDashChart } from '../../../shared/appcomponents/custom-charts';
import {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartValueAxisItem,
    ChartValueAxis,
    exportVisual,
} from "@progress/kendo-react-charts";
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
import './revenue.scss';
import RevenueService from "../../../services/revenueservice";
import TopKPI from "../revenue/topkpi";

import { process } from '@progress/kendo-data-query';
import { Grid as KendoGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ForecastChart } from '../../../shared/appcomponents/custom-charts';
import GridGlobalSearch from '../../../shared/appcomponents/data-grid-search'

import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
const { forwardRef, useRef, useImperativeHandle } = React;

const RevenueDashboard = (props) => {
    // console.log(props);
    const [loaded, setLoaded] = React.useState(false);
    const [partBilling, setPartBilling] = React.useState(true);
    const [revenueDashboardData, setRevenueDashboardData] = React.useState(null);
    const [products, setProducts] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [appliedFilters, setAppliedFilters] = React.useState(null);
    const [dataState, setDataState] = React.useState({
        sort: [
            {
                field: "ProductName",
                dir: "asc",
            },
        ],
        take: 10,
        skip: 0,
    });
    const [selectedYear, setSeletedYear] = React.useState({ key: 0, header: 'This Year', value: 0 });
    const [topKPIdata, setTopKPIdata] = React.useState(null);

    const [downloading, setDownloading] = React.useState(false);

    const [showChartPopup, SetShowChartPopup] = React.useState(false);

    const [chartDetailsData, SetChartDetailsData] = React.useState({});

    const [showChartPopup2, SetShowChartPopup2] = React.useState(false);

    const [chartDetailsData2, SetChartDetailsData2] = React.useState({});
    const [defaultCustomers, SetDefaultCustomers] = React.useState(props.defaultCustomers);
    const [salesList, setSalesList] = React.useState([]);
    const [salesSelected, setSalesSelected] = React.useState([]);
    const [updateId, setUpdateId] = React.useState("123");
    const [downloadExcel, SetDownloadExcel] = React.useState(0);
    const AnnualisedBilling = React.useRef(null);
    const TotalMonthlyBilling = React.useRef(null);
    const RecurringBilling = React.useRef(null);
    const GrowththisFYRecurringBilling = React.useRef(null);
    const ChurnedBilling = React.useRef(null);
    const TenantsAdded = React.useRef(null);
    const TenantsRemoved = React.useRef(null);
    const EndPoints = React.useRef(null);
    const TotalsEstateTenants = React.useRef(null);
    const TotalsEstateEndPoints = React.useRef(null);
    const NumberofCallRecordings = React.useRef(null);
    const CallRecordingMinutes = React.useRef(null);
    const RecordingStorage = React.useRef(null);



    // React.useEffect(() => {
    //     const onScroll = e => {
    //       console.log('onscroll');
    //     };
    //     document.addEventListener("scroll", onScroll);

    //     // return () => window.removeEventListener("scroll", onScroll);
    //   }, []);


    // const [revenueData, setRevenueData] = React.useState(null);

    const getRevenueDashboardData = (salesDDLEmail, updateFilter, isPageLoad, tryCache) => {
        let year = 0;
        let currentMonth = (new Date()).getMonth();
        if (isPageLoad == true && currentMonth == 3) { // for April defalut ddl val is prev year
            year = 1;
        }

        props.acquireToken((token) => {
            if (token) {
                let _params = {
                    year: year,
                    appliedFilters: null,
                    isRevenue: "1",
                    isPartMonthBillingIncluded: year == 0 ? partBilling : false,
                    salesDDLSelected: salesDDLEmail,
                    tryCache: tryCache
                };

                setLoaded(false);
                RevenueService.GetRevenueDashboard(token, _params).then((response) => {
                    if (response && response.status == 200) {
                        response = response.data;
                        response?.TenantChart?.map((t) => {
                            t.Date = new Date(t.BillMonth);
                        });
                        let groupedValues = {};
                        response?.LicenceChart?.map(t => {
                            groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
                        });
                        response.LicenceChart = groupedValues;
                        let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
                        setProducts(_products);
                        setSelectedProduct(_products[0]);
                        setRevenueDashboardData(response);
                        SetDefaultCustomers(response.DefaultCustomers);
                        if (updateFilter == true) {
                            setUpdateId(Math.random().toString());
                        }
                    }
                    else if (response && response.status == 401) {
                        props.viewStatus.InformChildPageStatus(response.status)
                    }
                    else {
                        props.viewStatus.InformChildPageStatus(500);
                    }
                    setLoaded(true);
                    // setUpdateId(Math.random().toString());
                });
            }
        });
    }


    React.useEffect(() => {
        let currentMonth = (new Date()).getMonth();
        if (currentMonth == 3) {
            setSeletedYear({ key: 1, header: 'Financial Year ' + getFY(1), value: 1 }); // for April defalut ddl val is prev year
        }
        getRevenueDashboardData(null, false, true, true);
    }, []);

    const salesChange = (e, elm) => {
        setSalesSelected(elm.value);
        getRevenueDashboardData(elm.value.content, true, true, true);

        //    console.log(elm);
    }

    React.useEffect(() => {
        props.acquireToken((token) => {
            if (token) {
                let _params = {
                    year: 0,
                    appliedFilters: appliedFilters,
                    isRevenue: "1",
                    isPartMonthBillingIncluded: true,
                    salesDDLSelected: salesSelected?.content
                };
                // setLoaded(false);
                RevenueService.GetSalesList(token, _params).then((res) => {
                    // console.log(res);
                    if (res && res.status == 200) {
                        let _salesList = res.data.SalesList.map(m => ({
                            image: {
                                alt: m.image,
                                className: "ddl-img"
                            },
                            header: m.header,
                            content: m.content
                        }));

                        setSalesList(_salesList);
                        if (_salesList && _salesList.length > 0) {
                            let selectedSalesDef = _salesList[0];
                            setSalesSelected(selectedSalesDef);
                        }
                    }

                    // setLoaded(true);
                    // setUpdateId(Math.random().toString());
                });
            }
        });

    }, []);

    const refreshData = (_selectedYear, _partBilling) => {
        setLoaded(false);
        setRevenueDashboardData(null);
        props.acquireToken((token) => {
            let _params = {
                year: _selectedYear.value,
                appliedFilters: appliedFilters,
                isRevenue: "1",
                isPartMonthBillingIncluded: _selectedYear?.value == 0 ? _partBilling : false,
                salesDDLSelected: salesSelected?.content,
                tryCache: false
            };
            if (token) {
                RevenueService.GetRevenueDashboard(token, _params).then((response) => {
                    if (response && response.status == 200) {
                        response = response.data;
                        response?.TenantChart?.map((t) => {
                            t.Date = new Date(t.BillMonth);
                        });
                        let groupedValues = {};
                        response?.LicenceChart?.map(t => {
                            groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
                        });
                        response.LicenceChart = groupedValues;
                        let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
                        setProducts(_products);
                        setSelectedProduct(_products[0]);
                        setRevenueDashboardData(response);
                    }
                    else if (response && response.status == 401) {
                        props.viewStatus.InformChildPageStatus(response.status);
                    }
                    else {
                        props.viewStatus.InformChildPageStatus(500);
                    }
                    setLoaded(true);
                });
            }
        })
    }
    const handleFYChange = (_selectedYear) => {
        setSeletedYear(_selectedYear);
        if (_selectedYear.value == 0) {
            setPartBilling(true);
            refreshData(_selectedYear, true);
        }
        else {
            setPartBilling(false);
            refreshData(_selectedYear, true);
        }
    }

    const handleFilterUpdate = (_appliedFilters) => {
        setLoaded(false);
        setRevenueDashboardData(null);
        setAppliedFilters(_appliedFilters);
        props.acquireToken((token) => {
            let _params = {
                year: selectedYear.value,
                appliedFilters: _appliedFilters,
                isRevenue: "1",
                isPartMonthBillingIncluded: selectedYear?.value == 0 ? partBilling : false,
                salesDDLSelected: salesSelected?.content,
                tryCache: false
            };
            if (token) {
                RevenueService.GetRevenueDashboard(token, _params).then((response) => {
                    if (response && response.status == 200) {
                        response = response.data;
                        response?.TenantChart?.map((t) => {
                            t.Date = new Date(t.BillMonth);
                        });
                        let groupedValues = {};
                        response?.LicenceChart?.map(t => {
                            groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
                        });
                        response.LicenceChart = groupedValues;
                        let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
                        setProducts(_products);
                        setSelectedProduct(_products[0]);
                        setRevenueDashboardData(response);
                    }
                    else if (response && response.status == 401) {
                        props.viewStatus.InformChildPageStatus(response.status);
                    }
                    else {
                        props.viewStatus.InformChildPageStatus(500);
                    }
                    setLoaded(true);
                });
            }
        })
    }

    const filterGridData = (value) => {
        let filter = {
            logic: 'or',
            filters: [
                { field: 'ProductCode', operator: 'contains', value: value },
                { field: 'ProductName', operator: 'contains', value: value }
            ],
        };
        setDataState({ ...dataState, filter: filter });
    };

    const rowRender = (trElement, props) => {
        const credit = props.dataItem.ProductCode.startsWith('-');
        const churned = props.dataItem.GrowthChurnQuantity < 0;

        let className = credit ? 'p-credit ' : '';
        className += churned ? 'p-churned' : '';
        const trProps = {
            class: className
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    const categories = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];

    const chartDefaultV4Colors = [
        "#28a745",
        "#dc3545",
    ];

    const chartColorsWarning = [
        "#dc3545",
    ];

    const downloadExcelHandler = (_appliedFilters) => {
        props.acquireToken((token) => {
            let _params = {
                year: selectedYear.value,
                appliedFilters: _appliedFilters,
                isRevenue: "1",
                isPartMonthBillingIncluded: selectedYear?.value == 0 ? partBilling : false,
                salesDDLSelected: salesSelected?.content
            };
            if (token) {
                setDownloading(true);
                RevenueService.DownloadRevenueExcel(token, _params).then((response) => {
                    if (response && response.status == 200) {
                        response = response.data;
                        const href = URL.createObjectURL(response);

                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'revenue_data.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                    else if (response && response.status == 401) {
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                    }
                    else {
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                    setDownloading(false);
                });

            }
        });
    }

    const seriesClickHandler = (category) => {
        SetChartDetailsData(category);
        SetShowChartPopup2(false);
        SetShowChartPopup(true);
        // childRef.current.getAlert();
    }

    const seriesClickHandler2 = (category) => {
        SetChartDetailsData2(category);
        SetShowChartPopup(false);
        SetShowChartPopup2(true);
        // childRef.current.getAlert();
    }

    const CloseChartDetails = () => {
        SetShowChartPopup(false);
        SetShowChartPopup2(false);
        // childRef.current.Close();
    }

    const pageClick = () => {
        SetShowChartPopup(false);
        SetShowChartPopup2(false);
        // childRef.current.Close();
    }

    const pageScroll = () => {
        console.log('page pageScroll');
    }

    const churnAxisCrossingValue = [0, 14];

    let labelRotation = "0";

    if (revenueDashboardData?.ChartData.CategorySeries && revenueDashboardData?.ChartData.CategorySeries.length > 8) {
        labelRotation = "45"
    }

    const childRef = useRef();

    const getFY = (i) => {
        let currentYear = (new Date()).getFullYear();
        let currentMonth = (new Date()).getMonth();
        let fy = currentYear;

        if (currentMonth < 3) {
            fy = currentYear - 1;
        }

        if (i == 1) {
            fy = fy - 1;
        }
        else if (i == 2) {
            fy = fy - 2;
        }

        let nextYear = fy + 1;

        return (fy.toString() + '-' + nextYear.toString().substring(2, 4));

    }


    const dropDownItems = [{ key: 0, header: 'This Year', value: 0 }, { key: 1, header: 'Financial Year ' + getFY(1), value: 1 }, { key: 2, header: 'Financial Year ' + getFY(2), value: 2 }];


    return (
        <div onClick={pageClick} onScroll={pageScroll}>
            <div className='fluid w-100'>
                {
                    props.loggedinPermissionGroupName == 'Sales Manager' ?
                        <div className='row'>
                            <div className='col'>
                                <Flex vAlign='center' fluid className='w-100 mb-2 pr-2'>
                                    <Text className='mr-2 ml-2' content="View As:" />
                                    <Dropdown
                                        items={salesList ? salesList : []}
                                        value={salesSelected}
                                        onChange={salesChange}
                                    />
                                </Flex>
                            </div>
                        </div>
                        : ""
                }

                <div className={loaded ? "row applied-sec" : "row mb-2 d-none"}>
                    <div className='col'>
                        <Flex vAlign='center' fluid className='w-100 mb-2'>

                            <RevenueCustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                                defaultAppliedFilter={
                                    [{ filter: 'Customer', operator: '==', value: defaultCustomers }]
                                }
                                elegibleFilters={props.restrictedAccess == true ? ['ProductStream', 'Customer', 'Region'] : ['Instance', 'ProductStream', 'Customer', 'Product', 'Region']}
                                isRevenue="1"
                                salesDDLSelected={salesSelected?.content}
                                downloadExcel={downloadExcel}
                                downloadExcelHandler={downloadExcelHandler}
                                key={updateId}
                            />
                            <Flex.Item push>
                                <div className='fy-ddl'>
                                    <Dropdown fluid value={selectedYear} items={dropDownItems}
                                        onChange={(e, selectedItem) => {
                                            handleFYChange(selectedItem.value);
                                        }}
                                    />
                                </div>
                            </Flex.Item>
                            {
                                
                                <Checkbox disabled={selectedYear?.value == 0 ? false : true}
                                    className="form-checkbox part-billing-checkbox"
                                    label="Part Month Billing"
                                    toggle
                                    onChange={(e, { checked }) => {
                                        setPartBilling(checked);
                                        refreshData(selectedYear, checked)
                                    }}
                                    checked={selectedYear?.value == 0 ? partBilling : true } /> 
                                    
                            }

                            {
                                props.loggedinPermissionGroupName == "System Admin" ?
                                    <>
                                        <div class="d-flex flex-nowrap">
                                            <div className='icnTrk ml-2 download-icon'>
                                                <Button title='Download PDF' icon={<FilesPdfIcon outline rotate={0} size="large" />} iconOnly />
                                            </div>
                                            <div className='icnTrk ml-2 download-icon'>
                                                {
                                                    downloading ? <Loader size="smallest" />
                                                        : <Button title='Download Excel' icon={<ExcelColorIcon outline rotate={0} size="large" />} iconOnly
                                                            // onClick={downloadExcelHandler} 
                                                            onClick={() => {
                                                                SetDownloadExcel((i) => i + 1);
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </>
                                    : ""
                            }

                        </Flex>
                    </div>
                </div>
                {
                    loaded ?
                        <div>
                            <TopKPI data={revenueDashboardData.TopKPI} isRevenue="1" year={selectedYear?.value} />

                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Annualised Billing (in thousands)" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Annualised Billing (in thousands)", data: revenueDashboardData?.ChartData.AnnualisedRevenue, color: "#28a745" },
												{type: "line", name:"Annualised Recurring Billing (in thousands)", data: revenueDashboardData?.ChartData.AnnualisedRecurringRevenue, color: "#f0ca29" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.AnnualisedRevenueChartMin}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Total Monthly Billing (in thousands)" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Total Monthly Billing (in thousands)", data: revenueDashboardData?.ChartData.TotalMonthlyBilling, color: "#28a745" },
												{type: "line", name:"Total Monthly Recurring Billing (in thousands)", data: revenueDashboardData?.ChartData.TotalMonthlyRecurringBilling, color: "#f0ca29" },
                                                {type: "line", name:"Licence Billing (in thousands)", data: revenueDashboardData?.ChartData.LicenseBilling, color: "#257fba" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.TotalMonthlyBillingMin}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="% Recurring Billing" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", data: revenueDashboardData?.ChartData.PercentRecurringRevenue, color: "#f0ca29" }]}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Growth this FY Recurring Billing" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", data: revenueDashboardData?.ChartData.GrowthThisFY_RR, color: "#f0ca29" }]}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Churned Billing" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries, axisCrossingValue: churnAxisCrossingValue }]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.ChurnBilling, name:"Churned Billing", axis:"TotalChurned", color:"#dc3545" },
													{type: "line", data: revenueDashboardData?.ChartData.ChurnBillingPercent, name:"% Recurring Billing Churn", axis:"PercentChurned", color:"#e077ae" }]}
												values={[{name: "TotalChurned"}, {name: "PercentChurned", position:"right"}]}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                {/* <div className='col-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="YOY Recurring Billing Growth" />
                                        </CardHeader>
                                        <CardBody>
                                            <Chart transitions={false}>
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem
                                                        labels={{
                                                            rotation: labelRotation,
                                                        }}
                                                        title={{
                                                            text: "Months",
                                                        }}
                                                        categories={revenueDashboardData?.ChartData.CategorySeries}
                                                        
                                                    />
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.Annulaised_RR_Growth} tooltip={{ visible: true }} />
                                                </ChartSeries>
                                            </Chart>
                                        </CardBody>
                                    </Card>
                                </div> */}
                            </div>

                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Tenants" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"Tenants Added", gap: 4, data: revenueDashboardData?.ChartData.TenantsAdded, color: "#28a745" }]}
											/>
                                        </CardBody>
                                    </Card>
                                    {
                                        <ChartDetails showChartPopup={showChartPopup} CloseChartDetails={CloseChartDetails} data={chartDetailsData} ></ChartDetails>
                                        // <ChartDetails2   ref={childRef}  ></ChartDetails2>
                                    }

                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Tenants" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"Tenants Removed %", gap: 4, data: revenueDashboardData?.ChartData.TenanatsRemoved, color: "#dc3545" }]}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                {/* <div className='col-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="Users" />
                                        </CardHeader>
                                        <CardBody>
                                            <Chart seriesColors={chartDefaultV4Colors} 
                                                // onSeriesClick={seriesClickHandler2}
                                                transitions={false} >
                                                <ChartLegend position="top" orientation="horizontal" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem
                                                        title={{
                                                            text: "Months",
                                                        }}
                                                        categories={revenueDashboardData?.ChartData.CategorySeries}
                                                    />
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                <ChartSeries>
                                                    <ChartSeriesItem type="column" gap={4} data={revenueDashboardData?.ChartData.UsersAdded} tooltip={{ visible: true }} name="Users Added"  />
                                                    <ChartSeriesItem type="column" data={revenueDashboardData?.ChartData.UsersRemoved} tooltip={{ visible: true }}  name="Users Removed"  />
                                                </ChartSeries>
                                                </ChartSeries>
                                            </Chart>
                                        </CardBody>
                                    </Card>
                                    {
                                        <ChartDetails showChartPopup={showChartPopup2} CloseChartDetails={CloseChartDetails} data={chartDetailsData2} ></ChartDetails>
                                    }
                                </div> */}
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="End Points" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"End Points Added", gap: 4, data: revenueDashboardData?.ChartData.EndPointsAdded, color: "#28a745" },
												{type: "column", name:"End Points Removed", data: revenueDashboardData?.ChartData.EndPointsRemoved, color: "#dc3545" }]}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Totals Estate -Tenants,Users" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Total Tenants (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalTenants, color: "#28a745" },
												{type: "line", name:"Total Users (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalUsers, color: "#f0ca29" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.EstateTotalChart1Min}}
											/>
                                        </CardBody>
                                    </Card>

                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Totals Estate -End Points" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Total End Points  (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalEndPoints, color: "#28a745" },
												{type: "line", name:"Total Recording End Points  (in hundreds)", data: revenueDashboardData?.ChartData.EstateRecordingEndPoints, color: "#f0ca29" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.EstateTotalChart2Min}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                {/* <div className='col-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="Totals Estate -End Points IC360" />
                                        </CardHeader>
                                        <CardBody>
                                        <Chart transitions={false}>
                                                <ChartLegend position="bottom" orientation="horizontal" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem
                                                        title={{
                                                            text: "Months",
                                                        }}
                                                        categories={revenueDashboardData?.ChartData.CategorySeries}
                                                    />
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateEndPointsIC360} tooltip={{ visible: true }} name="Total End Points IC360 (in thousands)"/>
                                                </ChartSeries>
                                                 <ChartValueAxis >
                                                    <ChartValueAxisItem narrowRange={true}  />
                                                </ChartValueAxis>
                                            </Chart>
                                        </CardBody>
                                    </Card>
                                </div> */}
                            </div>




                            {/* <div className='row mb-4'>
                                <div className='col'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="Totals - Estate" />
                                        </CardHeader>
                                        <CardBody>
                                            <Chart>
                                                <ChartLegend position="bottom" orientation="horizontal" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem
                                                        title={{
                                                            text: "Months",
                                                        }}
                                                        categories={revenueDashboardData?.ChartData.CategorySeries}
                                                    />
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateTotalTenants} tooltip={{ visible: true }} name="Total Tenants"/>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateTotalUsers} tooltip={{ visible: true }} name="Total Users"/>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateTotalEndPoints} tooltip={{ visible: true }} name="Total End Points"/>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateRecordingEndPoints} tooltip={{ visible: true }} name="Total Recording End Points"/>
                                                    <ChartSeriesItem type="line" data={revenueDashboardData?.ChartData.EstateEndPointsIC360} tooltip={{ visible: true }} name="Total End Points IC360 "/>
                                                </ChartSeries>
                                            </Chart>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div> */}

                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Number of Call Recordings (in thousands)" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingsAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Call Recording Minutes (in thousands)" />
                                        </CardHeader>
                                        <CardBody>
                                            <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingMinutesAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
                                        <CardHeader className='graph-header mb-2'>
                                            <Text size="large" weight='bold' content="Recording Storage (TB)" />
                                        </CardHeader>
                                        <CardBody>
                                        <BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingStorageAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>

                            {/* <div className='row mb-4'>
                                <div className='col'>
                                    <Card className="card-border bg-white" fluid>
                                        <CardHeader>
                                            <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                                                <Text size="large" weight='bold' content="Product Summary" />
                                                <Flex.Item push>
                                                    <span><Status state="warning" /><code className='ml-1'>Credit</code></span>
                                                </Flex.Item>
                                                <span className='ml-2 mr-2'><Status state="error" /><code className='ml-1'>Churned</code></span>
                                                <span className='mr-2'><Status state="Unknown" /><code className='ml-1'>Grown</code></span>
                                                <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                                                    <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                                                </div>
                                            </Flex>
                                        </CardHeader>
                                        <CardBody>
                                            <KendoGrid style={{ height: "356px" }}
                                                
                                                // pageable={true}
                                                pageable={{
                                                    buttonCount: 3
                                                }}
                                                sortable={true}
                                                data={process(revenueDashboardData?.ProductSummary, dataState)}
                                                {...dataState}
                                                onDataStateChange={(e) => {
                                                    setDataState(e.dataState);
                                                }}
                                                rowRender={rowRender}>
                                                <Column field="ProductCode" title="Product Code" width={130} />
                                                <Column field="ProductName" title="Product" />
                                                <Column field="Revenue" title='Billing (&#163;)' width={140} />
                                                <Column field="Quantity" title="Quantity" width={120} />
                                                <Column title='Growth'>
                                                    <Column field="GrowthChurnQuantity" width={100} title="Total" />
                                                    <Column field="GrowthChurnRate" width={100} title="Rate" />
                                                </Column>
                                                <Column field="CurrentMonthlyRevenue" width={220} title='Current Monthly Billing (&#163;)' />
                                            </KendoGrid>
                                        </CardBody>
                                        <CardHeader className='mb-2 mt-5'>
                                            <Flex>
                                                <Text size="large" weight='bold' content="Licence Growth By Product" />
                                                <Flex.Item push>
                                                    <Dropdown placeholder='Select Product' value={selectedProduct} items={products} onChange={(e, selectedItem) => {
                                                        setSelectedProduct(selectedItem.value);
                                                    }} />
                                                </Flex.Item>
                                            </Flex>
                                        </CardHeader>
                                        <CardBody>
                                            {
                                                selectedProduct != null ?
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <ForecastChart isForecast={selectedYear.value == 0} title="Billing" xField="BillMonth" yField="Revenue" yaxesLabel={`GBP (&#163;)`} data={revenueDashboardData?.LicenceChart[selectedProduct.value]} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <ForecastChart isForecast={selectedYear.value == 0} title="Quantity" xField="BillMonth" yField="Quantity" yaxesLabel={`Count`} data={revenueDashboardData?.LicenceChart[selectedProduct.value]} />
                                                        </div>
                                                    </div>
                                                    : <Loader />
                                            }

                                        </CardBody>

                                    </Card>
                                </div>
                            </div> */}


                            {/* <div className='row mb-4'>
                                <div className='col-md-6'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="Revenue" />
                                        </CardHeader>
                                        <CardBody>
                                            <ForecastChart isForecast={selectedYear.value == 0} xField="BillMonth" yField="TotalRevenue" yaxesLabel={`GBP (&#163;)`} data={revenueDashboardData?.RevenueChart} />
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-6'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Text size="large" weight='bold' content="Tenant Growth &amp; Churn" />
                                        </CardHeader>
                                        <CardBody>
                                            <AreaChartContainer aggregate="avg" title={""} yaxesLabel="Count" baseUnit={"months"} series={revenueDashboardData?.TenantChart} seriesColors={[
                                                "#4472C4",
                                                "#78d237",
                                                "#ED7D31",
                                                "#28b4c8",
                                                "#2d73f5",
                                                "#aa46be",
                                            ]} />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div> */}

                            {/* <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <Card className='card-border bg-white card-hov pt-2 pb-2' fluid>
                                        <CardHeader className='mb-2'>
                                            <Flex>
                                                <Text size="large" weight='bold' content="Licence Growth By Product" />
                                                <Flex.Item push>
                                                    <Dropdown placeholder='Select Product' value={selectedProduct} items={products} onChange={(e, selectedItem) => {
                                                        setSelectedProduct(selectedItem.value);
                                                    }} />
                                                </Flex.Item>
                                            </Flex>
                                        </CardHeader>
                                        <CardBody>
                                            {
                                                selectedProduct != null ?
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <ForecastChart isForecast={selectedYear.value == 0} title="Revenue" xField="BillMonth" yField="Revenue" yaxesLabel={`GBP (&#163;)`} data={revenueDashboardData?.LicenceChart[selectedProduct.value]} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <ForecastChart isForecast={selectedYear.value == 0} title="Quantiy" xField="BillMonth" yField="Quantity" yaxesLabel={`Count`} data={revenueDashboardData?.LicenceChart[selectedProduct.value]} />
                                                        </div>
                                                    </div>
                                                    : <Loader />
                                            }

                                        </CardBody>
                                    </Card>
                                </div>
                            </div> */}
                        </div>
                        : <Loader />
                }
            </div>
        </div>
    )
}



const ChartDetails = (props) => {

    // const [show, SetShow] = React.useState(true);

    const Close = () => {
        props.CloseChartDetails();
    }


    return (
        <>
            {
                props.showChartPopup ?
                    <div className='chart-popup' style={{ top: 0, right: 0 }} >
                        <div>Month: {props.data.category}</div>
                        <div> Value: {props.data.dataItem}</div>
                        {/* Value {props.Value} */}
                        {/* <CloseIcon outline size="smaller" /> */}
                        <Button onClick={Close} title='Close' className='chart-detail-close' icon={<CloseIcon outline size="smaller" />} iconOnly />
                    </div>
                    : ""
            }

        </>
    );
}




const ChartDetails2 = forwardRef((props, ref) => {

    const [show, SetShow] = React.useState(true);

    const Close = () => {
        SetShow(false);
    }

    const showDetails = () => {
        SetShow(true);
    }


    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

        getAlert() {
            SetShow(true);
            // alert("getAlert from Child");
        }


    }));


    return (
        <>
            {
                show ?
                    <div className='chart-popup'>
                        {/* <div>Month: {props.data.category}</div>
                <div> Value: {props.data.dataItem}</div> */}
                        {/* Value {props.Value} */}
                        {/* <CloseIcon outline size="smaller" /> */}
                        <Button onClick={Close} title='Close' className='chart-detail-close' icon={<CloseIcon outline size="smaller" />} iconOnly />
                    </div>
                    : ""
            }

        </>
    );
});


export default RevenueDashboard;