import React, { useEffect, useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
//import 'codemirror/lib/codemirror.css';
//import "codemirror/lib/codemirror.css";
//import "codemirror/theme/material.css";
//import "codemirror/mode/javascript/javascript.js";
//import "codemirror/mode/xml/xml.js";


// components

const TextEditor = (props) => {
  const [data, setData] = useState(props?.data);
  const [options, setOptions] = useState(props?.data);

  useEffect(() => {
    let extension = props?.fileExtension;
    if(extension == "json")
    {
      let _options = {
        mode: {
          name: "javascript",
          json: true,
          statementIndent: 2
        },
        lineNumbers: true
      };

      setOptions(_options);
    }
    else if(extension == "xml" || extension == "config")
    {
      let _options = {
        mode: 'xml',
        lineNumbers: true
      };

      setOptions(_options);
    }
  }, []);

  return (
<CodeMirror
  value={data}
  options={options}
  onChange={(editor, data, value) => {
    setData(value);
    props.onChange(value);
  }}
/>
  );
};

export {TextEditor};