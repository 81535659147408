import * as React from 'react';
import SettingsService from "../../services/settingsservice";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, EditIcon,
  Loader, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, ExclamationTriangleIcon, MoreIcon, Flex, FlexItem, Text
} from '@fluentui/react-northstar';
import { useCallback } from 'react';
import ReactFlow, {
  Handle, Position,
  NodeResizer,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge, MarkerType,
  useStore, getBezierPath
} from 'reactflow';

import { toPng } from 'html-to-image';
// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css';
import 'reactflow/dist/base.css';
import loadbalancer from '../../../assets/images/svg/internal-load-blancer.svg';
import loadbalanceDB from '../../../assets/images/svg/db-sql.svg';
import VirtualMachine from '../../../assets/images/svg/system-globe.svg';
import VirtualMachineDB from '../../../assets/images/svg/system-db.svg';
import VirtualMachineSystem from '../../../assets/images/svg/system.svg';
import storageDB from '../../../assets/images/svg/system-db.svg';
import internet from '../../../assets/images/svg/internet.svg';
import publicLoadBlancer from '../../../assets/images/svg/public-load-blancer.svg';

import { useMemo } from 'react';
import { Error500 } from '../error/500';

const controlStyle = {
  background: 'transparent',
  border: 'none',
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

function FloatingEdge({ id, source, target, markerEnd, style }) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <path
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
      style={style}
    />
  );
}


function FloatingConnectionLine({ toX, toY, fromPosition, toPosition, fromNode }) {
  if (!fromNode) {
    return null;
  }

  const targetNode = {
    id: 'connection-target',
    width: 1,
    height: 1,
    positionAbsolute: { x: toX, y: toY }
  };

  const { sx, sy } = getEdgeParams(fromNode, targetNode);
  const [edgePath] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: fromPosition,
    targetPosition: toPosition,
    targetX: toX,
    targetY: toY
  });

  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke="#222"
        strokeWidth={1.5}
      />
    </g>
  );
}

const edgeTypes = {
  floating: FloatingEdge,
};

const NodeRoot = ({data, selected}) => {
  return (
    <>
    <NodeResizer color="#ff0071" isVisible={selected} minWidth={100} minHeight={50} />
    <div className="text-updater-node" style={{ height: "calc(100% - 15px)", width: "100%", border: "1px solid black", marginTop: "15px" }}>
      <div className="text-center heading-text">
        <h3 className="text-bg"><span><MoreIcon size="large" /></span> {data.label}</h3>
      </div>
    </div>
    </>
  );
}


const NodePublicLoadBalancer = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={loadbalancer} className="" alt="logo" style={{ width: 100, height: 100, }} />
        <div style={{ fontSize: '12px' }}>
          <h4 style={{ margin: '0' }}>{props.data.label}</h4>
          {props.data.publicIp != null && <p><b>Public IP:</b> {props.data.publicIp}</p>}
          {props.data.privateIp != null && <p><b>Private IP:</b> {props.data.privateIp}</p>}
        </div>
      </div>
      <div>
        <Handle type="source" id="st" position={Position.Top} isConnectable={true} />
        <Handle type="target" id="tr" position={Position.Right} isConnectable={true} />
      </div>
    </>
  );
}

const NodeInternalLoadBalancer = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={publicLoadBlancer} className="" alt="logo" style={{ width: 90, height: 90, }} />
        <div style={{ fontSize: '12px' }}>
          <h4 style={{ margin: '0' }}>Internal Load Blancer</h4>
        </div>
      </div>
      <div>
      <Handle type="source" id="st" position={Position.Top} isConnectable={true} />
      <Handle type="source" id="sr" position={Position.Right} isConnectable={true} />
      <Handle type="source" id="sl" position={Position.Left} isConnectable={true} />
      </div></>
  );
}

const NodeDBLoadBalancer = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={loadbalanceDB} className="" alt="logo" style={{ width: 100, height: 100, }} />
        <div style={{ fontSize: '12px' }}>
          <h4 style={{ margin: '0' }}>{props.data.label}</h4>
          {props.data.publicIp != null && <p><b>Public IP:</b> {props.data.publicIp}</p>}
          {props.data.privateIp != null && <p><b>Private IP:</b> {props.data.privateIp}</p>}
        </div>
      </div>
      <Handle type="source" id="sr" position={Position.Right} isConnectable={true} />
      <Handle type="source" id="sl" position={Position.Left} isConnectable={true} />
      <div>
      </div></>


  );
}

const NodeStorage = (props) => {

  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={storageDB} className="" alt="logo" style={{ width: 85, height: 85, }} />
        <div style={{ fontSize: '10px' }}>
          <strong>{props.data.label}</strong>
        </div>
        <Handle type="target" id="tr" position={Position.Right} isConnectable={true} />
        <Handle type="target" id="tl" position={Position.Left} isConnectable={true} />
      </div>
    </>


  );
}

const NodeIIS = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={VirtualMachine} className="" alt="IIS" style={{ width: 70, height: 65, }} />
        <div style={{ fontSize: '10px' }}>
          <h4 style={{ margin: '0' }}>{props.data.label}</h4>
          {props.data.publicIp != null && <p><b>Public IP:</b> {props.data.publicIp}</p>}
          {props.data.privateIp != null && <p><b>Private IP:</b> {props.data.privateIp}</p>}
        </div>
      </div>
      <div>      
      <Handle type="target" id="tt" position={Position.Top} isConnectable={true} />
      <Handle type="source" id="sr" position={Position.Right} isConnectable={true} />
      <Handle type="source" id="sl" position={Position.Left} isConnectable={true} />
      </div>
    </>


  );
}
const NodeDB = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={VirtualMachineDB} className="" alt="Database" style={{ width: 70, height: 65, }} />
        <div style={{ fontSize: '10px' }}>
          <h4 style={{ margin: '0' }}>{props.data.label}</h4>
          {props.data.publicIp != null && <p><b>Public IP:</b> {props.data.publicIp}</p>}
          {props.data.privateIp != null && <p><b>Private IP:</b> {props.data.privateIp}</p>}
        </div>
      </div>
      <div>
      <Handle type="target" id="tt" position={Position.Top} isConnectable={true} />
      <Handle type="target" id="tr" position={Position.Right} isConnectable={true} />
      <Handle type="target" id="tl" position={Position.Left} isConnectable={true} />
      </div>
    </>
  );
}
const NodeDC = (props) => {
  const onChange = useCallback((evt) => {
    console.warn(evt.target.value);
  }, []);
  return (
    <>
      <div className='custom-node'>
        <img src={VirtualMachineSystem} className="" alt="Backend Server" style={{ width: 70, height: 65, }} />
        <div style={{ fontSize: '10px' }}>
          <h4 style={{ margin: '0' }}>{props.data.label}</h4>
          {props.data.publicIp != null && <p><b>Public IP:</b> {props.data.publicIp}</p>}
          {props.data.privateIp != null && <p><b>Private IP:</b> {props.data.privateIp}</p>}
        </div>
      </div>
      <Handle type="target" id="tb" position={Position.Bottom} isConnectable={true} />
      <div>
      </div>
    </>
  );
}

const NodeInternet = (props) => {
  return (
    <>
      <div className='custom-node'>
        <img src={internet} className="" alt="Internet" style={{ width: 100, height: 100, }} />
      </div>
      <div>
        <Handle type="source" id="sl" position={Position.Left} isConnectable={true} />
      </div>
    </>
  );
}

const nodeTypes = {
  NodeDC: NodeDC, NodeDB: NodeDB, NodeIIS: NodeIIS, NodeStorage: NodeStorage,
  NodeDBLoadBalancer: NodeDBLoadBalancer, NodeInternalLoadBalancer: NodeInternalLoadBalancer,
  NodeInternet: NodeInternet, NodePublicLoadBalancer: NodePublicLoadBalancer, NodeRoot: NodeRoot
};

export const InfraDocsReactFlow = (props) => {
  const [loading, setLoading] = React.useState(null);
  let storageName = "";
  const initialNodes = [];
  const initialEdges = [];
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: 'floating', markerEnd: { type: MarkerType.Arrow } }, eds)
      ),
    [setEdges]
  );
  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      setLoading(true);
      SettingsService.getInfraDocDetails(token, props.InfraDocObj.InstanceId,
        props.InfraDocObj.VNetResourceUri, props.InfraDocObj.StorageAccountName).then((response) => {
          if (response && response.status == 200) {
            if (response.data != null && response.data.DiagramObj != null) {
              let configuredVMs = response.data?.RelatedServerNames;
              response = response.data?.DiagramObj;
              response.VirtualMachines = response.VirtualMachines.filter(f => configuredVMs.includes(f.Name));

              let TOTALAVAILABLEWIDTH = document.getElementById('infra-doc-container').offsetWidth;
              let nodeArr = [], edgeArr = [];
              let ROWNUM = 0, RUNNINGROW = 1, xPos = 10, yPos = 0, nodeWidth = 130, nodeHeight = 130;

              yPos = yPos + 30;
              //virtual machine Non DB/IIS
              let arr = response.VirtualMachines.filter(f => !(f.Name.toLowerCase().includes('db-') || f.Name.toLowerCase().includes('iis-')));
              let numberOfRowsRequiredToFitView = Math.ceil((arr.length * nodeWidth) / TOTALAVAILABLEWIDTH)
              let offsetWidthForNodes = (TOTALAVAILABLEWIDTH - nodeWidth - (arr.length / numberOfRowsRequiredToFitView) * nodeWidth) / (arr.length / numberOfRowsRequiredToFitView);
              arr.forEach((rg, i) => {
                let currentRow = Math.ceil(numberOfRowsRequiredToFitView * (i + 1) / arr.length);
                if (currentRow > RUNNINGROW) {
                  RUNNINGROW = currentRow;
                  ROWNUM += 1;
                  xPos = 10;
                  yPos = 30 + ((ROWNUM) * nodeHeight);
                }
                nodeArr.push({ id: `dc${i}`, type: 'NodeDC', position: { x: Number(xPos), y: Number(yPos) }, data: { label: rg.Name, publicIp: rg.PrimaryPublicIPAddress, privateIp: rg.PrimaryPrivateIPAddress }, parentNode: "root", });
                xPos = xPos + nodeWidth + offsetWidthForNodes;

                edgeArr.push({
                  id: `e-ilb-dc${i}`, sourceHandle:"st", targetHandle:"tb",
                  source: 'ilb', target: `dc${i}`, animated: true,
                  type: 'floating',
                  markerEnd: {
                    type: MarkerType.Arrow,
                  }
                });
              });

              xPos = 10;
              ROWNUM += 1;
              yPos = 30 + ((ROWNUM) * nodeHeight);

              nodeArr.push({
                parentNode: "root",
                id: "ilb", type: "NodeInternalLoadBalancer",
                data: { label: "Internal Load Balancer" },
                position: { x: ((TOTALAVAILABLEWIDTH - 230) / 2) - (nodeWidth / 2), y: yPos },
              });


              ROWNUM += 1;
              yPos = 30 + ((ROWNUM) * nodeHeight);

              //virtual machine DB
              arr = response.VirtualMachines.filter(f => f.Name.toLowerCase().includes('db-'));
              numberOfRowsRequiredToFitView = Math.ceil((arr.length * nodeWidth) / (TOTALAVAILABLEWIDTH - 200))
              offsetWidthForNodes = (TOTALAVAILABLEWIDTH - 230 - nodeWidth - (arr.length / numberOfRowsRequiredToFitView) * nodeWidth) / (arr.length / numberOfRowsRequiredToFitView);
              RUNNINGROW = 1;
              arr.forEach((rg, i) => {
                let isEven = i % 2 == 0 ? true : false;
                let currentRow = Math.ceil(numberOfRowsRequiredToFitView * (i + 1) / arr.length);
                if (currentRow > RUNNINGROW) {
                  RUNNINGROW = currentRow;
                  ROWNUM += 1;
                  xPos = 10;
                  yPos = 30 + ((ROWNUM) * nodeHeight);
                }

                if (isEven) {
                  xPos = xPos + i * (nodeWidth + offsetWidthForNodes);
                }
                else {
                  xPos = (TOTALAVAILABLEWIDTH - 230 - (i - 1) * (nodeWidth + offsetWidthForNodes)) - nodeWidth;
                }

                nodeArr.push({ id: `db${i}`, type: 'NodeDB', position: { x: Number(xPos), y: Number(yPos) }, data: { label: rg.Name, publicIp: rg.PrimaryPublicIPAddress, privateIp: rg.PrimaryPrivateIPAddress }, parentNode: "root", });
                //xPos = xPos + nodeWidth;

                if (rg.LoadBalanceName != null && edgeArr.findIndex((item) => item.id === rg.LoadBalanceName + '-' + rg.Name) == -1) {
                  edgeArr.push({
                    id: `e-lbdb-db${i}`, sourceHandle:"sl", targetHandle:"tt",
                    source: rg.LoadBalanceName, target: `db${i}`, animated: true,
                    type: 'floating',
                    markerEnd: {
                      type: MarkerType.Arrow,
                    }
                  });
                }
                edgeArr.push({
                  id: `e-ilb-db${i}`, sourceHandle:"sl", targetHandle:"tt",
                  source: 'ilb', target: `db${i}`, animated: true,
                  type: 'floating',
                  markerEnd: {
                    type: MarkerType.Arrow,
                  }
                });
              });

              //Load Balancers DB
              arr = response.LoadBalancers.filter(f => f.LoadBalancerType == 1);
              arr.forEach((rg, i) => {
                if (i > 0) {
                  yPos = yPos + nodeHeight;
                }

                xPos = ((TOTALAVAILABLEWIDTH - 230) / 2) - (nodeWidth / 2);
                nodeArr.push({
                  id: rg.Name, type: 'NodeDBLoadBalancer',
                  position: { x: Number(xPos), y: Number(yPos) }, data: { label: rg.Name, publicIp: rg.PrimaryPublicIPAddress, privateIp: rg.PrimaryPrivateIPAddress },
                  parentNode: "root",
                });
              });

              xPos = 10;
              ROWNUM += 1;
              yPos = 30 + ((ROWNUM) * nodeHeight);

              //virtual machine IIS
              arr = response.VirtualMachines.filter(f => f.Name.toLowerCase().includes('iis-'));
              numberOfRowsRequiredToFitView = Math.ceil((arr.length * nodeWidth) / (TOTALAVAILABLEWIDTH - 200))
              offsetWidthForNodes = (TOTALAVAILABLEWIDTH - 230 - nodeWidth - (arr.length / numberOfRowsRequiredToFitView) * nodeWidth) / (arr.length / numberOfRowsRequiredToFitView);
              RUNNINGROW = 1;
              arr.forEach((rg, i) => {
                let isEven = i % 2 == 0 ? true : false;
                let currentRow = Math.ceil(numberOfRowsRequiredToFitView * (i + 1) / arr.length);
                if (currentRow > RUNNINGROW) {
                  RUNNINGROW = currentRow;
                  ROWNUM += 1;
                  xPos = 10;
                  yPos = 30 + ((ROWNUM) * nodeHeight);
                }

                if (isEven) {
                  xPos = xPos + i * (nodeWidth + offsetWidthForNodes);
                }
                else {
                  xPos = (TOTALAVAILABLEWIDTH - 230 - (i - 1) * (nodeWidth + offsetWidthForNodes)) - nodeWidth;
                }
                nodeArr.push({ id: `iis${i}`, type: 'NodeIIS', position: { x: Number(xPos), y: Number(yPos) }, data: { label: rg.Name }, parentNode: "root", });

                if (rg.LoadBalanceName != null && edgeArr.findIndex((item) => item.id === rg.LoadBalanceName + '-' + rg.Name) == -1) {
                  edgeArr.push({
                    id: `e-lb-iis${i}`, sourceHandle: 'st',
                    source: rg.LoadBalanceName, target: `iis${i}`, targetHandle: 'tb', animated: true,
                    type: 'floating',
                    markerEnd: {
                      type: MarkerType.ArrowClosed,
                    },
                  });
                }

                if (props.InfraDocObj.StorageAccountName != null || props.InfraDocObj.StorageAccountName != "") {
                  edgeArr.push({
                    id: `e-iis${i}-sa`, sourceHandle: 'sr', targetHandle: 'tl',
                    source: `iis${i}`, target: 'sa', animated: true,
                    type: 'floating',
                    markerEnd: {
                      type: MarkerType.Arrow,
                    }
                  });
                }
                edgeArr.push({
                  id: `e-ilb-iis${i}` + rg.Name, sourceHandle: 'sl', targetHandle: 'tt',
                  source: 'ilb', target: `iis${i}`, animated: true,
                  type: 'floating',
                  markerEnd: {
                    type: MarkerType.Arrow,
                  }
                });
              });

              //Storage Account
              if (props.InfraDocObj?.StorageAccountName != "") {
                response.StorageAccounts.map(rg => {
                  if (props.InfraDocObj.StorageAccountName === rg.Name) {
                    storageName = rg.Name;
                    nodeArr.push({
                      id: 'sa', type: 'NodeStorage',
                      position: { x: ((TOTALAVAILABLEWIDTH - 230) / 2) - (nodeWidth / 2), y: yPos },
                      data: { label: rg.Name }, parentNode: "root",
                    });
                  }
                })
              }


              //Load Balancers Public
              arr = response.LoadBalancers.filter(f => f.LoadBalancerType == 0);
              if (arr.length > 0) {
                xPos = ((TOTALAVAILABLEWIDTH - 230) / 2) - (nodeWidth / 2);
                ROWNUM += 1;
                yPos = 30 + ((ROWNUM) * nodeHeight);
              }
              numberOfRowsRequiredToFitView = Math.ceil((arr.length * nodeWidth) / (TOTALAVAILABLEWIDTH - 200))
              offsetWidthForNodes = (TOTALAVAILABLEWIDTH - nodeWidth - (arr.length / numberOfRowsRequiredToFitView) * nodeWidth) / (arr.length / numberOfRowsRequiredToFitView);
              RUNNINGROW = 1;
              arr.forEach((rg, i) => {
                let isEven = i % 2 == 0 ? true : false;
                let currentRow = Math.ceil(numberOfRowsRequiredToFitView * (i + 1) / arr.length);
                if (currentRow > RUNNINGROW) {
                  RUNNINGROW = currentRow;
                  ROWNUM += 1;
                  xPos = ((TOTALAVAILABLEWIDTH - 230) / 2) - (nodeWidth / 2);
                  yPos = 30 + ((ROWNUM) * nodeHeight);
                }

                if (isEven) {
                  xPos = xPos - (i) * nodeWidth - ((arr.length - i - 1) * offsetWidthForNodes);
                }
                else {
                  xPos = xPos + (i - 1) * nodeWidth + ((arr.length - i - 1) * offsetWidthForNodes);
                }

                nodeArr.push({
                  id: rg.Name, type: 'NodePublicLoadBalancer',
                  position: { x: xPos, y: yPos }, data: { label: rg.Name, publicIp: rg.PrimaryPublicIPAddress, privateIp: rg.PrimaryPrivateIPAddress },
                  parentNode: "root",
                });

                edgeArr.push({
                  id: `e-internet-lb${i}`,
                  target: rg.Name,
                  source: 'internet',
                  sourceHandle: 'sl',
                  targetHandle: 'tr',
                  type: 'floating',
                  markerEnd: {
                    type: MarkerType.ArrowClosed,
                  },
                  animated: true,
                });
              });

              // Group Node
              nodeArr = [{
                id: "root",
                data: { label: props.InfraDocObj.VNetName },
                type: "NodeRoot",
                position: { x: 10, y: 0 },
                style: { backgroundColor: 'rgba(0, 0, 0, 0)', width: 'calc(100% - 220px)', height: `${((ROWNUM + 1) * nodeHeight) + 60}px`, },
              }, ...nodeArr];

              nodeArr.push({
                id: "internet", type: "NodeInternet",
                data: { label: "Internet" },
                position: { x: TOTALAVAILABLEWIDTH - 180, y: 30 + ((ROWNUM) * nodeHeight) },
              });

              setNodes(nodeArr);
              setEdges(edgeArr);
            }
            setLoading(false);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }
        })
    };
  };

  function downloadImage(dataUrl) {
    const a = document.createElement('a');

    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
  }

  const onClick = () => {
    toPng(document.querySelector('.react-flow'), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains('react-flow__minimap') ||
          node?.classList?.contains('react-flow__controls')
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  }


  const rfStyle = {
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%'
  };

  return (
    <>
      {
        !loading ?
          nodes && nodes.length > 0 ?
            (
              <div style={{ height: '100%', width: '100%' }}>
                <Flex>
                  {props.InfraDocObj.StorageAccountName == "" ? <Text className='text-warning' content={<><ExclamationTriangleIcon /> Storage details are not provided for the Instance.</>} /> : ""}
                  <FlexItem push>
                    <Button className="download-btn" onClick={onClick} content="Download Image" style={{ marginBottom: '20px' }} />
                  </FlexItem>
                </Flex>


                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  connectionLineComponent={FloatingConnectionLine}
                  style={rfStyle}
                  fitView
                >
                  {/* <MiniMap /> */}
                  <Controls />
                  <Background gap={5} />
                </ReactFlow>
              </div>)
            :
            <Error500 />
          :
          <Loader />
      }
    </>

  )

};
