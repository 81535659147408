import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, RadioGroup, Dropdown, Label, Datepicker, FormDropdown
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellEmailLogs, CellEmail, CellDateEmailService, CellEmailAnalysisToken , CellEmailContent} from '../../shared/appcomponents/custom-grid-cells';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsEmailAnalysis = (props) => {

	const [EmailAnalysis, setEmailAnalysis] = React.useState(null);
	// const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [selectedFilter, setselectedFilter] = React.useState(null);
	const [filterByValue, setFilterByValue] = React.useState('');
	const filterByItems = ['Sent Date', 'Instance', 'Token', 'Sent To', 'Sent From', 'Subject', 'Email Content'];
	const [showDateFilter, setShowDateFilter] = useState(true);
	const [showInstanceFilter, setShowInstanceFilter] = useState(false);
	const [instanceNames, setInstanceNames] = React.useState(null);
	const [selectedInstance, setSelectedInstance] = React.useState(null);
	const [showTokenFilter, setShowTokenFilter] = useState(false);
	const [TokenNames, setTokenNames] = React.useState(null);
	const [selectedToken, setSelectedToken] = React.useState(null);
	const [filterError, setFilterError] = React.useState(false);
	const tokenSuffix = "***************";


	let maxEndDate = new Date();
	maxEndDate.setDate(maxEndDate.getDate() + 1);
	maxEndDate.setHours(23); maxEndDate.setMinutes(59);
	// setSelectedStartDate.setHours(0).setMinutes(0);

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "EmailSentDate",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});

	const filterRadioItems = [
		{
			name: 'Today',
			key: 'Today',
			label: 'Today',
			value: '0',
		},
		{
			name: 'Filter By',
			key: 'Filter By',
			label: 'Filter By:',
			value: '1',
		},
	];

	const [filterByRadioValue, setfilterByRadioValue] = React.useState('0')

	const filterByChange = (e, data) => {

		// if(data.value == '1' && selectedFilter == 'Sent Date'){
		// 	setShowDateFilter(true);
		// }
		// else{
		// 	setShowDateFilter(false);
		// }
		setFilterError(false);
		setfilterByRadioValue(data.value);


		// if(data.value != '-1'){ 
		// 	SetLoaded(false);
		// 	props.acquireToken((token) => {
		// 		if (token) {
		// 			let _params = {
		// 				EmailDays: data.value,
		// 			};
		// 			SettingsService.GetEmailLogs(token, _params).then((response) => {
		// 				if(response && response.status==200){
		// 					response=response.data;
		// 					setEmailLogs(response);
		// 				}
		// 				else if(response && response.status==401){
		// 				props.viewStatus.InformChildPageStatus(response.status);
		// 				}
		// 				else {
		// 				props.viewStatus.InformChildPageStatus(500);
		// 				}        
		// 				SetLoaded(true);
		// 			});
		// 		}
		// 	});
		// }
	};

	const applyFilter = () => {
		if(filterByRadioValue == "1" && selectedFilter === null){
			setFilterError(true);
			return;
		}

		SetLoaded(false);
		setActive("false");
		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() + 1) + '/' + sd.getDate() + ' '  + sd.getHours() + ':' + sd.getMinutes()  ;
		let ed = new Date(selectedEndDate);
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() + 1) + '/' + ed.getDate() + ' '  + ed.getHours() + ':' + ed.getMinutes();
		let filterByValueData = filterByValue;

		let filterByField = '';
		switch (selectedFilter) {
			case 'Sent To': filterByField = 'sentTo'; break;
			case 'Sent From': filterByField = 'sentFrom'; break;
			case 'Subject': filterByField = 'subject'; break;
			case 'Sent Date': filterByField = 'sentOn'; break;
			case 'Email Content': filterByField = 'content'; break;
			case 'Instance': filterByField = 'instance'; filterByValueData = selectedInstance; break;
			case 'Token': filterByField = 'token';
				filterByValueData = selectedToken && selectedToken.length >= 5 ? selectedToken.substring(0, selectedToken.indexOf("****")) : selectedToken; break;
		}
		props.acquireToken((token) => {
			if (token) {
				let _params = {
					filterByRadioValue: filterByRadioValue,
					filterByField: filterByField,
					filterByValue: filterByValueData,
					startDate: startDate,
					endDate: endDate
				};
				SettingsService.GetEmailAnalysis(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						setEmailAnalysis(response);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
				});
			}
		});
	}

	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);
		let sd =  new Date();
		let ed =  new Date();
		sd.setHours(0); sd.setMinutes(0);
		setSelectedStartDate(sd);
		ed.setHours(23); ed.setMinutes(59);
		setSelectedEndDate(ed);
	}, []);

	const authSuccessCallback = (token) => {
		if (token) {
			let _params = {
				filterByRadioValue: filterByRadioValue,
			};
			SettingsService.GetEmailAnalysis(token, _params).then((response) => {
				if (response && response.status == 200) {
					response = response.data;
					setEmailAnalysis(response);
					SettingsService.GetEmailFilterData(token).then(
						(response) => {
							if (response && response.status == 200) {

								response = response.data;
								setInstanceNames(response.Instances.map(item => item.header));
								if (response.Instances) {
									setSelectedInstance(response.Instances[0].header);
								}
								setTokenNames(response.Tokens.map(item => item.header + tokenSuffix));
								if (response.Tokens) {
									setSelectedToken(response.Tokens[0].header + tokenSuffix);
								}
								// console.log(instanceNames);
							}
						}
					);
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};

	const filterGridData = (value) => {
		let filter = {
			logic: "or",
			filters: [{ field: "SentTo", operator: "contains", value: value },
			{ field: "SentFrom", operator: "contains", value: value },
			{ field: "Subject", operator: "contains", value: value },
			{ field: "SentDate", operator: "contains", value: value },
			{ field: "SentContent", operator: "contains", value: value },
			{ field: "InstanceName", operator: "contains", value: value },
			{ field: "TokenPrefix", operator: "contains", value: value },
			],
		};
		setDataState({ ...dataState, filter: filter });
	};

	const ActionIconVersions = (props) => {
		return (
			<td style={{ textAlign: "center" }}>
				{/* <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/> */}
				<Button
					styles={{ height: "auto" }}
					iconOnly
					size="small"
					icon={<TrashCanIcon />}
					text
					onClick={() => {
						// setDeleteAlertObj(props.dataItem);
						// setDeleteConfirmationDialog(true);
					}}
				/>
			</td>
		);
	};

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>

				</FluentGrid>
			</section>
		);
	};

	const expandChange = (event) => {
		let newData = EmailAnalysis.map((item) => {
			if (item.alertId === event.dataItem.alertId) {
				item.expanded = !event.dataItem.expanded;
			}

			return item;
		});
		setEmailAnalysis(newData);
	};

	const addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	const CellEmailCol = (prop) => {
		return (
			<CellEmailContent {...prop} acquireToken={props.acquireToken} />
		);
	}

	const [isActive, setActive] = useState("false");
	const ToggleClass = () => {
		setActive(!isActive);
	};

	const handleFilterDdlChange = (_selectedType) => {

		setFilterError(false);
		if (filterByRadioValue == '1') {
			setShowDateFilter(false);
			setShowInstanceFilter(false);
			setShowTokenFilter(false);
			if (_selectedType == 'Sent Date') {
				setShowDateFilter(true);
			}
			else if (_selectedType == 'Instance') {
				setShowInstanceFilter(true);
			}
			else if (_selectedType == 'Token') {
				setShowTokenFilter(true);
			}
		}
		setselectedFilter(_selectedType);
		setFilterByValue('');
	}

	const handleFilterByTextChange = (e, data) => {
		setFilterByValue(data.value);
	}

	const handleInstanceNameChange = (alwaysNull, data) => {
		setSelectedInstance(data.value);
	};

	const handleTokenNameChange = (alwaysNull, data) => {
		setSelectedToken(data.value);
	};

	const handleEndDateChange = (event) => {
    setSelectedEndDate(event.value);
  };

	const handleStartDateChange = (event) => {
    setSelectedStartDate(event.value);
  };

	

	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100">
						<div className="col-md-12 h-100">
							<Card className="card-border bg-white h-100" fluid>
								<CardHeader className="mb-2">
									<div className="d-flex justify-content-between position-relative">
										<div className="f-size-18">Email Analysis</div>

										<Button styles={{ padding: '0 12px' }} icon={<FilterIcon />} content={"Filters"} title="Filters" onClick={ToggleClass} className="filetr-icon" circular />

										<Flex gap="gap.small" className={isActive ? "email-log-filter email-service" : "active email-log-filter email-service"} style={{ width: '350px' }}>

											<div className="radio-container" style={{ position: 'relative' }}>
												<RadioGroup vertical
													checkedValue={filterByRadioValue}
													items={filterRadioItems}
													onCheckedValueChange={filterByChange}
												// value={emailDaysSelected}
												/>

												<div className="ddl-container" style={{ width: '180px', position: 'absolute', top: '31px', left: '118px' }}>
													<Dropdown fluid items={filterByItems}  value={selectedFilter}
													 	placeholder="Select Filter"  error={filterError}
														onChange={(e, selectedItem) => {
															handleFilterDdlChange(selectedItem.value);
														}}
														disabled={filterByRadioValue == "0" ? true : false}
													/>
												</div>
												{
													filterError ? 
													<div className="error-message" style={{  position: 'absolute', top: '-14px', left: '4px', fontSize:'12px' }}>
														<Text className="text-danger">*Select Filter</Text>
													</div>
													:""
												}
												
											</div>
											<div style={{ marginLeft: '7px', marginTop: '8px' }}>
												{
													showDateFilter ?
														<div>
															<div style={{ marginTop: 'px', marginBottom: '5px', marginLeft: '1px', marginRight: '2px' }}>
																<div className="d-flex justify-content-between align-items-center" style={{  marginBottom: '5px' }}>
																	<Text content="Start Date"  size="medium" weight="regular"
																		disabled={filterByRadioValue == "0" || selectedFilter != 'Sent Date' ? true : false} />

																	<DateTimePicker 
																		disabled={filterByRadioValue == "0" || selectedFilter != 'Sent Date' ? true : false} width={210} format={"dd MMM, yyyy, hh:mm a"}
																		max={selectedEndDate}
																		 value={selectedStartDate} 
																		 onChange={handleStartDateChange} />
																	{/* <Datepicker allowManualInput={false} className="date-filter date-email-service"
																		selectedDate={selectedStartDate} disabled={filterByRadioValue == "0" ? true : false}
																		maxDate={selectedEndDate}
																		onDateChange={(e, data) => {
																			setSelectedStartDate(data.value);
																		}} /> */}
																</div>
																<div className="d-flex justify-content-between align-items-center">
																	<Text content="End Date"  size="medium" weight="regular"
																		disabled={filterByRadioValue == "0" || selectedFilter != 'Sent Date' ? true : false} />
																	<DateTimePicker 
																		disabled={filterByRadioValue == "0" || selectedFilter != 'Sent Date' ? true : false} width={210} format={"dd MMM, yyyy, hh:mm a"}
																		min={selectedStartDate}  max={maxEndDate}
																		value={selectedEndDate}
																		onChange={handleEndDateChange}/>

																	{/* <Datepicker  
																		selectedDate={selectedEndDate} disabled={filterByRadioValue == "0" ? true : false}
																		// minDate={selectedStartDate} maxDate={maxEndDate}
																		onDateChange={(e, data) => {
																			setSelectedEndDate(data.value);
																		}} /> */}
																</div>
															</div>
														</div> :
														showInstanceFilter ?
															<FormDropdown label={{ content: `Select Instance`, id: "Instance2 Name-inline2", }}
																items={instanceNames} fluid required value={selectedInstance} defaultValue={selectedInstance}
																onChange={handleInstanceNameChange} width={150} style={{ marginBottom: '17px' }}
																disabled={filterByRadioValue == "0" ? true : false} /> :
															showTokenFilter ?
																<FormDropdown label={{ content: `Select Token`, id: "Token2 Name-inline2", }}
																	items={TokenNames} fluid required value={selectedToken} defaultValue={selectedToken}
																	onChange={handleTokenNameChange} width={150} style={{ marginBottom: '17px' }}
																	disabled={filterByRadioValue == "0" ? true : false} /> :
																<div>
																	<Text content="Enter " className="date-filter-text" size="medium" weight="regular"
																		disabled={filterByRadioValue == "0" ? true : false} />
																	<Text content={selectedFilter} className="date-filter-text" size="medium" weight="regular"
																		disabled={filterByRadioValue == "0" ? true : false} />
																	<Input className="border-filter-input" fluid value={filterByValue} style={{ marginBottom: '17px' }}
																		disabled={filterByRadioValue == "0" ? true : false} onChange={handleFilterByTextChange} />
																</div>
												}


											</div>




											<Flex gap="gap.small" className="mt-2" style={{ marginLeft: '9px' }}>
												<Button content="Apply"
													onClick={applyFilter} />
												<Button content="Cancel" className="aws-applyFilter"
													onClick={() => setActive("false")} />
											</Flex>

										</Flex>
									</div>

								</CardHeader>
								<CardBody>
									<div className="tbl-search float-right text-right mb-2">
										<Input
											onChange={(e, { name, value }) => {
												filterGridData(value);
											}}
											inverted
											icon={<SearchIcon />}
											placeholder="Search..."
											className="border-gray"
										/>
									</div>
									<div className='grid-with-search'>
										<Grid
											style={{
												height: "100%",
											}}
											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(EmailAnalysis, dataState)}
											{...dataState}
											onDataStateChange={(e) => {
												setDataState(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>
											<Column field="SentDate" title="Sent Date" cell={CellDateEmailService} width={160} />
											<Column field="InstanceName" title="Instance" width={100} />
											<Column field="TokenPrefix" title="Token" width={100} cell={CellEmailAnalysisToken} />
											<Column field="SentTo" title="Sent To" cell={CellEmailLogs} width={160} />
											<Column field="SentFrom" title="Sent From" width={150} cell={CellEmailLogs} />
											<Column field="Subject" title="Email Subject" width={160} cell={CellEmailLogs}/>
											<Column field="SentContent" title="Email Content" width={100} cell={CellEmailCol} />
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { SettingsEmailAnalysis };
