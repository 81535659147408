import React from 'react'
import { Card, Loader, Text, Button, Tooltip, Toolbar } from '@fluentui/react-northstar'
import { PlugsIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';
import { ServiceAlarm } from './recent';
import { ServiceAlarmHistorical } from './historical';
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';



const ServiceIncident = (props) => {
    const { id } = useParams();

    const [viewArea, setViewArea] = React.useState("");
    const [searchParams] = useSearchParams();
    const [incidents, setIncidents] = React.useState(null);
    const [isLoaded, setLoaded] = React.useState(false);


    let navigate = useNavigate();
    const routeChange = (path, varea) => {
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
    }
    React.useEffect(() => {
        setLoaded(false);
        let _alarmType = searchParams.get('type');
        if (_alarmType) {
            setViewArea(_alarmType);
        }
        else {
            _alarmType = 'critical';
            setViewArea("critical");
        }
        props.acquireToken((token) => {
            if (token) {
                let _params = {
                    instanceId: id ? id : -1,
                    incidentLevelId: _alarmType != 'history' ? INCIDENTLEVELS[_alarmType] : -1,
                    appliedFilters: id
                        ? [`InstanceId IN (${id})`]
                        : [],
                };
                MonitoringService.GetServiceIncidents(token, _params).then((response) => {
                    if (response && response.status==200) {
                        response=response.data;
                        setIncidents(response);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                      }
                      else {
                        props.viewStatus.InformChildPageStatus(500);
                      }
                    setLoaded(true);
                });
            }
        });
    }, [searchParams]);
    return (
        <Card className='card card-service bg-white' elevated fluid>
            <Card.Header>
                <Toolbar
                    items={[
                        {
                            key: 'custom-text',
                            content: <Text className='f-size-20' content={`Service Alarms ${viewArea != '' ? ': ' + viewArea.toUpperCase() : ''}`} />,
                            kind: 'custom',
                            className: 'heading',
                            fitted: 'horizontally',
                        },
                        {
                            key: 'custom-service-critical',
                            content: <Button icon={incidents ? incidents.Critical : '-'} content="Critical" className={`${viewArea == "critical" ? 'selected' : ''}`} iconPosition="after" onClick={() => routeChange('/dashboards/monitoring/service/alarms?type=critical', 'critical')} />,
                            focusable: true,
                            kind: 'custom',
                            className: 'btn critical',
                            fitted: 'horizontally',
                        },
                        {
                            key: 'custom-service-warning',
                            kind: 'custom',
                            content: <Button icon={incidents ? incidents.Warning : '-'} content="Warning" className={`${viewArea == "warning" ? 'selected' : ''}`} iconPosition="after" onClick={() => routeChange('/dashboards/monitoring/service/alarms?type=warning', 'warning')} />,
                            fitted: 'horizontally',
                            className: 'btn warning',
                        },
                        {
                            key: 'custom-service-history',
                            kind: 'custom',
                            content: <Tooltip pointing trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text iconOnly onClick={() => routeChange('/dashboards/monitoring/service/alarms?type=history', 'history')} />} content="Historical Alarms" />,
                            fitted: 'horizontally',
                            className: 'card-actions',
                        },
                    ]}
                >
                </Toolbar>
            </Card.Header>
            <Card.Body>
                {
                    viewArea != "" && isLoaded ?
                        (
                            viewArea != 'history' ?
                                <ServiceAlarm {...props} incidents={incidents} alarmType={viewArea} routeChange={routeChange} />
                                :
                                <ServiceAlarmHistorical {...props} incidents={incidents} alarmType={viewArea} routeChange={routeChange} />
                        )
                        :
                        <Loader />
                }
            </Card.Body>
        </Card>
    )
}

export default ServiceIncident;