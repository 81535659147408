import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormTextArea, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status , Text, PopupContent} from '@fluentui/react-northstar';
import { Grid, GridColumn as Column , GridSelectionChangeEvent, GridHeaderSelectionChangeEvent, getSelectedState} from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon} from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import SettingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { getter } from '@progress/kendo-react-common';
import { Link } from 'react-router-dom';
//import PopupModal from '../serversm/PopupModal';
import { CellZohoURL, CellVNetTypes, rowRender, CellSPAccess } from "../../shared/appcomponents/custom-grid-cells";
import Modal from 'react-modal';
import EditableScriptTextarea from './EditableScriptTextArea';


const SettingsServerAndVM = (props) => {
    const DATA_ITEM_KEY = "Id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);

    const [vmData, setVMData] = useState([]);
    const [serverData, SetServerData] = useState(null);

    const [isLoaded, SetLoaded] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [visible, setVisible] = React.useState(false);

    const [visibleScript, setVisibleScript] = React.useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [responseData, setResponseData] = useState(null);
    const [requestData, setRequestData] = useState([]);
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [loading, setLoaded] = useState(false);
    const [powerShellScript, setPowerShellScript] = React.useState("");
    const [dataFetched, setDataFetched] = useState(false);


    const [serverNames, setServerNames] = useState([]);
    const [responses, setResponses] = useState([]);
    const [responseRawData, setResponseRawData] = useState([]);


    const [editMode, setEditMode] = React.useState(false);
    const [servers, setServers] = React.useState([]);

    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteServerObj, setDeleteServerObj] = useState(null);
  ///////////////
    const [powershellFolders, setPowershellFolders] = useState([]);
    const [selectedPowershellSubFolder, setSelectedPowershellSubFolder] = useState("");
    const [azureSubFolders, setAzureSubFolders] = useState([]);
    const [selectedAzureFolder, setSelectedAzureFolder] = useState("");
    const [directSubFolders, setDirectSubFolders] = useState([]);
    const [selectedDirectFolder, setSelectedDirectFolder] = useState("");

    const [azureGeneralSubFolders, setAzureGeneralSubFolders] = useState([]);
    const [azureUserSubFolders, setAzureUserSubFolders] = useState([]);

    const [directScheduleJobSubFolders, setDirectScheduleJobSubFolders] = useState([]);
    const [directSystemSubFolders, setDirectSystemSubFolders] = useState([]);
    const [directUserSubFolders, setDirectUserSubFolders] = useState([]);

    const [selectedGeneralSubFolders, setSelectedGeneralSubFolders] = useState("");
    const [selectedUserSubFolders, setSelectedUserSubFolders] = useState("");
    const [selectedScheduleJobSubFolders, setSelectedScheduleJobSubFolders] = useState("");
    const [selectedSystemSubFolders, setSelectedSystemSubFolders] = useState("");
    const [selectedDirectUserSubFolders, setSelectedDirectUserSubFolders] = useState("");

    // const [selectedAzureSubfolder, setSelectedAzureSubfolder] = useState("");
    // const [selectedDirectSubFolder, setSelectedDirectSubFolder] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFileContent, setSelectedFileContent] = useState('');
    const [filesWithContent, setFilesWithContent] = useState([]);

    const [azureGeneralScriptFiles, setAzureGeneralScriptFiles] = useState([]);
    const [azureUserScriptFiles, setAzureUserScriptFiles] = useState([]);

    const [directScheduleJobScriptFiles, setDirectScheduleJobScriptFiles] = useState([]);
    const [directSystemScriptFiles, setDirectSystemScriptFiles] = useState([]);
    const [directUserScriptFiles, setDirectUserScriptFiles] = useState([]);

    const [visibleAzure, setVisibleAzure] = React.useState(false);
    const [visibleDirect, setVisibleDirect] = React.useState(false);

  ///////////////



    const addEditSubmitClickHandler = () => {
      props.acquireToken(authSuccessAddEditServerCallback);
      setVisible(false);
      setEditMode(false);
      SetServerData(null);
    };
    

    const sendExecuteScriptHandler = () => {
      props.acquireToken(sendExecuteScript);
      setVisibleScript(false);
      setVisibleAzure(false);
      setVisibleDirect(false);
      setVisiblePopup(true);
    };

    React.useEffect(() => {
      debugger;
      props.acquireToken(sendExecuteScript);
    }, []);

    React.useEffect(() => {
      props.acquireToken(getPowershellFilesWithContent);
    }, []);

    const getPowershellFilesWithContent = async (token) => {
      debugger;
      try{
        if(token){
          const response = await SettingServerSM.GetPowershellFilesWithContent(token);
          debugger
          if(response) {
            const files = response.data;
            setFilesWithContent(files);

            // if(files.length > 0) {
            //   setSelectedFileContent(files[0].fileContent);
            // }
          }
        }  
      }
      catch(error){
        console.error("Error fetching script files:", error);
      }
    };

    ////--------------Folder and SubFolders  start---------------///////
    React.useEffect(() => {
      props.acquireToken(getPowershellFolders);
    }, []);

    const getPowershellFolders = async (token) => {
      debugger;
      try{
        if(token) {
          const response = await SettingServerSM.GetPowershellFolders(token);
          if(response) {
            const powershellScriptFolder = response.data;
            const subfolders = powershellScriptFolder.Subfolders;
            setPowershellFolders(subfolders.map((folder) => folder.Name));

            if(subfolders.length > 1){
              const azureSubFolders = subfolders[0].Subfolders;
              const directSubFolders = subfolders[1].Subfolders;
              setAzureSubFolders(azureSubFolders.map((folder) => folder.Name));
              setDirectSubFolders(directSubFolders.map((folder) => folder.Name));

              if(azureSubFolders.length > 0){
                const azureGeneralSubFolders = azureSubFolders[0].Subfolders;
                setAzureGeneralSubFolders(azureGeneralSubFolders.map((folder) => folder.Name));

                const azureUserSubFolders = azureSubFolders[1].Subfolders;
                setAzureUserSubFolders(azureUserSubFolders.map((folder) => folder.Name));
                
                //for azureFiles
                if(azureGeneralSubFolders.length > 0){
                  const azureGeneralScriptFiles = azureGeneralSubFolders[0].Files;
                  setAzureGeneralScriptFiles(azureGeneralScriptFiles);
                }
                if(azureUserSubFolders.length > 0){
                  const azureUserScriptFiles = azureUserSubFolders[0].Files;
                  setAzureUserScriptFiles(azureUserScriptFiles);
                }
              }

              if(directSubFolders.length > 0){
                const directUserSubFolders = directSubFolders[0].Subfolders;
                setDirectUserSubFolders(directUserSubFolders.map((folder) => folder.Name));

                const directScheduleJobSubFolders = directSubFolders[1].Subfolders;
                setDirectScheduleJobSubFolders(directScheduleJobSubFolders.map((folder) => folder.Name));

                const directSystemSubFolders = directSubFolders[2].Subfolders;
                setDirectSystemSubFolders(directSystemSubFolders.map((folder) => folder.Name));

                //for directFiles
                if(directUserSubFolders.length > 0 ){
                  const directUserScriptFiles = directUserSubFolders[0].Files;
                  setDirectUserScriptFiles(directUserScriptFiles);
                }
                if(directSystemSubFolders.length > 0 ){
                  const directSystemScriptFiles = directSystemSubFolders[0].Files;
                  setDirectSystemScriptFiles(directSystemScriptFiles);
                }
                if(directScheduleJobSubFolders.length > 0 ){
                  const directScheduleJobScriptFiles = directScheduleJobSubFolders[0].Files;
                  setDirectScheduleJobScriptFiles(directScheduleJobScriptFiles);
                }
              }
            }

            
            
            //
            console.log("powershellFolders: " + powershellFolders)
          }
        }
      }
      catch(error) {

      }
    };

    const handlePowershellSubFolders = (event, data) => {
      debugger
      const selectedValue = data.value.value;
      setSelectedPowershellSubFolder(selectedValue);
      console.log(selectedValue);

      const selectedSubfolder = powershellFolders.find((folder) => folder === data.value.value);

      if(selectedSubfolder) {
        if (selectedSubfolder === 'Azure' && azureSubFolders.length > 0 && azureGeneralSubFolders.length > 0 && azureUserSubFolders.length > 0) {
          setSelectedAzureFolder(azureSubFolders[0]);
          setSelectedDirectFolder(""); 
          setSelectedGeneralSubFolders(azureGeneralSubFolders[0]);
          setSelectedUserSubFolders(azureUserSubFolders[0]);
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders("");
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");

        } else if (selectedSubfolder === 'Direct' && directSubFolders.length > 0 && directScheduleJobSubFolders.length > 0 && directSystemSubFolders.length > 0 && directUserSubFolders.length > 0) {
          setSelectedDirectFolder(directSubFolders[0]); 
          setSelectedAzureFolder(""); 
          setSelectedScheduleJobSubFolders(directScheduleJobSubFolders[0]);
          setSelectedSystemSubFolders(directSystemSubFolders[0]);
          setSelectedDirectUserSubFolders(directUserSubFolders[0]);
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders("");
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders("");
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");

        } else {
          setSelectedAzureFolder("");
          setSelectedDirectFolder("");
        }
      }
      else {
        setSelectedAzureFolder("");
        setSelectedDirectFolder("");
      }
    };

    

    const handleCategoryFolders = (event, data) => {
      debugger;
      const selectedCategoryValue = data.value.value;
      setSelectedAzureFolder(selectedCategoryValue);
      setSelectedDirectFolder(selectedCategoryValue);

      const selectedFile = filesWithContent.find(file => file.fileName === data.value .value);

      if(selectedFile) {
        setSelectedFileContent(selectedFile.fileContent);
      }
      else {
        setSelectedFileContent('');

      }

      const selectedAzureSubfolder = azureSubFolders.find((folder) => folder === data.value.value);
      const selectedDirectSubFolder = directSubFolders.find((folder) => folder === data.value.value);
      if(selectedAzureSubfolder) {
        if(selectedAzureSubfolder === 'General'){
          setSelectedGeneralSubFolders(azureGeneralSubFolders[0]);
          setSelectedUserSubFolders("");
          setSelectedFileName("");
        }
        else if (selectedAzureSubfolder === 'User'){
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders(azureUserSubFolders[0]);
          setSelectedFileName("");
        }
        else {
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders("");
          setSelectedFileName("");
        }
      }
      else if (selectedDirectSubFolder) {
        if(selectedDirectSubFolder === 'ScheduleJob'){
          setSelectedScheduleJobSubFolders(directScheduleJobSubFolders[0]);
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");
        }
        else if (selectedDirectSubFolder === 'System') {
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders(directSystemSubFolders[0]);
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");
        }
        else if (selectedDirectSubFolder === 'Direct-User') {
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders(directUserSubFolders[0]);
          setSelectedFileName("");
        }
        else {
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");
        }
      }
      else {
          setSelectedGeneralSubFolders("");
          setSelectedUserSubFolders("");
          setSelectedScheduleJobSubFolders("");
          setSelectedSystemSubFolders("");
          setSelectedDirectUserSubFolders("");
          setSelectedFileName("");
      }
    };
  
    

    const handleCategoryFilesChange = (event, data) => {
      debugger;
      const selectedFileName = data.value.value;
      setSelectedFileName(selectedFileName);

      const selectedFile = filesWithContent.find(file => file.fileName === selectedFileName);

      if(selectedFile) {
        setSelectedFileContent(selectedFile.fileContent);
      } else {
        setSelectedFileContent("");
      }
      
    };

    
    
    ///->
    ////--------------Folder and SubFolders  end---------------///////


    // const handleFileSelect = (fileName) => {
    //   if (fileName) {
    //     const selectedFileData = filesWithContent.find((file) => file.fileName === fileName);
    
    //     if (selectedFileData) {
    //       setSelectedFile(fileName);
    //       setFileContent(selectedFileData.fileContent);
    //     }
    //   }
    //   else {
    //     setSelectedFile("");
    //     setFileContent("");
    //   }
    // };

    //const [index, setIndex] = useState(0);
    const [internalIndex, setInternalIndex] = useState(0);
    const [newContents, setNewContents] = useState([]);

    const handleSaveContent = (updatedText, internalIndex) => {
      setNewContents((prevContents) => {
        const newContentsCopy = [...prevContents];
        newContentsCopy[internalIndex] = updatedText;
        return newContentsCopy;
      });
    };
    

    const sendExecuteScript = async (token) => {
      debugger;
      try {
        if (token && selectedIds.length > 0) {
          const requestData = selectedIds.map((serverId, internalIndex) => ({
            ServerId: serverId,
            PowershellScript: newContents[internalIndex] || '',
          }));
    
          const response = await SettingServerSM.UpdatePowershellScriptData(token, requestData);
          debugger
          if(response) {
            console.log(response);
            const serverNames = response.map((item) => item.ServerName);
            const responses = response.map((item) => item.Response);
            const responseRawData = response.map((item) => item.ResponseData);

            setServerNames(serverNames);
            setResponses(responses);
            setResponseData(response);
            setResponseRawData(responseRawData);
            setVisibleScript(false);
            setVisiblePopup(true);
            setDataFetched(true);
            
          }
        }
      } catch (error) {
        
      }
    };

  const DetailComponent = (responseData) => {
    debugger;
    return (
      responseData.dataItem.ResponseData
  
    );
    
  };

  const expandServerChange = (event) => {
    debugger
    let newData = responseData.map((item) => {
      if (item.ServerName === event.dataItem.ServerName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setResponseData(newData);
  };
    
    const resetGridData = () => {
      setServerNames([]);
      setResponses([]);
      setResponseData([]);
      setDataFetched(false);
    };
    
    const handleClosePopup = () => {
      setVisiblePopup(false);
      resetGridData();
      resetScriptData();
    };


    const ExecuteStatusIcon = (props) => {
      debugger
      const { dataItem } = props;
      const {Response} = dataItem;
      const [hovered, setHovered] = useState(false);
    
      const handleMouseEnter = () => {
        setHovered(true);
      };
    
      const handleMouseLeave = () => {
        setHovered(false);
      };
    
      const renderIconAndText = () => {
        if (Response === 'Failure') {
          return (
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Status size="larger" state="error" icon={<CloseIcon />} />
              {hovered && <span className="hover-text">Failed</span>}
            </div>
          );
        } else if (Response === 'Success') {
          return (
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Status size="larger" state="success" icon={<AcceptIcon />} />
              {hovered && <span className="hover-text">Success</span>}
            </div>
          );
        } else {
          return (
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Loader size="smallest" />
              {hovered && <span className="hover-text">Loading</span>}
            </div>
          );
        }
      };
    
      return (
        <td style={{ textAlign: 'center' }}>
          {renderIconAndText()}
        </td>
      );
    };

    

    const expandChange = (event) => {
      let newData = vmData?.map((item) => {
        if (item.ServerId === event.dataItem.ServerId) {
          item.expanded = !event.dataItem.expanded;
        }

        return item;
      });
      setVMData(newData);
    };

    const authSuccessAddEditServerCallback = (token) => {
      debugger;
      if (token) {
        if(editMode){
          SettingsService.UpdateServer(token, serverData).then((response) => {
            if(response && response.status==200){
              response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                    }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
        else{
          SettingServerSM.AddServer(serverData, token).then((response) => {
            if(response && response.status==200){
              response=response.data;          
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
          else{
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
      }
    };

    const CellIsConnectionConfigured = (props1) => {
      return (
        <td style={{ textAlign: "center" }}>
          <Button
            styles={{ height: "auto" }}
            iconOnly
            size="small"
            icon={props1.dataItem.Status === 1? <Status size="larger" state="success" icon={<AcceptIcon />} /> : props1.dataItem.Status === 0 ?  <Status size="larger" state="error" icon={<CloseIcon />} /> : <Loader  size="smallest"/>}
            text
            onClick={() => null}
          />
        </td>
      );
    };

    const authSuccessUpdateTogglesCallback = (token, serverData) => {
      if (token) {
        SettingsService.UpdateServer(token, serverData).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const [dataSelectionState, setDataSelectionState] = React.useState(
    );

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "InstanceName",
          dir: "asc",
        },
      ],
      take: 10,
      skip: 0,
    });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "DisplayFriendlyName", operator: "contains", value: value },
          { field: "IPAddress", operator: "contains", value: value },
          { field: "ServerTypeName", operator: "contains", value: value },
          { field: "MachineName", operator: "contains", value: value },
          { field: "IHubClientAddress", operator: "contains", value: value },
          { field: "Timezone", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };
    
    useEffect(() => checkIsValid(), [serverData]);

    const authSuccessRemoveServerCallback = (token, serverId) => {
      if (token) {
        let _params = {
          serverId: serverId,
        };
        SettingsService.RemoveServer(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const submitHandler = e => {
      e.preventDefault();
    }

    const handleServerChange = (alwaysNull, data) => {
      let key = data.value.key;
      let serverInfo = key.split('_');
      let serverId = serverInfo[0];
      let isServer = (serverInfo[1] == 'VM' ? false : true);
      SetServerData({ ...serverData, ServerId: serverId, IsServer : isServer });
    };

    const handleAzureVMChange = (checked) => {
      SetServerData({ ...serverData, IsAzureVM: checked });
    };


    React.useEffect(() => {
       props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
      debugger;
       if (token) {
        SettingServerSM.GetAllServersAndVMs(token).then((response) => {
          if(response && response.status==200){
            let serversFound =response.data;
            setServers(serversFound);

            SettingServerSM.GetAllEntries(token).then(
               (response) => {
                debugger;
                if(response && response.status==200){
                  response.data = (response.data).map((item) => ({
                    ...item,
                    IsAzureVM: item.IsAzureVM ? 'Yes':'No',
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  }));

                  setVMData(process(response.data, dataState));
                  if(response.data != null)
                  {
                    for(let i = 0; i < response.data.length; i++)
                      {
                        let serverObj = {IPAddress : response.data[i].IPAddress, Port : response.data[i].Port};
                        props.acquireToken((token) => {
                        SettingServerSM.GetServerOverview(serverObj, token).then((response1) => {
                        response.data[i].Status = response1.data != null && response1.data != '' ? 1 : 0;
                        response.data[i].OverviewDetail = response1.data;
                        response.data = (response.data).map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }));


                        setVMData(process(response.data, dataState));
                      });
                    })    
                    }
                  }
                 }
               }
             );
           }
           else if(response && response.status==401){            
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else{
            props.viewStatus.InformChildPageStatus(500);
          }
          SetLoaded(true);
         });          
       }
    };
    

    const ActionIconVM = (props) => {
      console.log(props.dataItem);
      let serverObj = {IPAddress : '22.33.55.77', Port : 5050};

    

    return (
        <td 
        style={{ textAlign: "center" }}>
          {(props.dataItem.OverviewDetail != null && props.dataItem.OverviewDetail != ''? 
            <Link
            to={"/serversm/serverOverview"}
            state={{data: {...props.dataItem.OverviewDetail, IPAddress : props.dataItem.IPAddress, Port : props.dataItem.Port}}}>
              <InfoIcon />
          </Link>
          : 
          <Button
            styles={{ height: "auto" }}
            iconOnly
            size="small"
            icon={props.dataItem.Status === 1? <Status size="larger" state="success" icon={<AcceptIcon />} /> : props.dataItem.Status === 0 ?  <Status size="larger" state="error" icon={<CloseIcon />} /> : <Loader  size="smallest"/>}
            text
            onClick={() => null}
          />
          )}

            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        </td>
    );
    }

    const removeData = () => {
       if (deleteServerObj != null) {
         props.acquireToken((token) =>
           authSuccessRemoveServerCallback(token, deleteServerObj.ServerId)
         );
       }
       setDeleteConfirmationDialog(false);
       setDeleteServerObj(null);
    };

    const getDetail = (dataItem, isEdit) => {

       <Link to={{ 
        pathname: '/components/pages/serversm/serverOverview',
        state: "3666"
       }}>
        Register
       </Link>
    };

    const handleAddEditClickOpen = (dataItem, isEdit) => {
      setVisible(true);
      if (isEdit === true) {
        SetServerData(dataItem);
        setEditMode(true);
      }
    };

    const resetScriptData = () => {
      setSelectedPowershellSubFolder("");
      setSelectedAzureFolder("");
      setSelectedDirectFolder("");
      setSelectedFileContent("");
      setSelectedFileName("");
      
    };

    const handleScriptClickOpen = (dataItem, isEdit) => {
      setVisibleScript(true);
    };

    const handleAzureClickOpen = () => {
      setVisibleAzure(true);
    };

    const handleAzureClcikClose = () => {
      setVisibleAzure(false);
      resetScriptData();
    };

    const handleDirectClickOpen = () => {
      setVisibleDirect(true);
    }

    const handleDirectClcikClose = () => {
      setVisibleDirect(false);
      resetScriptData();
    }


    const handleAddEditClickClose = () => {
      setVisible(false);
      SetServerData(null);
      setEditMode(false);
      setIsValid(false);
    };

    const checkIsValid = () => {
      if (
        serverData?.ServerId !== undefined &&
        serverData?.ServerId?.length > 0 &&
        serverData?.Port !== undefined  &&
        serverData?.Port?.length > 0
      ) 
      {
        setIsValid(true);
      } else setIsValid(false);
    };

    const [selectedState, setSelectedState] = React.useState({});
    const [azureButtonDisabled, setAzureButtonDisabled] = useState(true);
    const [selectedDataItems, setSelectedDataItems] = useState([]);
    const onSelectionChange = React.useCallback(
      (event) => {
        debugger;
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        const dataItem = event.dataItem;
        let updatedSelectedDataItems = null;
        if(newSelectedState[event.dataItem.Id] === false)
        {
          updatedSelectedDataItems = selectedDataItems.filter(function (obj) {
            return obj.Id != event.dataItem.Id;
        });
        }
        else{
          updatedSelectedDataItems = [...selectedDataItems, dataItem];
        }

        setSelectedDataItems(updatedSelectedDataItems);

        const areAllAzureVMSelected = updatedSelectedDataItems.length > 0 ? updatedSelectedDataItems.every(
          (item) => item.IsAzureVM === "Yes"
          ) : false;

          debugger;
        setAzureButtonDisabled(!areAllAzureVMSelected);
        
      },
      [selectedState, selectedDataItems]
    );

    const selectedIds = Object.keys(selectedState)
      .filter((id) => selectedState[id] === true)
      .map((id) => Number(id));
    console.log(selectedIds);


    const onHeaderSelectionChange = React.useCallback((event) => {
      debugger;
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    }, []);


    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="f-size-18">SERVER & VM DETAILS</div>
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "ServerVM.ReadWrite" 
                    ) && (
                      <Button
                        icon={<EditIcon />}
                        iconPosition="before"
                        disabled={azureButtonDisabled}
                        content="Azure"
                        primary
                        title="Azure"
                        onClick={handleAzureClickOpen}
                        className="ml-auto"
                      />
                    )}
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "ServerVM.ReadWrite" 
                    ) && (
                      <Button
                        icon={<EditIcon />}
                        iconPosition="before"
                        disabled={!selectedIds.length > 0}
                        content="Direct"
                        primary
                        title="Direct"
                        onClick={handleDirectClickOpen}
                        className="ml-2"
                      />
                    )}
                
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "ServerVM.ReadWrite" 
                    ) && (
                      <Button
                        icon={<AddIcon />}
                        iconPosition="before"
                        content="Add Entry"
                        primary
                        title="Add Entry"
                        onClick={handleAddEditClickOpen}
                        className="ml-2"
                      />
                    )}
                  </div>
          
                  <div>
                    {visibleAzure && (
                      <Dialog
                        title={
                          editMode ? "Edit Script" : "Add New Script"
                        }
                        width="640px"
                        height="590px"
                        onClose={handleAzureClcikClose}
                      >
                        <Form onSubmit={submitHandler}>
                          <div className='h-100' style={{ maxHeight: "590px", overflowY: "auto" }}>
                            <div className='row mx-auto mb-0'>
                              <div className='col-12 col-md-6 mb-3'>
                                <FormDropdown
                                  label={{
                                    content: `Category`,
                                    id: "Azure-inline",
                                  }}
                                  items={azureSubFolders.map((folder) => ({
                                    header: folder,
                                    value: folder,
                                  }))}
                                  placeholder="Choose type"
                                  required
                                  autosize={true}
                                  value={selectedAzureFolder}
                                  onChange={handleCategoryFolders}
                                />
                              </div>
                            </div>
                            <div className='row mx-auto mb-0'>
                              <div className='col-12 col-md-6 mb-3'>
                                {selectedAzureFolder === 'General' &&(
                                  <FormDropdown
                                    label={{
                                      content: `Category-files`,
                                      id: "Azure-inline",
                                    }}
                                    items={azureGeneralScriptFiles.map((file) => ({
                                      header: file,
                                      value: file,
                                    }))}
                                    placeholder="Choose Script file"
                                    required
                                    autosize={true}
                                    value={selectedFileName}
                                    onChange={handleCategoryFilesChange}
                                  />
                                )}
                                {selectedAzureFolder === 'User' && (
                                  <FormDropdown
                                    label={{
                                      content: `Category-files`,
                                      id: "Direct-inline",
                                    }}
                                    items={azureUserScriptFiles.map((file) => ({
                                      header: file,
                                      value: file,
                                    }))}
                                    placeholder="Choose Script file"
                                    required
                                    autosize={true}
                                    value={selectedFileName}
                                    onChange={handleCategoryFilesChange}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='row mx-auto mb-0'>
                              <div className='col mb-3'>
                                {selectedFileName && (
                                  // <FormTextArea                          
                                  //   label="Write PowerShell script here"
                                  //   name="Write PowerShell script here"
                                  //   id="RDPPort-inline"
                                  //   fluid
                                  //   required
                                  //   readOnly
                                  //   maxLength={4000}
                                  //   value={selectedFileContent}
                                  //   //onChange={(value) => setPowerShellScript(value.target.value)}
                                  //   style={{ height: "200px" }}
                                  // />
                                  //<EditableScriptTextarea scriptText={selectedFileContent} onSave={(updatedText) => handleSaveContent(updatedText, index)} index={index} />
                                  <EditableScriptTextarea
                                    scriptText={selectedFileContent}
                                    onSave={(updatedText) => handleSaveContent(updatedText, internalIndex)}
                                    index={internalIndex}
                                  />
                                )}
                                
                              </div>
                            </div>
                          </div>
                          
                          <DialogActionsBar>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={sendExecuteScriptHandler}
                              >
                                {" "}
                                Execute
                              </button>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={handleAzureClcikClose}
                              >
                                {" "}
                                Close
                              </button>
                            
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                    
                  </div>
                  <div>
                    {visibleDirect && (
                      <Dialog
                        title={
                          editMode ? "Edit Script" : "Add New Script"
                        }
                        width="640px"
                        height="590px"
                        onClose={handleDirectClcikClose}
                      >
                        <Form onSubmit={submitHandler}>
                          <div className='h-100' style={{ maxHeight: "590px", overflowY: "auto" }}>
                            <div className='row mx-auto mb-0'>
                              <div className='col-12 col-md-6 mb-3'>
                                <FormDropdown
                                  label={{
                                    content: `Category`,
                                    id: "Direct-inline",
                                  }}
                                  items={directSubFolders.map((folder) => ({
                                    header: folder,
                                    value: folder,
                                  }))}
                                  placeholder="Choose type"
                                  required
                                  autosize={true}
                                  value={selectedDirectFolder}
                                  onChange={handleCategoryFolders}
                                />
                              </div>
                            </div>
                            <div className='row mx-auto mb-0'>
                              <div className='col-12 col-md-6 mb-3'>
                                {selectedDirectFolder === 'ScheduleJob' && (
                                  <FormDropdown
                                    label={{
                                      content: `Category-files`,
                                      id: "Azure-inline",
                                    }}
                                    items={directScheduleJobScriptFiles.map((file) => ({
                                      header: file,
                                      value: file,
                                    }))}
                                    placeholder="Choose Script file"
                                    required
                                    autosize={true}
                                    value={selectedFileName}
                                    onChange={handleCategoryFilesChange}
                                  />
                                )}
                                {selectedDirectFolder === 'System' && (
                                  <FormDropdown
                                    label={{
                                      content: `Category-files`,
                                      id: "Direct-inline",
                                    }}
                                    items={directSystemScriptFiles.map((file) => ({
                                      header: file,
                                      value: file,
                                    }))}
                                    placeholder="Choose Script file"
                                    required
                                    autosize={true}
                                    value={selectedFileName}
                                    onChange={handleCategoryFilesChange}
                                  />
                                )}
                                {selectedDirectFolder === 'Direct-User' && (
                                  <FormDropdown
                                    label={{
                                      content: `Category-files`,
                                      id: "Direct-inline",
                                    }}
                                    items={directUserScriptFiles.map((file) => ({
                                      header: file,
                                      value: file,
                                    }))}
                                    placeholder="Choose Script file"
                                    required
                                    autosize={true}
                                    value={selectedFileName}
                                    onChange={handleCategoryFilesChange}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='row mx-auto mb-0'>
                              <div className='col mb-3'>
                                {selectedFileName && (
                                  // <FormTextArea                          
                                  //   label="Write PowerShell script here"
                                  //   name="Write PowerShell script here"
                                  //   id="RDPPort-inline"
                                  //   fluid
                                  //   required
                                  //   readOnly
                                  //   maxLength={4000}
                                  //   value={selectedFileContent}
                                  //   //onChange={(value) => setPowerShellScript(value.target.value)}
                                  //   style={{ height: "200px" }}
                                  // />
                                  //<EditableScriptTextarea scriptText={selectedFileContent} />
                                  <EditableScriptTextarea
                                    scriptText={selectedFileContent}
                                    onSave={(updatedText) => handleSaveContent(updatedText, internalIndex)}
                                    index={internalIndex}
                                  />
                                )}
                                
                              </div>
                            </div>
                          </div>
                          
                          <DialogActionsBar>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={sendExecuteScriptHandler}
                              >
                                {" "}
                                Execute
                              </button>
                              <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={handleDirectClcikClose}
                              >
                                {" "}
                                Close
                              </button>
                            
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>

                  <div>
                    <Modal
                      isOpen={visiblePopup}
                      onRequestClose={handleClosePopup}
                      ariaHideApp={false} 
                      contentLabel="Powershell Script"
                      shouldCloseOnOverlayClick={false}
                      style={{
                        overlay: {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        content: {
                          width: "640px", 
                          height: "590px",
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)', 
                          padding: '20px',
                          textAlign: 'center',
                        },
                      }}
                    >
                      <div className="popup-content">
                        <h2>Powershell Script</h2>
                        {dataFetched ? (
                          <>
                          <Grid
                            style={{
                              height: "400px",
                            }}
                            pageable={{
                              buttonCount: 3,
                            }}
                            sortable={true}
                            data={responseData}
                            dataItemKey="ServerId"
                            detail={DetailComponent}
                            expandField="expanded"
                            onExpandChange={expandServerChange}
                          >
                            <Column field="ServerName" title="Server Name" width={200} />
                            {!context.mediaCategory.sm && (
                              <Column field="Response" title="Response" width={200} cell={ExecuteStatusIcon} />
                            )}
                          </Grid>
                          <div style={{marginTop: '10px', float: 'right'}}>
                            <button
                                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                  onClick={handleClosePopup}
                            >
                              {" "}
                              Close
                            </button>
                          </div>
                          </>
                        ) : (
                          <Loader />
                        )}
                        {/* <div style={{marginTop: '10px', float: 'right'}}>
                          <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={handleClosePopup}
                          >
                            {" "}
                            Close
                          </button>
                        </div> */}
                      </div>
                    </Modal>
                  </div>
                  
                  <div>
                    {visible && (
                      <Dialog
                        title={
                          editMode ? "Edit Server & VM Port" : "Add New Server/VM with port"
                        }
                        onClose={handleAddEditClickClose}
                      >
                        <Form onSubmit={submitHandler}>
                        <FormDropdown
                            items={servers.map((obj) => {
                              let keyT = obj.IsServer == true ? obj.Id + "_Server" : obj.Id + "_VM";
                              return {
                                header: obj.EntityName,
                                value: obj.EntityName,
                                key: keyT
                              };
                            })}
                            aria-labelledby={"Region-inline"}
                            placeholder="Choose Server/VM"
                            required
                            search = "true"
                            autosize="true"
                            value={serverData?.Name}  
                            onChange={handleServerChange}
                          />
                          <FormInput
                            label="RDPPort"
                            name="RDPPort"
                            id="RDPPort-inline"
                            fluid
                            required
                            maxLength={4}
                            value={serverData?.Port}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                Port: value.target.value,
                              })
                            }
                          />
                          <FormCheckbox
                            className='checkBoxMT'
                            label="Azure VM"
                            id="IsAzureVM"
                            value={serverData?.IsAzureVM}
                            checked={serverData?.IsAzureVM}
                            onChange={(e, { checked }) => {
                              handleAzureVMChange(checked);
                            }}
                          />
                          
                          <DialogActionsBar>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditSubmitClickHandler}
                              disabled={isValid === false}
                            >
                              {" "}
                              Submit
                            </button>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              onClick={handleAddEditClickClose}
                            >
                              {" "}
                              Close
                            </button>
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3,
                      }}
                      sortable={true}
                      data={vmData?.data?.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                      }))}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}

                      dataItemKey={DATA_ITEM_KEY}
                      selectedField={SELECTED_FIELD}
                      selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                      }}
                      onSelectionChange={onSelectionChange}
                      onHeaderSelectionChange={onHeaderSelectionChange}
              
                    >
                      <Column
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                          vmData && vmData.data && vmData?.data?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        }
                      />
                      <Column
                        field="Name"
                        title="Server Name"
                        width={140}
                      />
                                       
                      <Column
                        field="IPAddress"
                        title="IP Address Name"
                        width={140}
                      />
  
                      {!context.mediaCategory.sm && (
                        <Column field="Port" title="RDP Port" width={100} />
                      )}

                      <Column
                        field="IsAzureVM"
                        title="IsAzureVM"
                        width={100}
                      />

                      {props.user?.Permissions?.some(
                        (p) =>
                          p == "ServerVM.ReadWrite" 
                      ) && (
                        
                        <Column
                          field=""
                          title="Actions"
                          cell={ActionIconVM}
                          width={100}
                        />
                      )}
                    </Grid>
                  </div>
                </CardBody>
              </Card>
            </div>
            <FluentDialog
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setDeleteConfirmationDialog(false);
                setDeleteServerObj(null);
              }}
              onConfirm={() => removeData()}
              open={openDeleteConfirmationDialog}
              backdrop
              header="Action confirmation"
              headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => {
                  setDeleteConfirmationDialog(false);
                  setDeleteServerObj(null);
                },
              }}
              content={`Are you sure to delete Server: ${deleteServerObj?.DisplayFriendlyName}?`}
            />
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
  }

export { SettingsServerAndVM };