
import React, { useState } from 'react';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, Text, Flex } from '@fluentui/react-northstar';
import MonitoringService from '../../../../services/monitoringservice';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { useSearchParams, useParams } from 'react-router-dom';
import { WindowContext } from "../../../../shared/contexts/Context";
import { process } from '@progress/kendo-data-query';



import { CellIncidentHistory, CellNumberedStringList, CellAccessIcon, CellIncidentLevel, CellInstanceOverview, CellAccessSPPortal, CellCountGroup, rowRender, CellDateTime, CellComments, CellIncidentStatus, CellIncidentOwner, CellInstanceOverviewLabel, CellColTitle, CellIncidentId } from '../../../../shared/appcomponents/custom-grid-cells';
import { INCIDENTLEVELS,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';


const ServiceAlarmHistorical = (props) => {
  const { id, type } = useParams();


  const [searchParams] = useSearchParams();
  const [alarmType, setAlarmType] = React.useState(null);
  const [incidents, setIncidents] = React.useState(null);
  const [isUpdating, setUpdating] = React.useState(false);
  const [IncidentStatuses, setIncidentStatuses] = React.useState(null);
  const [AppUsers, setAppUsers] = React.useState(null);

  const [hide, setHide] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [isLoaded, setLoaded] = React.useState(false);
  const [serviceState, setserviceState] = React.useState(null);

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "IncidentTimestampUTC",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });
  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if (_state == null) {
      _state = -1;
    }
    setserviceState(_state);
    props.incidents?.Table?.map(m => m.IsUpdating = false);
    setIncidents(props.incidents?.Table);
    setIncidentStatuses(props.incidents?.IncidentStatuses);
    setAppUsers(props.incidents?.AppUsers);
    setLoaded(true);
  }, [props.incidents]);
  const CellIncidentHistoryCol = (prop) => {
    return (

      <CellIncidentHistory {...prop} IncidentSection={3} acquireToken={props.acquireToken} />

    );
  }
  const CellCommentsCol = (prop) => {
    return (
     
          <CellComments {...prop} user={props.user} IncidentSection={3} acquireToken={props.acquireToken} />
      
    );
  }

  const CellIncidentStatusCol = (prop) => {
    return <CellIncidentStatus {...prop} user={props.user} incidentStatus={IncidentStatuses}
      onChange={() => {
        props.acquireToken((token) => {
          setUpdating(true);
          prop.dataItem.IncidentOwner = props.account.username;
          UpdateIncedent(token, prop.dataItem);
        })
      }}
    />
  }

  const UpdateIncedent = (token, dataItem) => {
    let _params = {
      IncidentSection: 3,
      IncidentId: dataItem.IncidentId,
      IncidentOwner: dataItem.IncidentOwner,
      IncidentStatusId: dataItem.IncidentStatusId,
      IsHistoricalUpdate:"1"
    }

    MonitoringService.UpdateIncident(token, _params).then((response) => {
      if(response && response.status==200){
        response=response.data;
        setUpdating(false);
        if(dataItem.IncidentStatusId!==3){
          let array = [...incidents];
            let index= array.findIndex(element => element.IncidentId === dataItem.IncidentId)
            if (index !== -1) {
                array.splice(index, 1);
                setIncidents(array);
              }
        }
       // props.routeChange('/dashboards/monitoring/service/alarms?type=critical', 'critical', 1);
        // RefeshData(null);
     
 props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
}
else if(response && response.status==401){
    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
  }
  else {
    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
  }
      dataItem.IsUpdating=false;    
      if(dataItem.IncidentStatusId===3){
      let arr = [...incidents];
      setIncidents(arr);  }
    });
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'ServiceName', operator: 'contains', value: value },
        { field: 'ServiceDisplayName', operator: 'contains', value: value },
        { field: 'DCName', operator: 'contains', value: value },
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'Platform', operator: 'contains', value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setIncidents(null);
    setIncidentStatuses(null);
    setAppUsers(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: serviceState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetServiceIncidents(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            updateDetailData(response);
}
else if(response && response.status==401){
  props.viewStatus.InformChildPageStatus(response.status);
  }
  else {
    props.viewStatus.InformChildPageStatus(500);
  }
          setLoaded(true);
        });
      }
    })
  }
  const updateDetailData = (response) => {
    response.Table?.map(m => m.IsUpdating = false);

    setIncidents(response.Table);
    setIncidentStatuses(response.Table);
    setAppUsers(response.Table);
  }



  


  return <WindowContext.Consumer>
    {(context) => (
      <>
        <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
          <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}

            defaultAppliedFilter={
              type
                ? [
                  { filter: "Instance", operator: "==", value: id },
                  {
                    filter: "ServiceType",
                    operator: "==",
                    value: type.split(",").map((i) => Number(i)),
                  },
                ]
                : { filter: 'Instance', operator: '==', value: id }
            }
            //defaultAppliedFilter={{filter: 'Instance', operator: '==', value: id}} 
            elegibleFilters={['Instance', 'Platform', 'ServiceType']} />

          <Flex.Item push>
            <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
              <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
            </div>
          </Flex.Item>
        </Flex>
        <div className='w-100 no-bg'>
          {
            isLoaded && incidents ?
              (
                <Grid
                  style={{
                    height: "456px",
                  }}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
                  data={process(incidents, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }}
                  rowRender={rowRender}
                >
                  <Column field="IncidentId" title="Incident Ref#" locked={true} cell={CellIncidentId} width={120} />
                  <Column field="IncidentTimestampUTC" title="Timestamp UTC" locked={true} cell={CellDateTime} width={200} />
                  <Column field="MachineName" title="Server Name" locked={true} width={200} />
                  <Column field="ServiceDisplayName" title="Service Display Name" locked={true} width={400} />
                  <Column field="InstanceName" title="Instance" width={180} cell={CellInstanceOverviewLabel} />
                  <Column field="AlertTypes" title="Rule Breached" cell={CellNumberedStringList} width={220} />
                  <Column field="LastUpdated" title="Last Alert Time" cell={CellDateTime} width={200} />
                  <Column field="IncidentId" title="Incident History" cell={CellIncidentHistoryCol} width={150} />
                  <Column field="IncidentStatusId" title="Status" cell={CellIncidentStatusCol} width={150} />
                  <Column field="ClearedTimeStamp" title="Cleared TimeStamp" cell={CellDateTime} width={180} />
                  <Column field="AssignedUserId" title="Owner" width={150} />
                  <Column field="IncidentId" title={<CellColTitle title="Comments" />} cell={CellCommentsCol} width={110} filterable={false} sortable={false}  />
                  <Column field="IncidentLevel" title="Incident Level" width={150} /> 
                </Grid>
              )
              :
              <Loader />
          }
        </div>
      </>
    )}
  </WindowContext.Consumer>
};


export { ServiceAlarmHistorical }