import React from 'react'
import { CloseIcon } from "@fluentui/react-icons-northstar";
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, Text, Dialog, Flex } from '@fluentui/react-northstar';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { Outlet, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { CellCountGroup, CellAccessSPPortal, CellInstanceOverview, CellInstanceDetail, CellDateTime, CellInstanceOverviewLabel, CellReportingTenantStatus, CellRecordingTenantStatus, CellCountGroupClickable, CellColTitle } from '../../../../shared/appcomponents/custom-grid-cells';
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../../../shared/contexts/Context";
const PortalSummary = (props) => {
  const { id } = useParams();
  const [summary, setSummary] = React.useState(null);
  const [summaryTable, setsummaryTable] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [instanceState, setInstanceState] = React.useState(null);
  const [searchParams] = useSearchParams();
  const [spDialogOpen, setSPDialogOpen] = React.useState(false);
  const [spDialogDataItem, setSPDialogDataItem] = React.useState(null);
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const CellAccessServiceProvider = (props1) => {
    return <CellAccessSPPortal onClick={() => {
      props.acquireToken((token) => {
        setSPDialogOpen(true);
        getSPUrl(token, props1.dataItem);
      })
    }} text={props1.text} />
  }

  const getSPUrl = (token, dataItem) => {
    let _host = dataItem.AccessSPPortal.split('/')[2];
    let _url = "http://" + _host + "/serviceprovider/token.aspx";
    if (_host.toString().lastIndexOf('call-analytics.co.uk') > 0)
      _url = "https://" + _host + "/serviceprovider/token.aspx";

    MonitoringService.GetServiceProviderToken(token, { host: _host }).then((response) => {
      if(response && response.status==200){
        response=response.data;
        let t = JSON.stringify(response);
        t = t.replace('"', '');
        t = t.replace('"', '');
        if (response.length > 5 && response.length < 50) {
          dataItem.SPPortalLoginUrl = _url + "?token=" + t;
        }
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
      }
      else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      setSPDialogDataItem(dataItem);
    });
  }

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InstanceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  React.useEffect(() => {
    props.acquireToken((token) => {
      setLoaded(false);
      let _alarmType = searchParams.get('state');
      let _state = INCIDENTLEVELS[_alarmType];
      if (_state == null) {
        _state = -1;
      }
      setInstanceState(_state);
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: _state,
        appliedFilters: id ? [`InstanceId IN (${id})`] : []
      }
      if (token) {
        MonitoringService.GetDataSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setSummary(response);
            setsummaryTable(response.Table);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }
          setLoaded(true);
        });
      }
    });
  }, [id]);

  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if (_state == null) {
      _state = -1;
    }
    setInstanceState(_state);
  }, [searchParams]);

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setSummary(null);
    setsummaryTable(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: instanceState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetDataSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setSummary(response);
            setsummaryTable(response.Table);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }
          setLoaded(true);
        });
      }
    })
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'Platform', operator: 'contains', value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const expandChange = (event) => {
    let newData = summaryTable.map((item) => {
      if (item.InstanceName === event.dataItem.InstanceName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setsummaryTable(newData);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>Platform: {dataItem.Platform}</td>
          </tr>
          <tr>
            <CellDateTime {...props1} field={"InstanceTime"} text={"InstanceTimeUTC:"} />
          </tr>
          <tr>
            <CellDateTime {...props1} field={"LastCallIn"} text={"LastCallInsertedUTC:"} />
          </tr>
          <tr>
            <CellDateTime {...props1} field={"LastRecordingIn"} text={"LastRecordingInsertedUTC:"} />
          </tr>
          <tr>
            <td>Stuck Calls (Data_CTI): {dataItem.StuckCalls}</td>
          </tr>
          <tr>
            <CellCountGroupClickable {...props1} field={"ReportingTenants"} text={"ReportingTenants:"} pass={"0"} routesection={"portal"} />
          </tr>
          <tr>
            <CellCountGroupClickable {...props1} field={"RecordingTenants"} text={"RecordingTenants:"} pass={"1"} routesection={"portal"} />
          </tr>
          <tr>
            <CellInstanceDetail  {...props1} field={"DataDetail"} text={"Data Detailed Page:"} />
          </tr>
          <tr>
            <CellAccessServiceProvider {...props1} field={"AccessSPPortal"} text={"SPPortal:"} />
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <WindowContext.Consumer>
      {(context) => (
        <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
          <Card.Header>
            <div className='d-flex'>
              <div className='f-size-20'>Data Summary</div>
            </div>
          </Card.Header>
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <Card
                onClick={() => routeChange(`/dashboards/monitoring/portal/summary${id ? '/' + id : ''}`)}
                className={`card-border bg-white br-0 pt-1 p-0 card-action ${instanceState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                <div className='w-100 text-center'>
                  <div className='f-size-15'>Total Instances</div>
                  <div className='f-size-26'>{summary ? summary.Total : '-'}</div>
                </div>
              </Card>
            </div>
            <div className='col-md-3 mb-3'>
              <Card
                onClick={() => routeChange(`/dashboards/monitoring/portal/summary${id ? '/' + id : ''}?state=healthy`)}
                className={`card-border bg-success br-0 pt-1 p-0 card-action ${instanceState == 0 ? 'selected' : ''}`} fluid ghost elevated>
                <div className='w-100 text-center text-white'>
                  <div className='f-size-15'>Healthy</div>
                  <div className='f-size-26'>{summary ? summary.Healthy : '-'}</div>
                </div>
              </Card>
            </div>
            <div className='col-md-3 mb-3'>
              <Card
                onClick={() => routeChange(`/dashboards/monitoring/portal/summary${id ? '/' + id : ''}?state=warning`)}
                className={`card-border bg-warning br-0 pt-1 p-0 card-action ${instanceState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                <div className='w-100 text-center text-white'>
                  <div className='f-size-15'>Warning</div>
                  <div className='f-size-26'>{summary ? summary.Warning : '-'}</div>
                </div>
              </Card>
            </div>
            <div className='col-md-3 mb-3'>
              <Card
                onClick={() => routeChange(`/dashboards/monitoring/portal/summary${id ? '/' + id : ''}?state=critical`)}
                className={`card-border bg-danger br-0 pt-1 p-0 card-action ${instanceState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                <div className='w-100 text-center text-white'>
                  <div className='f-size-15'>Critical</div>
                  <div className='f-size-26'>{summary ? summary.Critical : '-'}</div>
                </div>
              </Card>
            </div>
          </div>
          <Card.Body>
            <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
              <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                defaultAppliedFilter={{ filter: 'Instance', operator: '==', value: id }}
                elegibleFilters={['Instance', 'Platform', 'TenantRange']} />
              <Flex.Item push>
                <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                  <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                </div>
              </Flex.Item>
            </Flex>
            <div className='w-100'>
              {
                isLoaded ?
                  (
                    <KendoGrid
                      style={{
                        height: "456px",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3
                      }}
                      sortable={true}
                      data={process(summaryTable.filter(tr => {
                        if (instanceState == -1) { return true }
                        else if (instanceState != -1 && tr.Health == instanceState) { return true }
                        return false;
                      }), dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                      // detail={
                      // context.mediaCategory.sm ? DetailComponentSm : null
                      // }
                      expandField="expanded"
                      onExpandChange={expandChange}
                    >
                      <Column field="InstanceName" title="Instance" cell={CellInstanceOverviewLabel} locked={true} width={150} />
                      <Column field="IndustryName" title="Industry" width={140} />
                      <Column field="domain" title="Main Domain" width={180} />
                      <Column field="InstanceUsersCount" title="Size" width={80} />                      
                      <Column field="Platform" title="Platform" locked={true} width={150} style={{ textAlign: "center" }} />
                      <Column field="InstanceTime" title={<CellColTitle title="UTC" />} cell={CellDateTime} locked={true} width={150} />
                      <Column field="LastCallIn" title={<CellColTitle title="Last Call Inserted UTC" />} cell={CellDateTime} width={180} />
                      <Column field="LastRecordingIn" title={<CellColTitle title="Last Recording Inserted UTC" />} cell={CellDateTime} width={220} />
                      <Column field="StuckCalls" title={<CellColTitle title="Stuck Calls (Data_Cti)" style={{ textAlign: "center" }} />} width={100} />
                      <Column field="TotalTenants" title={<CellColTitle title="Total Tenants" style={{ textAlign: "center" }} />} width={100} />
                      <Column field="ReportingTenants" title={<CellColTitle title="Reporting Tenants" />} cell={CellReportingTenantStatus} width={200} filterable={false} sortable={false} />
                      <Column field="RecordingTenants" title={<CellColTitle title="Recording Tenants" />} cell={CellRecordingTenantStatus} width={200} filterable={false} sortable={false} />
                      <Column field="DataDetail" title={<CellColTitle title="Detailed Page" />} cell={CellInstanceDetail} width={110} filterable={false} sortable={false} />
                      <Column field="AccessSPPortal" title={<CellColTitle title="SP Portal" />} cell={CellAccessServiceProvider} width={85} filterable={false} sortable={false} />
                      <Column field="InstanceOverview" title={<CellColTitle title="Instance Overview" />} cell={CellInstanceOverview} width={160} filterable={false} sortable={false} />

                    </KendoGrid>
                  )
                  :
                  <Loader />
              }
            </div>
            <Dialog
              open={spDialogOpen}
              onCancel={() => { setSPDialogOpen(false); setSPDialogDataItem(null); }}
              onConfirm={() => { window.open(spDialogDataItem?.SPPortalLoginUrl, '_blank') }}
              confirmButton={spDialogDataItem && spDialogDataItem.SPPortalLoginUrl ? "Visit Page" : null}
              cancelButton="Close"
              content={<Text content={spDialogDataItem ? (spDialogDataItem.SPPortalLoginUrl ? `Please click on Visit button to access ${spDialogDataItem?.InstanceName}` : `Could not retrieve token for ${spDialogDataItem?.InstanceName}`) : "Retrieveing token..."} />}
              header="Service Provider Access"
              headerAction={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: () => setSPDialogOpen(false),
              }} />
          </Card.Body>
        </Card>
      )}
    </WindowContext.Consumer>
  );
};


export { PortalSummary }

