import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, FormDropdown, FormCheckbox,
	Tooltip, InfoIcon
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellConfigurationState } from "../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const CUSTOMERPRIORITYLEVELS = [
	{ key: 1, header: 'true', value: true },
	{ key: 0, header: 'false', value: false }
]
const SettingsServiceAlert = (props) => {
	const [serviceAlerts, setserviceAlerts] = React.useState(null);
	const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "alertId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);
	}, []);

	const authSuccessCallback = (token) => {
		if (token) {
			SettingsService.GetServiceAlerts(token).then((response) => {
				if (response && response.status == 200) {
					response = response.data;
					setserviceAlerts(response);
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};

	const filterGridData = (value) => {
		let filter = {
			logic: "or",
			filters: [{ field: "alertId", operator: "contains", value: value },
			{ field: "cancelID", operator: "contains", value: value },
			{ field: "details", operator: "contains", value: value },
			{ field: "Steps", operator: "contains", value: value }],
		};
		setDataState({ ...dataState, filter: filter });
	};

	const handleAddEditClickOpen = (dataItem, isEdit) => {
		SetisAlertIdValid(true);
		setVisible(true);


		if (isEdit === true) {
			setalertData(dataItem);
			setEditMode(true);
			setIsAlertIdDisabled(true);
			// checkIsValid();
		}
		else {
			setalertData(null);
			setIsAlertIdDisabled(false);
		}
	};

	const handleAddEditClickClose = () => {
		setVisible(false);
		setalertData(null);
		setEditMode(false);
		setIsValid(false);
	};

	const ActionIconVersions = (props) => {
		return (
			<td style={{ textAlign: "center" }}>
				<Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)} />
				<Button
					styles={{ height: "auto" }}
					iconOnly
					size="small"
					icon={<TrashCanIcon />}
					text
					onClick={() => {
						setDeleteAlertObj(props.dataItem);
						setDeleteConfirmationDialog(true);
					}}
				/>
			</td>
		);
	};

	const removeData = () => {
		if (deleteAlertObj != null) {
			props.acquireToken((token) => {
				if (token) {
					let _params = {
						AlertId: deleteAlertObj.alertId,
					};
					SettingsService.RemoveServiceAlert(_params, token).then(
						(response) => {
							if (response && response.status == 200) {
								response = response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}
							else if (response && response.status == 401) {
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

							}
							else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						}
					);
				}
			});
		}
		setDeleteConfirmationDialog(false);
		setDeleteAlertObj(null);
	};

	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		if (isValid == true) {
			props.acquireToken((token) => {
				if (token) {
					if (editMode) {
						SettingsService.EditServiceAlert(alertData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
							else if (response && response.status == 200) {
								response = response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							} else if (response && response.status == 401) {
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

							}
							else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						});
					} else {
						SettingsService.AddServiceAlert(alertData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
							if (response && response.status == 200) {
								response = response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							} else if (response && response.status == 401) {
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

							}
							else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						});
					}
				}
				setVisible(false);
				setEditMode(false);
				setIsValid(false);
			});
		}

	};

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>
					<Text weight="bold" content="Cancel ID:" />
					<Text content={dataItem.cancelID} />
					<Text weight="bold" content="Service Name:" />
					<Text content={dataItem.serviceName} />
					<Text weight="bold" content="Details:" />
					<Text content={dataItem.details} />
					<Text weight="bold" content="Steps:" />
					<Text content={dataItem.Steps} />
					<Text weight="bold" content="Tenanted Alert:" />
					{
						props1.dataItem.isTenanted ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}
					<Text weight="bold" content="Call Data Alert:" />
					{
						props1.dataItem.isCallDataAlert ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}

				</FluentGrid>
			</section>
		);
	};

	const CheckForm = () => {
		let isValid = true;

		if (alertData?.alertId && alertData?.alertId.toString().length > 0) {
			isValid = true;
		}
		else {
			isValid = false;
			SetisAlertIdValid(false);
		}

		return isValid;

	};

	const expandChange = (event) => {
		let newData = serviceAlerts.map((item) => {
			if (item.alertId === event.dataItem.alertId) {
				item.expanded = !event.dataItem.expanded;
			}

			return item;
		});
		setserviceAlerts(newData);
	};


	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100">
						<div className="col-md-12 h-100">
							<Card className="card-border bg-white h-100" fluid>
								<CardHeader className="mb-2">
									<div className="d-flex justify-content-between">
										<div className="f-size-18">Service Alerts</div>
										{props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) &&

											<Button
												icon={<AddIcon />}
												iconPosition="before"
												content="Add Service Alert"
												primary
												title="Add Service Alert"
												onClick={handleAddEditClickOpen} className="ml-auto"
											/>}
									</div>
									<div>
										{visible && (
											<Dialog
												className="k-dialog-md"
												title={editMode ? "Edit Service Alert" : "Add Service Alert"}
												onClose={handleAddEditClickClose}
											>
												<Form
												// onSubmit={submitHandler}
												>
													<FormInput
														label={isAlertIdValid == true ? "Alert ID* " : <Text color="red" content="Alert ID *Required" />}
														id="alertId-inline"
														fluid
														disabled={isAlertIdDisabled}
														value={alertData?.alertId}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
														onChange={(value) => {
															if (value.target.value && value.target.value.length > 0) {
																SetisAlertIdValid(true);
															}
															else {
																SetisAlertIdValid(false);
															}
															setalertData({
																...alertData,
																alertId: value.target.value,
															})
														}
														}
													/>
													{/* <Text color="red" content="*Required" /> */}
													<FormInput
														label="Cancel ID"
														name="cancelID"
														id="cancelID-inline"
														fluid

														value={alertData?.cancelID}
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}

														onChange={(value) =>
															setalertData({
																...alertData,
																cancelID: value.target.value,
															})
														}
													/>

													<FormInput
														label="Service Name"
														name="serviceName"
														id="serviceName-inline"
														fluid

														value={alertData?.serviceName}

														onChange={(value) =>
															setalertData({
																...alertData,
																serviceName: value.target.value,
															})
														}
													/>

													<FormInput
														label="Details"
														name="details"
														id="details-inline"
														fluid

														value={alertData?.details}

														onChange={(value) =>
															setalertData({
																...alertData,
																details: value.target.value,
															})
														}
													/>

													<FormInput
														label="Steps"
														name="Steps"
														id="Steps-inline"
														fluid

														value={alertData?.Steps}

														onChange={(value) =>
															setalertData({
																...alertData,
																Steps: value.target.value,
															})
														}
													/>
													<div className="d-flex">
														<FormCheckbox
															styles={{ marginRight: "20px" }}
															toggle
															name="isTenanted"
															id="isTenanted-inline"
															labelPosition="start"
															label="Tenanted Alert :"
															checked={alertData?.isTenanted}
															onClick={(event, isChecked) => {
																setalertData({
																	...alertData,
																	isTenanted: isChecked.checked,
																})
															}}
														/>
														<Tooltip
															pointing
															trigger={<InfoIcon />}
															content="This alert is only applicable on tenent ID Range configured on serve rage.."
														/>
													</div>
													<div className="d-flex">
													<FormCheckbox
														styles={{ marginRight: "20px" }}
														toggle
														name="isCallDataAlert"
														id="isCallDataAlert-inline"
														labelPosition="start"
														label="CallDataAlert :"
														checked={alertData?.isCallDataAlert}
														onClick={(event, isChecked) => {
															setalertData({
																...alertData,
																isCallDataAlert: isChecked.checked,
															})
														}}
													/>
														<Tooltip
															pointing
															trigger={<InfoIcon />}
															content="If checked, then in case of aluto call placmnt srvc having this alrt only will not place auto call."
														/>
													</div>
													<DialogActionsBar>
														<button
															className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
															onClick={addEditSubmitClickHandler}
														// disabled={isValid === false}
														>
															{" "}
															Submit
														</button>
														<button
															className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
															onClick={handleAddEditClickClose}
														>
															{" "}
															Close
														</button>
													</DialogActionsBar>
												</Form>
											</Dialog>
										)}
									</div>
								</CardHeader>
								<CardBody>
									<div className="tbl-search float-right text-right mb-2">
										<Input
											onChange={(e, { name, value }) => {
												filterGridData(value);
											}}
											inverted
											icon={<SearchIcon />}
											placeholder="Search..."
											className="border-gray"
										/>
									</div>
									<div className='grid-with-search'>
										<Grid
											style={{
												height: "100%",
											}}
											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(serviceAlerts, dataState)}
											{...dataState}
											onDataStateChange={(e) => {
												setDataState(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>
											<Column field="alertId" title="Alert ID" width={context.mediaCategory.sm ? 70 : 100} />
											{!context.mediaCategory.sm && (
												<Column field="cancelID" title="Cancel ID" width={120} />
											)}
											{!context.mediaCategory.sm && (
												<Column
													field="serviceName"
													title="Service Name"
													width={200}
												/>
											)}
											{!context.mediaCategory.sm && (
												<Column field="details" title="Details" width={300} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Steps" title="Steps" width={350} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="isTenanted" title="Tenanted Alert" cell={CellConfigurationState} width={150} filterable={false} sortable={false} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="isCallDataAlert" title="Call Data Alert" cell={CellConfigurationState} width={150} filterable={false} sortable={false} />
											)}

											{props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) &&

												<Column
													field=""
													title="Actions"
													style={{ textAlign: "center" }}
													width={100}
													cell={ActionIconVersions}
												/>}
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>
						<FluentDialog
							cancelButton="Cancel"
							confirmButton="Confirm"
							onCancel={() => {
								setDeleteConfirmationDialog(false);
								setDeleteAlertObj(null);
							}}
							onConfirm={() => removeData()}
							open={openDeleteConfirmationDialog}
							backdrop
							header="Action confirmation"
							headerAction={{
								icon: <CloseIcon />,
								title: "Close",
								onClick: () => {
									setDeleteConfirmationDialog(false);
									setDeleteAlertObj(null);
								},
							}}
							content={`Are you sure to delete Alert ID: ${deleteAlertObj?.alertId}?`}
						/>
					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { SettingsServiceAlert };
