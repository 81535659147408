import React from 'react';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, CardHeader, CardBody, Flex } from '@fluentui/react-northstar';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellInstanceOverview, CellServiceDetail, CellDateTime, CellColTitle, CellServiceStatus, CellInstanceName, CellInstanceOverviewLabel } from '../../../../shared/appcomponents/custom-grid-cells';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../../../shared/contexts/Context";
const ServiceSummary = (props) => {
  const { id } = useParams();
  const [details, setDetails] = React.useState(null);
  const [detailTable, setDetailTable] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [instanceState, setInstanceState] = React.useState(null);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InstanceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  React.useEffect(() => {
    props.acquireToken((token) => {
      setLoaded(false);
      let _alarmType = searchParams.get('state');
      let _state = INCIDENTLEVELS[_alarmType];
      if (_state == null) {
        _state = -1;
      }
      setInstanceState(_state);
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: _state,
        appliedFilters: id ? [`InstanceId IN (${id})`] : []
      }
      if (token) {
        MonitoringService.GetServiceMonitoringSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setDetails(response);
            setDetailTable(response.Table);
}
else if(response && response.status==401){
  props.viewStatus.InformChildPageStatus(response.status);
  }
  else {
    props.viewStatus.InformChildPageStatus(500);
  }

          setLoaded(true);
        });
      }
    });
  }, [id]);

  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if (_state == null) {
      _state = -1;
    }
    setInstanceState(_state);
  }, [searchParams]);

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setDetails(null);
    setDetailTable(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: instanceState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetServiceMonitoringSummary(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setDetails(response);
            setDetailTable(response.Table);
}
else if(response && response.status==401){
  props.viewStatus.InformChildPageStatus(response.status);
  }
  else {
    props.viewStatus.InformChildPageStatus(500);
  }

          setLoaded(true);
        });
      }
    })
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'Platform', operator: 'contains', value: value }
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const expandChange = (event) => {
    let newData = detailTable.map((item) => {
      if (item.InstanceId === event.dataItem.InstanceId) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDetailTable(newData);
  };

  return (
    <>
      <WindowContext.Consumer>
        {(context) => (
          <div className='fluid w-100'>
            <div className='row mb-3'>
              <div className='col-md-12'>
                <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                  <CardHeader className='mb-2'>
                    <div className='d-flex'>
                      <div className='f-size-20'>Services Summary</div>
                    </div>
                  </CardHeader>
                  <div className='row'>
                    <div className='col-md-3 mb-3'>
                      <Card
                        onClick={() => routeChange(`/dashboards/monitoring/service/summary${id ? '/' + id : ''}`)}
                        className={`card-border bg-white br-0 pt-1 p-0 card-action ${instanceState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                        <div className='w-100 text-center'>
                          <div className='f-size-15'>Total Instances</div>
                          <div className='f-size-26'>{details ? details.Total : '-'}</div>
                        </div>
                      </Card>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Card
                        onClick={() => routeChange(`/dashboards/monitoring/service/summary${id ? '/' + id : ''}?state=healthy`)}
                        className={`card-border bg-success br-0 pt-1 p-0 card-action ${instanceState == 0 ? 'selected' : ''}`} fluid ghost elevated>
                        <div className='w-100 text-center text-white'>
                          <div className='f-size-15'>Healthy</div>
                          <div className='f-size-26'>{details ? details.Healthy : '-'}</div>
                        </div>
                      </Card>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Card
                        onClick={() => routeChange(`/dashboards/monitoring/service/summary${id ? '/' + id : ''}?state=warning`)}
                        className={`card-border bg-warning br-0 pt-1 p-0 card-action ${instanceState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                        <div className='w-100 text-center text-white'>
                          <div className='f-size-15'>Warning</div>
                          <div className='f-size-26'>{details ? details.Warning : '-'}</div>
                        </div>
                      </Card>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Card
                        onClick={() => routeChange(`/dashboards/monitoring/service/summary${id ? '/' + id : ''}?state=critical`)}
                        className={`card-border bg-danger br-0 pt-1 p-0 card-action ${instanceState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                        <div className='w-100 text-center text-white'>
                          <div className='f-size-15'>Critical</div>
                          <div className='f-size-26'>{details ? details.Critical : '-'}</div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <CardBody>
                    <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
                      <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                        defaultAppliedFilter={{ filter: 'Instance', operator: '==', value: id }}
                        elegibleFilters={['Instance', 'Platform']} />
                      <Flex.Item push>
                        <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                          <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                        </div>
                      </Flex.Item>
                    </Flex>
                    <div className='w-100'>
                      {
                        isLoaded ?
                          (
                            <KendoGrid
                              style={{
                                height: "456px",
                              }}
                              // pageable={true}
                              pageable={{
                                buttonCount: 3
                              }}
                              sortable={true}
                              data={process(detailTable.filter(tr => {
                                if (instanceState == -1) { return true }
                                else if (instanceState != -1 && tr.Health == instanceState) { return true }
                                return false;
                              }), dataState)}
                              {...dataState}
                              onDataStateChange={(e) => {
                                setDataState(e.dataState);
                              }}
                              expandField="expanded"
                              onExpandChange={expandChange}
                            >
                              <Column field="InstanceName" title="Instance" cell={CellInstanceOverviewLabel} locked={true} width={180} />
                              <Column field="Platform" title="Platform" width={180}  />
                              <Column field="ReportingStatus" title="Reporting Services Status" cell={CellServiceStatus} filterable={false} sortable={false} width={220} />
                              <Column field="RecordingStatus" title="Recording Services Status" cell={CellServiceStatus} filterable={false} sortable={false} width={220} />
                              <Column field="PortalLastPing" title="Portal Last Ping Status UTC" cell={CellDateTime} width={220} />
                              <Column field="DBLastPing" title="DB Last Ping Status UTC" cell={CellDateTime} width={220} />
                              <Column field="InfraDetail" title={<CellColTitle title="Service Detailed View" />} cell={CellServiceDetail} filterable={false} sortable={false} width={180} />
                              <Column field="InstanceOverview" title={<CellColTitle title="Instance Overview" />} cell={CellInstanceOverview} filterable={false} sortable={false} width={160} />
                            </KendoGrid>
                          ) : (
                            <Loader />
                          )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
      </WindowContext.Consumer>
    </>
  );
};


export { ServiceSummary }