import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, FormDropdown, FormCheckbox } from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellConfigurationState } from "../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const SettingsServices = (props) => {
	const [services, setservices] = React.useState(null);
	const [serviceData, setserviceData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteServiceObj, setDeleteServiceObj] = useState(null);
	const [instances, setInstances] = React.useState(null);
  const [serviceGroupTypes, setServiceGroupTypes] = React.useState(null);

	const [isserviceNameValid, SetIsserviceNameValid] = React.useState(true);

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "serviceName",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetServices(token).then((response) => {
        if(response && response.status==200){
          response=response.data;
          setservices(response?.Services);
		      setInstances(response?.Instances?.map((m) => ({header: m.Value, value: m.Key})));
          setServiceGroupTypes(
            response?.ServiceGroupTypes?.map(m => m.Value)
          );
          
        }
        else if(response && response.status==401){
          props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
          props.viewStatus.InformChildPageStatus(500);
          }  
          SetLoaded(true);    
      });
    }
    
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "serviceName", operator: "contains", value: value },
      { field: "serviceDisplayName", operator: "contains", value: value },
								{ field: "machineName", operator: "contains", value: value },
								{ field: "InstanceName", operator: "contains", value: value }],
    };
    setDataState({ ...dataState, filter: filter });
  };

	const handleAddEditClickOpen = (dataItem, isEdit) => {
		SetIsserviceNameValid(true);
    setVisible(true);
		

    if (isEdit === true) {
      setserviceData(dataItem);
      setEditMode(true);
      // checkIsValid();
    }
		else{
			setserviceData(null) ;
		}
    };

	const handleAddEditClickClose = () => {
    setVisible(false);
    setserviceData(null);
    setEditMode(false);
    setIsValid(false);
  };

	const ActionIconServices = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteServiceObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteServiceObj != null) {
      props.acquireToken((token) => {
        if (token) {
          let _params = {
            id: deleteServiceObj.id,
          };
          SettingsService.RemoveService(_params, token).then((response) => {
             if(response && response.status==200){
              response=response.data;
              props.acquireToken(authSuccessCallback);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }  else if(response && response.status==401){
              debugger;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          });
        }
      });
    }
    setDeleteConfirmationDialog(false);
    setDeleteServiceObj(null);
  };

  const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		if(isValid == true){
			props.acquireToken((token) => {
				if (token) {
					if (editMode) {
						SettingsService.EditService(serviceData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							} 
							else if(response && response.status==200){
                response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							} 
              else if(response && response.status==401){
                       debugger;
                       props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                     }
                     else {
                       props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                     }
						});
					} else {
						SettingsService.AddService(serviceData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							} 
							else if(response && response.status==200){
                response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}  else if(response && response.status==401){
                debugger;
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);      
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
              }
						});
					}
				}      
				setVisible(false);
				setEditMode(false);
				setIsValid(false);
			});
		}
   
  };

  const CheckForm = () => {
    let isValid = true;
	
		if(serviceData?.serviceName && serviceData?.serviceName.toString().length > 0 &&
    serviceData?.serviceDisplayName && serviceData?.serviceDisplayName.toString().length > 0){
			isValid = true;
		}
		else{
			isValid = false;
			SetIsserviceNameValid(false);
		}
	
		return isValid;
		
  };

  const expandChange = (event) => {
    let newData = services.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setservices(newData);
  };

	const handleInstanceChange = (alwaysNull, data) => {
    setserviceData({ ...serviceData, InstanceId: data.value.value, InstanceName: data.value.header });
  };

  const handleServiceGroupTypeChange = (alwaysNull, data) => {
    setserviceData({ ...serviceData,  ServiceGroupType: data.value });
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <FluentGrid columns={1}>
          <Text weight="bold" content="MachineName:" />
          <Text content={dataItem.machineName} />
          <Text weight="bold" content="InstanceName:" />
          <Text content={dataItem.InstanceName} />
          <Text weight="bold" content="GroupType:" />
          <Text content={dataItem.ServiceGroupType} />
        </FluentGrid>
      </section>
    );
  };


	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
    <>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <Card className="card-border bg-white h-100" fluid>
            <CardHeader className="mb-2">
              <div className="d-flex justify-content-between">
                <div className="f-size-18">Services</div>
                {/* <Button
                  icon={<AddIcon />}
                  iconPosition="before"
                  content="Add Service"
                  primary
                  title="Add Service"
                  onClick={handleAddEditClickOpen}
                /> */}
              </div>
              
              <div>
                {visible && (
                  <Dialog
                    title={editMode ? "Edit Service" : "Add Service"}
                    onClose={handleAddEditClickClose}
                    className="k-dialog-md"
                  >
                    <Form>
                      <FormInput
                        label={
                          isserviceNameValid == true ? (
                            "Service Name* "
                          ) : (
                            <Text
                              color="red"
                              content="Service Name* Required"
                            />
                          )
                        }
                        name="serviceName"
                        id="serviceName-inline"
                        fluid
                        disabled
                        value={serviceData?.serviceName}
                        onChange={(value) => {
                          if (
                            value.target.value &&
                            value.target.value.length > 0
                          ) {
                            SetIsserviceNameValid(true);
                          } else {
                            SetIsserviceNameValid(false);
                          }
                          setserviceData({
                            ...serviceData,
                            serviceName: value.target.value,
                          });
                        }}
                      />
                      <FormInput
                        label={
                          isserviceNameValid == true ? (
                            "Service Display Name* "
                          ) : (
                            <Text
                              color="red"
                              content="Service Name* Required"
                            />
                          )
                        }
                        name="serviceDisplayName"
                        id="serviceDisplayName-inline"
                        fluid
                        disabled
                        value={serviceData?.serviceDisplayName}
                        onChange={(value) => {
                          if (
                            value.target.value &&
                            value.target.value.length > 0
                          ) {
                            SetIsserviceNameValid(true);
                          } else {
                            SetIsserviceNameValid(false);
                          }
                          setserviceData({
                            ...serviceData,
                            serviceDisplayName: value.target.value,
                          });
                        }}
                      />
                      <FormInput
                        label="Machine Name"
                        name="machineName"
                        id="machineName-inline"
                        fluid
                        disabled
                        value={serviceData?.machineName}
                        onChange={(value) =>
                          setserviceData({
                            ...serviceData,
                            machineName: value.target.value,
                          })
                        }
                      />
                      <FormDropdown
                        label={{
                          content: `Instance`,
                          id: "instances-inline",
                        }}
                        items={instances}
                        aria-labelledby={"instances-inline"}
                        placeholder="Choose Instance"
                        fluid
                        autosize="true"
                        value={
                          serviceData != null && serviceData.InstanceId > -1
                            ? {
                                header: serviceData?.InstanceName,
                                value: serviceData?.InstanceId,
                              }
                            : {}
                        }
                        onChange={handleInstanceChange}
                      />
                      <FormDropdown
                        label={{
                          content: `Service Group Type`,
                          id: "serviceGroupTypes-inline",
                        }}
                        items={serviceGroupTypes}
                        aria-labelledby={"serviceGroupTypes-inline"}
                        placeholder="Choose Service Group Type"
                        fluid
                        autosize="true"
                        value={serviceData?.ServiceGroupType}
                        onChange={handleServiceGroupTypeChange}
                      />
                      <FormCheckbox
											styles={{ marginRight: "20px" }}
											toggle
											labelPosition="start"
											label="Monitoring Enabled :"
											checked={serviceData?.MonitoringEnabled}
											onClick={(event, isChecked) => {
												setserviceData({
													...serviceData,
													MonitoringEnabled: isChecked.checked,
												})
											}}
											/>
                       <FormInput
                        label="Start Tenant Id"
                        name="StartTenantIdInclusive"
                        id="startTenantid-inline"
                        fluid
                        
                        value={serviceData?.StartTenantIdInclusive}
                        onChange={(value) =>
                          setserviceData({
                            ...serviceData,
                            StartTenantIdInclusive: value.target.value,
                          })
                        }
                      />
                       <FormInput
                        label="End Tenant Id"
                        name="EndTenantIdInclusive"
                        id="endTenantid-inline"
                        fluid
                        
                        value={serviceData?.EndTenantIdInclusive}
                        onChange={(value) =>
                          setserviceData({
                            ...serviceData,
                            EndTenantIdInclusive: value.target.value,
                          })
                        }
                      />
                      <DialogActionsBar>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                          onClick={addEditSubmitClickHandler}
                        >
                          {" "}
                          Submit
                        </button>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          onClick={handleAddEditClickClose}
                        >
                          {" "}
                          Close
                        </button>
                      </DialogActionsBar>
                    </Form>
                  </Dialog>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <div className="tbl-search float-right text-right mb-2">
                <Input
                  onChange={(e, { name, value }) => {
                    filterGridData(value);
                  }}
                  inverted
                  icon={<SearchIcon />}
                  placeholder="Search..."
                  className="border-gray"
                />
              </div>
              <div className="grid-with-search">
                <Grid
                  style={{
                    height: "100%",
                  }}
                  // pageable={true}
									pageable={{
										buttonCount: 3
									}}
                  sortable={true}
                  data={process(services, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }}
                  detail={context.mediaCategory.sm ? DetailComponentSm : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                >
                  {/* <Column field="serviceName" locked title="Service Name" width = {200} /> */}
                  <Column field="serviceDisplayName" locked title="Service Display Name" width = {200} />
                  {!context.mediaCategory.sm && (
                  <Column field="machineName" locked title="Machine Name"  width = {150}/>
                  )}
                  {!context.mediaCategory.sm && (
                  <Column field="InstanceName" title="Instance Name" width = {150} />
                  )}
                  {!context.mediaCategory.sm && (
                  <Column field="StartTenantIdInclusive" title="Start TenantId" width = {140} />
                  )}
                  {!context.mediaCategory.sm && (
                  <Column field="EndTenantIdInclusive" title="End TenantId" width = {140} />
                  )}
                  {!context.mediaCategory.sm && (
                  <Column field="MonitoringEnabled" title="Monitoring Enabled" cell={CellConfigurationState} width = {170} filterable={false} sortable={false} />
                  )}
                  {!context.mediaCategory.sm && (
                  <Column field="ServiceGroupType" title="Service Group Type" width = {160} />
                  )}
                 { props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
                  <Column
                    field=""
                    title="Actions"
                    style={{ textAlign: "center" }}
                    width={100}
                    cell={ActionIconServices}
                  />}
                </Grid>
              </div>
            </CardBody>
          </Card>
        </div>
        <FluentDialog
          cancelButton="Cancel"
          confirmButton="Confirm"
          onCancel={() => {
            setDeleteConfirmationDialog(false);
            setDeleteServiceObj(null);
          }}
          onConfirm={() => removeData()}
          open={openDeleteConfirmationDialog}
          backdrop
          header="Action confirmation"
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => {
              setDeleteConfirmationDialog(false);
              setDeleteServiceObj(null);
            },
          }}
          content={`Are you sure to delete Service: ${deleteServiceObj?.serviceDisplayName}?`}
        />
      </div>
    </>
    )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsServices };
