import React, { useEffect, useState } from 'react'
import { Card, CardBody, Menu, tabListBehavior, Flex, Segment, List, Divider, Box, Text, Loader, Button } from '@fluentui/react-northstar'
import { Outlet, useNavigate ,  useLocation, useParams, useSearchParams} from "react-router-dom";
import { setRouteInLocalStorage } from '../../shared/contexts/Context';
import { ContactGroupIcon, SpeakerPersonIcon, SettingsIcon, CompanionIcon, ContentIcon, AttendeeIcon, AppFolderIcon, BellIcon, CallPstnIcon, PlugsIcon, TextBulletListTreeIcon, ListIcon, EmailIcon, OptionsIcon, SpotlightIcon, AudienceIcon, PersonIcon, MenuIcon, ShiftActivityIcon, ReactionsIcon, TvIcon } from "@fluentui/react-icons-northstar";

import { ServerOverview } from './serverOverview';
import { ServerServices } from './serverServices';
import { ServerProcesses } from './serverProcesses';
import { ServerIIS } from './serverIIS';


import { OperationStatusIndicator } from '../../shared/appcomponents/enums';
import { Error403 } from '../../shared/error/403';
import { Error500 } from '../../shared/error/500';
import FileManager from './fileManager';
import { ServerEvents } from './serverEvents';


const PermittedGroupsConfiguration=(props)=>{
  if( props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite"||
  p == "Settings.Configuration.Read"|| p == "Settings.Configuration.ReadWrite" )) )
  return true;
  else return false;
 
}
const PermittedGroupsEmailDeliveryNotification=(props)=>{
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite"||
  p == "Settings.EmailDeliveryNotification.Read"|| p == "Settings.EmailDeliveryNotification.ReadWrite" )) )
  return true;
  else return false;
}
const PermittedGroupsManageLogin=(props)=>{
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite"||
  p == "Settings.ManageLogin.Read"|| p == "Settings.ManageLogin.ReadWrite" )) )
  return true;
  else return false;
}
const PermittedGroupsOnCall=(props)=>{
  if ( props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite"||
  p == "Settings.OnCall.Read"|| p == "Settings.OnCall.ReadWrite" )))
  return true;
  else return false;
}

const PermittedCategories = (props) => {
  let items = []; 
  
    if (PermittedGroupsConfiguration(props)) {
      items.push(...["serverOverview", "cpuDetail"]);
    }
    return items;
  }


const ConfigCategories = (props) => {
  const [itemState, setItemState] = useState({});
  const [listItems, setListItems]= useState([]);  

  useEffect(() => {
    let items=[], itemsConfig = 0, itemsManageLogin = 0, itemsOnCall = 0, itemsEmail = 0;
    if (PermittedGroupsConfiguration(props)){
      items.push(...[{
        key: "serverOverview",
        header: "Overview",
        media: <CompanionIcon />,
        name: "serverOverview",
      },{
        key: "serverProcesses",
        header: "Processes",
        media: <CompanionIcon />,
        name: "serverProcesses",
      },{
        key: "serverServices",
        header: "Services",
        media: <CompanionIcon />,
        name: "serverServices",
      },{
        key: "serverIIS",
        header: "IIS",
        media: <CompanionIcon />,
        name: "serverIIS",
      },{
        key: "serverFile",
        header: "Files",
        media: <PlugsIcon />,
        name: "serverFile",
    },
    {
      key: "serverEvent",
      header: "Events",
      media: <PlugsIcon />,
      name: "serverEvent",
  }]);
      itemsConfig = items.length;
    }

    setItemState({
      itemsConfig: itemsConfig,
      itemsManageLogin: itemsManageLogin,
      itemsOnCall: itemsOnCall,
      itemsEmail: itemsEmail
    })
    setListItems(items)
  }, []);

  const onSelectChange = (index, newProps) => {
    const category = newProps.items[newProps.selectedIndex].key;
    const header = newProps.items[newProps.selectedIndex].header;
    const name = newProps.items[newProps.selectedIndex].name;
    props.handleCategoryChange(index, category, name, header);
  };

  return (
    <div>
      {listItems.length > 0 && (
        <>
        {
          itemState?.itemsConfig > 0 ?
          <><Divider
            content={<Text content={props.serverName} weight="bold" />}
          />
          <List
            className="categorylist"
            selectable
            selectedIndex={props.selectedCategory}
            onSelectedIndexChange={(e, newProps) => {
              onSelectChange(newProps.selectedIndex, newProps);
            }}
            items={listItems.slice(0, itemState?.itemsConfig)}
            style={{ width: "100%" }}
          /></>
          : <></>
        }
        </>
      )}
    </div>
  );
};


const ServerSM = (props) => {
  let location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(null);
  const [serverData, setServerData] = useState(null);

  let navigate = useNavigate();

  React.useEffect(() => {
    setServerData(location.state.data);
  }, []);

  React.useEffect(() => {
    props.viewStatus.setPageStatus(null);
    props.viewStatus.InformChildPageStatus(null);
   let _permitted= PermittedCategories(props);
   if(_permitted.length>0)
   {
    setSelectedCategory(0);
    setSelectedCategoryKey(_permitted[0]);
   }
   else{
    props.viewStatus.setPageStatus(401);
    // let path ='/dashboards/monitoring/infra/summary/'; 
    // navigate(path);
   }
   
   setLoaded(true);
 
  }, []);

  const handleCategoryChange = (_categoryIndex, _category, _name, _header) => {
    props.viewStatus.InformChildPageStatus(null);
    setSelectedCategory(_categoryIndex);
    setSelectedCategoryKey(_category);
    let path = "/serversm/" + _name;
    navigate(path);
    if (global.localStorage) {
      global.localStorage.setItem(
        'contentUrl_configuration',
        'configuration/' + _categoryIndex
      );
    }
  }

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive); 
   };

  return loaded ?
  props.viewStatus.pageStatus == 401 ? <Error403 /> :
  props.viewStatus.pageStatus == 500 ? <Error500 /> : 
  (
    <>
      <Box className="settings pt-3 pb-1">
        {selectedCategory!==null ? (
          <Flex className="addcardcontainer h-100" gap="gap.small"
          //padding="padding.medium"
          // style={{ minHeight: "100vh" }}
          >
          
            <Flex.Item className="leftSideBar">
              <Segment className={isActive ? "h-100 left-segment" : "active h-100 left-segment"}>
                <Button icon={<MenuIcon />} iconOnly title="Close" onClick={ToggleClass} className="menu-icon" primary />
                <ConfigCategories
                  selectedCategory={selectedCategory}
                  selectedCategoryKey={selectedCategoryKey}
                  handleCategoryChange={handleCategoryChange}
                  serverName = {serverData?.ComputerName}
                  {...props}
                />
              </Segment>
            </Flex.Item>
            <Flex.Item className="rightSideBar h-100" size="size.half" grow>
              <Segment className='p-0 h-100 right-segment'>
                {
                  props.viewStatus.childpageStatus == 401 ? <Error403 /> :
                  props.viewStatus.childpageStatus == 500 ? <Error500 /> :
                  (
                    <>
                    <OperationStatusIndicator status={props.viewStatus.operationStatus} revertStatusToNull={() => props.viewStatus.DisplayOperationStatus(null)} />
                    {selectedCategoryKey === 'serverOverview' && <ServerOverview {...props} serverObj = {serverData} />}
                    {selectedCategoryKey === 'serverProcesses' && <ServerProcesses {...props} serverObj = {serverData} />}
                    {selectedCategoryKey === 'serverServices' && <ServerServices {...props} serverObj = {serverData}  />}
                    {selectedCategoryKey === 'serverIIS' && <ServerIIS {...props} serverObj = {serverData} />}
                    {selectedCategoryKey === 'serverFile' && <FileManager {...props} serverObj = {serverData} />}
                    {selectedCategoryKey === 'serverEvent' && <ServerEvents {...props} serverObj = {serverData} />}
                    </>
                  )
                }
              </Segment>
            </Flex.Item>
          </Flex>
        ) : (
          <Flex fill className="error" hAlign="center" vAlign="center"
          >
            {/* <Segment className="auth-form-segment default-segment">
              <Flex gap="gap.small" column hAlign="center" vAlign="center">
                <Text
                  weight="semibold"
                  size="large"
                  content="Access Denied"
                />
                <Text content="Access to settings is restricted to App Owners or Team Owners." />
              </Flex>
            </Segment> */}

            <div className="notfound">
              <div className="notfound-section">
                <h1>Oops!</h1>
                <h2>
                  There's Nothing...!
                </h2>
              </div>
              {/* <Link to="/" className="k-button k-primary py-3 px-5">Contact Administrator</Link> */}
            </div>
          </Flex>
        )}
      </Box>
    </>
  ) : (
    <Loader />
  );
};

export { ServerSM }