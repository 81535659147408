import * as React from 'react';
import { DiskChartContainer, MetricChartContainer } from '../../../../shared/appcomponents/custom-charts';
import { Card, Grid, Divider,Pill, PillGroup, Dropdown, CardBody, Text, Flex, Button, Header, Loader, Datepicker as FluentDatePicker, Dialog, Popup } from '@fluentui/react-northstar';
import { FilterIcon } from '@fluentui/react-northstar';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import MonitoringService from '../../../../services/monitoringservice';
import { setRouteInLocalStorage } from '../../../../shared/contexts/Context';
import _ from 'lodash';
import { NativeDatePicker, TeamsDatePicker, GETOFFSETDATE } from '../../../../shared/appcomponents/custom-datetime-inputs';
import { WindowContext } from '../../../../shared/contexts/Context';
import { formatDate } from '@progress/kendo-intl';
import { FORMATBYTES,OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';


const periodFilterItemList = [
    { header: 'Today', value: 0, daystoreduce: 0 },
    { header: 'Last 7 days', value: 1, daystoreduce: 6 },
    { header: 'This Week', value: 2, daystoreduce: -1 },
    { header: 'This Month', value: 3, daystoreduce: -1 },
    { header: 'Last Month', value: 4, daystoreduce: -1 },
    { header: 'Custom Date Range', value: 5, daystoreduce: -1 }
];

const BASEUNITS = ['fit', 'minutes', 'hours', 'days', 'weeks', 'months'];

const ServerGraph = (props) => {
    let navigate = useNavigate(); 
    const routeChange = (path) =>{
        navigate(path);
    }
    const { id } = useParams(); // -1 : vm, -2 : storage account, else [Servers] Table
    const [searchParams] = useSearchParams();
    const[isLoaded, setLoaded] = React.useState(false);
    const[serverName, setServerName] = React.useState("");
    const[incidentServerId, setIncidentServerId] = React.useState(null);
    const [portalOpen, setPortalOpen] = React.useState(false);
    const [servers, setServers] = React.useState(null);
    const [selectedServer, setSelectedServer] = React.useState(null);
    const [selectedPeriodId, setSelectedPeriodId] = React.useState(0);
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(GETOFFSETDATE(new Date(), 0));
    const [endDatePlaceholder, setEndDatePlaceholder] = React.useState(new Date());
    const [startDatePlaceholder, setStartDatePlaceholder] = React.useState(GETOFFSETDATE(new Date(), 0));
    const [metricData, setMetricData] = React.useState(null);
    const [baseUnit, setBaseUnit] = React.useState("hours");
    const [baseUnits, setBaseUnits] = React.useState(BASEUNITS);
    const [isDiskView, setDiskView] = React.useState(false);
    React.useEffect(() => {   
        props.acquireToken((token) => 
            MonitoringService.GetServerGraphOptions(token).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    setServers(response);
                }
                else if(response && response.status==401){
                    props.viewStatus.InformChildPageStatus(response.status);
                  }
                  else {
                    props.viewStatus.InformChildPageStatus(500);
                  }

            })
        );        
    }, []);

    React.useEffect(() => {
        setLoaded(false);
        props.acquireToken(authSuccessCallback);
    }, [searchParams])

    const authSuccessCallback = (token) => {             
        setLoaded(false);
        let _serverName = searchParams.get('name');
        let _incidentServerId = searchParams.get('id');
        if(_serverName){
            setServerName(_serverName);
        }
        if(_incidentServerId){
            setIncidentServerId(_incidentServerId);
        }
        setSelectedServer({
            value: id, header: _serverName, incidentserverid: _incidentServerId, servertype: id
        });

        let sd = startDatePlaceholder;
        let ed = endDatePlaceholder;
        let startDate = sd;
        let endDate = ed;
        
        if( sd && ed){
             startDate = (new Date(Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate(), 0, 0, 0))).toISOString();
             endDate = (new Date(Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate(), 0, 0, 0))).toISOString();
        }


        if (token) {      
            let params = {
                serverId: id,
                incidentServerId: _incidentServerId,
                machineName: _serverName,                
                startDate: startDate,
                endDate: endDate
            };
            let dayOffsetFromToday = parseInt((new Date().getTime() - startDatePlaceholder.getTime())/(1000 * 3600 * 24));
            if(dayOffsetFromToday > 7){
                setBaseUnits(BASEUNITS.filter(b => b != 'minutes'));
            }
            else{
                setBaseUnits(BASEUNITS);
            }
            if(selectedPeriodId == 0){
                setBaseUnit('hours');
            }
            else{
                setBaseUnit('days');
            }
            MonitoringService.GetServerGraph(token, params).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    formatMetricData(response);              
                }
                else if(response && response.status==401){
                    props.viewStatus.InformChildPageStatus(response.status);
                  }
                  else {
                    props.viewStatus.InformChildPageStatus(500);
                  }

                setLoaded(true);
            });
        }
    };

    const handleServerChange = (option) => {
        if (serverName != option.header) {
          setSelectedServer(option);
          let path = `/dashboards/monitoring/infra/metrics/${option.value}?id=${option.incidentserverid}&name=${option.header}`;
          setRouteInLocalStorage('contentUrl_dashboards', path);
          navigate(path);
        }
    };

    const formatMetricData = (response) => {
        if(response){
            if(id != -2){  
                //VM     
                let _diskAndBootHistory = {};
                response.DiskAndBootHistory?.map((p) => {
                    p.Date = new Date(p.MetricTimeStamp);
                    if(_diskAndBootHistory[p.MetricKey]){
                        _diskAndBootHistory[p.MetricKey].push(p);
                    }
                    else{
                        _diskAndBootHistory[p.MetricKey] = [p];
                    }
                });
                setMetricData({
                    Metrics: response.Metrics?.map((p) => {
                        p.Date = new Date(p.MetricTimeStamp);
                        p.PercentageAvailableMemory = 100 - p.PercentageAvailableMemory;
                        p.PercentageAvailableMemoryMax = 100 - p.PercentageAvailableMemoryMax;
                        
                        return p;
                    }),
                    Disks:response.Disks?.map((p) => {
                        p.Date = new Date(p.MetricTimeStamp);
                        return p;
                    }),
                    DiskAndBootHistory: _diskAndBootHistory,
                    DateUpdatedHub : response.Metrics[0]?.DateUpdatedHub,
                    DateUpdatedHubDisk : response.Disks[0]?.DateUpdatedHub,
                    Trends : response.Trends?.map((p) => {
                        p.Date = new Date(p.DateUpdatedHub);
                        return p;
                    }), 
                });
            }
            else{
                //Storage Account
                setMetricData(response.map((p) => {
                    p.Date = new Date(p.MetricTimeStamp);
                    return p;
                }));
            }
        }  
    }

    const getNumberOfdaystoreduce = (periodFilterOption, _endDate) => {      
        let _today = new Date();
        if(periodFilterOption.daystoreduce !== -1){
            return {endDate: _today, startDate: GETOFFSETDATE(_today, -Math.abs(periodFilterOption.daystoreduce))};
        }
        else{
            let _daystoreduce = 0;
            switch(periodFilterOption.value){
                case 2://This Week
                    _daystoreduce = _today.getDay() - 1;
                    break;
                case 3://This Month
                    _daystoreduce = _today.getDate() - 1;
                    break;
                case 4://Last Month
                    _today = new Date(_today.setDate(0));
                    _daystoreduce = _today.getDate() - 1;
                    break;
            }
            return {endDate: _today, startDate: GETOFFSETDATE(_today,-Math.abs(_daystoreduce))};
        }
    }

    const handlePeriodChange = (option) => {
        let filterOptionId = option.value;
        if (selectedPeriodId !== filterOptionId) {
          setSelectedPeriodId(filterOptionId);
          if(filterOptionId != 5){
            let _filterParams = getNumberOfdaystoreduce(option);
            setEndDatePlaceholder(_filterParams.endDate);
            setStartDatePlaceholder(_filterParams.startDate);
          }
        }
    };

    const handleFilterUpdate = () => {
        setLoaded(false);        
        setStartDate(startDatePlaceholder);
        setEndDate(endDatePlaceholder);
        props.acquireToken(authSuccessCallback);
    }

    const handleStartDateChange = (value) => {
        //daystoreduce
        setStartDatePlaceholder(value);
    };
    const handleEndDateChange = (value) => {
        //Anchor Date
        setEndDatePlaceholder(value);
    };

    return (
        <WindowContext.Consumer>
        {(context) =>
        <Card  className="card-border bg-white" fluid>
            <Card.Header>
                <div className='text-right'>
                    <Button onClick={() => navigate(-1)} content="Back" />  
                </div>
                        <Flex className='mt-3 flex-sm-column justify-content-between'>  
                            <div className='d-flex flex-sm-column justify-content-between w-100'>
                                <Flex vAlign="center" className="mr-2 mb-3">
                                    <div class="dropdown-width">
                                        <Text as='h4' className="m-0" content="Server / Storage Account: " />
                                        <Dropdown
                                            search
                                            items={servers ? servers : []}
                                            placeholder={serverName}
                                            // defaultValue={
                                            //     servers && serverName != "" ? servers.find((d) => d.header == serverName) : {}
                                            // }
                                            value={selectedServer}
                                            onChange={(e, selectedOption) => {
                                                if (selectedOption.value != null)
                                                {
                                                    setMetricData(null);
                                                    setLoaded(false);
                                                    handleServerChange(selectedOption.value);
                                                }
                                            }}
                                            renderItem={(Component, props) => {
                                                if (props.value == -2) {
                                                    props.className =
                                                        props.className + " _storageAccount";
                                                }
                                                else if (props.value == -1) {
                                                    props.className =
                                                        props.className + " _virtualMachine";
                                                }
                                                return <Component {...props}></Component>;
                                            }}
                                        />
                                    </div>
                                </Flex>
                                <div className='d-flex mt-sm-3 w-100 mb-3'>
                                    <div className='ml-0'>
                                    <Text as='h4' className="m-0" content="Applied Filter:" />
                                    <PillGroup aria-label="filter group">
                                        <Pill className='m-0' appearance="outline">{'Period: ' + startDate.toDateString() + ' - ' + endDate.toDateString()}</Pill>
                                    </PillGroup>
                                    </div>
                                    
                                    <div className='ml-2'>
                                        <Text as='h4' className="m-0" content="&nbsp;" />
                                        <Popup  
                                        position= 'below'
                                        align= 'end'
                                        pointing
                                        open={portalOpen}
                                        trapFocus={{
                                            // When 'false', all clicks outside the Portal will be caught and not handled.
                                            // 'true' by default.
                                            isClickableOutsideFocusTrap: false,
                                            // Indicates whether to focus element which triggered FTZ on outside click
                                            // 'false' by default
                                            // note: if isClickableOutsideFocusTrap === 'false', focusTriggerOnOutsideClick will not be taken into account.
                                            focusTriggerOnOutsideClick: false,
                                            // Allows to pass element which you want to be focused after Portal is closed.
                                            // 'null' by default, so the trigger element would be focused on close.
                                            elementToFocusOnDismiss: null,
                                            // Indicates whether to force focus inside a Portal, if the 'focus' event was invoked at any place.
                                            // 'false' by default.
                                            forceFocusInsideTrapOnOutsideFocus: false,
                                            // Ignore focusing element which activated Portal after it was closed.
                                            // 'false' by default.
                                            ignoreExternalFocusing: false,
                                            // Do not focus first focusable element of Portal when opened.
                                            // 'false' by default.
                                            disableFirstFocus: false,
                                            // Indicates an element to focus after Portal has opened.
                                            // 'null' by default. The first focusable element of Portal will be focused.
                                            firstFocusableSelector: null,
                                        }}
                                        content={
                                            <Flex column gap="gap.small">
                                                <Header className="mt-0" as="h4">Add Filter</Header>
                                                <Grid columns={2} style={{gap: '8px', gridTemplateColumns: '100px 1fr', alignItems: 'center'}}>
                                                    <Text content="Period"/>
                                                    <Dropdown items={periodFilterItemList} 
                                                    defaultValue={
                                                        periodFilterItemList.find((d) => d.value === selectedPeriodId)
                                                    }
                                                    onChange={(e, selectedOption) =>
                                                        handlePeriodChange(selectedOption.value)
                                                    }
                                                    />
                                                    <Text content="from:"/>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                        context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                            onChange={(value) => {
                                                                handleStartDateChange(value);
                                                            }}
                                                            value={startDate}
                                                            min={GETOFFSETDATE(new Date(), -90)} 
                                                            max={endDate} 
                                                            disabled={selectedPeriodId != 5} /> 
                                                        ) : (
                                                            <NativeDatePicker
                                                            onChange={(value) => {
                                                            handleStartDateChange(value);
                                                        }}
                                                        value={startDatePlaceholder}
                                                        min={GETOFFSETDATE(new Date(), -90)} 
                                                        max={endDatePlaceholder} 
                                                        disabled={selectedPeriodId != 5} />
                                                        )
                                                    }
                                                    <Text content="to:"/>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                        context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                            onChange={(value) => {
                                                                handleEndDateChange(value);
                                                            }}
                                                            value={endDatePlaceholder}
                                                            min={startDatePlaceholder} 
                                                            max={new Date()} 
                                                            disabled={selectedPeriodId != 5} />
                                                        ) : (
                                                            <NativeDatePicker
                                                            onChange={(value) => {
                                                                handleEndDateChange(value);
                                                            }}
                                                            value={endDatePlaceholder}
                                                            min={startDatePlaceholder} 
                                                            max={new Date()} 
                                                            disabled={selectedPeriodId != 5} />
                                                        )
                                                    }
                                                </Grid>
                                                <Flex.Item push>
                                                    <Flex gap="gap.small" className="mt-2">
                                                        <Button primary content="Apply" onClick={() => {setPortalOpen(false); handleFilterUpdate();}} />
                                                        <Button content="Cancel" onClick={() => setPortalOpen(false)} />
                                                    </Flex>
                                                </Flex.Item>
                                            </Flex>
                                        }
                                        trigger={<Button disabled={!isLoaded} className="pl-3 pr-3" circular icon={<FilterIcon />} content={'Filter'} onClick={() => {
                                            // setEndDate(new Date());
                                            // setStartDate(GETOFFSETDATE(new Date(), -7));
                                            setPortalOpen(!portalOpen);
                                        }} />}
                                        />
                                    </div>
                                </div>
                            </div>
                    
                    
                    <Flex.Item>
                        <div className='dropdown-width'>
                            <Text as='h4' className="m-0" content="Graph Unit by:"/>
                            <Dropdown
                                items={baseUnits}
                                value={baseUnit}
                                checkable
                                onChange={(e, selectedOption) => {
                                    setBaseUnit(selectedOption.value);
                                }}
                            />
                        </div>
                        </Flex.Item> 
                        <Flex.Item push className='mb-3'>
                            <div className='ml-2'>
                                <Text as='h4' className="m-0" content="&nbsp;" />
                                {id!="-2" &&
                                <Button primary content={isDiskView ? 'View All' : 'View Disks'} onClick={() => {setDiskView(!isDiskView);}} />}
                            </div>
                        </Flex.Item>        
                </Flex>

                <Flex vAlign="center" className='w-100 mb-3'>
                    <Flex.Item push>
                        <div>
                           {
                            isLoaded && metricData ? 
                            <div className='mt-1'>
                               {
                                    metricData.DateUpdatedHub && !isDiskView ? 
                                    <Text content={"Last Updated UTC: " + formatDate(new Date(metricData.DateUpdatedHub), "dd-MMM-yyyy HH:mm")  } ></Text> : ""
                               } 
                               {
                                    metricData.DateUpdatedHubDisk && isDiskView ? 
                                    <Text content={"Last Updated UTC: " + formatDate(new Date(metricData.DateUpdatedHubDisk), "dd-MMM-yyyy HH:mm") } ></Text> : ""
                               }
                            </div>
                            :
                            ""
                           }
                           
                        </div>
                    </Flex.Item>
                </Flex>
               
            </Card.Header>
            <Card.Body>
                {
                    !isDiskView ?
                    <>
                <div className='row mb-3'>
                    <div className='col-sm-12 col-md-6'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Availability" yaxesLabel="Percent (Total Billable Requests/ Request Count)" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Availability",
                                                field: "Availability",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Availability",
                                                field: "BlobAvailability",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        : <MetricChartContainer title="CPU Utilization" yaxesLabel="% Utilization" baseUnit={baseUnit} data={metricData.Metrics} series={[
                                            {
                                                name: "Average",
                                                field: "PercentageCPU",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Maximum",
                                                field: "PercentageCPUMax",
                                                aggregate: "max"
                                            }
                                        ]} />
                                    : <Loader /> 
                                }                                
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-sm-12 col-md-6 mt-sm-3'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                            {
                                isLoaded && metricData ?
                                    id == -2 ? <MetricChartContainer title="Capacity" yaxesLabel="GB" baseUnit={baseUnit} data={metricData} series={[
                                        {
                                            name: "Used Capacity",
                                            field: "UsedCapacity",
                                            aggregate: "avg"
                                        },
                                        {
                                            name: "Blob Capacity",
                                            field: "BlobCapacity",
                                            aggregate: "avg"
                                        }
                                    ]} />
                                    : <MetricChartContainer title="RAM Usage" yaxesLabel="% Utilization" baseUnit={baseUnit} data={metricData.Metrics} series={[
                                        {
                                            name: "Average",
                                            field: "PercentageAvailableMemory",
                                            aggregate: "avg"
                                        },
                                        {
                                            name: "Maximum",
                                            field: "PercentageAvailableMemoryMax",
                                            aggregate: "max"
                                        }
                                    ]} />
                                : <Loader />
                            }   
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-12 col-md-6'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Egress" yaxesLabel="MB (External + Internal)" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Egress",
                                                field: "Egress",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Egress",
                                                field: "BlobEgress",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        : <MetricChartContainer title="Network Utilization" yaxesLabel="MB" baseUnit={baseUnit} data={metricData.Metrics} series={[
                                            {
                                                name: "Network In (Avg)",
                                                field: "NetworkInTotal",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Network In (Max)",
                                                field: "NetworkInTotalMax",
                                                aggregate: "max"
                                            },
                                            {
                                                name: "Network Out (Avg)",
                                                field: "NetworkOutTotal",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Network Out (Max)",
                                                field: "NetworkOutTotalMax",
                                                aggregate: "max"
                                            }
                                        ]} />
                                    : <Loader />
                                }
                            </CardBody>
                        </Card>
                    </div>
                     <div className='col-sm-12 col-md-6 mt-sm-3'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Egress" yaxesLabel="MB (External + Internal)" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Egress",
                                                field: "Egress",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Egress",
                                                field: "BlobEgress",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        : <MetricChartContainer title="Network Utilization Trend" yaxesLabel="MB" baseUnit={baseUnit} data={metricData.Trends} series={[
                                            {
                                                name: "Network In (Avg)",
                                                field: "NetworkInTotal",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Network In (Trend)",
                                                field: "NetworkInTotalTrend",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Network Out (Avg)",
                                                field: "NetworkOutTotal",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Network Out (Trend)",
                                                field: "NetworkOutTotalTrend",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                    : <Loader />
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-12 col-md-6'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Ingress" yaxesLabel="MB (External + Internal)" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Ingress",
                                                field: "Ingress",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Ingress",
                                                field: "BlobIngress",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        : <MetricChartContainer title="Disk Operations" yaxesLabel="kB/sec" baseUnit={baseUnit} data={metricData.Metrics} series={[
                                            {
                                                name: "Read (Avg)",
                                                field: "DataDiskReadBytesPerSec",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Read (Max)",
                                                field: "DataDiskReadBytesPerSecMax",
                                                aggregate: "max"
                                            },
                                            {
                                                name: "Write (Avg)",
                                                field: "DataDiskWriteBytesPerSec",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Write (Max)",
                                                field: "DataDiskWriteBytesPerSecMax",
                                                aggregate: "max"
                                            }
                                        ]} />
                                    : <Loader />
                                }
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-sm-12 col-md-6 mt-sm-3'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Latency" yaxesLabel="MilliSeconds" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Latency",
                                                field: "SuccessE2ELatency",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Latency",
                                                field: "BlobSuccessE2ELatency",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        : <MetricChartContainer title="Disk Queue Depth" yaxesLabel="Count" baseUnit={baseUnit} data={metricData.Metrics} series={[
                                            {
                                                name: "Average",
                                                field: "DataDiskQueueDepth",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Maximum",
                                                field: "DataDiskQueueDepthMax",
                                                aggregate: "max"
                                            }
                                        ]} />
                                    : <Loader /> 
                                }
                            </CardBody>
                        </Card>
                    </div>
                   
                </div>
                <div className='row mb-3'>
                     <div className='col-sm-12 col-md-6'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                                {
                                    isLoaded && metricData ?
                                        id == -2 ? <MetricChartContainer title="Transactions" yaxesLabel="Count" baseUnit={baseUnit} data={metricData} series={[
                                            {
                                                name: "Transactions",
                                                field: "Transactions",
                                                aggregate: "avg"
                                            },
                                            {
                                                name: "Blob Transactions",
                                                field: "BlobTransactions",
                                                aggregate: "avg"
                                            }
                                        ]} />
                                        :<MetricChartContainer series={metricData.Disks} title="Server Disks Usage" yaxesLabel="GB" />
                                    : <Loader /> 
                                }
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-sm-12 col-md-6 mt-sm-3'>
                        <Card className='card-border bg-white card-hov' fluid ghost elevated>
                            <CardBody>
                            {
                                isLoaded && metricData ?
                                    id == -2 ? <MetricChartContainer title="Blob Count" yaxesLabel="Count" baseUnit={baseUnit} data={metricData} series={[
                                        {
                                            name: "Blob Count",
                                            field: "BlobCount",
                                            aggregate: "avg"
                                        }
                                    ]} />
                                    : <MetricChartContainer title="Server Uptime (hours)" yaxesLabel="Hours" baseUnit={baseUnit} data={metricData.DiskAndBootHistory['LastBootTime']} series={[
                                        {
                                            name: "ServerUpTime",
                                            field: "ServerUpTime",
                                            aggregate: "avg"
                                        }
                                    ]} />
                                : <Loader /> 
                            }                                
                            </CardBody>
                        </Card>
                    </div>
                </div>
                </>
                :
                <div>                
                    {
                        isLoaded && metricData ?
                        id == -2 ? <></> : 
                        <Grid fluid columns={3} style={{gridGap: '15px'}}>{
                        Object.keys(metricData.DiskAndBootHistory).map((m) => {
                            if(m == 'LastBootTime'){
                                return <></>;
                            }
                            else{
                                return  (
                                    <div key={m}>
                                    <Card className='card-border bg-white card-hov' fluid ghost elevated>
                                    <CardBody>
                                        <DiskChartContainer aggregate="avg" title={m} yaxesLabel="GB" baseUnit={baseUnit} series={metricData.DiskAndBootHistory[m]}/>
                                    </CardBody>
                                    </Card></div>)
                            }
                        })}
                        </Grid>
                        : <Loader/>
                    }
                </div>
                }
            </Card.Body>
        </Card>
        }
        </WindowContext.Consumer>
    )
};

export default ServerGraph