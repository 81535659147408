import * as React from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridRowClickEvent,
  GridRowDoubleClickEvent,
  GridSortChangeEvent,
  GRID_COL_INDEX_ATTRIBUTE
} from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { useInternationalization } from '@progress/kendo-react-intl';
import { classNames } from '@progress/kendo-react-common';
import { convertDateFormat, getName, formatBytes , getFileExtension} from './../serversm/helper';
import { FileEditDialog } from './fileEditDialog';
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status , Text} from '@fluentui/react-northstar';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon, FilesFlashIcon, DownloadIcon } from '@fluentui/react-icons-northstar'

const DateCreatedCell  = (props) => {
  const intl = useInternationalization();

  const field = props.field || '';
  const date = props.dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  return (
    <td
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
      {date === null ? '' : convertDateFormat(date, intl)}
    </td>
  );
};


const SizeCell = (props) => {
  const size = props.dataItem[props.field || ''];
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  return (
    <td
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
      {size === null ? '' : formatBytes(size)}
    </td>
  );
};

const NameCell = (props) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const name = getName(props.dataItem.path);

  return (
    <td
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
      <span className={classNames('k-icon', props.dataItem.icon ? props.dataItem.icon.iconClass : '')} />
      {name}
    </td>
  );
};

export const GridView = (props) => {
  const ViewFileCell = (props1) => {
    return (props1?.dataItem?.isFolder === false ? 
      <td style={{ textAlign: "center" }}>
      <FileEditDialog {...props} fileObj={props1.dataItem} ></FileEditDialog>
      <Button
            styles={{ height: "auto" }}
            iconOnly
            size="small"
            icon={<DownloadIcon />}
            text
            onClick={() => {
              debugger;
              props.handleDownloadClick(props1?.dataItem);
            }
            }
          /> 
      </td>
      : <td></td>
    );
    ;
  }

  const RowRender = (trElement, dataItem) => {
    const trProps = {
      onContextMenu: (event) => {
        event.preventDefault()
        handleContextMenu(event, dataItem);
      }
    }
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const handleOnSortChange = (event) => {
    props.onSortChange({
      sort: event.sort,
      event: event
    });
  };

  const handleSelectionChange = (event) => {
    props.onSelectionChange(event);
  };

  const handleMultipleSelection = (event) => {
    props.onMultipleSelection(event);
  };

  const handleDoubleClick = (event) => {
    props.onDoubleClick(event);
  };

  const handleContextMenu = (event, dataItem) => {
    props.onContextMenu({
      dataItem: dataItem.dataItem,
      event: event
    });
  };

  return (
    <Grid
      data={props.data}
      rowRender={RowRender}
      className={'k-filemanager-grid k-grid-display-block k-editable'}
      style={{ height: 'calc(100% - 35px)' }}      
      navigatable={true}
      sortable={{
        allowUnsort: false
      }}
      sort={props.sort}
      selectedField={'selected'}
      selectable={{ enabled: true, cell: false, drag: true, mode: 'multiple' }}
      dataItemKey={props.dataItemKey}
      onRowClick={handleSelectionChange}
      onSelectionChange={handleMultipleSelection}
      onSortChange={handleOnSortChange}
      onRowDoubleClick={handleDoubleClick}
      >
      <Column field='path' title='Name' cell={NameCell} />
      <Column field='dateCreated' title='Date Created' cell={DateCreatedCell} />
      <Column field='size' title='Size' cell={SizeCell} />
      <Column field='' title='' cell={ViewFileCell} />
       </Grid>
  );
}
