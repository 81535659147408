import axios from 'axios';
import * as constants from '../../Constants';

class MonitoringService {

  static AuthenticatedApi = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    // will inject auth header on-demand later as needed.
    headers: {
      'Content-Type': 'application/json'
    }
  });

  static AuthenticatedApi_download = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    responseType: 'blob',

  });

  static GetMonitoringHome(token) {
    return this.AuthenticatedApi.get('/Monitoring/GetMonitoringHome/', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInfraIncidents(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetInfraIncidents', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetDataIncidents(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetDataIncidents', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServiceIncidents(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetServiceIncidents', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetDataDetail(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetDataDetail', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetDataSummary(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetDataSummary', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServerGraph(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetServerGraph', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInfraDetail(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetInfraDetail',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInfraSummary(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetInfraSummary', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInstanceOverview(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetInstanceOverview', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServerGraphOptions(token) {
    return this.AuthenticatedApi.get('/Monitoring/GetServerGraphOptions', {
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetInstances(token) {
    return this.AuthenticatedApi.get('/Monitoring/GetInstances', {
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServiceProviderToken(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetServiceProviderToken', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetMasterFilterData(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetMasterFilterData', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateIncident(token, _params) {

    return this.AuthenticatedApi.post('/Monitoring/UpdateIncident', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetIncidentHistory(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetIncidentHistory', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddIncidentComment(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/AddIncidentComment', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetRDPHandler(token) {
    return this.AuthenticatedApi.get('/Monitoring/GetRDPHandler', {
      headers: {
        Authorization: "Bearer " + token,
        ResponseType: 'blob'
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetPortalGraph(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetPortalGraph', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetPortalGraphOptions(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetPortalGraphOptions', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetOnCallDetails(token) {
    return this.AuthenticatedApi.get("/Monitoring/GetOnCallDetails", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetTenantIncidents(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetTenantIncidents', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static GetServiceMonitoringDetails(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetServiceMonitoringDetails', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServiceMonitoringSummary(token, _params) {
    return this.AuthenticatedApi.post('/Monitoring/GetServiceMonitoringSummary', _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetServiceAlerts(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetServiceAlerts', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTenantedAlerts(token, _params) {
    return this.AuthenticatedApi.get('/Monitoring/GetTenantedAlerts', {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
        // ContentType: 'application/json'
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateContact(token, userData) {
    debugger
    return this.AuthenticatedApi.post("/Monitoring/UpdateContact/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTimeSheets(token, _params) {
    return this.AuthenticatedApi.get("/Monitoring/GetTimeSheets", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddTimeSheet(userData, token) {
    return this.AuthenticatedApi.put("/Monitoring/AddTimeSheet/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static EditTimeSheet(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/UpdateTimeSheet/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static UpdateTimesheetApprove(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/UpdateTimesheetApprove/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static CheckTimeSheet(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/CheckTimeSheet/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static RemoveTimeSheet(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/RemoveTimeSheet/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTimesheetsData(token) {
    return this.AuthenticatedApi.get("/Monitoring/GetTimesheetsData/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static SubmitTimesheetRecords(token, csvData) {
    return this.AuthenticatedApi.post("/Monitoring/SubmitTimesheetRecords", csvData, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch((error) => {
        if (error.response) {
          return { status: error.response.status, data: error.response.data };
        } else {
          return { status: 500, data: { message: "An unknown error occurred." } };
        }
      });
  }

  static fetchVersions(token) {
    return this.AuthenticatedApi.get("/Monitoring/GetVersions/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }



  static GetTimeSheetReport(token, _params) {

    return this.AuthenticatedApi.get("/Monitoring/GetTimeSheetReport", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DownloadTimsheetCsv(token, _params) {
    return this.AuthenticatedApi_download.post('/Monitoring/DownloadTimsheetCsv',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DownloadTimsheetHistoryCsv(token, _params) {
    return this.AuthenticatedApi_download.post('/Monitoring/DownloadTimsheetHistoryCsv',
      _params, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // Set the response type to 'blob'
      })
      .then((response) => {
        return response; // Ensure this returns the Blob directly
      })
      .catch((error) => {
        console.error("Error downloading CSV:", error);
        return error.response ? error.response.data : { message: "Unknown error occurred" };
      });
}

  static CheckTimeSheetDaily(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/CheckTimeSheetDaily/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddTimeSheetDaily(userData, token) {
    return this.AuthenticatedApi.put("/Monitoring/AddTimeSheetDaily/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static CheckTimeSheetWeekly(userData, token) {
    return this.AuthenticatedApi.post(
      "/Monitoring/CheckTimeSheetWeekly/",
      userData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddTimeSheetWeekly(userData, token) {
    return this.AuthenticatedApi.put("/Monitoring/AddTimeSheetWeekly/", userData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTimesheetsDataDaily(token, _params) {
    return this.AuthenticatedApi.get("/Monitoring/GetTimesheetsDataDaily/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetTimesheetsDataWeekly(token, _params) {
    return this.AuthenticatedApi.get("/Monitoring/GetTimesheetsDataWeekly/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

}

export default MonitoringService;