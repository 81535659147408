import { useEffect, useState } from 'react';
import { Route , Routes, Navigate, Outlet } from 'react-router-dom';
import { Dashboard } from './components/pages/dashboard/dashboard';
import { InfraDetail } from './components/pages/dashboard/infra/details/detail';
import { PortalOverview } from './components/pages/dashboard/portal/overview/overview';
import { ServiceDetail } from './components/pages/dashboard/service/details/detail';
import { PortalDetail } from './components/pages/dashboard/portal/details/detail';
import { InfraMonitoring } from './components/pages/dashboard/infra';
import { ServiceMonitoring } from './components/pages/dashboard/service';
import { PortalMonitoring } from './components/pages/dashboard/portal';
import RevenueDashboard from './components/pages/dashboard/revenue';
import BusinessPerformanceDashboard from './components/pages/dashboard/revenue/businessPerformance';
import MonitoringDashboard from './components/pages/dashboard/monitoring';
import InfraIncident from './components/pages/dashboard/infra/alarms';
import { InfraSummary } from './components/pages/dashboard/infra/summary/summary';
import PortalIncident from './components/pages/dashboard/portal/alarms';
import ServiceIncident from './components/pages/dashboard/service/alarms';
import { PortalSummary } from './components/pages/dashboard/portal/summary/summary';
import { ServiceSummary } from './components/pages/dashboard/service/summary/summary';
import ServerGraph from './components/pages/dashboard/infra/details/servergraph';
import PortalGraph from './components/pages/dashboard/portal/details/portalgraph';
import { Timesheet } from './components/pages/dashboard/timesheets/Timesheet';
import { TimesheetReport } from './components/pages/dashboard/timesheets/timesheetReport';


import { Home } from './components/pages/common/home';
import { Accessdenied } from './components/pages/common/accessdenied';
import { Settings } from './components/pages/settings/settings';
import { SettingsVM } from './components/pages/settings/settingsVM';
import { SettingsUsers } from './components/pages/settings/settingsUsers';
import { SettingsPortals } from './components/pages/settings/settingsPortals';
import { InstanceServers } from './components/pages/settings/instanceServers';
import { SettingsMasterResellers } from './components/pages/settings/settingsMasterResellers';
import { SettingsDbRepoVersions, SettingsVersions } from './components/pages/settings/settingsVersions';
import { SettingsServiceAlert } from "./components/pages/settings/settingsServiceAlert";
import { SettingsServices } from "./components/pages/settings/settingsServices";
import { SettingsOnCallPlanner } from './components/pages/settings/settingsOnCall';
import { SettingsEmailLogs } from "./components/pages/settings/settingsEmailLogs";
import { SettingsAWSNotifications } from "./components/pages/settings/settingsAWSNotifications";
import { SettingsServerRules } from "./components/pages/settings/settingsServerRules";
import { SettingsUserPermissions } from "./components/pages/settings/settingsUserPermissions";
import { SettingsAPITokens  } from "./components/pages/settings/settingsAPITokens";
import { SettingsEmailAnalysis  } from "./components/pages/settings/settingsEmailAnalysis";
import { SettingsBrandsAnalysis  } from "./components/pages/settings/settingsBrandsAnalysis";
import { SettingsCertificateStatus } from './components/pages/settings/settingsCertificateStatus';
import { DevTest } from './components/pages/settings/devtest';

import { Tools } from './components/pages/tools/tools';
import { BusinessPriorities } from './components/pages/tools/businesspriority';
import SettingsPriorityCustomers from './components/pages/settings/settingsPriorities';
import { SettingsBusinessHours } from "./components/pages/settings/settingsBusinessHours";
import { SettingsHoliday} from "./components/pages/settings/settingsHoliday";
import { SettingsLogs } from './components/pages/settings/settingsLogs';
import { SettingsServerAndVM } from './components/pages/settings/settingsServerAndVM';

import { ServerSM } from './components/pages/serversm/serverSM';
import { ServerOverview } from './components/pages/serversm/serverOverview';
import { ServerServices } from './components/pages/serversm/serverServices';
import { ServerProcesses } from './components/pages/serversm/serverProcesses';
import { ServerIIS } from './components/pages/serversm/serverIIS';
import FileManager from './components/pages/serversm/fileManager';
import { ServerEvents } from './components/pages/serversm/serverEvents';


const ProtectedRoute = (props) => {
    if (!props.isAuthenticated) {
        return <Navigate to={"/"} replace />;
      }
    if (!props.isAllowed) {
      return <Navigate to={props.redirectTo} replace />;
    }
  
    return props.children ? props.children : <Outlet />;
  };

const RoutesStore = (props) => {
    return (
      <Routes>
        <Route path="DevTest" element={<DevTest {...props} />}
            />
        <Route index element={<Home {...props} />} />
        <Route
          element={
            <ProtectedRoute
              {...props}
              isAllowed={!!props.user && props.user.IsDashboardAllowed}
              redirectTo="/accessdenied"
            />
          }
        >
          <Route path="dashboards" element={<Dashboard {...props} />}>
            <Route path="revenue/*" element={<RevenueDashboard {...props}  />} />
              {/* <Route path="overview" element={<RevenueOverview {...props} />} />
              <Route path="fy" element={<RevenueFY {...props} />} /> */}
            {/* </Route> */}
            <Route path="businessPerformance/*" element={<BusinessPerformanceDashboard {...props}  />} />
              {/* <Route path="overview" element={<RevenueOverview {...props} />} />
              <Route path="fy" element={<RevenueFY {...props} />} /> */}
            {/* </Route> */}
            <Route path="timesheets/*" element={<Timesheet {...props}  />} />
            <Route path="timesheetReport/*" element={<TimesheetReport {...props}  />} />
            <Route
              path="monitoring"
              element={<MonitoringDashboard {...props} />}
            >
              <Route path="service" element={<ServiceMonitoring />}>
                  <Route path="detail/:id" element={<ServiceDetail {...props}/>} />
                  <Route path="detail/:id/:type" element={<ServiceDetail {...props}/>} />
                  <Route path="detail" element={<ServiceDetail {...props} />}/>
                  <Route path="summary/:id" element={<ServiceSummary {...props} />} />
                  <Route path="summary" element={<ServiceSummary {...props} />} />
                  <Route path="alarms/:id" element={<ServiceIncident {...props} />} />
                  <Route path="alarms" element={<ServiceIncident {...props} />} />
              </Route>
              <Route path="portal" element={<PortalMonitoring />}>
                <Route
                  path="metrics/:id"
                  element={<PortalGraph {...props} />}
                />
                <Route
                  path="detail/:id"
                  element={<PortalDetail {...props} />}
                />
                <Route
                  path="detail/:id/:type"
                  element={<PortalDetail {...props} />}
                />
                <Route path="detail" element={<PortalDetail {...props} />} />
                <Route path="summary/:id" element={<PortalSummary {...props} />} />
                <Route path="summary" element={<PortalSummary {...props} />} />
                <Route path="alarms/:id" element={<PortalIncident {...props} />} />
                <Route path="alarms" element={<PortalIncident {...props} />} />
                <Route
                  path="overview/:id"
                  element={<PortalOverview {...props} />}
                />
              </Route>
              <Route path="infra" element={<InfraMonitoring />}>
                <Route
                  path="metrics/:id"
                  element={<ServerGraph {...props} />}
                />
                <Route path="summary/:id" element={<InfraSummary {...props} />} />
                <Route path="summary" element={<InfraSummary {...props} />} />
                <Route path="alarms/:id" element={<InfraIncident {...props} />} />
                <Route path="alarms" element={<InfraIncident {...props} />} />
                <Route path="detail/:id" element={<InfraDetail {...props} />} />
                <Route
                  path="detail/:id/:type"
                  element={<InfraDetail {...props} />}
                />
                <Route path="detail" element={<InfraDetail {...props} />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoute
              {...props}
              isAllowed={!!props.user && props.user.IsSettingsAllowed}
              redirectTo="/accessdenied"
            />
          }
        >
          <Route path="settings" element={<Settings {...props} />}>
            <Route path="servers" element={<SettingsVM {...props} />} />
            <Route path="servers_VM" element={<SettingsServerAndVM {...props} />} />
            <Route path="instances" element={<SettingsPortals {...props} />} />
            <Route path="ManageUsers" element={<SettingsUsers {...props} />} />
            <Route
              path="instanceServers"
              element={<InstanceServers {...props} />}
            />
            <Route
              path="prioritycustomers"
              element={<SettingsPriorityCustomers {...props} />}
            />
            {/* <Route path="VpnUsers" element={<SettingsVpnUsers {...props} />} /> */}
            <Route
              path="MasterResellers"
              element={<SettingsMasterResellers {...props} />}
            />
            {/* <Route
              path="Versions"
              element={<SettingsVersions {...props} />}
            /> */}
            <Route
              path="DBRepoVersions"
              element={<SettingsDbRepoVersions {...props} />}
            />
            <Route
              path="ServiceAlert"
              element={<SettingsServiceAlert {...props} />}
					  />
            <Route
              path="Services"
              element={<SettingsServices {...props} />}
					  />
            <Route
              path="OnCallPlanner"
              element={<SettingsOnCallPlanner {...props} />}
					  />
            <Route
              path="EmailLogs"
              element={<SettingsEmailLogs {...props} />}
					  />
             <Route
              path="AWSNotifications"
              element={<SettingsAWSNotifications {...props} />}
					  />
             <Route
              path="ServerRules"
              element={<SettingsServerRules {...props} />}
					  />
             <Route
              path="UserPermissions"
              element={<SettingsUserPermissions {...props} />}
					  />
            <Route
              path="BusinessHours"
              element={<SettingsBusinessHours {...props} />}
					  />
             <Route
              path="Holiday"
              element={<SettingsHoliday {...props} />}
					  />
            <Route
              path="SystemDiagnostics"
              element={<SettingsLogs {...props} />}
					  />
            <Route
              path="APITokens"
              element={<SettingsAPITokens {...props} />}
					  />
             <Route
              path="SentEmailAnalysis"
              element={<SettingsEmailAnalysis {...props} />}
					  />
            <Route
              path="GetBrandsAnalysis"
              element={<SettingsBrandsAnalysis {...props} />}
					  />
            <Route
              path="CertificateStatus"
              element={<SettingsCertificateStatus {...props} />}
            />
           
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoute
              {...props}
              isAllowed={!!props.user && props.user.IsAccessBlocked == false}
              redirectTo="/accessdenied"
            />
          }
        >
          <Route path="Tools" element={<Tools {...props} />}>
            <Route
              path="BusinessPriorities"
              element={<BusinessPriorities {...props} />}
            />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoute
              {...props}
              isAllowed={!!props.user && props.user.IsAccessBlocked == false}
              redirectTo="/accessdenied"
            />
          }
        >
          <Route path="ServerSM" element={<ServerSM {...props} />}>
            <Route
              path="serverServices"
              element={<ServerServices {...props} />}
            />
            <Route
              path="serverOverview"
              element={<ServerOverview {...props} />}
            />
            <Route
              path="serverProcesses"
              element={<ServerProcesses {...props} />}
            />
            <Route
              path="serverIIS"
              element={<ServerIIS {...props} />}
            />
            <Route
              path="serverFile"
              element={<FileManager {...props} />}
            />
              <Route
              path="serverEvent"
              element={<ServerEvents {...props} />}
            />
          </Route>
          
        </Route>
        <Route path="accessdenied" element={<Accessdenied {...props} />} />
        <Route path="*" element={<Home {...props}/>} />
        
      </Routes>
      
    );
}

export default RoutesStore;