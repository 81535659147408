import React, { useState, useEffect } from 'react'
import { Flex, Grid as FluentGrid, Card, CardHeader, CardBody, Button, Input, Image, Segment, List, ListItem, Text, Avatar, Datepicker, Dropdown, RadioGroup, Header, TextArea, Divider, Popup, PillGroup, Pill, Loader } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, EditIcon, SearchIcon, TrashCanIcon } from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import { CellDropDownListUser } from "../../shared/appcomponents/custom-grid-cells";
import SettingsService from '../../services/settingsservice';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TeamsDatePicker } from '../../shared/appcomponents/custom-datetime-inputs';
import { Calendar } from '@progress/kendo-react-dateinputs';
import { classNames } from "@progress/kendo-react-common";
import { formatDate } from '@progress/kendo-intl';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());
    const dates = [];

    //while loop adding one date less at the end so 1  day added
    endDate.setDate(endDate.getDate() +1);

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

const SettingsOnCallPlanner = (props) => {
    const [popOpen, setPopOpen] = useState(false);
    const [onCallDataPlaceholder, setOnCallDataPlaceholder] = useState(null);
    const [onCallData, setOnCallData] = useState(null);
    const [onCallPageData, setOnCallPageData] = useState(null);
    const [occupiedOnCallDates, setOccupiedOnCallDates] = useState(null);
    const [restrictedDates, setRestrictedDates] = useState([]);
    const [maxAllowedDate, setMaxAllowedDate] = useState(null);
    const[isLoaded, SetLoaded] = useState(false);
    React.useEffect(() => {
        props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
        if (token) {
        SettingsService.GetOnCallPlannerPage(token).then((response) => {
            if(response && response.status==200){
                response=response.data;
                response.PeoplePickerList = response.EligibleUsers.map((m) => ({ key: m.AppUserId, header: m.DisplayName, value: m.AppUserId, content: m.AppUserEmail, primarycontact: m.PrimaryContact }))
                setOnCallPageData({ PeoplePickerList: response.PeoplePickerList, EligibleUsers: response.EligibleUsers, ONCallRollOverPolicies: response.ONCallRollOverPolicies, Supervisor: response.Supervisor });
                let _onCallData = {
                    CurrentMemberPrimary: [],
                    CurrentMemberSecondary: [],
                    PlannedMembers: []
                }
                let runningMaxEndDate = new Date(new Date().setHours(0, 0, 0, 0));
                response.MemberData.CurrentMembers.map((m) => {
                    if (m.MemberTypeId == 1) {
                        _onCallData.CurrentMemberPrimary.push({ ...m, StartDate: new Date(m.StartTime), EndDate: new Date(m.EndTime) });
                    }
                    else if (m.MemberTypeId == 2) {
                        _onCallData.CurrentMemberSecondary.push({ ...m, StartDate: new Date(m.StartTime), EndDate: new Date(m.EndTime) });
                    }

                    if (new Date(m.EndTime) > runningMaxEndDate) {
                        runningMaxEndDate = new Date(m.EndTime);
                    }
                });
                response.MemberData.PlannedMembers.map((m) => {
                    _onCallData.PlannedMembers.push({ ...m, StartDate: new Date(m.StartTime), EndDate: new Date(m.EndTime) });
                    if (new Date(m.EndTime) > runningMaxEndDate) {
                        runningMaxEndDate = new Date(m.EndTime);
                    }
                });
                setOnCallData(_onCallData);
                setCalenderData(_onCallData, runningMaxEndDate);
            }
            else if(response && response.status==401){
                props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
                props.viewStatus.InformChildPageStatus(500);
            }  
            SetLoaded(true);         
        });
        }
    };

    const setCalenderData = (_onCallData, runningMaxEndDate) => {
        let plannedDateRange = getDatesInRange(new Date(), runningMaxEndDate);
        let occupiedDates = plannedDateRange.reduce((a, v) => ({ ...a, [new Date(v.setHours(0, 0, 0, 0))]: { Members: [], hasPrimary: false } }), {});

        _onCallData.CurrentMemberPrimary.map((m) => {
            Object.keys(occupiedDates).map((b) => {
                let dateC = new Date(b).getTime();
                if (new Date(m.StartDate).getTime() <= dateC && new Date(m.EndDate).getTime() >= dateC) {
                    occupiedDates[b]?.Members.push(m);
                    occupiedDates[b].hasPrimary = true;
                }
            });
        });
        _onCallData.CurrentMemberSecondary.map((m) => {
            Object.keys(occupiedDates).map((b) => {
                let dateC = new Date(b).getTime();
                if (new Date(m.StartDate).getTime() <= dateC && new Date(m.EndDate).getTime() >= dateC) {
                    occupiedDates[b]?.Members.push(m);
                }
            });
        });
        _onCallData.PlannedMembers.map((m) => {
            Object.keys(occupiedDates).map((b) => {
                let dateC = new Date(b).getTime();
                if (new Date(m.StartDate).getTime() <= dateC && new Date(m.EndDate).getTime() >= dateC) {
                    occupiedDates[b]?.Members.push(m);
                    if (m.MemberTypeId == 1) {
                        occupiedDates[b].hasPrimary = true;
                    }
                }
            });
        });
        let _restrictedDates = [];
        Object.keys(occupiedDates).map((o) => {
            if (occupiedDates[o]?.hasPrimary) {
                _restrictedDates.push(new Date(o));
            }
        });
        setOccupiedOnCallDates(occupiedDates);
        setRestrictedDates(_restrictedDates);
    }

    const handleSupervisorUpdate = (user) => {
        let _supervisor = onCallPageData.EligibleUsers?.filter(f => f.AppUserEmail == user)[0];
        props.acquireToken((token) => {
            if (token) {
                SettingsService.UpdateOnCallSupervisor(token, _supervisor).then((response) => {
                    if(response && response.status==200){
                        response=response.data;
                        setOnCallPageData({ ...onCallPageData, Supervisor: _supervisor });
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                      }
                      else {
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                      }
                });
            }
        });
    };

    const handleMemberAdd = () => {

        let sd ;
        let startTime;
        let ed ;
        let endTime;

        if(onCallDataPlaceholder && onCallDataPlaceholder.StartTime){
            sd = onCallDataPlaceholder?.StartTime;
            startTime = (new Date(Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate(), 0, 0, 0))).toISOString();
        }

        if(onCallDataPlaceholder && onCallDataPlaceholder.EndTime){
            ed = onCallDataPlaceholder?.EndTime;
            endTime = (new Date(Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate() , 23, 55, 0, 0))).toISOString();
        }

        

        let _onCallMember = {
            MemberTypeId: onCallDataPlaceholder?.OnCallRoleId,
            MemberTypeName: onCallDataPlaceholder?.OnCallRoleName,
            UserId: onCallDataPlaceholder?.Member?.value,
            DisplayName: onCallDataPlaceholder?.Member?.header,
            EmailId: onCallDataPlaceholder?.Member?.content,
            PrimaryContact: onCallDataPlaceholder?.Member?.primarycontact,
            StartTime: startTime,
            EndTime: endTime,
            StartDate: onCallDataPlaceholder?.StartTime,
            EndDate: onCallDataPlaceholder?.EndTime
        };

        props.acquireToken((token) => {
            if (token) {
                SettingsService.AddOnCallMember(token, _onCallMember).then((response) => {
                    if(response && response.status==200){
                        response=response.data;
                        authSuccessCallback(token);
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                      }
                      else {
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                      }
                });
            }
        });
        setPopOpen(false);
        setOnCallDataPlaceholder(null);
    };
    const handleMemberDelete = (_memberId) => {
        props.acquireToken((token) => {
            if (token) {
                SettingsService.RemoveOnCallMember(token, { memberId: _memberId }).then((response) => {
                    if(response && response.status==200){
                        response=response.data;
                        authSuccessCallback(token);
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    }
                    else if(response && response.status==401){
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                      }
                      else {
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                      }
                });
            }
        });
        setOnCallDataPlaceholder(null);
    };
    const getMaxAllowedDate = (_date) => {
        for (let i = 0; i < restrictedDates.length; i++) {
            if (restrictedDates[i].getTime() >= _date.getTime()) {
                return restrictedDates[i];
            }
        }
        return new Date(new Date().setDate(new Date().getDate() + 90));
    }

    const CustomCell = (props) => {
        const title = "Occupied";

        const handleClick = () => {
            if (!props.isWeekend) {
                if (props.onClick) {
                    props.onClick(props.value);
                }
            }
        };

        const style = {
            cursor: "pointer",
            verticalAlign: 'top'
        };
        const className = classNames({
            "k-selected": props.isSelected,
            "k-focus": props.isFocused,
        });
        const titleValue = occupiedOnCallDates[props.value] ? title : "";
        return (
            <td onClick={handleClick} className={className} style={style}>
                <span className="k-link" style={{ width: '100%' }} title={titleValue}>
                    {props.children}
                </span>
                <PillGroup key={props.value.getTime()} style={{ alignItems: 'center', flexDirection: 'column' }}>
                    {
                        occupiedOnCallDates[props.value]?.Members?.map((m) => (
                            <Pill size="smaller" className={m.MemberTypeName} content={m.DisplayName} key={`${props.value.getTime()}-${m.MemberId}`} />
                        ))
                    }
                </PillGroup>
            </td>
        );
    };

    return isLoaded ? (
    <div className='row h-100'>
        <div className='col-md-12 h-100'>
            <Card className='card-border bg-white h-100' fluid>
                <CardHeader className='mb-2'>
                    <Flex vAlign="center" className='header-flex flex-sm-column'>
                        <div className='f-size-18 mb-sm-2'>On-Call Planner</div>
                        <Flex.Item push className='ml-sm-auto'>
                            <Text className='mr-2 mb-sm-2 ml-sm-0 ml-0 ml-md-auto' content="Supervisor:" weight="bold" />
                        </Flex.Item>
                        <Dropdown className='mr-3 mb-sm-2 supervisor-dropdown'
                            disabled={!props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.OnCall.ReadWrite" ))}
                            placeholder='Choose On-Call Supervisor'
                            items={onCallPageData?.PeoplePickerList}
                            value={onCallPageData?.PeoplePickerList?.filter(f => f.content == onCallPageData?.Supervisor?.AppUserEmail)[0]}
                            onChange={(e, data) => handleSupervisorUpdate(data.value.content)} />
                        { props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.OnCall.ReadWrite" )) &&
                        <Popup open={popOpen} pointing trapFocus autoSize trigger={<Button onClick={() => setPopOpen(true)} content="Add Member to Plan" primary />} content={<div>
                            <FluentGrid columns={1} className="mt-2" style={{ gridTemplateColumns: '1fr', alignItems: 'center', gridGap: '25px' }}>
                                <FluentGrid columns={2} style={{ gridTemplateColumns: '120px 1fr', alignItems: 'center', height: '100%', gridGap: '4px' }}>
                                    <Text weight="bold" content="Member Role:" />
                                    <RadioGroup
                                        onCheckedValueChange={(e, data) => setOnCallDataPlaceholder({ ...onCallDataPlaceholder, OnCallRoleId: data.value, OnCallRoleName: data.label })}
                                        items={[
                                            {
                                                key: '1',
                                                label: 'Primary',
                                                value: '1',
                                            },
                                            {
                                                key: '2',
                                                label: 'Secondary',
                                                value: '2',
                                            }]} />
                                    <Text weight="bold" content="Member:" />
                                    <Dropdown fluid
                                        placeholder='Choose Member'
                                        items={onCallPageData?.PeoplePickerList}
                                        onChange={(e, data) => setOnCallDataPlaceholder({ ...onCallDataPlaceholder, Member: data.value })} />
                                </FluentGrid>
                                <Text weight="bold" content="Member On-Call Period:" />
                                <FluentGrid className='mb-2' columns={5} style={{ gridTemplateColumns: '50px 1fr 50px 1fr', alignItems: 'center', gridGap: '6px' }}>
                                    <Text weight="bold" content="From:" />
                                    <TeamsDatePicker disabled={!onCallDataPlaceholder?.OnCallRoleId} restrictedDates={onCallDataPlaceholder?.OnCallRoleId == 1 ? restrictedDates : []} value={onCallDataPlaceholder?.StartTime} min={new Date()} 
                                        onChange={(_date) => {  
                                                                setOnCallDataPlaceholder({ ...onCallDataPlaceholder, StartTime: _date }); 
                                                                setMaxAllowedDate(getMaxAllowedDate(_date)) ;
                                                                }} />
                                    <Text className='text-center' weight="bold" content="To:" />
                                    <TeamsDatePicker disabled={!(onCallDataPlaceholder?.OnCallRoleId && onCallDataPlaceholder?.StartTime)} restrictedDates={onCallDataPlaceholder?.OnCallRoleId == 1 ? restrictedDates : []} value={onCallDataPlaceholder?.EndTime} min={onCallDataPlaceholder?.StartTime > new Date() ? onCallDataPlaceholder?.StartTime : new Date()} max={onCallDataPlaceholder?.OnCallRoleId == 1 ? maxAllowedDate : null} onChange={(_date) => setOnCallDataPlaceholder({ ...onCallDataPlaceholder, EndTime: _date })} />
                                </FluentGrid>
                            </FluentGrid>
                            <Flex className='mt-5'>
                                <Flex.Item push>
                                    <Button className='mr-2' onClick={() => setPopOpen(false)} content="Cancel" />
                                </Flex.Item>
                                <Button disabled={!(onCallDataPlaceholder && onCallDataPlaceholder.Member
                                    && onCallDataPlaceholder.OnCallRoleId && onCallDataPlaceholder.StartTime && onCallDataPlaceholder.EndTime)} content="Add" primary
                                    onClick={handleMemberAdd} />

                            </Flex>
                        </div>} />}
                    </Flex>
                </CardHeader>
                <CardBody style={{ overflow: 'auto' }} className="cal-cb">
                    <div className='row m-0 h-100'>
                        <div className='col-sm-7 col-md-6'>
                            <div className='row ml-0'>
                                <div className='col-md-12 p-0'>
                                    <Text content={`Current On-Call`} weight='bold' />
                                    <FluentGrid className='current-members mt-1' columns={2} style={{ gridGap: '8px' }}>
                                        {
                                            onCallData?.CurrentMemberPrimary[0] != null ?
                                                <Segment className={'Primary'} style={{ width: '100%', height: 'auto', padding: '5px 8px' }}>
                                                    <CardHeader className='mb-0'>
                                                        <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
                                                            <p className='m-0'>
                                                                <Text weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberPrimary[0]?.StartTime), "dd-MMM-yyyy")} />
                                                                <Text content={" to "} />
                                                                <Text className="mb-2" weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberPrimary[0]?.EndTime), "dd-MMM-yyyy")} />
                                                            </p>
                                                            <Text size="medium" content={onCallData?.CurrentMemberPrimary[0]?.MemberTypeName} />
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <FluentGrid style={{ gridGap: '4px', alignItems: 'center', justifyItems: 'top', gridTemplateColumns: '50px 1fr' }} columns={2} hAlign="center" vAlign="top">
                                                            <Avatar name={onCallData?.CurrentMemberPrimary[0]?.DisplayName} size="large" />
                                                            <div>
                                                                <Flex column>
                                                                    <Text size="large" content={onCallData?.CurrentMemberPrimary[0]?.DisplayName} />
                                                                    <Text className='mb-1' content={onCallData?.CurrentMemberPrimary[0]?.EmailId} />
                                                                    <Text className='mb-1' content={onCallData?.CurrentMemberPrimary[0]?.PrimaryContact} />
                                                                    <Text size="small" content={`Last Login: ${formatDate(new Date(onCallData?.CurrentMemberPrimary[0]?.LastLoginTimeStamp), "dd-MMM-yyyy HH:mm:ss")}`} />
                                                                </Flex>
                                                            </div>
                                                        </FluentGrid>
                                                    </CardBody>
                                                </Segment>
                                                : <></>
                                        }
                                        <FluentGrid columns={1} style={{ gridGap: '8px' }}>
                                            {
                                                onCallData?.CurrentMemberSecondary[0] != null ?
                                                    <Segment className={'Secondary'} style={{ width: '100%', height: 'auto', padding: '5px 8px' }}>
                                                        <CardHeader className='mb-0'>
                                                            <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
                                                                <p className='m-0'>
                                                                    <Text weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberSecondary[0]?.StartTime), "dd-MMM-yyyy")} />
                                                                    <Text content={" to "} />
                                                                    <Text className="mb-2" weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberSecondary[0]?.EndTime), "dd-MMM-yyyy")} />
                                                                </p>
                                                                <Text size="medium" content={onCallData?.CurrentMemberSecondary[0]?.MemberTypeName} />
                                                            </div>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Flex hAlign="center" column vAlign="center">

                                                            </Flex>
                                                            <FluentGrid style={{ gridGap: '4px', alignItems: 'center', justifyItems: 'top', gridTemplateColumns: '50px 1fr' }} columns={2} hAlign="center" vAlign="top">
                                                                <Avatar
                                                                    name={onCallData?.CurrentMemberSecondary[0]?.DisplayName}
                                                                    size="large"
                                                                />
                                                                <div>
                                                                    <Flex column>
                                                                        <Text size="medium" content={onCallData?.CurrentMemberSecondary[0]?.DisplayName} />
                                                                        <Text size="small" className='mb-1' content={onCallData?.CurrentMemberSecondary[0]?.EmailId} />
                                                                        <Text size="small" className='mb-1' content={onCallData?.CurrentMemberSecondary[0]?.PrimaryContact} />
                                                                        <Text size="small" content={`Last Login: ${formatDate(new Date(onCallData?.CurrentMemberSecondary[0]?.LastLoginTimeStamp), "dd-MMM-yyyy HH:mm:ss")}`} />
                                                                    </Flex>
                                                                </div>
                                                            </FluentGrid>
                                                        </CardBody>
                                                    </Segment>
                                                    : <></>
                                            }
                                            {
                                                onCallData?.CurrentMemberSecondary?.length > 1 ?
                                                    <Segment className={'Secondary'} style={{ width: '100%', height: 'auto', padding: '5px 8px 5px 8px' }}>
                                                        <CardBody>
                                                            <Text weight="bold" className="text-center" content={`+ ${onCallData?.CurrentMemberSecondary?.length - 1} more Secondary Member`} />
                                                        </CardBody>
                                                    </Segment>
                                                    : <></>
                                            }
                                        </FluentGrid>
                                    </FluentGrid>
                                </div>
                                {/* <div className='col-md-12 mt-3 p-0'>
                                    <Text content={`Upcoming On-Call Members`} weight='bold' />
                                    <Flex className='planned-members mt-3 pl-1' fluid wrap style={{ height: '300px', overflowY: 'auto', alignItems: 'flex-start' }}>
                                        {
                                            onCallData?.PlannedMembers?.map((m) => (
                                                <Segment className={`${m?.MemberTypeName}`}>
                                                    <CardHeader>
                                                        <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
                                                            <Text size="medium" content={m?.MemberTypeName} />
                                                            <Button icon={<TrashCanIcon size="small" />} size="small" circular iconOnly title="Remove Member" primary onClick={() => handleMemberDelete(m.MemberId)} />
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Flex hAlign="center" className="mb-2" vAlign="center">
                                                            <Text weight="bold" content={formatDate(new Date(m?.StartTime), "dd-MMM-yyyy")} />
                                                            <Text className="mx-1" content={" to "} />
                                                            <Text weight="bold" content={formatDate(new Date(m?.EndTime), "dd-MMM-yyyy")} />
                                                        </Flex>

                                                        <div className='d-flex'>
                                                            <Avatar primary name={m?.DisplayName} size="medium" />
                                                            <div className='pl-2 avt-details'>
                                                                <Flex column>
                                                                    <Text size="medium" content={m?.DisplayName} />
                                                                    <Text size="small" className='mb-1' content={m?.EmailId} />
                                                                    <Text size="small" className='mb-1' content={m?.PrimaryContact} />
                                                                </Flex>
                                                            </div>
                                                        </div>


                                                    </CardBody>
                                                </Segment>
                                            ))
                                        }
                                    </Flex>
                                </div> */}
                            </div>
                        </div>


                        <div className='col-md-12 col-lg-12 calender-view h-100'>
                            
                            {occupiedOnCallDates ?
                                <Calendar className='oncall-calender' cell={CustomCell} navigation={false} min={new Date()} max={new Date(new Date().setDate(new Date().getDate() + 90))} />
                                : <></>
                            }
                        </div>
                    </div>

                </CardBody>
            </Card>
        </div>
    </div>
    ) : (<Loader />)    
}

export { SettingsOnCallPlanner };