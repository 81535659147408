import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input, Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const SettingsServerRules = (props) => {
  const [ruleList, setRuleList] = React.useState([]);
  const [ruleListOrig, setRuleListOrig] = React.useState([]);
  const [serverRules, setServerRules] = React.useState(null);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [placeholderRuleId, setPlaceholderRuleId] = useState(-1);
  const [placeholderRulePriorityLevel, setPlaceholderRulePriorityLevel] = useState(null);
  const [placeholderRuleDesc, setPlaceholderRuleDesc] = useState();
  const [placeholderRuleName, setPlaceholderRuleName] = useState();
  const [isValid, setValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteVersionObj, setDeleteVersionObj] = useState(null);
  const editField = "inEdit";

  const addEditSubmitClickHandler = () => {
    {
      props.acquireToken((token) => {
        if (token) {
          var result = ruleList.reduce(
            (obj, item) => ((obj[item.ruleName] = {value: item.value, description: item.description}), obj),
            {}
          );
          var serverRule = {
            RuleId: placeholderRuleId,
            PriorityLevel: placeholderRulePriorityLevel,
            RuleName: placeholderRuleName,
            RuleDesc: placeholderRuleDesc,
            [placeholderRuleName]: result,
          };
          debugger          
          if (editMode) {
            SettingsService.UpdateServerRule(token, serverRule).then(
              (response) => {
                if(response && response.status==200){
                  response=response.data;               
                  props.acquireToken(authSuccessCallback);
                  props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);              
              }
                else if(response && response.status==401){
                  props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                  debugger;
                }
                else{
                  props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

                  debugger;
                }
              }
            );
          } else {
            // no add allowed
          }
        }
      });
      setVisible(false);
      setEditMode(false);
    }
  };
  const expandChange = (event) => {
    let newData = serverRules.map((item) => {
      if (item.RuleId === event.dataItem.RuleId) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setServerRules(newData);
  };

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "VersionNumber",
        dir: "asc",
      },
    ],
    // take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [
        { field: "PriorityLevel", operator: "contains", value: value },
        { field: "RuleName", operator: "contains", value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetServerRules(token).then((response) => {
        if(response && response.status==200){
          response=response.data;
          setServerRules(Object.values(response).flat());
        }
        else if(response && response.status==401){
          props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
          props.viewStatus.InformChildPageStatus(500);
          }  
          SetLoaded(true);    
      });
    }
  };

  const ActionIconServerRules = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<EditIcon />}
          text
          onClick={() => handleAddEditClickOpen(props.dataItem, true)}
        />
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteVersionObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteVersionObj != null) {
      props.acquireToken((token) => {
        if (token) {
          let _params = {
            versionNumber: deleteVersionObj?.RuleName,
          };
          SettingsService.RemoveDbRepoVersion(token, _params).then(
            (response) => {
              if(response && response.status==200){
                response=response.data;
                props.acquireToken(authSuccessCallback);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
              } 
              else if(response && response.status==401){
                debugger;
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
      
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
              }
            }
          );
        }
      });
    }
    setDeleteConfirmationDialog(false);
    setDeleteVersionObj(null);
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    debugger
    if (isEdit === true) {
      setPlaceholderRuleId(dataItem.RuleId);
      setPlaceholderRulePriorityLevel(dataItem.PriorityLevel);
      setPlaceholderRuleDesc(dataItem.RuleDesc);
      setPlaceholderRuleName(dataItem.RuleName);
      debugger
      let ruleValue = dataItem[dataItem.RuleName];
        let ruleArray = Object.keys(ruleValue).map((m) => ({
          ruleName: m,
          value: ruleValue[m].value,
          description: ruleValue[m].description,
        }));
        setRuleList(ruleArray);
        setRuleListOrig(ruleArray);
        setValid(false);
        setEditMode(true);
    } else {
      //Add server rule logic
    }
    setVisible(true);
  };

  const handleAddEditClickClose = () => {
    setVisible(false);
    setPlaceholderRuleId(-1);
    setPlaceholderRulePriorityLevel(null);
    setPlaceholderRuleDesc(null);
    setPlaceholderRuleName(null);
    setEditMode(false);
    setValid(false);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <p>
          {dataItem.RuleKeyDescriptive}
        </p>
      </section>
    );
  };

   const CommandCell = (props) => (
     <MyCommandCell
       {...props}
       edit={enterEdit}
       remove={remove}
       add={add}
       discard={discard}
       update={update}
       cancel={cancel}
       editField={editField}
     />
   ); // modify the data in the store, db etc

   const remove = (dataItem) => {
     //const newData = deleteItem(dataItem);
     //setRuleList(newData);
   };

   const add = (dataItem) => {
     //dataItem.inEdit = true;
     //const newData = insertItem(dataItem);
     //setRuleList(newData);
   };

   const update = (dataItem) => {
     dataItem.inEdit = false;
     const newData = updateItem(dataItem);
     setRuleList(newData);
     setRuleListOrig(newData);
   }; // Local state operations

   const updateItem = (item) => {
     let index = ruleList.findIndex(
       (record) => record.ruleName === item.ruleName
     );
     ruleList[index] = item;
     ruleListOrig[index] = item;
     return ruleList;
   };

   const discard = (dataItem) => {
     const newData = [...ruleList];
     newData.splice(0, 1);
     setRuleList(newData);
   };

   const cancel = (dataItem) => {
     const originalItem = ruleListOrig.find(
       (p) => p.ruleName === dataItem.ruleName
     );
     const newData = ruleList.map((item) =>
       item.ruleName === originalItem.ruleName ? originalItem : item
     );
     setRuleList(newData);
     setRuleListOrig(newData);
   };

   const enterEdit = (dataItem) => {
     let newData = ruleList.map((item) =>
       item.ruleName === dataItem.ruleName ? { ...item, inEdit: true } : item
     );
     setRuleList(newData);
   };

   const itemChange = (event) => {
     const field = event.field || "";
     const newData = ruleList.map((item) =>
       item.ruleName === event.dataItem.ruleName
         ? { ...item, [field]: event.value }
         : item
     );
     setRuleList(newData);
   };


  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">Server Rules</div>
                  {/* <Button
                    icon={<AddIcon />}
                    iconPosition="before"
                    content="Add Server Rule"
                    primary
                    title="Add Server Rule"
                    onClick={() => handleAddEditClickOpen(null, false)}
                  /> */}
                </div>
                
                <div>
                  {visible ? (
                    <Dialog
                      title={editMode ? "Edit Server Rule" : "Add Server Rule"}
                      onClose={handleAddEditClickClose}
                      className="mid-wide"
                    >
                      <Form onSubmit={submitHandler}>
                        <FormInput
                          label={`Priority: ${placeholderRulePriorityLevel}, Rule Name: ${placeholderRuleName}`}
                          name="VersionDesc"
                          id="VersionDesc-inline"
                          fluid
                          value={placeholderRuleDesc}
                          onChange={(e, { value }) => {
                            setPlaceholderRuleDesc(value);
                          }}
                          //onBlur={(e) => checkValid()}
                        />
                        <Grid
                          style={{
                            height: "100%",
                          }}
                          data={process(ruleList, dataState)}
                          {...dataState}
                          onDataStateChange={(e) => {
                            setDataState(e.dataState);
                          }}
                          //   detail={
                          //     context.mediaCategory.sm ? DetailComponentSm : null
                          //   }
                          //   expandField="expanded"
                          //   onExpandChange={expandChange}
                          onItemChange={itemChange}
                          editField={editField}
                          dataItemKey={"RuleName"}
                        >
                          <Column
                            field="ruleName"
                            title="Parameter"
                            editable={false}
                            width={context.mediaCategory.sm ? 70 : 280}
                          />
                          {!context.mediaCategory.sm && (
                            <Column
                              field="description"
                              title="Description"
                            />
                          )}
                          {!context.mediaCategory.sm && (
                            <Column field="value" title="Value" width={70} />
                          )}
                          <Column cell={CommandCell} width={180} />
                        </Grid>
                        <DialogActionsBar style={{ border: "none" }}>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            //disabled={!isValid}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  ) : (
                    <></>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className="grid-with-search">
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    sortable={true}
                    data={process(serverRules, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column field="PriorityLevel" title="Associated Priority" width={context.mediaCategory.sm ? 100 : 170} />
                    <Column
                      field="RuleName"
                      title="Rule Name"
                      width={context.mediaCategory.sm ? 100 : 170}
                    />
                    {!context.mediaCategory.sm && (
                      <Column
                        field="RuleDesc"
                        title="Description"
                      />
                    )}
                    {/* {!context.mediaCategory.sm && (
                      <Column
                        field="RuleKeyDescriptive"
                        title="Rule Key Descriptive"
                        width={400}
                      />
                    )} */}
								{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                    
                    <Column
                      field=""
                      title="Actions"
                      style={{ textAlign: "center" }}
                      cell={ActionIconServerRules}
                      width={100}
                    />}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteVersionObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteVersionObj(null);
              },
            }}
            content={`Are you sure to the Rule: ${deleteVersionObj?.RuleName}?`}
          />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsServerRules };

export const MyCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.ruleName === undefined;
  return inEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      >
        {isNewItem ? "Add" : "Update"}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
      >
        {isNewItem ? "Discard" : "Cancel"}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={() => props.edit(dataItem)}
      >
        Edit
      </button>
      {/* <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
        onClick={() =>
          //confirm("Confirm deleting: " + dataItem.ProductName) &&
          props.remove(dataItem)
        }
      >
        Remove
      </button> */}
    </td>
  );
};