import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, Label, Checkbox } from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import {CellPermissionGroupNames} from "../../shared/appcomponents/custom-grid-cells"
import { OPERATIONSTATUS } from "../../shared/appcomponents/enums";
// import { Checkbox } from "@progress/kendo-react-inputs";

const SettingsUserPermissions = (props) => {
	const [UserPermissions, setUserPermissions] = React.useState(null);
	const [permissionData, setpermissionData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deletepermissionObj, setDeletepermissionObj] = useState(null);

	const [ispermissionIdValid, SetispermissionIdValid] = React.useState(true);
	const [ispermissionIdDisabled, setIspermissionIdDisabled] = useState(false);
	const [permissions, setPermissions] = useState(null);

	const [chkState, setChkState] = React.useReducer(
		(state, action) => {
      return { ...state, ...action }
    },
		{},
  )

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "permissionId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
			// SetLoaded(false);
      SettingsService.GetUserPermissions(token).then((response) => {
        if(response && response.status==200){
            response=response.data;
          setUserPermissions(response.PermissionGroups);
		  
					setPermissions(response.Permissions);
debugger
					if(response.Permissions && response.Permissions.length > 0){
						for(let i = 0; i < response.Permissions.length; i++){
							let p = response.Permissions[i].Key;
							setChkState({ 
								[p] : false
							});
						}
					}
		}
		else if(response && response.status==401){
			props.viewStatus.InformChildPageStatus(response.status);
			}
			else {
			props.viewStatus.InformChildPageStatus(500);
			}  
			SetLoaded(true);    
      });
    }
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "permissionId", operator: "contains", value: value }],
    };
    setDataState({ ...dataState, filter: filter });
  };

	const checkChange = (e, data) => {
		let p = data.permissionid;
		setChkState({
			[p]: data.checked,
		})
		
	}

	const handleAddEditClickOpen = (dataItem, isEdit) => {
    // console.log(dataItem);
		SetispermissionIdValid(true);
    setVisible(true);

    if (isEdit === true) {
			let ids = dataItem.PermissionIds.split(',');

			for(let i = 0; i < permissions.length ; i++){
				let p = permissions[i].Key;

				if(ids && ids.includes(permissions[i].Key)){
					setChkState({
						[p]: true,
					});
				}
				else{
					setChkState({
						[p]: false,
					})
				}
			}
      setpermissionData(dataItem);
      setEditMode(true);
			setIspermissionIdDisabled(true);
      // checkIsValid();
    }
		else{
			for(let i = 0; i < permissions.length ; i++){
				let p = permissions[i].Key;
				setChkState({
					[p]: false,
				});
			}

			setpermissionData(null) ;
			setIspermissionIdDisabled(false);
		}
  };

	const handleAddEditClickClose = () => {
    setVisible(false);
    setpermissionData(null);
    setEditMode(false);
    setIsValid(false);
  };

	const ActionIconVersions = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
		{
			props1.dataItem.GroupName != "System Admin" && props1.dataItem.GroupName != "Sales Manager"?
			<>
			<Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text 
					onClick={() => handleAddEditClickOpen(props1.dataItem, true)}
					/>
			<Button
			styles={{ height: "auto" }}
			iconOnly
			size="small"
			icon={<TrashCanIcon />}
			text
			onClick={() => {
				setDeletepermissionObj(props1.dataItem);
				setDeleteConfirmationDialog(true);
			}}
			/>
			</> : <></>
		}
      </td>
    );
  };

  const removeData = () => {
    if (deletepermissionObj != null) {
      props.acquireToken((token) => {
        if (token) {
          let _params = deletepermissionObj;
           SettingsService.RemoveUserPermission(_params, token).then(
             (response) => {
				if(response && response.status==200){
					response=response.data;
                 props.acquireToken(authSuccessCallback);
				 props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
               } 
			    else if(response && response.status==401){
				debugger;
				props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
	  
			  }
			  else {
				props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
			  }
             }
           );
        }
      });
    }
    setDeleteConfirmationDialog(false);
    setDeletepermissionObj(null);
  };

	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		if(isValid == true){
			props.acquireToken((token) => {
				if (token) {
					
					permissionData.PermissionIds = Object.keys(chkState).filter(x => chkState[x] ==  true).toString();

					if (editMode) {
						SettingsService.EditUserPermission(permissionData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

							} 
							else if(response && response.status==200){
								response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}  else if(response && response.status==401){
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
							  }
							  else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							  }
						});
					} else {
						SettingsService.AddUserPermission(permissionData, token).then((response) => {
							if (response && response.data == '-1') {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							} 
							else if(response && response.status==200){
								response=response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}  else if(response && response.status==401){
								debugger;
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
							  }
							  else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							  }
						});
					}
				}      
				setVisible(false);
				setEditMode(false);
				setIsValid(false);
			});
		}
   
  };

	const CheckForm = () => {
    let isValid = true;
	
		if(permissionData?.GroupName && permissionData?.GroupName.toString().length > 0){
			isValid = true;
		}
		else{
			isValid = false;
			SetispermissionIdValid(false);
		}
	
		return isValid;
		
  };

	const expandChange = (event) => {
		let newData = UserPermissions.map((item) => {
			if (item.permissionId === event.dataItem.permissionId) {
				item.expanded = !event.dataItem.expanded;
			}

			return item;
		});
		setUserPermissions(newData);
	};

	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between">
								<div className="f-size-18">User Permission Groups</div>
								
								{(permissions && permissions.some(f => f.Value == 'Settings.ReadWrite' || f.Value == 'Settings.ManageLogin.ReadWrite'))&&
								<Button
									icon={<AddIcon />}
									iconPosition="before"
									content="Add Permission Group"
									primary
									title="Add Permission Group"
									onClick={handleAddEditClickOpen} className="ml-auto"
								/>}
							</div>
								
								<div>
								{visible && (
									<Dialog
										title={editMode ? "Edit Permission Group" : "Add Permission Group"}
										onClose={handleAddEditClickClose}
										width={800}
										className="permissions-dailog"
									>
										<Form >
											<FormInput
											label= {ispermissionIdValid == true ? "Permission Group Name* " : <Text color="red" content="Permission Group Name *Required" />}
												name="groupName"
												id="groupName-inline"
												fluid
												
												value={permissionData?.GroupName}
												onChange={(value) =>
													{
														if(value.target.value && value.target.value.length > 0){
															SetispermissionIdValid(true);
														}
														else{
															SetispermissionIdValid(false);
														}
														setpermissionData({
															...permissionData,
															GroupName: value.target.value,
														})
													}
												}
											/>

											<Text content="Permissions" size="large" className="permissions-title"></Text>
											
											<div>
												<div className="permissions-grid row" >
													<div className="col-md-12 permissions-grid-title" >
														<Text content="Dashboards" size="large"  ></Text>
													</div>
													{ permissions ?
														permissions.filter(x => x.Value.includes('Dashboards')).map(item => (  
														<div className="col-md-6 d-flex align-items-center">
															<Checkbox 
																permissionid={item.Key} 
																onChange={checkChange} 
																checked={chkState ? chkState[item.Key] :  false} 
																label={item.Value}
																className="permission-checkbox">
															</Checkbox>
														</div>
														))  
														: null
													}

													<div className="col-md-12 permissions-grid-title permissions-grid-title-settings" >
														<Text content="Settings" size="large"  ></Text>
													</div>
													{ permissions ?
														permissions.filter(x => x.Value != "Settings.ReadWrite" && x.Value.includes('Settings')).map(item => (  
															<div className="col-md-6 d-flex align-items-center">
																<Checkbox 
																	permissionid={item.Key} 
																	onChange={checkChange} 
																	checked={chkState ? chkState[item.Key] :  false} 
																	label={item.Value}
																	className="permission-checkbox">
																</Checkbox>
															</div>
														))  
														: null
													}

												</div>
											</div>
											

											<DialogActionsBar className="button-container" layout="end">
												<Button content="Cancel"   onClick={handleAddEditClickClose} className="settings-button"/>
												<Button content="Submit"
												disabled={
													permissionData?.GroupName == "System Admin" ||
													!(permissionData && permissionData?.GroupName?.length > 0 && Object.keys(chkState)?.length > 0)}
												primary onClick={addEditSubmitClickHandler} className="settings-button"/>
											</DialogActionsBar>

										</Form>
									</Dialog>
								)}
							</div>
						</CardHeader>
						<CardBody>
							<div className="tbl-search float-right text-right mb-2">
								<Input
									onChange={(e, { name, value }) => {
										filterGridData(value);
									}}
									inverted
									icon={<SearchIcon />}
									placeholder="Search..."
									className="border-gray"
								/>
							</div>
							<div className='grid-with-search'>
								<Grid
									style={{
										height: "100%",
									}}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
									data={process(UserPermissions, dataState)}
									{...dataState}
									onDataStateChange={(e) => {
										setDataState(e.dataState);
									}}
								>
									{/* <Column field="permissionId" title="permission ID" /> */}
									
									<Column
										field="GroupName"
										title="Permission Group"
										width={150}
									/>
									
									<Column
										field="Permissions"
										title="Permissions"
										cell={CellPermissionGroupNames}
										width={600}
									/>
								 {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite")) &&
								 
									<Column
										field=""
										title="Actions"
										style={{ textAlign: "center" }}
										width={100}
										cell={ActionIconVersions}
									/>}
								</Grid>
							</div>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							
						</CardBody>
					</Card>
				</div>
				<FluentDialog
					cancelButton="Cancel"
					confirmButton="Confirm"
					onCancel={() => {
						setDeleteConfirmationDialog(false);
						setDeletepermissionObj(null);
					}}
					onConfirm={() => removeData()}
					open={openDeleteConfirmationDialog}
					backdrop
					header="Action confirmation"
					headerAction={{
						icon: <CloseIcon />,
						title: "Close",
						onClick: () => {
							setDeleteConfirmationDialog(false);
							setDeletepermissionObj(null);
						},
					}}
					 content={<> Are you sure to delete permission group <strong>{deletepermissionObj?.GroupName}</strong> ?</>}
				/>
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { SettingsUserPermissions };
