import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input, Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Box} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, ClipboardCopiedToIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellConfigurationState } from "../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const SettingsVersions = (props) => {
  const [versions, setVersions] = React.useState(null);
  const [versionData, setVersionData] = React.useState(null);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteVersionObj, setDeleteVersionObj] = useState(null);


  useEffect(() => checkIsValid(), [versionData]);

  const addEditSubmitClickHandler = () => {
    props.acquireToken((token) => {
      if (token) {
        if (editMode) {
          SettingsService.UpdateVersion(token, versionData).then((response) => {
            if (response) {
              props.acquireToken(authSuccessCallback);
             
            } else {

            }
          });
        } else {
          SettingsService.AddVersion(versionData, token).then((response) => {
            if (response) {
              props.acquireToken(authSuccessCallback);
              
            } else {
             
            }
          });
        }
      }      
      setVisible(false);
      setEditMode(false);
      setIsValid(false);
    });
  };


  const expandChange = (event) => {
    let newData = versions.map((item) => {
      if (item.VersionId === event.dataItem.VersionId) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setVersions(newData);
  };

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "VersionNumber",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "VersionNumber", operator: "contains", value: value }],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetVersions(token).then((response) => {
        if (response) {
          setVersions(response);
          SetLoaded(true);
        }
      });
    }
  };

  const ActionIconVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteVersionObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteVersionObj != null) {
      props.acquireToken((token) => {
        if (token) {
          let _params = {
            VersionId: deleteVersionObj.VersionId,
          };
          SettingsService.RemoveVersion(token, _params).then((response) => {
            if (response) {
              props.acquireToken(authSuccessCallback);
            
            } else {
            
            }
          });
        }
      });
    }
    setDeleteConfirmationDialog(false);
    setDeleteVersionObj(null);
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setVisible(true);
    if (isEdit === true) {
      setVersionData(dataItem);
      setEditMode(true);
      checkIsValid();
    }
  };

  const handleAddEditClickClose = () => {
    setVisible(false);
    setVersionData(null);
    setEditMode(false);
    setIsValid(false);
  };

  const checkIsValid = () => {
    if (
      versionData?.VersionNumber !== undefined &&
      versionData?.VersionNumber?.length > 0
    ) {
      setIsValid(true);
    } else setIsValid(false);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <p>
          VersionStatus: <strong>{dataItem.VersionStatus}</strong>
        </p>
        <p>
          VersionDescription: <strong>{dataItem.VersionDesc}</strong>
        </p>
      </section>
    );
  };

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">Versions</div>
                  <Button
                    icon={<AddIcon />}
                    iconPosition="before"
                    content="Add Version"
                    primary
                    title="Add Version"
                    onClick={handleAddEditClickOpen}
                  />
                </div>
              

                <div>
                  {visible && (
                    <Dialog
                      title={editMode ? "Edit Version" : "Add New Version"}
                      onClose={handleAddEditClickClose}
                    >
                      <Form onSubmit={submitHandler}>
                        <FormInput
                          label="VersionNumber"
                          name="VersionNumber"
                          id="VersionNumber-inline"
                          fluid
                          required
                          value={versionData?.VersionNumber}
                          onChange={(value) =>
                            setVersionData({
                              ...versionData,
                              VersionNumber: value.target.value,
                            })
                          }
                        />
                        <FormInput
                          label="Version Description"
                          name="VersionDesc"
                          id="VersionDesc-inline"
                          fluid
                          required
                          value={versionData?.VersionDesc}
                          onChange={(value) =>
                            setVersionData({
                              ...versionData,
                              VersionDesc: value.target.value,
                            })
                          }
                        />
                        <FormInput
                          label="Version Status"
                          name="VersionStatus"
                          id="VersionStatus-inline"
                          fluid
                          required
                          value={versionData?.VersionStatus}
                          onChange={(value) =>
                            setVersionData({
                              ...versionData,
                              VersionStatus: value.target.value,
                            })
                          }
                        />

                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            disabled={isValid === false}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className='grid-with-search'>
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(versions, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                      <Column
                        field="VersionNumber"
                        title="VersionNumber"
                      />
                    {!context.mediaCategory.sm && (
                      <Column
                        field="VersionStatus"
                        title="VersionStatus"
                      />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="VersionDesc"
                        title="VersionDescription"
                      />
                    )}
                      <Column
                        field=""
                        title="Actions"
                        style={{ textAlign: "center" }}
                        cell={ActionIconVersions}  width={100}
                      />
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
           <FluentDialog
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setDeleteConfirmationDialog(false);
                setDeleteVersionObj(null);
              }}
              onConfirm={() => removeData()}
              open={openDeleteConfirmationDialog}
              backdrop
              header="Action confirmation"
              headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => {
                  setDeleteConfirmationDialog(false);
                  setDeleteVersionObj(null);
                },
              }}
              content={`Are you sure to delete Version: ${deleteVersionObj?.VersionNumber}?`}
            />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

const SettingsDbRepoVersions = (props) => {
  const [queryList, setQueryList] = React.useState([]);
  const [selectedQueryListItem, setSelectedQueryListItem] = React.useState({index: 0, queryName: 'Billing_InstanceBillRunHistory'});
  const [queryPool, setQueryPool] = React.useState(null);
  const [versions, setVersions] = React.useState(null);
  const [versionNumberList, setVersionNumberList] = React.useState([]);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [checkedValue, setCheckedValue] = useState(null);
  const [enteredText, setEnteredText] = useState('');
  const [addEditFormPlaceholder, setAddEditFormPlaceholder] = useState(null);
  const [isValid, setValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteVersionObj, setDeleteVersionObj] = useState(null);
  const [copiedVisible, setCopiedVisible] = React.useState(false);

  const checkValid = () => {  
    if(!editMode && addEditFormPlaceholder?.versionNumber.trim() != ''){
      if(!versionNumberList.includes(addEditFormPlaceholder?.versionNumber.trim())){
        let isAllQueryNotConfigured = queryList.map(q => { if(q.queryPoolId == -1 && q.queryValue != null && q.queryValue.trim() != '') return true;
        else if(q.queryPoolId > 0) return true;
        else return false}).some(m => m == false);
        setValid(!isAllQueryNotConfigured);
      }
      else{
        setValid(false);
      }
    }
    else if(editMode){
      if(addEditFormPlaceholder){
        let isAllQueryNotConfigured = queryList.map(q => { if(q.queryPoolId == -1 && q.queryValue != null && q.queryValue.trim() != '') return true;
        else if(q.queryPoolId > 0) return true;
        else return false}).some(m => m == false);
        setValid(!isAllQueryNotConfigured);
      }
    }
    else setValid(true);
  }

  const addEditSubmitClickHandler = () => {
    if(isValid){
      props.acquireToken((token) => {
        if (token) {
          let _configuredQueries = Object.keys(addEditFormPlaceholder).filter(v => !(v === 'versionDesc' || v === 'versionNumber')).map(m => addEditFormPlaceholder[m]);
          let version = {
            DbRepoVersionNumber : addEditFormPlaceholder.versionNumber,
            DbRepoVersionDesc : addEditFormPlaceholder.versionDesc,
            ConfigureQueries : _configuredQueries
          }
          if (editMode) {
            SettingsService.UpdateDbRepoVersion(token, version).then((response) => {
              if(response && response.status==200){
                response=response.data;
                props.acquireToken(authSuccessCallbackDbRepoVersions);
                 props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.success);

              } 
              else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

                debugger;
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
               
              }
            });
          } else {
            SettingsService.AddDbRepoVersion(version, token).then((response) => {
              if(response && response.status==200){
                response=response.data;
                props.acquireToken(authSuccessCallbackDbRepoVersions);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.success);
              } 
              else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

                debugger;
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

              }
            });
          }
        }
      });    
      setVisible(false);
      setEditMode(false);
    }
  };



  const expandChange = (event) => {
    let newData = versions.map((item) => {
      if (item.DbRepoVersionNumber === event.dataItem.DbRepoVersionNumber) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setVersions(newData);
  };

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "VersionNumber",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "DbRepoVersionNumber", operator: "contains", value: value }],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallbackDbRepoVersions);
  }, []);

  const authSuccessCallbackDbRepoVersions = (token) => {
    if (token) {
      SettingsService.GetDbRepoVersions(token).then((response) => {
        if(response && response.status==200){
          response=response.data;
          setQueryPool(response.QueryPool);
          setVersions(response.DbRepoVersions);
          setVersionNumberList(response.DbRepoVersions.map(v => v.DbRepoVersionNumber));
          
        }
        else if(response && response.status==401){
          props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
          props.viewStatus.InformChildPageStatus(500);
          }  
          SetLoaded(true);    
      });
    }
  };

  const ActionIconDbRepoVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteVersionObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const removeData = () => {
    if(deleteVersionObj != null){
      props.acquireToken((token) =>
        {
          if (token) {
            let _params = {
              versionNumber: deleteVersionObj?.DbRepoVersionNumber,
            };
            SettingsService.RemoveDbRepoVersion(token, _params).then((response) => {
              if(response && response.status==200){
                response=response.data;
                props.acquireToken(authSuccessCallbackDbRepoVersions);
            
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.success);

              }
              else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                debugger;
              }
               else {
             
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);

              }
            });
          }
        }
      );
    }
    setDeleteConfirmationDialog(false);
    setDeleteVersionObj(null);
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setAddEditFormPlaceholder(null);
    if (isEdit === true) {      
      props.acquireToken((token) => {
        if (token) {
          SettingsService.GetDbRepoVersionQueries(token, dataItem.DbRepoVersionNumber).then((response) => {
            if(response && response.status==200){
              response=response.data;       
              let queryArray = Object.keys(queryPool).map(m => ({queryName: m, queryValue: response[m] ? response[m].QueryValue : null, queryPoolId: response[m] ? response[m].QueryPoolId : null}));
              let placeholderObj = queryArray.reduce(
                (obj, item) => (obj[item.queryName] = item, obj) ,{});
              
              setQueryList(queryArray);
              
              placeholderObj.versionNumber = dataItem.DbRepoVersionNumber;
              placeholderObj.versionDesc = dataItem.DbRepoVersionDesc;
              setAddEditFormPlaceholder(placeholderObj);
              setCheckedValue(placeholderObj[selectedQueryListItem.queryName]?.queryPoolId);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
              }
          });
        }
      })
      setValid(false);
      setEditMode(true); 
    }
    else{
      setSelectedQueryListItem({index: 0, queryName: 'Billing_InstanceBillRunHistory'});
      let queryArray = Object.keys(queryPool).map(m => ({queryName: m, queryValue: null, queryPoolId: null}));
      let placeholderObj = queryArray.reduce(
        (obj, item) => (obj[item.queryName] = item, obj) ,{});
      
      setQueryList(queryArray);
      placeholderObj.versionNumber = '';
      placeholderObj.versionDesc = '';
      setAddEditFormPlaceholder(placeholderObj);
      setEditMode(false);
      setValid(false);
    }        
    setEnteredText(''); 
    setVisible(true);
  };

  const handleAddEditClickClose = () => {
    setVisible(false);
    setAddEditFormPlaceholder(null);
    setEnteredText('');
    setCheckedValue(null);
    setEditMode(false);
    setValid(false);
  };

  const onSelectChangeQueryList = (_index, newProps) => {
    let qName = newProps.items[newProps.selectedIndex].key;
    setSelectedQueryListItem({ index: _index, queryName: qName });
    setCheckedValue(addEditFormPlaceholder ? addEditFormPlaceholder[qName]?.queryPoolId : null);
    setEnteredText(addEditFormPlaceholder ? addEditFormPlaceholder[qName]?.queryPoolId == -1 ? addEditFormPlaceholder[qName]?.queryValue : '' : '');
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <p>
          Description: <strong>{dataItem.DbRepoVersionDesc}</strong>
        </p>
        <CellConfigurationState {...props1} field={"IsCompletelyConfigured"} text="Configuration Status" />
      </section>
    );
  };

  
  const CopyLabel = (props) => {

    // const [copiedVisible, setCopiedVisible] = React.useState(false);
    const [data, setData] = React.useState({Host: 0});


    // const showLabel = () => {
    //   // setCopiedVisible(true);
    //   // setCount(count +1);
    //   debugger
    // }

    const [count, setCount] = React.useState(1);
   
    return (
      <div  count={count}> 
        { props.text} 

        {/* <Copied visible={copiedVisible} count={count} {...props} > </Copied> */}
        <Button primary size="small" className="copy-icon" icon={<ClipboardCopiedToIcon />} text  iconOnly title="Copy"
          onClick={() => {
            navigator.clipboard.writeText(props.text);
            props.updateLabel(true);
          }} />
      </div>
    );
  }
  
  const updateLabel = (val) => {
    setCopiedVisible(val);
  }

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">DB Repo Versions</div>
                  {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                  <Button
                    icon={<AddIcon />}
                    iconPosition="before"
                    content="Add DB Repo Version"
                    primary
                    title="Add DB Repo Version"
                    onClick={() => handleAddEditClickOpen(null, false)} className="ml-auto"
                  />}
                </div>
          

                <div>
                  {visible ? (
                    <Dialog
                      title={editMode ? "Edit DB Repo Version" : "Add New DB Repo Version"}
                      onClose={handleAddEditClickClose}
                      className="mid-wide"
                    >
                      <Form onSubmit={submitHandler}>
                        <FluentGrid  style={{gridGap:'15px'}} columns={editMode ? '1' : '2'}>
                            {/* <div className="copied-label"  style={{ display: copiedVisible === true ? "block" : "none" }} >
                              Copied!
                            </div>  */}
                            {
                            !editMode ?(
                            <FormInput
                              label="VersionNumber"
                              name="VersionNumber"
                              id="VersionNumber-inline"
                              fluid
                              required
                              value={addEditFormPlaceholder?.versionNumber}
                              onChange={(e, {value}) =>{ setAddEditFormPlaceholder({ ...addEditFormPlaceholder, versionNumber: value }) }                                                              }
                              onBlur={(e) => checkValid() }
                            />
                          ) : (
                            <></>
                          )}

                          <FormInput
                            label="Version Description"
                            name="VersionDesc"
                            id="VersionDesc-inline"
                            fluid
                            value={addEditFormPlaceholder?.versionDesc}
                            onChange={(e, {value}) => {
                              setAddEditFormPlaceholder({
                                ...addEditFormPlaceholder,
                                versionDesc: value,
                              });
                            }}
                            onBlur={(e) => checkValid()}
                          />
                        </FluentGrid>
                        {!editMode ? (
                          <Header
                            as="h3"
                            className="mb-2 mt-0"
                            content="Select queries to run"
                          />
                        ) : (
                          <Header
                            as="h3"
                            className="mb-2 mt-0"
                            content="Update queries"
                          />
                        )}
                        <FluentGrid
                          style={{gridGap: "8px" }}
                          columns="275px calc(100% - 275px)"
                        >
                          <Segment>
                            <List
                              selectable
                              selectedIndex={selectedQueryListItem.index}
                              onSelectedIndexChange={(e, newProps) => {
                                onSelectChangeQueryList(
                                  newProps.selectedIndex,
                                  newProps
                                );
                              }}
                              //items={Object.keys(queryPool).map(m => ({ name:m, key:m, header:m}))}/>
                              items={queryList.map((m) => ({
                                name: m.queryName,
                                key: m.queryName,
                                header: m.queryName,
                                media:
                                  m.queryPoolId &&
                                  (m.queryPoolId != -1 ||
                                    (m.queryPoolId === -1 &&
                                      m.queryValue != null &&
                                      m.queryValue.trim() != "")) ? (
                                    <Status
                                      state="success"
                                      icon={<AcceptIcon />}
                                    />
                                  ) : m.queryPoolId === -1 &&
                                    m.queryValue != null &&
                                    m.queryValue.trim() == "" ? (
                                    <Status state="warning" />
                                  ) : (
                                    <Status state="error" />
                                  ),
                              }))}
                            />
                          </Segment>
                          <Segment>
                            <FormRadioGroup
                              vertical
                              checkedValue={checkedValue}
                              onCheckedValueChange={(e, data) => {
                                addEditFormPlaceholder[
                                  selectedQueryListItem.queryName
                                ].queryPoolId = data.value;
                                if (data.value === -1) {
                                  addEditFormPlaceholder[
                                    selectedQueryListItem.queryName
                                  ].queryValue = enteredText;
                                }

                                setCheckedValue(data.value);
                                checkValid();
                                //setAddEditFormPlaceholder(addEditFormPlaceholder);
                              }}
                              items={[
                                ...queryPool[
                                  selectedQueryListItem.queryName
                                ].map((m) => ({
                                  name: m.QueryName,
                                  value: m.QueryPoolId,
                                  key: m.QueryPoolId,
                                  label:  <Segment className="dbrepo-segment"
                                            content={ <CopyLabel text={m.QueryValue.replace(/,/g, ", ")} updateLabel={updateLabel}></CopyLabel>  }
                                            // content={ m.QueryValue.replace(/,/g, ", ")}
                                            >
                                          </Segment>,
                                })),
                                {
                                  name: selectedQueryListItem.queryName,
                                  key: -1,
                                  label: "Write New",
                                  children: (Component, { key, ...props }) => {
                                    return (
                                      <Flex key={key} gap="gap.small" column>
                                        <Component {...props} />
                                        <TextArea
                                          onFocus={() => {
                                            addEditFormPlaceholder[
                                              selectedQueryListItem.queryName
                                            ].queryPoolId = -1;
                                            setCheckedValue(-1);
                                            //setAddEditFormPlaceholder(addEditFormPlaceholder);
                                          }}
                                          input={{
                                            tabIndex: addEditFormPlaceholder
                                              ? addEditFormPlaceholder[
                                                  selectedQueryListItem
                                                    .queryName
                                                ].queryPoolId === -1
                                                ? "0"
                                                : "-1"
                                              : "-1",
                                          }}
                                          value={enteredText}
                                          onChange={(e, { value }) => {
                                            addEditFormPlaceholder[
                                              selectedQueryListItem.queryName
                                            ].queryValue = value;
                                            setEnteredText(value);
                                          }}
                                          onBlur={(e) => checkValid()}
                                          inline
                                          styles={{ height: "100px" }}
                                          placeholder="Enter new query here..."
                                        />
                                      </Flex>
                                    );
                                  },
                                  value: -1,
                                  "aria-label": "Press Tab to change flavour",
                                },
                              ]}
                            />
                          </Segment>
                        </FluentGrid>

                        <DialogActionsBar style={{ border: "none" }}>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            disabled={!isValid}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  ) : (
                    <></>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className="grid-with-search">
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(versions, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column
                      field="DbRepoVersionNumber"
                      title="Version Number"
                      width={context.mediaCategory.sm ? 70: 180}
                    />
                    {!context.mediaCategory.sm && (
                      <Column field="DbRepoVersionDesc" title="Description"width={250}/>
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="IsCompletelyConfigured"
                        title="Configuration Status"
                        cell={CellConfigurationState}
                        width={150} filterable={false} sortable={false}
                      />
                    )}
                    
								 {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                    <Column
                      field=""
                      title="Actions"
                      style={{ textAlign: "center" }}
                      cell={ActionIconDbRepoVersions}
                      width={100}
                    />}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteVersionObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteVersionObj(null);
              },
            }}
            content={`Are you sure to delete DB Repo Version: ${deleteVersionObj?.DbRepoVersionNumber}?`}
          />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsVersions, SettingsDbRepoVersions };
