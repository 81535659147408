import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status , Text} from '@fluentui/react-northstar';
import { Grid, GridColumn as Column , GridSelectionChangeEvent, GridHeaderSelectionChangeEvent, getSelectedState} from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon} from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import SettingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { getter } from '@progress/kendo-react-common';
import { useLocation,  useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ProgressBar } from "@progress/kendo-react-progressbars";


const ServerOverview = (props) => {
    const [vmData, setVMData] = useState([]);
    const [serverData, SetServerData] = useState(null);

    const [isLoaded, SetLoaded] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [visible, setVisible] = React.useState(false);

    const [editMode, setEditMode] = React.useState(false);
    const [servers, setServers] = React.useState([]);

    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteServerObj, setDeleteServerObj] = useState(null);
    const location = useLocation()


    const addEditSubmitClickHandler = () => {
      props.acquireToken(authSuccessAddEditServerCallback);
      setVisible(false);
      setEditMode(false);
      SetServerData(null);
    };
    
    const expandChange = (event) => {
      let newData = vmData.map((item) => {
        if (item.ServerId === event.dataItem.ServerId) {
          item.expanded = !event.dataItem.expanded;
        }

        return item;
      });
      setVMData(newData);
    };

    const authSuccessAddEditServerCallback = (token) => {

      if (token) {
        if(editMode){
          SettingsService.UpdateServer(token, serverData).then((response) => {
            if(response && response.status==200){
              response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                    }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
        else{
          SettingServerSM.AddServer(serverData, token).then((response) => {
            if(response && response.status==200){
              response=response.data;          
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
          else{
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
      }
    };

    const CellIsConnectionConfigured = (props1) => {
      return (
        <td style={{ textAlign: "center" }}>
          <Button
            styles={{ height: "auto" }}
            iconOnly
            size="small"
            icon={props1.dataItem.Status === 1? <Status size="larger" state="success" icon={<AcceptIcon />} /> : props1.dataItem.Status === 0 ?  <Status size="larger" state="error" icon={<CloseIcon />} /> : <Loader  size="smallest"/>}
            text
            onClick={() => null}
          />
        </td>
      );
    };

    const authSuccessUpdateTogglesCallback = (token, serverData) => {
      if (token) {
        SettingsService.UpdateServer(token, serverData).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "InstanceName",
          dir: "asc",
        },
      ],
      take: 10,
      skip: 0,
    });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "DisplayFriendlyName", operator: "contains", value: value },
          { field: "IPAddress", operator: "contains", value: value },
          { field: "ServerTypeName", operator: "contains", value: value },
          { field: "MachineName", operator: "contains", value: value },
          { field: "IHubClientAddress", operator: "contains", value: value },
          { field: "Timezone", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };
    
    useEffect(() => checkIsValid(), [serverData]);

    const authSuccessRemoveServerCallback = (token, serverId) => {
      if (token) {
        let _params = {
          serverId: serverId,
        };
        SettingsService.RemoveServer(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const submitHandler = e => {
      e.preventDefault();
    }

    const handleServerChange = (alwaysNull, data) => {
      let key = data.value.key;
      let serverInfo = key.split('_');
      let serverId = serverInfo[0];
      let isServer = (serverInfo[1] == 'VM' ? false : true);
      SetServerData({ ...serverData, ServerId: serverId, IsServer : isServer });
    };


    React.useEffect(() => {

       props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
      debugger;
      let serverOverview = props.serverObj;
      serverOverview.AttcahedDisks = serverOverview.AttcahedDisks.filter(elements => {
        return elements !== null;
       });

      SetServerData(serverOverview);
      SetLoaded(true);
    };

    const ActionIconVM = (props) => {
    return (
        <td style={{ textAlign: "center" }}>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
        </td>
    );
    }

    const removeData = () => {
       if (deleteServerObj != null) {
         props.acquireToken((token) =>
           authSuccessRemoveServerCallback(token, deleteServerObj.ServerId)
         );
       }
       setDeleteConfirmationDialog(false);
       setDeleteServerObj(null);
    };

    const handleAddEditClickOpen = (dataItem, isEdit) => {
      setVisible(true);
      if (isEdit === true) {
        SetServerData(dataItem);
        setEditMode(true);
      }
    };

    const handleAddEditClickClose = () => {
      setVisible(false);
      SetServerData(null);
      setEditMode(false);
      setIsValid(false);
    };

    const checkIsValid = () => {
      if (
        serverData?.ServerId !== undefined &&
        serverData?.ServerId?.length > 0 &&
        serverData?.Port !== undefined  &&
        serverData?.Port?.length > 0
      ) 
      {
        setIsValid(true);
      } else setIsValid(false);
    };


    const MemoryLeftCell = (props) => {
      let percentageLeft = Math.round(props?.dataItem?.UsedSpace * 100 / props?.dataItem?.TotalSpace);

      return (
        <td>
        <div className="text-center">
        <ProgressBar
          value={percentageLeft}
          label={(props) => {
            return <strong>{percentageLeft}% </strong>;
          }}
          labelPlacement = "start"
          title = {"Used space : " + props?.dataItem?.UsedSpace + " out of Total Space : " + props?.dataItem?. TotalSpace}
        />
      </div>
        </td>
      );      
    };

    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
                    <CardBody>
                    <div className='row mb-4 detailed-cards'>
                        <div className='col-lg-4 col-md-6'>
                        <Card fluid elevated ghost styles={{backgroundColor: 'rgba(255, 248, 221, 0.5)'}}>
                            <CardHeader><Text content='' /></CardHeader>
                            <CardBody className='h-100'>
                                <div className='row h-100'>
                                    <div className='col-md-12'>
                                        <div className='listC h-100 border-gray border-radius-6 p-3'>
                                            <div className='listMain'>
                                            <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Computer Name</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>{serverData?.ComputerName}</div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Private IP</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>{serverData?.PrivateIP}</div>
                                                </div>

                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Last Boot Time</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                    {serverData?.LastBootTime}
                                                    </div>
                                                </div>

                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>OS Name</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                    {serverData?.OSName}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>OS Version</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                    {serverData?.OSVersion}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Processor</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk float-left font-weight-bold'>
                                                    {serverData?.Processor}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Installed Memory</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                        <div className='d-flex align-item-center'>
                                                      {serverData?.InstalledMemory}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Available Memory</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                    {serverData?.AvailableMemory}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>TimeZone</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                    {serverData?.TimeZone}
                                                    </div>
                                                </div>
                                                <div className='listV float-left w-100'>
                                                    <div className='listVtrk float-left'>Local System Time</div>
                                                    <div className='listVtrk float-left font-weight-bold'>:</div>
                                                    <div className='listVtrk font-weight-bold'>
                                                    {serverData?.LocalSystemTime}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        </div>
                        <div className='col-lg-8 col-md-6'>
                        <Card fluid elevated ghost styles={{backgroundColor: 'rgba(241, 250, 255, 0.8)'}}>
                            <CardHeader></CardHeader>
                            <CardBody className='h-100'>
                            <div className='row h-100'>
                            <Text content='Attached Disks' />
                                <div className='col-md-12'>
                                    <div className='listC h-100 border-gray border-radius-6 p-3'>
                                        <div className='listMain'>
                                        <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3,
                      }}
                      sortable={true}
                      data={serverData?.AttcahedDisks}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                    >
                      
                      <Column
                        field="DriveLetter"
                        title="Drive"
                        width={140}
                      />
                       <Column
                          field=""
                          title="Space"
                          cell={MemoryLeftCell}
                        />
                    </Grid>
                  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row h-100'>
                            <Text content=' RDP sessions' />
                                <div className='col-md-12'>
                                    <div className='listC h-100 border-gray border-radius-6 p-3'>
                                        <div className='listMain'>
                                        <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3,
                      }}
                      sortable={true}
                      data={serverData?.RDPSession}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                    >
                      
                      <Column
                        field="SessionId"
                        title="Session Id"
                        width={140}
                      />
                                       
                      <Column
                        field="Username"
                        title="Username"
                        width={140}
                      />
                        <Column
                          field="State"
                          title="State"
                          width={150}
                        />
                    </Grid>
                  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </CardBody>
                        </Card>
                        </div>
                    </div>

                    </CardBody>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
}

export { ServerOverview };