import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, RadioGroup, Label, Datepicker , Dropdown , Toolbar as ToolbarFluent} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import settingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { CellAWSNotifications, CellEmail} from '../../shared/appcomponents/custom-grid-cells';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { extendDataItem } from "@progress/kendo-react-common";
import {
	Toolbar,
	ToolbarItem,
	Button as ReactButton,
	ButtonGroup,
  } from "@progress/kendo-react-buttons";
import { DateFilter } from "@progress/kendo-react-data-tools";

const ServerEvents = (props) => {

	const [Events, setEvents] = React.useState(null);
	// const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [selectedEventType, setEventType] = React.useState({ key: 0,  header: 'Application', value: 'Application' });

	const eventTypes = [									{  key: 0,  header: 'Application', value: 'Application'  }, 
															{  key: 1,  header: 'Security', value: 'Security'  }, 
															{  key: 2,  header: 'Others', value: 'Others'  }];

	const [destEmail, setDestEmail] = useState("");
	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "NotificationTimestamp",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});

	const emailDaysItems = [
		{
			name: 'Today',
			key: 'today',
			label: 'Today',
			value: '0',
		},
		{
			name: 'Select Date Range',
			key: 'all',
			label: 'Select Date Range:',
			value: '-1',
		},
	];

	const [errorEvent, setErrorEvent] = React.useState(true);
	const [informationEvent, setInformationEvent] = React.useState(false);
	const [warningEvent, setWarningEvent] = React.useState(false);

	React.useEffect(() => {
		applyDateFilter();
	  }, [errorEvent, informationEvent, warningEvent]);

	const [emailDaysCheckedValue, setEmailDaysCheckedValue] = React.useState('0')

	const emailDaysChange = (e, data) => {
		
		setEmailDaysCheckedValue(data.value);
	};

	const applyDateFilter = () => {
		debugger;
		SetLoaded(false);
		setActive("false");

		let entryTypes = [];
		if(errorEvent == false && informationEvent == false && warningEvent == false)
		{
			entryTypes.push('Error');
			entryTypes.push('Warning');
			entryTypes.push('Information');
		}
		else{
		if(errorEvent == true)
			entryTypes.push('Error');
		if(informationEvent == true)
			entryTypes.push('Information');
	    if(warningEvent == true)
			entryTypes.push('Warning');
		}

		
		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '-' + (((sd.getMonth() +1)) < 10 ? '0' : '') + (sd.getMonth() +1) + '-' + (((sd.getDate())) < 10 ? '0' : '') +  sd.getDate();
		let ed = new Date(selectedEndDate) ;
		let endDate = ed.getFullYear() + '-' + (((ed.getMonth() +1)) < 10 ? '0' : '') + (ed.getMonth() +1) + '-' + (((ed.getDate())) < 10 ? '0' : '') + ed.getDate();
		

		props.acquireToken((token) => {
			debugger;
			if (token) {
				let _params = {
					ServerName: props.serverObj.ComputerName,
					DateStartTime : startDate,
					DateEndTime : endDate,
					EventType : selectedEventType.value,
					EntryTypes : entryTypes
				};
				settingServerSM.GetEvents(_params,token).then((response) => {
					if (response && response.status==200) {
						response = response.data;
						setEvents(response);						
					}
					else if(response && response.status==401){
						props.viewStatus.InformChildPageStatus(response.status);
						}
						else {
						props.viewStatus.InformChildPageStatus(500);
						}        
						SetLoaded(true);
				});
			}
		});
	}
	
React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
	debugger;
	SetLoaded(false);
	setActive("false");
	
	let entryTypes = [];
	if(errorEvent == false && informationEvent == false && warningEvent == false)
	{
		entryTypes.push('Error');
		entryTypes.push('Warning');
		entryTypes.push('Information');
	}
	else{
	if(errorEvent == true)
		entryTypes.push('Error');
	if(informationEvent == true)
		entryTypes.push('Information');
	if(warningEvent == true)
		entryTypes.push('Warning');
	}

	let sd = new Date(selectedStartDate);
	let startDate = sd.getFullYear() + '-' + (((sd.getMonth() +1)) < 10 ? '0' : '') + (sd.getMonth() +1) + '-' + (((sd.getDate())) < 10 ? '0' : '') + sd.getDate();
	let ed = new Date(selectedEndDate) ;
	let endDate = ed.getFullYear() + '-' + (((ed.getMonth() +1)) < 10 ? '0' : '') + (ed.getMonth() +1) + '-' + (((ed.getDate())) < 10 ? '0' : '') + ed.getDate();
	

	props.acquireToken((token) => {
		debugger;	
		if (token) {
			let _params = {
				ServerName: props.serverObj.ComputerName,
				DateStartTime : startDate,
				DateEndTime : endDate,
				EventType : selectedEventType.value,
				EntryTypes : entryTypes
			};
			
			settingServerSM.GetEvents(_params,token).then((response) => {
				if (response && response.status==200) {
					response = response.data;
					setEvents(response);						
				}
				else if(response && response.status==401){
					props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
					props.viewStatus.InformChildPageStatus(500);
					}        
					SetLoaded(true);
			});
		}
	});
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "Source", operator: "contains", value: value },
								{ field: "EventType", operator: "contains", value: value },
								],
    };
    setDataState({ ...dataState, filter: filter });
  };

	const ActionIconVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        {/* <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/> */}
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            // setDeleteAlertObj(props.dataItem);
            // setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<div>
					{props1.dataItem.Message};
				</div>
			</section>
		);
	};

    const expandChange = (event) => {

		debugger;
		let alteredData = (Events).map((item) => {
			item.expanded = false;
		  return item;
		});
  
		let newData = (alteredData).map((item) => {
		  if (item.Key === event.dataItem.Key) {
			item.expanded = event.syntheticEvent.target.classList.contains('k-i-minus') ? false : true;
		  }
	
		  return item;
		});
		setEvents(newData);
    };

	const  addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	const CellEmailCol = (prop) => {
    return (
        <CellEmail {...prop} acquireToken={props.acquireToken} />
    );
 }

 const [isActive, setActive] = useState("false");
 const ToggleClass = () => {
   setActive(!isActive); 
  };

	const handleEventTypeChange = (_selectedType) => {
		setEventType(_selectedType);
	}

	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between position-relative">
								<div className="f-size-18">Events</div>
								<Toolbar>
									<ToolbarItem>
										<ButtonGroup>
										<ReactButton selected = {errorEvent} togglable={true} onClick={(e) => {setErrorEvent(!errorEvent); }} >Error</ReactButton>
										<ReactButton selected = {informationEvent} togglable={true} onClick={(e) => {setInformationEvent(!informationEvent);}} >Information</ReactButton>
										<ReactButton selected = {warningEvent} togglable={true} onClick={(e) => {setWarningEvent(!warningEvent); }} >Warning</ReactButton>
										</ButtonGroup>
									</ToolbarItem>
									</Toolbar>
								<Button styles={{padding:'0 12px'}} icon={<FilterIcon />} content={"Filters"} title="Filters" onClick={ToggleClass} className="filetr-icon" circular />
								<Flex gap="gap.small" className={isActive ? "email-log-filter" : "active email-log-filter"}>
									<RadioGroup
										checkedValue={emailDaysCheckedValue}
										items={emailDaysItems}
										onCheckedValueChange={emailDaysChange}
										// value={emailDaysSelected}
									/>
									<Text content="Start Date" className="date-filter-text"  size="medium" weight="regular"
									 disabled={emailDaysCheckedValue == "-1" ? false:true} />
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedStartDate} disabled={emailDaysCheckedValue == "-1" ? false:true} 
										maxDate={selectedEndDate}
										onDateChange={ (e, data) => {
												setSelectedStartDate(data.value);
											}} />
									<Text content="End Date"  className="date-filter-text" size="medium" weight="regular"
										disabled={emailDaysCheckedValue == "-1" ? false:true}/>
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedEndDate}  disabled={emailDaysCheckedValue == "-1" ? false:true} 
										minDate={selectedStartDate}
										onDateChange={(e, data) => {
												setSelectedEndDate(data.value);
											}}/>
									<Text content="Event Type"  className="date-filter-text" size="medium" weight="regular"/>

									<Dropdown value={selectedEventType} items={eventTypes} className="aws-notification-type date-filter"
										onChange={(e, selectedItem) => {
											handleEventTypeChange(selectedItem.value);
										}}										
										fluid
									/>

									<Flex gap="gap.small" className="mt-2">
										<Button content="Apply" className="aws-applyFilter" 
											onClick = {applyDateFilter}/>
										<Button content="Cancel" className="aws-applyFilter" 
											onClick = {() => setActive("false")}/>
									</Flex>
									
								</Flex>
							</div>
							
						</CardHeader>
						<CardBody>
							<div className="tbl-search float-right text-right mb-2">
								<Input
									onChange={(e, { name, value }) => {
										filterGridData(value);
									}}
									inverted
									icon={<SearchIcon />}
									placeholder="Search..."
									className="border-gray"
								/>
							</div>
							<div className='grid-with-search'>
								<Grid
									style={{
										height: "100%",
									}}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
									data={process(Events, dataState)}
									{...dataState}
									onDataStateChange={(e) => {
										setDataState(e.dataState);
									}}
									 detail={DetailComponentSm}
									 expandField="expanded"
									 onExpandChange={expandChange}
								>
									<Column field="EventId"  title="Instance Id" width={100}/>
									<Column field="EventType"  title="Event Type" width={100}/>
									<Column field="Source"  title="Source" width={100}/>
									<Column field="DateCreated"  title="Date Created" width={100}/>
									<Column field="Level"  title="Level" width={100}/>
								</Grid>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { ServerEvents };
