import * as React from "react";
import { ListIcon, CloseIcon, DesktopIcon, AppsIcon, BellIcon, SearchIcon, ContentIcon, ContentViewGalleryIcon, BroadcastIcon, PlugsIcon, ShiftActivityIcon, EditIcon, TrashCanIcon, LinkIcon, TranscriptIcon, AcceptIcon, BuildingIcon, StarIcon, UndoIcon, ExclamationTriangleIcon, SpeakerPersonIcon, AddIcon, ClipboardCopiedToIcon } from "@fluentui/react-icons-northstar";
import { Button, Card, Dialog, Text, Loader, TextArea, Flex, Status, itemLayoutClassName, Pill, PillGroup, Toolbar, FormDropdown, Label } from "@fluentui/react-northstar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import MonitoringService from "../../services/monitoringservice";
import SettingsService from "../../services/settingsservice";
import { GridColumnMenuSort } from '@progress/kendo-react-grid';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { formatDate } from '@progress/kendo-intl';
import { Color } from "@progress/kendo-drawing";
import { filterBy, process } from "@progress/kendo-data-query";
// import '../../../assets/images/';
import sunImage from '../../../assets/images/icon-sun.svg';
import moonImage from '../../../assets/images/icon-moon.svg';
import { FORMATBYTES, OPERATIONSTATUS } from "./enums";
import { useEffect } from "react";
import moment from "moment";
import { lastIndexOf } from "lodash";

export const CellNumGroupIIS = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <div className='d-flex'>
        <span className='mr-2'>75</span>
        <span className='text-success mr-2'> (35)</span>
        <span className='text-warning mr-2'> (25)</span>
        <span className='text-danger'> (15)</span>
      </div>
    </td>
  );
};

export const CellIncidentLevel = (props) => {
  const incidentLevelId = props.dataItem.IncidentLevelId;
  return (
    <td style={{ textAlign: "center" }}>
      {incidentLevelId === 0 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-success"></div>
          <span>Healthy</span>
        </div>
      )}
      {incidentLevelId === 1 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-warning"></div>
          <span>Warning</span>
        </div>
      )}
      {incidentLevelId === 2 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-danger"></div>
          <span>Critical</span>
        </div>
      )}
    </td>
  );
};

export const CellAccessIcon = (props) => {
  const dataItem = props.dataItem;
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button className={`anch-rdp ${props.dataItem.RDPAccess == "" ? 'disabled' : ''}`} as="a" disabled={props.dataItem.RDPAccess == ""} href={`rdp://${props.dataItem.RDPAccess}`} styles={{ height: 'auto' }} iconOnly size="small" icon={<DesktopIcon />} text />
    </td>
  );
};

export const CellServiceName = (props) => {
  const data = props.dataItem;
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.ServiceName}</span> </>}
      />
    </td>
  );
};

export const CellInstanceName = (props) => {
  const data = props.dataItem;
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.InstanceName}</span> </>}
      />
    </td>
  );
};

export const CellIncidentId = (props) => {
  const data = props.dataItem;
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.IncidentId}</span> </>}
      />
    </td>
  );
};

export const ButtonBlobStatus = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      {data.BlobStatus === 0 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-success"></div>
          <span>Healthy</span>
        </div>
      )}
      {data.BlobStatus === 1 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-warning"></div>
          <span>Warning</span>
        </div>
      )}
      {data.BlobStatus === 2 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-danger"></div>
          <span>Critical</span>
        </div>
      )}
    </td>
  );
};

export const ListButtonDetail = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        styles={{ height: "auto" }}
        iconOnly
        size="small"
        icon={<ListIcon />}
        text
        title="View Details"
      />
    </td>
  );
};

export const CellPortalDetail = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        styles={{ height: "auto" }}
        onClick={() => routeChange("/dashboards/portal/1")}
        iconOnly
        size="small"
        icon={<ListIcon />}
        text
        title="View Details"
      />
    </td>
  );
};

export const CellServerMonitoring = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button styles={{ height: 'auto' }} onClick={() => routeChange('/dashboards/infra/metrics/1')} iconOnly size="small" icon={<ListIcon />} text title="View Details" />
    </td>
  );
};

export const CellRecievedExpected = (props) => {
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.dataItem[props.field]?.map(m => `${m.Key}: ${FORMATBYTES(m.Recieved)}/${FORMATBYTES(m.Expected)} `)}
    </td>
  );
};

export const CellCallRecievedExpected = (props) => {
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.dataItem[props.field]?.map(m => `${m.Key}: ${m.Recieved}/${m.Expected} `)}
    </td>
  );
};

export const CellServerGraph = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button styles={{ height: 'auto' }} onClick={() => routeChange(`/dashboards/monitoring/infra/metrics/${props.dataItem.ServerId}?id=${props.dataItem.IncidentServerId}&&name=${props.dataItem.ServerName}`)} iconOnly size="small" icon={<ContentIcon />} text title="View Details" />
    </td>
  );
};

export const CellServerGraphLabel = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "center" }}>
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}

      <Text
        size="medium"
        as="p"
        // content={props.dataItem.ServerName}

        content={<>
          {props.dataItem.PowerState === 'NULL' || props.dataItem.PowerState === 'null' || props.dataItem.PowerState === '' && (
            <Button styles={{ height: 'auto' }} className="text-danger" iconOnly size="small" icon={<ExclamationTriangleIcon />} text title="Power State: Unable to Sync" />
          )}
          {/* {props.dataItem.PowerState === 'running' && (
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<ExclamationTriangleIcon />} text title="Power State: Running" />
          )} */}
          {props.dataItem.PowerState === 'deallocated' && (
            <Button styles={{ height: 'auto' }} className="text-warning" iconOnly size="small" icon={<ExclamationTriangleIcon />} text title="Power State: Deallocated" />
          )}
          <span> {props.dataItem.ServerName}</span>
        </>}


        weight="semibold"
        className="text-primary cursor-pointer"
        title="View Server Graph"
        onClick={() =>
          routeChange(
            `/dashboards/monitoring/infra/metrics/${props.dataItem.ServerId}?id=${props.dataItem.IncidentServerId}&&name=${props.dataItem.ServerName}`
          )
        }
      />
    </td>
  );
};

export const CellAccessSPPortal = (props) => {
  //'https://bwus.icallsuite.com' onClick={() => {  window.open(data, '_blank');}}
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button onClick={() => props.onClick()} styles={{ height: 'auto' }} iconOnly size="small" icon={<SearchIcon />} text title="Access SP Portal" />
    </td>
  );
};

export const CellZohoURL = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const data = props.dataItem[props.field];
  return (
    <td style={{ textAlign: "center" }}>
      <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<LinkIcon />} text title="Access Zoho Instance"
        onClick={() => { window.open(data, '_blank'); }} />
    </td>
  );
};

export const CellInstanceDetail = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const data = props.dataItem;
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button styles={{ height: 'auto' }} onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}`)} iconOnly size="small" icon={<AppsIcon />} text title="Data Detail Page" />
    </td>
  );
};

export const CellInfraDetail = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  const data = props.dataItem;
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button styles={{ height: 'auto' }} onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}`)} iconOnly size="small" icon={<BroadcastIcon />} text title="Detail" />
    </td>
  );
};

export const CellServiceDetail = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button styles={{ height: 'auto' }} onClick={() => routeChange(`/dashboards/monitoring/service/detail/${props.dataItem.InstanceId}`)} iconOnly size="small" icon={<PlugsIcon />} text title="Detail" />
    </td>
  );
};

export const CellInstanceOverview = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button styles={{ height: 'auto' }} onClick={() => routeChange(`/dashboards/monitoring/portal/overview/${props.dataItem.InstanceId}`)} iconOnly size="small" icon={<ContentViewGalleryIcon />} text title="Instance Overview" />
    </td>
  );
};

export const CellInstanceOverviewLabel = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }

  const data = props.dataItem;
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.InstanceName}</span>
          {data.IsDay == '0' ? <img className="dayNight-icon" src={moonImage} /> : data.IsDay == '1' ? <img className="dayNight-icon" src={sunImage} /> : <></>} </>}
        weight="semibold"
        className="text-primary cursor-pointer"
        title="View Instance Overview"
        onClick={() =>
          routeChange(
            `/dashboards/monitoring/portal/overview/${props.dataItem.InstanceId}`
          )
        }
      />
    </td>
  );
};

export const CellServiceDetailLabel = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }

  const data = props.dataItem;
  return (
    <td
      className={props.className}
      style={{
        left: props.style.left,
        right: props.style.right,
        textAlign: "center",
      }}
    >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={
          <>
            {" "}
            <span> {data.ServiceDisplayName}</span>
            {" "}
          </>
        }
        weight="semibold"
        className="text-primary cursor-pointer"
        title="View Sevice Detail Page"
        onClick={() =>
          routeChange(
            `../detail`
          )
        }
      />
    </td>
  );
};

export const CellCountGroup = (props) => {
  const data = props.dataItem[props.field];
  return (
    <td style={{ textAlign: "center" }}>
      <div className="d-flex">
        {data != null ? (
          <>
            {props.text && <span>{props.text}&nbsp;</span>}
            <span className='mr-2'>{data.Total}</span>
            <span className='text-success mr-2'> ({data.Healthy})</span>
            <span className='text-warning mr-2'> ({data.Warning})</span>
            <span className='text-danger'> ({data.Critical})</span>
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellCountGroupClickable = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  return (
    <td style={{ textAlign: "center" }}>
      <div className="d-flex">
        {data != null ? (
          <>
            {props.text && <span>{props.text}&nbsp;</span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/${props.routesection}/detail/${props.dataItem.InstanceId}/${props.pass}`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/${props.routesection}/detail/${props.dataItem.InstanceId}/${props.pass}?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/${props.routesection}/detail/${props.dataItem.InstanceId}/${props.pass}?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/${props.routesection}/detail/${props.dataItem.InstanceId}/${props.pass}?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellTenantStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();

  let criticalPriorityText = data.CriticalPriorityTenants;
  let isCriticalPriority = '';

  if (criticalPriorityText && criticalPriorityText.includes('P')) {
    isCriticalPriority = '*';
  }

  let criticalText = '(' + data.Critical + ')' + isCriticalPriority;


  let warningPriorityText = data.WarningPriorityTenants;
  let isWarningPriority = '';

  if (warningPriorityText && warningPriorityText.includes('P')) {
    isWarningPriority = '*';
  }
  let warningText = '(' + data.Warning + ')' + isWarningPriority;

  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}?state=warning`)} icon={<span className='text-warning'>{warningText}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}?state=critical`)} icon={<span className='text-danger'>{criticalText}</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellReportingTenantStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();

  let criticalPriorityText = data.CriticalPriorityTenants;
  let isCriticalPriority = '';

  if (criticalPriorityText && isInt(criticalPriorityText) && criticalPriorityText > 0) {
    isCriticalPriority = '*';
  }

  let criticalText = '(' + data.Critical + ')' + isCriticalPriority;


  let warningPriorityText = data.WarningPriorityTenants;
  let isWarningPriority = '';

  if (warningPriorityText && isInt(warningPriorityText) && warningPriorityText > 0) {
    isWarningPriority = '*';
  }
  let warningText = '(' + data.Warning + ')' + isWarningPriority;


  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/0`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/0?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/0?state=warning`)} icon={<span className='text-warning'>{warningText}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/0?state=critical`)} icon={<span className='text-danger'>{criticalText}</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellRecordingTenantStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();

  let criticalPriorityText = data.CriticalPriorityTenants;
  let isCriticalPriority = '';

  if (criticalPriorityText && isInt(criticalPriorityText) && criticalPriorityText > 0) {
    isCriticalPriority = '*';
  }

  let criticalText = '(' + data.Critical + ')' + isCriticalPriority;


  let warningPriorityText = data.WarningPriorityTenants;
  let isWarningPriority = '';

  if (warningPriorityText && isInt(warningPriorityText) && warningPriorityText > 0) {
    isWarningPriority = '*';
  }
  let warningText = '(' + data.Warning + ')' + isWarningPriority;


  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/1`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/1?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/1?state=warning`)} icon={<span className='text-warning'>{warningText}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${props.dataItem.InstanceId}/1?state=critical`)} icon={<span className='text-danger'>{criticalText}</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};


export const CellOverviewTenantReporting = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let criticalPriorityText = data.CriticalPriorityTenants;
  let isCriticalPriority = '';

  if (criticalPriorityText && isInt(criticalPriorityText) && criticalPriorityText > 0) {
    isCriticalPriority = '*';
  }

  let criticalText = '(' + data.Critical + ')' + isCriticalPriority;


  let warningPriorityText = data.WarningPriorityTenants;
  let isWarningPriority = '';

  if (warningPriorityText && isInt(warningPriorityText) && warningPriorityText > 0) {
    isWarningPriority = '*';
  }
  let warningText = '(' + data.Warning + ')' + isWarningPriority;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (

    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0?state=warning`)} icon={<span className='text-warning'>{warningText}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0?state=critical`)} icon={<span className='text-danger'>{criticalText}</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>

  );
};

export const CellOverviewTenantRecording = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let criticalPriorityText = data.CriticalPriorityTenants;
  let isCriticalPriority = '';

  if (criticalPriorityText && isInt(criticalPriorityText) && criticalPriorityText > 0) {
    isCriticalPriority = '*';
  }

  let criticalText = '(' + data.Critical + ')' + isCriticalPriority;


  let warningPriorityText = data.WarningPriorityTenants;
  let isWarningPriority = '';

  if (warningPriorityText && isInt(warningPriorityText) && warningPriorityText > 0) {
    isWarningPriority = '*';
  }
  let warningText = '(' + data.Warning + ')' + isWarningPriority;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (

    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/1`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/1?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/1?state=warning`)} icon={<span className='text-warning'>{warningText}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/1?state=critical`)} icon={<span className='text-danger'>{criticalText}</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>

  );
};

export const CellOverviewPortalIncidents = (props) => {
  // const data = props.data;
  const instanceId = props.instanceId;

  const criticalCount = props.criticalCount;
  const warningCount = props.warningCount;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (

    <div className="overview-status" >
      {criticalCount != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          {/* <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text /> */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId}?type=critical`)} icon={<span className='text-danger'>{criticalCount}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/portal/alarms/${instanceId}?type=warning`)} icon={<span className='text-warning'>{warningCount}</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>

  );
};

export const CellOverviewInfraIncidents = (props) => {
  // const data = props.data;
  const instanceId = props.instanceId;

  const criticalCount = props.criticalCount;
  const warningCount = props.warningCount;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (

    <div className="overview-status" >
      {criticalCount != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          {/* <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/portal/detail/${instanceId}/0?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text /> */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/alarms/${instanceId}?type=critical`)} icon={<span className='text-danger'>{criticalCount}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/alarms/${instanceId}?type=warning`)} icon={<span className='text-warning'>{warningCount}</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>

  );
};

export const CellOverviewServiceIncidents = (props) => {
  // const data = props.data;
  const instanceId = props.instanceId;

  const criticalCount = props.criticalCount;
  const warningCount = props.warningCount;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (

    <div className="overview-status" >
      {criticalCount != null ? (
        <>
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/alarms/${instanceId}?type=critical`)} icon={<span className='text-danger'>{criticalCount}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/alarms/${instanceId}?type=warning`)} icon={<span className='text-warning'>{warningCount}</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>

  );
};

export const CellOverviewIIS = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/1,4`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/1,4?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/1,4?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/1,4?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>
  );
};

export const CellOverviewDB = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/3,6`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/3,6?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/3,6?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/3,6?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>
  );
};

export const CellOverviewDC = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/2,5`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/2,5?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/2,5?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${instanceId}/2,5?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>
  );
};

export const CellOverviewReportingServices = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/2`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/2?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/2?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/2?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>
  );
};
export const CellOverviewRecordingServices = (props) => {
  const data = props.data;
  const instanceId = props.instanceId;

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <div className="overview-status" >
      {data != null ? (
        <>
          {/* {props.text && <span>{props.text}</span>}
           {props.text && <span></span>} */}
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/1`)} icon={<span>{data.Total}</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/1?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/1?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
          <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${instanceId}/1?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
        </>
      ) : (
        <span>NA</span>
      )}
    </div>
  );
};

export const CellIISStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/1,4`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/1,4?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/1,4?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/1,4?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellDBStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/3,6`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/3,6?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/3,6?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/3,6?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};
export const CellHealthStatus = (props) => {
  const data = props.dataItem[props.field];
  return (
    <td style={{ textAlign: "center" }}>
      {data === 0 && (
        <div className="d-flex align-item-center">
          {props.text && <span>{props.text}&nbsp;</span>}
          <div className="stausDot mr-1 bg-success"></div>
          <span>Healthy</span>
        </div>
      )}
      {data === 1 && (
        <div className="d-flex align-item-center">
          {props.text && <span>{props.text}&nbsp;</span>}
          <div className="stausDot mr-1 bg-warning"></div>
          <span>Warning</span>
        </div>
      )}
      {data === 2 && (
        <div className="d-flex align-item-center">
          {props.text && <span>{props.text}&nbsp;</span>}
          <div className="stausDot mr-1 bg-danger"></div>
          <span>Critical</span>
        </div>
      )}
    </td>
  );
};

// export const CellOpenIncidents = (props) => {
//   const TenantCriticalCount = props.dataItem["TenantCriticalCount"];
//   const TenantWarningCount = props.dataItem["TenantWarningCount"];

//   return (
//     <td className={props.className}  style={ { left: props.style.left, right: props.style.right , textAlign:"center"}}>
//       <Button   icon={<span className='text-danger' >{TenantCriticalCount}</span>} iconOnly text />
//       <Button    icon={<span className='text-warning' >{TenantWarningCount}</span>} iconOnly text />
//        {/* {TenantCriticalCount + "/" + TenantWarningCount} */}
//     </td>
//   );
// };

export const CellOpenIncidents = (props) => {
  const TenantCriticalCount = props.dataItem["TenantCriticalCount"];
  const TenantWarningCount = props.dataItem["TenantWarningCount"];


  const [tenantsData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);


  const tenantId = props.dataItem[props.field];
  const instanceId = props.dataItem["InstanceId"];

  const customerName = props.dataItem["CustomerName"];

  let incidentLevelId = 2;

  //console.log('load CellIncidenttenants');
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "IncidentTimestampUTC",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });

  let LoadData = () => {
    // console.log('load data');
    props.acquireToken((token) => {
      let _params = {
        tenantId: tenantId,
        instanceId: instanceId,
        incidentLevelId: incidentLevelId
      }
      setLoaded(false);
      MonitoringService.GetTenantIncidents(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;

          // console.log(response);
          setData(response?.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }

  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Tenant Incidents"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>

            <Text size="medium" as="p" content={`Customer: ${customerName}`} />
            <Card.Body>
              <Toolbar
                items={[
                  {
                    key: 'custom-button',
                    kind: 'custom',
                    content: <Button onClick={() => { incidentLevelId = 2; LoadData(); }} content="Open Incidents" />,
                    fitted: 'horizontally',
                    className: 'btn mr-2 active',
                  }, {
                    key: 'custom-button',
                    kind: 'custom',
                    content: <Button onClick={() => { incidentLevelId = -1; LoadData(); }} content="Historical Incidents" />,
                    fitted: 'horizontally',
                    className: 'btn',
                  },

                ]}
              />

            </Card.Body>
            {

              isLoaded ? (
                <div className="w-100">
                  <Grid
                    //  data={tenantsData} 
                    data={process(tenantsData, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    style={{ height: "300px" }}>
                    <Column field="IncidentId" title="Incident Id" cell={CellIncidentIdNew} />
                    <Column field="InstanceName" title="Instance Name" />
                    <Column field="IncidentTimestampUTC" title={<CellColTitle title="Timestamp UTC" />} cell={CellDateTime} width={200} />
                    <Column field="IncidentType" title="Incident Type" />
                    <Column field="IncidentLevel" title={<CellColTitle title="Incident Level" />} cell={CellIncidentLevelNew} />
                    <Column field="IncidentStatus" title="Incident Status" />
                  </Grid>
                </div>

              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={<div>
          <Button onClick={() => { incidentLevelId = 2; }} icon={<span className='text-danger' >{TenantCriticalCount}</span>} iconOnly text />
          <Button onClick={() => { incidentLevelId = 1; }} icon={<span className='text-warning' >{TenantWarningCount}</span>} iconOnly text />
        </div>}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

export const CellIncidentLevelNew = (props) => {
  const incidentLevel = props.dataItem.IncidentLevel;
  return (
    <td style={{ textAlign: "center" }}>
      {incidentLevel === 'Healthy' && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-success"></div>
          <span>Healthy</span>
        </div>
      )}
      {incidentLevel === 'Warning' && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-warning"></div>
          <span>Warning</span>
        </div>
      )}
      {incidentLevel === 'Critical' && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-danger"></div>
          <span>Critical</span>
        </div>
      )}
    </td>
  );
};

export const CellIncidentIdNew = (props) => {
  const data = props.dataItem;
  return (
    <td className='position-relative' style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.IncidentLevel === 'Healthy' && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.IncidentLevel === 'Warning' && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.IncidentLevel === 'Critical' && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.IncidentId}</span> </>}
      />
    </td>
  );
};


export const CellAlertLevelNew = (props) => {
  //debugger
  const alertLevel = props.dataItem.Health;
  return (
    <td style={{ textAlign: "center" }}>
      {alertLevel === 0 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-success"></div>
          <span>Healthy</span>
        </div>
      )}
      {alertLevel === 1 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-warning"></div>
          <span>Warning</span>
        </div>
      )}
      {alertLevel === 2 && (
        <div className="d-flex align-item-center">
          <div className="stausDot mr-1 bg-danger"></div>
          <span>Critical</span>
        </div>
      )}
    </td>
  );
};

export const CellAlertIdNew = (props) => {
  //debugger

  const data = props.dataItem;
  return (
    <td className='position-relative' style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.AlertId}</span> </>}
      />
    </td>
  );
};

export const CellTenantAlertIdNew = (props) => {
  //debugger

  const data = props.dataItem;
  return (
    <td className='position-relative' style={{ left: props.style.left, right: props.style.right, textAlign: "center" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {props.dataItem.Health === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {props.dataItem.Health === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {props.dataItem.Health === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
      <Text
        size="medium"
        as="p"
        content={<> <span> {data.AlertTypeId}</span> </>}
      />
    </td>
  );
};

export const CellDCStatus = (props) => {
  const data = props.dataItem[props.field];
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/2,5`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/2,5?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/2,5?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/infra/detail/${props.dataItem.InstanceId}/2,5?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};

export const CellNumberedStringList = (props) => {
  const data = props.dataItem[props.field];
  return (
    <td style={{ textAlign: "left", wordBreak: "break-word" }}>
      <div className='d-flex'>
        {
          data != null ?
            <>
              {
                data.map((d, i) => (<span className='mb-1'>{i + 1}: {d}</span>))
              }
            </>
            :
            <span>NA</span>
        }
      </div>
    </td>
  );
};


export const CellIncidentHistory = (props) => {
  const [historyData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const incidentSection = props.IncidentSection;
  const incidentId = props.dataItem[props.field];

  //console.log('load CellIncidentHistory');
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "DateAdded",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });

  let LoadData = () => {
    props.acquireToken((token) => {
      let _params = {
        IncidentSection: incidentSection,
        IncidentId: incidentId,
      }
      setLoaded(false);
      MonitoringService.GetIncidentHistory(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;

          // console.log(response);
          setData(response?.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }



  //    const  historyData = [{
  //         "DateUpdated": "0001-01-01T00:00:00",
  //         "IncidentStatusId": 1,
  //         "IncidentOwner": "prashant.malik@tollring.com",
  //         "Comment": "Additional teams comment"
  //     },
  //     {
  //         "DateUpdated": "0001-01-01T00:00:00",
  //         "IncidentStatusId": 2,
  //         "IncidentOwner": "prashant.malik@tollring.com",
  //         "Comment": "Additional teams comment"
  //     },
  //     {
  //         "DateUpdated": "0001-01-01T00:00:00",
  //         "IncidentStatusId": 3,
  //         "IncidentOwner": "prashant.malik@tollring.com",
  //         "Comment": "Additional teams comment"
  //     }
  //     ]
  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Incident History"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            <Text size="medium" as="p" content={`Incident Ref#: ${incidentId}`} />
            {
              isLoaded ? (
                <Grid
                  // data={historyData}
                  data={process(historyData, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }}
                  className="no-scroll-width"

                >
                  <Column field="DateAdded" title="Date Updated" cell={CellDateTime} />
                  <Column field="IncidentStatus" title="Status" />
                  <Column field="IncidentOwner" title="Owner" />
                  <Column field="Comment" title="Comment" />
                </Grid>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={<Button iconOnly text icon={<UndoIcon />} />}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

export const CellComments = (props) => {
  const [historyData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const incidentSection = props.IncidentSection;
  const incidentId = props.dataItem[props.field];
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "DateAdded",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });
  const [state, setState] = useState({
    comment: "",
    RequiredCommentMessage: ""
  });


  const commentChange = (event) => {
    setState({
      comment: event.target.value,
      RequiredCommentMessage: ""
    });
  };


  let LoadData = (showLoading) => {
    props.acquireToken((token) => {
      let _params = {
        IncidentSection: incidentSection,
        IncidentId: incidentId,
      }
      if (showLoading == true) {
        setLoaded(false);
      }
      MonitoringService.GetIncidentHistory(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;

          // console.log(response);
          setData(response?.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }

  const addComment = () => {
    if (state.comment && state.comment.length > 0) {
      setState({
        comment: state.comment,
        RequiredCommentMessage: ""
      });
    }
    else {
      setState({
        comment: state.comment,
        RequiredCommentMessage: "Required"
      });
      return;
    }

    props.acquireToken((token) => {
      let _params = {
        IncidentSection: incidentSection,
        IncidentId: incidentId,
        Comment: state.comment
      }
      MonitoringService.AddIncidentComment(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          setState({
            comment: "",
            RequiredCommentMessage: ""
          });
          LoadData(false);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if (response && response.status == 401) {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    })
  }

  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }}>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Incident History"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            <Text size="medium" as="p" content={`Incident Ref#: ${incidentId}`} />
            {
              isLoaded ? (
                <>
                  <Grid
                    // data={historyData}
                    className="no-scroll-width"
                    data={process(historyData, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}

                  >
                    <Column field="DateAdded" title="Date Updated" cell={CellDateTime} />
                    <Column field="IncidentStatus" title="Status" />
                    <Column field="IncidentOwner" title="Owner" />
                    <Column field="Comment" title="Comment" />
                  </Grid>

                  {
                    props.user?.Permissions?.some((p) => (p == "Dashboards.ReadWrite" || p == "Dashboards.Monitoring.ReadWrite")) &&
                    <Flex gap="gap.small" className="mt-2"  >
                      <TextArea fluid value={state.comment} onChange={commentChange} required />
                      <Text content="*" error />
                      <Text error content={state.RequiredCommentMessage} />
                      <Flex.Item push align="end" fluid>
                        <Button content="Add Comment" size="small" primary className="add-comment-button" onClick={() => { addComment(); }} />
                      </Flex.Item>
                    </Flex>
                  }


                </>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => {
          setOpen(true); LoadData(true);
          setState({
            comment: "",
            RequiredCommentMessage: ""
          });
        }}
        trigger={<Button iconOnly text icon={<TranscriptIcon />} />}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

export const CellDropDownListUser = () => {
  const role = ["Sales", "Management Team", "R&D", "Admin"];

  return (
    <td style={{ textAlign: "center" }}>
      <DropDownList
        style={{
          width: "100%",
        }}
        data={role}
        defaultItem="Select"
      />
    </td>
  );
};
export const ActionIcon = (props) => {
  const dataItem = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text />
      <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<TrashCanIcon />} text />
    </td>
  );
}

export const CellDateTime = (props) => {
  // let navigate = useNavigate();
  // const routeChange = (path) =>{
  //     navigate(path);
  // }
  // console.log(props);
  const data = props.dataItem[props.field];
  const Level = props.dataItem.ImpactLevel;
  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data), "dd-MMM-yyyy HH:mm");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
      {Level === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {Level === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {Level === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
    </td>
  );

};
export const CellDateTimeRelative = (props) => {
  // let navigate = useNavigate();
  // const routeChange = (path) =>{
  //     navigate(path);
  // }
  // console.log(props);
  const data = props.dataItem[props.field];
  const Level = props.dataItem.ImpactLevel;
  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data), "dd-MMM-yyyy HH:mm");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left", position: "relative" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
      {Level === 0 && (
        <span className="staus-bookmark mr-1 bg-success"></span>
      )}
      {Level === 1 && (
        <span className="staus-bookmark mr-1 bg-warning"></span>
      )}
      {Level === 2 && (
        <span className="staus-bookmark mr-1 bg-danger"></span>
      )}
    </td>
  );

};

export const CellDateTimeUpdated = (props) => {
  // let navigate = useNavigate();
  // const routeChange = (path) =>{
  //     navigate(path);
  // }
  // console.log(props);
  const data = props.dataItem[props.field];
  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data), "dd-MMM-yyyy HH:mm");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
    </td>
  );

};

export const CellVNetTypes = (props) => {
  //debugger;
  const [selectedValue, setSelectedValue] = useState({});
  useEffect(() => {
    let index = props.vnetTypes.findIndex(f => f.VNetTypeId == props.dataItem[props.field]);
    if (index >= 0)
      setSelectedValue(props.vnetTypes[index]);
    else
      setSelectedValue({});
  }, []);
  const handleChange = (event) => {
    //debugger;
    setSelectedValue(event.target.value);
    props.dataItem.VNetTypeId = event.target.value.VNetTypeId;
    props.dataItem.IsUpdating = true;
    props.onChange();
  };

  return (
    <td style={{ textAlign: "center" }}>
      <DropDownList
        style={{
          width: "100%",
        }}
        data={props.vnetTypes}
        textField="VNetTypeName"
        dataItemKey="VNetTypeId"
        value={selectedValue}
        onChange={handleChange}
        disabled={props.dataItem.IsUpdating == true || (props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) == false)}
      />
    </td>
  );
};


export const CellIncidentStatus = (props) => {
  //debugger;
  const IncidentStatuses = props.incidentStatus;
  const id = props.dataItem[props.field];
  const selectedObj = IncidentStatuses.find(obj => {
    return obj.Id == id
  });

  const [state, setState] = useState({
    value: selectedObj
  });

  const handleChange = (event) => {

    setState({
      value: event.target.value,
    });

    props.dataItem.IncidentStatusId = event.target.value.Id;
    props.dataItem.IsUpdating = true;
    props.onChange();
  };

  return (
    <td style={{ textAlign: "center" }}>
      <DropDownList
        style={{
          width: "100%",
        }}
        data={IncidentStatuses}
        textField="Status"
        dataItemKey="Id"
        value={state.value}
        onChange={handleChange}
        disabled={props.dataItem.IsUpdating == true || (props.user?.Permissions?.some((p) => (p == "Dashboards.ReadWrite" || p == "Dashboards.Monitoring.ReadWrite")) == false)}
      />
    </td>
  );
};

export const CellIncidentOwner = (props) => {
  const [data, setData] = React.useState(props.appUsers.slice());
  const email = props.dataItem[props.field];
  const selectedObj = data?.find((obj) => {
    return obj.AppUserEmail == email;
  });
  const [state, setState] = useState({
    value: selectedObj
  });

  const handleChange = (event) => {
    setState({
      value: event.target.value,
    });
    props.dataItem.IncidentOwner = event.target.value.AppUserEmail;
    props.dataItem.IsUpdating = true;
    props.onChange();
    // props.dataItem.Comment = event.target.value.DisplayName;
  };

  const filterData = (filter) => {
    const data = props.appUsers.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  //   const handleBlur = (e) => {
  //     if (isChanged == true) {
  //       props.onChange();
  // props.dataItem.IsUpdating = true;      
  //     }
  //   };
  //console.log(AppUsers);
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <DropDownList
        style={{
          width: "100%",
        }}
        data={data.sort((a, b) =>
          a.DisplayName > b.DisplayName ? 1 : -1
        )}
        textField="DisplayName"
        dataItemKey="AppUserEmail"
        disabled={props.dataItem.IsUpdating == true || (props.user?.Permissions?.some((p) => (p == "Dashboards.ReadWrite" || p == "Dashboards.Monitoring.ReadWrite")) == false)}
        value={state.value}
        // onBlur={handleBlur}
        onChange={handleChange}
        sort
        filterable={true}
        onFilterChange={filterChange}
      />
    </td>
  );
};

export const CellConfigurationState = (props) => {
  const data = props.dataItem[props.field];
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      {
        data ?
          <Status size="larger" state="success" icon={<AcceptIcon />} />
          :
          <Status size="larger" state="error" icon={<CloseIcon />} />
      }
    </td>
  );
};

export const CellPortalGraph = (props) => {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      {props.text && <span>{props.text}&nbsp;</span>}
      <Button styles={{ height: 'auto' }}
        onClick={() => {
          // window.open( "/dashboards/monitoring/infra/metrics/8?id=11&&name=4COM-DB-01", "_blank");
          routeChange(`/dashboards/monitoring/portal/metrics/0?InstanceId=${props.dataItem.InstanceId}&TenantId=${props.dataItem.TenantId}&InstanceName=${props.dataItem.InstanceName}&TenantName=${props.dataItem.TenantName}&Last7Days=0`);
        }}
        iconOnly size="small" icon={<ContentIcon />} text title="View Details" />
    </td>
  );
};

export const CellEmailLogs = (props) => {

  const data = props.dataItem[props.field];

  return (
    <td>
      <div className="line-clamp-2">
        {data}
      </div>
    </td>
  );

};

export const CellAWSNotifications = (props) => {

  const data = props.dataItem[props.field];

  return (
    <td>
      <div className="line-clamp-2">
        {data}
      </div>
    </td>
  );

};


export const CellEmail = (props) => {

  const [emailData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  //const emailId = props.dataItem[props.field];
  const awsMessageId = props.dataItem[props.field];

  const emailSentDate = props.dataItem["EmailSentDate"];

  const subject = props.dataItem["Subject"];

  let LoadData = () => {
    props.acquireToken((token) => {
      let parsedDate = moment(emailSentDate).format("DDMMYYYY");
      let _params = {
        awsMessageId: awsMessageId,
        emailSentDate: parsedDate,
      };
      setLoaded(false);
      SettingsService.GetEmail(token, _params).then((response) => {

        if (response && response.status == 200) {
          response = response.data;
          if (response == '-1') {
            setData("File Not Found");
          }
          else {
            setData(response);
          }

          setLoaded(true);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if (response && response.status == 401) {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    })
  }


  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Email Details"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            {/* <Text size="medium" as="p" content={`Email Sent Date: ${emailSentDate}`} /> */}
            <Text size="medium" as="p" content={<><span className="font-weight-bold" >Email Sent Date: </span> {emailSentDate} </>} />
            <Text size="medium" as="p" content={<><span className="font-weight-bold" >Email Subject: </span> {subject} </>} />

            {
              isLoaded ? (
                <div dangerouslySetInnerHTML={{ __html: emailData }}>

                </div>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={<div>
          <Button icon={<span className='text-primary' >View</span>} iconOnly text />
        </div>}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};




export const CellPermissionGroupNames = (props) => {

  const data = props.dataItem[props.field];
  let arr = data ? data.split(',') : [];

  return (
    <td >
      <div className="row">
        {
          arr ?
            arr.map(item => (
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 permissions-pill">
                <Pill size="small">
                  {item}
                </Pill>
              </div>
            ))
            :
            ""
        }
      </div>
    </td>
  );

};

export const CellUserPermissions = (props) => {

  const data = props.dataItem[props.field];
  const str = props.dataItem["Permissions"];
  let arr = data ? data.split(',') : [];


  return (
    <td >
      {/* <Tooltip cc="s" 
        trigger={
          <div>{data} </div>
        } 
      /> */}

      <Tooltip anchorElement="target" content={(props) => <TooltipContentTemplate title={props.title} />} position="left" className="settings-tooltip" >
        <div title={str}> {data}</div>
      </Tooltip>
    </td>
  );

};


const TooltipContentTemplate = (props) => {

  let str = props.title;
  let arr = props.title.split("$$");

  return (
    <div >
      {
        arr ?
          arr.map(item => (
            <div >
              {item}
            </div>
          ))
          :
          ""
      }
    </div>
  );
};

export const CellColTitle = (props) => {
  // console.log(props);
  const str = props.title;

  return (
    <Tooltip anchorElement="target" content={(props) => <TooltipTemplateHeader title={props.title} />} position="top" className="settings-tooltip" >
      <div title={str}> {str}</div>
    </Tooltip>
  );

};

const TooltipTemplateHeader = (props) => {

  let str = props.title;
  let arr = props.title.split("$$");

  return (
    <div >
      {
        str
      }
    </div>
  );
};


export const CellPillGroup = (props) => {
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      <PillGroup style={{ flexWrap: 'wrap' }} aria-label={props.id + '_' + props.field}>
        {
          props.dataItem[props.field].map((m) => <Pill key={props.id + '_' + props.field + '_' + m.text} className={`state-pill ${m.state}`} size="smaller">{m.text}</Pill>)
        }
      </PillGroup>
    </td>
  );
};

export const CellPill = (props) => {
  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      <PillGroup style={{ flexWrap: 'wrap' }} aria-label={props.id + '_' + props.field}>
        {
          props.dataItem[props.field].map((m) => <Pill key={props.id + '_' + props.field + '_' + m.text} className={`state-pill ${m.state}`} size="smaller">{`${m.text.toFixed(2)}%`}</Pill>)
        }
      </PillGroup>
    </td>
  );
};

export const CellServerRuleBreached = (props) => {
  let y = props.dataItem[props.field];
  let impactedElems = [];
  if (y.includes('StorageAccount')) {
    impactedElems.push({ 'text': y, 'state': props.dataItem.IncidentLevelId == 2 ? 'critical' : 'warning' })
  }
  else {
    y = y.split(',');
    if (y.length > 0) {
      y.forEach((v, i) => {
        if (v.includes('Critical')) {
          v = v.replace('Critical: ', '');
          let _ar = v.split('|').map(m => ({ 'text': m, 'state': 'critical' }));
          impactedElems.push(..._ar);
        }
        else if (v.includes('Warning')) {
          v = v.replace('Warning: ', '');
          let _ar = v.split('|').map(m => ({ 'text': m, 'state': 'warning' }));
          impactedElems.push(..._ar);
        }
      })
    }
  }


  return (
    <td style={!props.text ? { textAlign: "center" } : {}}>
      <PillGroup style={{ flexWrap: 'wrap' }} aria-label={props.id + '_' + props.field}>
        {
          impactedElems.map((m) => <Pill key={props.id + '_' + props.field + '_' + m.text} className={`state-pill ${m.state}`} size="smaller">{`${m.text}`}</Pill>)
        }
      </PillGroup>
    </td>
  );
};
export const MinutesFormat = (props) => {

  const m = props.minutes;

  let diff = '';

  if (m && isInt(m) && m > 0) {
    let days = Math.floor(m / 24 / 60);
    let hours = Math.floor(m / 60 % 24);
    let minutes = Math.floor(m % 60);
    if (days > 0) {
      diff = Math.floor(m / 24 / 60) + ' days, ' + Math.floor(m / 60 % 24) + ' hours, ' + Math.floor(m % 60) + ' minutes';
    }
    else if (hours > 0) {
      diff = Math.floor(m / 60 % 24) + ' hours, ' + Math.floor(m % 60) + ' minutes';
    }
    else {
      diff = Math.floor(m % 60) + ' minutes';
    }
  }

  return (
    <span>{diff}</span>
  );

};

export const CellCustomerName = (props) => {

  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right }} >
      <span> {props.dataItem.CustomerName}</span>
      {props.dataItem.IsDayCustomer == '0' ?
        <img className="dayNight-icon" src={moonImage} />
        : props.dataItem.IsDayCustomer == '1' ? <img className="dayNight-icon" src={sunImage} /> : <></>}
    </td>

  )

}

export const CellTenantName = (props) => {

  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right }} >
      <span> {props.dataItem.TenantName}</span>
      {props.dataItem.PriorityLevel && props.dataItem.PriorityLevel.includes('P') ? ' *' : ''}
    </td>

  )

}

function isInt(value) {
  return !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10));
}

export const CellServiceStatus = (props) => {
  const data = props.dataItem[props.field];
  const serviceType = props.field == 'ReportingStatus' ? 2 : 1;
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  return (
    <td className="tenantStatus" style={{ textAlign: "center" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {data != null ? (
          <>
            {props.text && <span>{props.text}</span>}
            {props.text && <span></span>}
            <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${props.dataItem.InstanceId}/${serviceType}`)} icon={<span>{data.Total}</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${props.dataItem.InstanceId}/${serviceType}?state=healthy`)} icon={<span className='text-success'>({data.Healthy})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${props.dataItem.InstanceId}/${serviceType}?state=warning`)} icon={<span className='text-warning'>({data.Warning})</span>} iconOnly text />
            <Button onClick={() => routeChange(`/dashboards/monitoring/service/detail/${props.dataItem.InstanceId}/${serviceType}?state=critical`)} icon={<span className='text-danger'>({data.Critical})</span>} iconOnly text />
          </>
        ) : (
          <span>NA</span>
        )}
      </div>
    </td>
  );
};


export const CellOpenAlerts = (props) => {
  const NLogCriticalCount = props.dataItem["NLogCriticalCount"];
  const NLogWarningCount = props.dataItem["NLogWarningCount"];

  const [serviceData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  const serviceId = props.dataItem[props.field];

  const serviceName = props.dataItem["ServiceName"];
  const ServiceDisplayName = props.dataItem["ServiceDisplayName"];
  const DCName = props.dataItem["DCName"];

  let alertType = 'Critical';
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InsertTime",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });

  //console.log('load CellIncidenttenants');

  let LoadData = () => {
    // console.log('load data');
    props.acquireToken((token) => {
      let _params = {
        serviceId: serviceId,
        alertType: alertType
      }
      setLoaded(false);
      MonitoringService.GetServiceAlerts(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          // console.log(response);
          setData(response?.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }


  const [open, setOpen] = useState(false);
  const [alerTypeStateForTitle, setAlerTypeStateForTitle] = useState(1);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Service NLog Alerts"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            <Text size="medium" as="p" content={`Service Name: ${serviceName}`} />
            <Text size="medium" as="p" content={`Service Display Name: ${ServiceDisplayName}`} />
            <Text size="medium" as="p" content={`Machine Name: ${DCName}`} />
            <Toolbar
              items={[
                {
                  key: 'custom-button',
                  kind: 'custom',
                  content: <Button onClick={() => { alertType = 'Critical'; LoadData(); setAlerTypeStateForTitle(1); }} content="Open Alerts" />,
                  fitted: 'horizontally',
                  className: 'btn mr-2',
                }, {
                  key: 'custom-button',
                  kind: 'custom',
                  content: <Button onClick={() => { alertType = '-1'; LoadData(); setAlerTypeStateForTitle(-1); }} content="Historical Alerts" />,
                  fitted: 'horizontally',
                  className: 'btn',
                },

              ]}
            />

            {
              isLoaded ? (
                <Grid

                  data={process(serviceData, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }} className="no-scroll-width" style={{
                    height: "300px",
                  }}>

                  <Column field="AlertId" title="Alert Id" cell={CellAlertIdNew} width={100} />

                  <Column field="Health" title="Incident Level" cell={CellAlertLevelNew} width={100} />
                  <Column field="MessageText" title="Message" width={200} />
                  <Column field="DBName" title="Database" width={180} />

                  <Column field="InsertTime" title={<CellColTitle title="Date Added" />} cell={CellDateTime} width={180} />
                  <Column field="HubDateUpdated" title={<CellColTitle title={alerTypeStateForTitle == "-1" ? "Date Cleared" : "Date Updated"} />} cell={CellDateTime} width={180} />

                </Grid>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={<div>
          <Button onClick={() => { alertType = 'Critical'; }} icon={<span className='text-danger' >{NLogCriticalCount}</span>} iconOnly text />
          <Button onClick={() => { alertType = 'High'; }} icon={<span className='text-warning' >{NLogWarningCount}</span>} iconOnly text />
        </div>}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};




export const CellTenantedAlerts = (props) => {
  const TenantedCriticalCount = props.dataItem["TenantedCriticalCount"];

  const [serviceData, setData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  const serviceId = props.dataItem[props.field];

  const serviceName = props.dataItem["ServiceName"];
  const ServiceDisplayName = props.dataItem["ServiceDisplayName"];
  const DCName = props.dataItem["DCName"];
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "DateAddedHub",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });
  //console.log('load CellIncidenttenants');
  let alertType = '1';
  let LoadData = () => {
    // console.log('load data');
    props.acquireToken((token) => {
      let _params = {
        serviceId: serviceId,
        alertType: alertType
      }
      setLoaded(false);
      MonitoringService.GetTenantedAlerts(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          // console.log(response);
          setData(response?.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }


  const [open, setOpen] = useState(false);
  const [alerTypeStateForTitle, setAlerTypeStateForTitle] = useState(1);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Tenanted Alerts"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            <Text size="medium" as="p" content={`Service Name: ${serviceName}`} />
            <Text size="medium" as="p" content={`Service Display Name: ${ServiceDisplayName}`} />
            <Text size="medium" as="p" content={`Machine Name: ${DCName}`} />
            <Toolbar
              items={[
                {
                  key: 'custom-button',
                  kind: 'custom',
                  content: <Button onClick={() => { alertType = '1'; LoadData(); setAlerTypeStateForTitle(1); }} content="Open Alerts" />,
                  fitted: 'horizontally',
                  className: 'btn mr-2',
                }, {
                  key: 'custom-button',
                  kind: 'custom',
                  content: <Button onClick={() => { alertType = '-1'; LoadData(); setAlerTypeStateForTitle(-1); }} content="Historical Alerts" />,
                  fitted: 'horizontally',
                  className: 'btn',
                },

              ]}
            />

            {
              isLoaded ? (
                <Grid
                  //  data={serviceData}
                  data={process(serviceData, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }}
                  className="no-scroll-width" style={{
                    height: "300px",
                  }} >

                  <Column field="AlertTypeId" title="Type Id" cell={CellTenantAlertIdNew} width={100} />

                  <Column field="Health" title="Incident Level" cell={CellAlertLevelNew} width={100} />
                  <Column field="TenantId" title="Tenant Id" width={100} />
                  <Column field="CustomerName" title="Tenant Name" width={200} />
                  <Column field="AlertDetails" title="Details" width={150} />

                  <Column field="DateAddedHub" title={<CellColTitle title="Date Added" />} cell={CellDateTime} width={200} />
                  <Column field="DateUpdatedHub" title={<CellColTitle title={alerTypeStateForTitle == "-1" ? "Date Cleared" : "Date Updated"} />} cell={CellDateTime} width={200} />

                </Grid>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={<div>
          <Button icon={<span className='text-danger' >{TenantedCriticalCount}</span>} iconOnly text />
        </div>}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

export const rowRender = (trElement, props) => {
  const update = props.dataItem.IsUpdating;
  const updating = {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };
  const notupdating = {
    backgroundColor: "#fff",
  };
  const trProps = {
    style: update ? updating : notupdating,
  };
  return React.cloneElement(
    trElement,
    {
      ...trProps,
    },
    trElement.props.children
  );
};


export const CellSPAccess = (props) => {

  const [data, setData] = useState(null);
  const [portalTypes, setPortalTypes] = useState(null);
  const [isLoaded, setLoaded] = useState(false);


  const tenantId = props.dataItem[props.field];
  const instanceId = props.dataItem["InstanceId"];

  const InstanceName = props.dataItem["InstanceName"];

  let incidentLevelId = 2;

  //console.log('load CellIncidenttenants');

  let LoadData = () => {
    // console.log('load data');
    props.acquireToken((token) => {
      let _params = {
        tenantId: tenantId,
        instanceId: instanceId,
        incidentLevelId: incidentLevelId
      }
      setLoaded(false);

      SettingsService.GetSPAccessData(token, _params).then((response) => {
        // console.log(response);
        if (response && response.status == 200) {
          response = response.data;
          setPortalTypes(response.PortalTypes?.map((m) => ({ header: m.Value, value: m.Key })));
          // console.log(response);

          if (response.Table) {
            for (let i = 0; i < response.Table.length; i++) {
              response.Table[i].isEdit = false;
            }
          }
          setData(response.Table);
          setLoaded(true);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })
  }

  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '50%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="SP Access"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            <Text size="medium" as="p" content={`Instance Name: ${InstanceName}`} />
            {
              isLoaded ? (
                <SpAccessGrid data={data} portalTypes={portalTypes} acquireToken={props.acquireToken} instanceId={instanceId} setOpen={setOpen}> </SpAccessGrid>
              )
                :
                <Loader />
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); LoadData(); }}
        trigger={
          <Button iconOnly size="small" icon={<SpeakerPersonIcon />} text title="SP Access" />
        }
        // cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

function SpAccessGrid(props) {

  const [count, setCount] = useState(1);

  const remove = (data) => {
    // console.log(e);
    // let id = e.target.attributes.Id.value;

    // setCount(count + 1);

    // let index = props.data.findIndex(x => x.Id == id);

    setCount(count + 1);

    let index = props.data.findIndex(x => x.Id == data.Id);

    if (index > -1) {
      props.data[index].visible = 0;
    }

  }

  const update = (data) => {
    setCount(count + 1);

    let index = props.data.findIndex(x => x.Id == data.Id);

    // debugger

    if (index > -1) {
      props.data[index].PortalName = data.PortalName;
      props.data[index].Host = data.Host;
      props.data[index].PortalType = data.PortalType;
      props.data[index].PortalTypeId = data.PortalTypeId;
    }

  }

  const setIsEdit = (data, val) => {

    setCount(count + 1);
    let index = props.data.findIndex(x => x.Id == data.Id);

    if (index > -1) {
      props.data[index].isEdit = val;
    }
  }


  const save = () => {

    props.acquireToken((token) => {
      let table = props.data.filter(x => x.visible != 0);
      let _params = {
        instanceId: props.instanceId,
        Table: table
      }
      // setLoaded(false);

      SettingsService.SaveSpAccessData(token, _params).then((response) => {

        console.log(response);
        if (response && response.status == 200) {
          response = response.data;
          props.setOpen(false);
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
      });
    })

  }

  return (

    <>

      <table className='inline-grid'>
        <thead>
          <tr>
            {/* <th style={{ "width" : "10%"}}>Id</th> */}
            <th style={{ "width": "20%" }}>Portal Name</th>
            <th style={{ "width": "40%" }}>Host</th>
            <th style={{ "width": "20%" }}>Portal Type</th>
            <th style={{ "width": "20%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            props.data.map(x => (
              <SpAccessGridRow Id={x.Id} PortalName={x.PortalName} Host={x.Host} PortalType={x.PortalType} PortalTypeId={x.PortalTypeId}
                remove={remove} visible={x.visible} count={count} key={x.Id} update={update} portalTypes={props.portalTypes}
                setIsEdit={setIsEdit} isEdit={x.isEdit}>
              </SpAccessGridRow>
            ))
          }
        </tbody>
      </table>
      <Button className='grid-inline-add k-button-solid-primary' onClick={() => {

        let maxId = 1;
        if (props.data && props.data.length > 0) {
          maxId = props.data[0].Id;
          for (let i = 0; i < props.data.length; i++) {
            if (props.data[i].Id > maxId) {
              maxId = props.data[i].Id;
            }
          }
        }

        props.data.push({ PortalName: "", Host: "", PortalType: "Live", PortalTypeId: 1, Id: (+maxId + 1), isEdit: true });
        // console.log(props.data);
        setCount(count + 1);
      }}> <AddIcon /> Add
      </Button>
      <Button className='grid-inline-save k-button-solid-primary' onClick={() => {
        save();
        // console.log(props.data);
      }}> Save
      </Button>
    </>

  );


};


const SpAccessGridRow = (props) => {

  // const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = React.useState({ Id: props.Id, PortalName: props.PortalName, Host: props.Host, PortalType: props.PortalType, PortalTypeId: props.PortalTypeId });

  const portalNameChange = (e) => {
    setData({ ...data, PortalName: e.target.value })
  }

  const hostNameChange = (e) => {
    setData({ ...data, Host: e.target.value })
  }

  const handlePortalTypeChange = (alwaysNull, d) => {
    // debugger;
    setData({ ...data, PortalType: d.value.header, PortalTypeId: d.value.value });
  };

  return props.visible != 0 ? (
    <tr>
      {
        props.isEdit ? <>
          {/* <td>{props.Id}</td> */}
          <td><input value={data.PortalName} onChange={portalNameChange} /></td>
          <td><input value={data.Host} onChange={hostNameChange} /></td>
          <td>
            <FormDropdown
              // label={{
              //   content: `Portal Type`,
              //   id: "portal-type-inline",
              // }}
              items={props.portalTypes}
              aria-labelledby={"portaltype-inline"}
              placeholder="Choose Portal Type"
              // required
              autosize="always"
              value={data.PortalType}
              // value={
              //   props.portalTypes != null  ? {
              //         header: props.portalTypes,
              //         value: props.portalTypes,
              //       }
              //     : null
              // }
              onChange={handlePortalTypeChange}
              maxHeight="20px"
            />
          </td>
          <td style={{ textAlign: "center" }}>
            <button Id={props.Id} className="text-success"
              onClick={() => {
                props.setIsEdit(data, false);
                props.update(data);
              }}> <AcceptIcon />
            </button>

            <button Id={props.Id} className="text-danger"
              onClick={() => {
                props.setIsEdit(data, false);
              }}> <CloseIcon />
            </button>
          </td>
        </>
          : <>
            {/* <td>{props.Id}</td> */}
            <td>{props.PortalName}</td>
            <td>{props.Host}</td>
            <td>{props.PortalType}</td>
            <td style={{ textAlign: "center" }}>
              <button Id={props.Id} className="text-primary"
                onClick={() => {
                  props.setIsEdit(data, true);
                }}> <EditIcon />
              </button>
              <button Id={props.Id} className="text-danger"
                onClick={() => {
                  props.remove(data);
                }}>
                <TrashCanIcon /></button>
            </td>
          </>
      }

    </tr>
  ) : ""
};

export const CustomHeaderCell = (props) => {
  const { field, title, sort } = props;
  const isSorted = sort.some(s => s.field === field);
  const sortDir = isSorted ? sort.find(s => s.field === field).dir : '';
  
  return (
    <div className="k-link">
      {title}
      {isSorted && sortDir === 'asc' && <span style={{ color: 'red' }}> ▲</span>}
      {isSorted && sortDir === 'desc' && <span style={{ color: 'red' }}> ▼</span>}
    </div>
  );
};

export const CellDateEmailService = (props, dt) => {
debugger;
  const data = props.dataItem[props.field];

  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data),  "dd-MMM-yyyy hh:mm a");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
    </td>
  );

};

export const CellDateEmailServiceDt = (props) => {

  const data = props.dataItem[props.field];

  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data), "dd-MMM-yyyy");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
    </td>
  );

};

export const CellEmailToken = (props) => {

  const data = props.dataItem[props.field];
  const [showCopied, setShowCopied] = React.useState(false);

  let dataDisplay = "";

  if (data && data.length >= 5) {
    dataDisplay = data.substring(0, 5);
  }

  return (
    <td>
      <div className="d-flex position-relative" >
        <div style={{ fontFamily: "Consolas", fontSize: '15px' }}>
          <>
            {dataDisplay}
            <span style={{ verticalAlign: 'text-top' }}>*************************</span>
          </>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {
            showCopied ? <Label style={{ position: 'absolute', right: '25px' }}>Copied!</Label> : ""
          }
          <Button primary size="small" className="copy-icon" icon={<ClipboardCopiedToIcon />} text iconOnly title="Copy"
            onClick={() => {
              navigator.clipboard.writeText(data);
              setShowCopied(true);
              setTimeout(() => { setShowCopied(false); }
                , 1000);
              // props.updateLabel(true);
            }} />
        </div>
      </div>
    </td>
  );

};

export const CellEmailContent = (props) => {


  const emailSentDate = props.dataItem["SentDate"];

  const subject = props.dataItem["Subject"];
  const emailData = props.dataItem["SentContent"];
  const content1 = props.dataItem["SentContent1"];
  const content2 = props.dataItem["SentContent2"];
  const templateId = props.dataItem["TemplateId"];
  let parsedDate = moment(emailSentDate).format("DD MMM, yyyy, hh:mm:ss a");



  const [open, setOpen] = useState(false);
  return (
    <td style={{ textAlign: "center" }} className='historyCell'>
      <Dialog
        styles={{ width: '60%', textAlign: "left", maxHeight: "90%" }}
        backdrop={true}
        header="Email Content"
        headerAction={{
          icon: <CloseIcon />,
          title: 'Close',
          onClick: () => setOpen(false),
        }}

        content={
          <>
            {/* <Text size="medium" as="p" content={`Email Sent Date: ${emailSentDate}`} /> */}
            <Text size="medium" as="p" content={<><span className="font-weight-bold" >Email Sent Date: </span> {parsedDate} </>} />
            <Text size="medium" as="p" content={<><span className="font-weight-bold" >Email Subject: </span> {subject} </>} />
            {
              templateId == '1' ?
                <div dangerouslySetInnerHTML={{ __html: content1 }}></div>
              : templateId == '2' ?
                <div>
                  <div style={{ marginBottom: "2px" }}><span className="font-weight-bold" >Content 1:</span></div>
                  <div dangerouslySetInnerHTML={{ __html: content1 }}></div>

                  <div style={{ marginBottom: "2px", marginTop:'12px' }}><span className="font-weight-bold" >Content 2:</span></div>
                  <div dangerouslySetInnerHTML={{ __html: content2 }}></div>
                  
                </div>
              : ""
            }

          </>
        }
        open={open}
        onOpen={() => { setOpen(true); }}
        trigger={<div>
          <Button icon={<span className='text-primary' >View</span>} iconOnly text />
        </div>}
        cancelButton="Close"
        onCancel={() => setOpen(false)}
      />
    </td>
  )
};

export const CellEmailAnalysisToken = (props) => {

  const data = props.dataItem[props.field];

  let dataDisplay = "";

  if (data) {
    if (data.length >= 5) {
      dataDisplay = data.substring(0, 5);
    }
  }

  return (
    <td>
      <div className="d-flex">
        <div >
          <>
            {dataDisplay}
            {
              dataDisplay && dataDisplay.length > 0 ?
                <span style={{ verticalAlign: 'sub' }}>******</span> : ""
            }
          </>
        </div>
        <div style={{ marginLeft: "auto" }}>
        </div>
      </div>
    </td>
  );

};
export const CellBrandName = (props) => {
    return <td>{props.dataItem.Name}</td>;
};

export const CellBrandDescription = (props) => {
    return <td>{props.dataItem.Description}</td>;
};

export const CellBrandStatus = (props) => {
    return <td>{props.dataItem.Status}</td>;
};

export const CellDateTimeSheet = (props) => {
  
  const data = props.dataItem[props.field];
  const Level = props.dataItem.ImpactLevel;
  let date = '';
  if (data && data.length > 0) {
    date = formatDate(new Date(data), "dd-MMM-yyyy");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {date}
    </td>
  );

};

export const CellDateTimeSheetLR = (props) => {
  
  const l = props.dataItem.L;
  const r = props.dataItem.R;
  let date = '';
  if (l && l.length > 0) {
    date = formatDate(new Date(l), "dd-MMM-yyyy");
  }
  if (r && r.length > 0) {
    date += ' - '  + formatDate(new Date(r), "dd-MMM-yyyy");
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {date}
    </td>
  );

};

export const CellHoursTimeSheet = (props) => {
  
  const data = props.dataItem[props.field];
  let date = data;
  if (data && data.length > 0) {
    let arr = data.split(':');
    if(arr.length > 1){
      date = arr[0] + ':' + arr[1] ;
    }
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {props.text && <span>{props.text}&nbsp;</span>}
      {date}
      
    </td>
  );

};


export const CellIsPS = (props) => {
  
  const data = props.dataItem[props.field];
  let isps = '';
  if (data && data === true) {
    isps = 'Yes'
  }
  else{
    isps = 'No';
  }
  return (
    <td className={props.className} style={{ left: props.style.left, right: props.style.right, textAlign: "left" }} >
      {isps}
    </td>
  );

};

