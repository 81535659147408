import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip, Status } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon, CheckmarkCircleIcon, AcceptIcon , FilesFlashIcon} from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import SettingServerSM from "../../services/settingServerSM";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { getter } from '@progress/kendo-react-common';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactJson from 'react-json-view';


const ServerProcesses = (props) => {
  const [vmData, setVMData] = useState([]);
  const [isLoaded, SetLoaded] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "ProcessName",
        dir: "asc",
      },
    ],
    take: 10000000,
    skip: 0,
  });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "ProcessName", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };

    const submitHandler = e => {
      e.preventDefault();
    }



    React.useEffect(() => {
      props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
      var data = props.serverObj;
      let serverObj = { IPAddress: data.IPAddress, Port: data.Port };

      if (token) {
        SettingServerSM.GetServerProcesses(serverObj, token).then((response) => {
          if (response && response.status == 200) {
            let servicesFound = response.data;
            debugger;
            setVMData(response.data);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }
          SetLoaded(true);
        });
      }
    };

    
    const handleKillProcess = (props1) => {

      let serverObj = {IPAddress : props?.serverObj?.IPAddress, Port : props?.serverObj?.Port, ProcessId : props1?.dataItem?.ProcessId};

      props.acquireToken((token) =>
      {
        SettingServerSM.KillProcess(serverObj,token).then((response) => {
          if(response && response.status==200){
            response=response.data;
              props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
      );
    }


    const KillProcessCell = (props) => {
      return (
        <td>
              <Button size="small" className="sp-access-button" content="Kill" onClick = {() => handleKillProcess(props)} />
        </td>
      );
    }

    const ProcessDetailDialogue = (props1) => {
 
      const [data, setData] = useState(null);
      const [isLoaded, setLoaded] = useState(false);
      const processId = props1?.dataItem?.Id;

      const [open, setOpen] = useState(false);
    
      let incidentLevelId = 2;
    
      //console.log('load CellIncidenttenants');
    
      let LoadData = () => {
        // console.log('load data');
        props1.acquireToken((token) => {
          let _params = {
            IPAddress : props.serverObj.IPAddress,
            Port : props.serverObj.Port,
            ProcessId : processId
          };

          SettingServerSM.GetProcessDetail(token, _params).then((response) => {
            debugger;
            if(response && response.status==200){
              response=response.data;

              setData(response);
              setLoaded(true);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
            }
            else{
              props.viewStatus.InformChildPageStatus(500);
            }
          });
          setLoaded(false);
        })
      }
    
      return (
        <td style={{ textAlign: "center" }} className='historyCell'>
          <FluentDialog
            styles={{ width: '50%', textAlign: "left", maxHeight: "90%" }}
            backdrop={true}
            header="Process Details"
            headerAction={{
              icon: <CloseIcon />,
              title: 'Close',
              onClick: () => setOpen(false),
            }}
    
            content={
              <>
                {data && <ReactJson src={data} />}
              </>
            }
            open={open}
            onOpen={() => { setOpen(true); LoadData(); }}
            trigger={
              <Button iconOnly size="small" icon={<FilesFlashIcon />} text title="Get Process Detail" />
            }
            // cancelButton="Close"
            onCancel={() => setOpen(false)}
          />
        </td>
      )
    };

    const ProcessDetailCell = (prop) => {
      return (
        <ProcessDetailDialogue {...prop} acquireToken={props.acquireToken} />
      );
    };

    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2">
                </CardHeader>
                <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    { vmData && 
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      sortable={true}
                      data={process(vmData, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                    >
                      <Column
                        field="Id"
                        title="Process Id"
                        width={140}
                      />

                      <Column
                        field="ProcessName"
                        title="Process Name"
                        width={140}
                      />
                      <Column
                        field="WorkingSet64"
                        title="WorkingSet64"
                        width={150}
                      />
                      <Column
                        field="PeakVirtualMemorySize64"
                        title="PeakVirtualMemorySize64"
                        width={150}
                      />
                      <Column
                        field=""
                        title="Detail"
                        cell={ProcessDetailCell}
                        width={150}
                      />
                      {props.user?.Permissions?.some(
                          (p) =>
                            p == "ServerVM.Process.ReadWrite" 
                        ) &&  (
                      <Column
                        field=""
                        title="Action"
                        cell={KillProcessCell}
                        width={150}
                      />)
                      }
                    </Grid>
                    }
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
  }


  export { ServerProcesses };