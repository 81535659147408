import React, { useState, useEffect } from 'react';
import SettingsService from '../../services/settingsservice';
import { Dropdown, Text, Card, CardHeader, CardBody, Button, Input, Form, FormDropdown, Loader, EditIcon, TrashCanIcon, Alert, Dialog as FluentDialog } from '@fluentui/react-northstar';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const CUSTOMERPRIORITYLEVELS = [
    {key: 'P1', header: 'P1', value: 'P1'},
    {key: 'P2', header: 'P2', value: 'P2'},
    {key: 'P3', header: 'P3', value: 'P3'}
]
const SettingsPriorityCustomers = (props) => {
    const [isLoaded, setLoaded] = useState(false);
    const [priorityCustomers, setPriorityCustomers] = useState([]);
    const [instances, setInstances] = useState(null);
    const [customersMap, setCustomersMap] = useState(null);
    const [priorityCustomerData, setPriorityCustomerData] = useState(null);
    const [deletePriorityCustomerObj, setDeletePriorityCustomerObj] = useState(null);
    const [isEdit, setEdit] = useState(false);

    const [visible, setVisible] = React.useState(false);
    const [isValid, setIsValid] = useState(false);
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteInstanceServerObj, setDeleteInstanceServerObj] = useState(null);

    const [dataState, setDataState] = React.useState({
        sort: [
        { 
            field: "InstanceName",
            dir: "asc",
        },
        ],
        take: 10,
        skip: 0,
    });

    const filterGridData = (value) => {
        let filter = {
        logic: "or",
        filters: [
            { field: "InstanceName", operator: "contains", value: value },
            { field: "CustomerName", operator: "contains", value: value },
            { field: "TenantName", operator: "contains", value: value },
            { field: "PriorityLevel", operator: "contains", value: value },
        ],
        };
        setDataState({ ...dataState, filter: filter });
    };

    useEffect(() => checkIsValid(), [priorityCustomerData]);

    useEffect(() => {
        props.acquireToken((token) => {
            if(token){
                SettingsService.GetPriorityCustomers(token).then((response) => {
                  if(response && response.status==200){
                    response=response.data;
                        setPriorityCustomers(response.PriorityCustomers);
                        setCustomersMap(response.Customers);
                        setInstances(response.Instances);
                      }
                      else if(response && response.status==401){
                        props.viewStatus.InformChildPageStatus(response.status);
                        }
                        else {
                        props.viewStatus.InformChildPageStatus(500);
                        }  
                        setLoaded(true);    
                })
            }
        });
    }, []);

    const addEditSubmitClickHandler = () => {
        props.acquireToken((token) => {
            if (token) {
                if (isEdit) {
                    SettingsService.AddUpdatePriorityCustomer(
                    token,
                    priorityCustomerData
                    ).then((response) => {
                      if(response && response.status==200){
                        response=response.data;
                        //props.acquireToken(authSuccessCallback);
                        priorityCustomers.map((f) => {
                            if(f.CustomerId == priorityCustomerData.CustomerId){
                                f.PriorityLevel = priorityCustomerData.PriorityLevel;
                            }
                        });
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    }  else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
             }
                    });
                } else {
                    SettingsService.AddUpdatePriorityCustomer(
                    token, priorityCustomerData
                    ).then((response) => {
                      if(response && response.status==200){
                        response=response.data;
                        //props.acquireToken(authSuccessCallback);
                        customersMap[priorityCustomerData.InstanceId] = customersMap[priorityCustomerData.InstanceId]?.filter((f) => f.CustomerId != priorityCustomerData.CustomerId);
                        setPriorityCustomers([...priorityCustomers, {
                            CustomerId: priorityCustomerData.CustomerId, 
                            InstanceId: priorityCustomerData.InstanceId,
                            InstanceName: priorityCustomerData.InstanceName,
                            CustomerName: priorityCustomerData.CustomerName,
                            TenantName: priorityCustomerData.TenantName,
                            PriorityLevel: priorityCustomerData.PriorityLevel
                        }]);
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    }  else if(response && response.status==401){
                      debugger;
                      props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                    }
                    else {
                      props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                    });
                }
            }
        });
        setVisible(false);
        setPriorityCustomerData(null);
        setEdit(false);
        setIsValid(false);
    };
    
    const submitHandler = (e) => {
        e.preventDefault();
    };

    const ActionIconIS = (props) => {
        return (
          <td style={{ textAlign: "center" }}>
            <Button
              styles={{ height: "auto" }}
              iconOnly
              size="small"
              icon={<EditIcon />}
              text
              onClick={() => handleAddEditClickOpen(props.dataItem, true)}
            />
            <Button
              styles={{ height: "auto" }}
              iconOnly
              size="small"
              icon={<TrashCanIcon />}
              text
              onClick={() => {
                setDeletePriorityCustomerObj(props.dataItem);
                setDeleteConfirmationDialog(true);
              }}
            />
          </td>
        );
    };

    const handleAddEditClickOpen = (dataItem, isEdit) => {
        setVisible(true);
        if (isEdit === true) {
          setPriorityCustomerData(dataItem);
          setEdit(true);
          checkIsValid();
        }
    };
    
    const handleAddEditClickClose = () => {
        setVisible(false);
        setPriorityCustomerData(null);
        setEdit(false);
        setIsValid(false);
    };

    const removeData = () => {
        if (deletePriorityCustomerObj != null) {
            props.acquireToken((token) => {
                if (token) {
                    let _params = {
                      CustomerId: deletePriorityCustomerObj.CustomerId,
                    };
                    SettingsService.RemovePriorityCustomer(token, _params).then(
                      (response) => {
                        if(response && response.status==200){
                          response=response.data;
                          //props.acquireToken(authSuccessCallback);
                          let deletedCustomer = priorityCustomers.filter((f) => f.CustomerId == deletePriorityCustomerObj.CustomerId);
                          setPriorityCustomers(priorityCustomers.filter((f) => f.CustomerId != deletePriorityCustomerObj.CustomerId))
                          setCustomersMap({...customersMap, [deletedCustomer.InstanceId]: [...customersMap[deletedCustomer.InstanceId], {
                            key: deletedCustomer.CustomerId, value: deletedCustomer.CustomerId, 
                            header: deletedCustomer.TenantName, content: deletedCustomer.CustomerName
                          }]});
                          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                        }  else if(response && response.status==401){
                          debugger;
                          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                        }
                        else {
                          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                        }
                      }
                    );
                }
            }
        );
        }
        setDeleteConfirmationDialog(false);
        setDeletePriorityCustomerObj(null);
    };

    const checkIsValid = () => {
        if (
          priorityCustomerData?.InstanceId !== undefined &&
          priorityCustomerData?.InstanceId > 0 &&
          priorityCustomerData?.CustomerId !== undefined &&
          priorityCustomerData?.CustomerId > 0 
        ) {
          setIsValid(true);
        } else setIsValid(false);
      };

    return(
        isLoaded ? <>
        <WindowContext.Consumer>
        {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">Priority Customers</div>
								{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                  <Button
                    icon={<AddIcon />}
                    iconPosition="before"
                    content="Add New"
                    primary
                    title="Add New Priority Customer"
                    onClick={handleAddEditClickOpen} className="ml-auto"
                  />}
                </div>
                
                <div>
                  {visible && (
                    <Dialog
                      title={
                        isEdit
                          ? "Edit Priority Customer"
                          : "Add Priority Customer"
                      }
                      width="600px"
                      onClose={handleAddEditClickClose}
                    >
                      <Form onSubmit={submitHandler}>
                        {isEdit ?
                         <div>
                            Instance: <Text content={priorityCustomerData?.InstanceName} weight="bold" />
                        </div> : 
                          <FormDropdown
                            label={{
                              content: `Instance Name`,
                              id: "Instance-Name-inline",
                            }}
                            items={instances}
                            aria-labelledby={"Instance-Name-inline"}
                            placeholder="Choose Instance"
                            required
                            //search
                            autosize="true"
                            value={priorityCustomerData?.InstanceName}
                            onChange={(e, data) => {
                                setPriorityCustomerData({...priorityCustomerData, InstanceId: data.value.value, InstanceName: data.value.header, CustomerId: null, TenantName: null})
                            }}
                          />
                        }
                        {isEdit ? <div>
                            Tenant: <Text content={priorityCustomerData?.TenantName} weight="bold" />
                          </div>
                          : <div>
                          <FormDropdown
                          label={{
                              content: `Tenant Name`,
                              id: "Customer-Name-inline",
                          }}
                          loading
                          placeholder="Choose Tenant"
                          aria-labelledby={"Customer-Name-inline"}
                          required
                          items={customersMap[priorityCustomerData?.InstanceId]}
                          //search
                          itemToValue={(obj) => {
                              return obj.key;
                          }}
                          disabled={!(priorityCustomerData?.InstanceId > 0)}
                          value={priorityCustomerData?.TenantName}
                          onChange={(e, data) => {
                              setPriorityCustomerData({...priorityCustomerData, CustomerId: data.value.value, TenantName: data.value.header, CustomerName: data.value.content})
                          }}
                          />
                      </div>
                          }
                        
                        <div>
                            <FormDropdown
                            label={{
                                content: `Priority Level`,
                                id: "Priority-Level-inline",
                            }}
                            placeholder="Choose Priority Level"
                            aria-labelledby={"Priority-Level-inline"}
                            required
                            items={CUSTOMERPRIORITYLEVELS}
                            //search
                            disabled={!(priorityCustomerData?.InstanceId > 0 && priorityCustomerData?.CustomerId > 0)}
                            value={priorityCustomerData?.PriorityLevel}
                            onChange={(e, data) => {
                                setPriorityCustomerData({...priorityCustomerData, PriorityLevel: data.value.value})
                            }}
                            />
                        </div>
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            disabled={isValid === false}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className='grid-with-search'>
                  <KendoGrid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(priorityCustomers, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    //detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    //onExpandChange={expandChange}
                  >
                    <Column
                      field="InstanceName"
                      title="Instance Name"
                      filterable={false}
                      width={200}
                    />
                    <Column
                        field="CustomerName"
                        title="Customer Name"
                        width={200}
                    />
                    <Column
                        field="TenantName"
                        title="Tenant Name"
                        width={150}
                    />
                    <Column
                        field="PriorityLevel"
                        title="Priority Level"
                        width={150}
                    />
								{ props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&
                    
                    <Column
                      field=""
                      title="Actions"
                      cell={ActionIconIS}
                      filterable={false}
                      width={100}
                    />}
                  </KendoGrid>
                </div>
              </CardBody>
            </Card>
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteInstanceServerObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteInstanceServerObj(null);
              },
            }}
            content={
              "Are you sure you want to delete mapping between " +
              deleteInstanceServerObj?.InstanceName +
              " and " +
              deleteInstanceServerObj?.MachineNameList?.join(", ") +
              "?"
            }
          />
        </div>
      )}
    </WindowContext.Consumer>
        </>
        : <Loader />
    )
}
export default SettingsPriorityCustomers;