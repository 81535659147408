import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog as FluentDialog,
  Text,
  Button,
  Input,
  Form,
  FormInput,
  Loader,
  EditIcon,
  TrashCanIcon,
  Checkbox,
  Card,
  CardBody,
  CardHeader
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, CloseIcon } from "@fluentui/react-icons-northstar";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import debounce from 'lodash.debounce';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsBrandsAnalysis = (props) => {
  const [brands, setBrands] = useState([]);
  const [brandData, setBrandData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [errors, setErrors] = useState({});
   const [dataState, setDataState] = useState({
    sort: [{ field: "BrandCode", dir: "asc" }],
    take: 10,
    skip: 0,
    filter: {
      logic: "or",
      filters: [],
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);

  useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetBrandsAnalysis(token).then((response) => {
        if (response && response.status === 200) {
          setBrands(response.data || []);
          setDataState({ ...dataState, data: response.data || [] });
          setIsLoaded(true);
        } else {
          props.viewStatus.InformChildPageStatus(response.status || 500);
          setIsLoaded(true);
        }
      });
    }
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setErrors({});
    if (isEdit) {
      setBrandData(dataItem);
      setShowModal(true);
      setShowEditModal(true);
    } else {
      setBrandData({
        BrandCode: "",
        ProductSuiteName: "",
        IsMicrosoftPartnerLogo: false
        // Ensure BrandId is not set for new brands
      });
      setShowModal(true);
      setShowEditModal(false);
    }
  };

  const handleAddEditClickClose = () => {
    setShowModal(false);
    setShowEditModal(false);
    setBrandData(null);
  };

  const handleDeleteClickOpen = (dataItem) => {
    setBrandToDelete(dataItem);
    setShowDeleteConfirm(true);
  };

  const handleDeleteClose = () => {
    setShowDeleteConfirm(false);
    setBrandToDelete(null);
  };

  const handleInputChange = (e, field) => {
    setBrandData({ ...brandData, [field]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!brandData.BrandCode) newErrors.BrandCode = "Brand Code is required.";
    if (!brandData.ProductSuiteName) newErrors.ProductSuiteName = "Product Suite Name is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [
        { field: "BrandCode", operator: "contains", value: value },
        { field: "ProductSuiteName", operator: "contains", value: value }
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const handleToggleChange = (dataItem) => {
    const updatedBrand = { ...dataItem, IsMicrosoftPartnerLogo: !dataItem.IsMicrosoftPartnerLogo };

    props.acquireToken((token) => {
      SettingsService.UpdateBrand(token, updatedBrand).then((response) => {
        if (response && response.status === 200) {
          const updatedBrands = brands.map(brand =>
            brand.BrandId === dataItem.BrandId
              ? updatedBrand
              : brand
          );
          setBrands(updatedBrands);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else {
          const revertedBrands = brands.map(brand =>
            brand.BrandId === dataItem.BrandId
              ? dataItem
              : brand
          );
          setBrands(revertedBrands);
          props.viewStatus.DisplayOperationStatus(response.status || 500);
        }
      }).catch(() => {
        const revertedBrands = brands.map(brand =>
          brand.BrandId === dataItem.BrandId
            ? dataItem
            : brand
        );
        setBrands(revertedBrands);
      });
    });
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (isSaving) return;
    setIsSaving(true);

    // Check if BrandCode already exists in the list (for new brands only)
    if (!showEditModal) {
      const brandCodeExists = brands.some(brand => brand.BrandCode === brandData.BrandCode);
      if (brandCodeExists) {
        setErrors({ BrandCode: "Brand Code already exists." });
        setIsSaving(false);
        return;
      }
    }

    props.acquireToken((token) => {
      // Create a new object excluding BrandId
      const { BrandId, ...brandDataToSend } = brandData;

      const serviceCall = showEditModal
        ? SettingsService.UpdateBrand(token, brandData)
        : SettingsService.AddBrand(token, brandDataToSend); // Exclude BrandId for new brands

      console.log("Sending data to API:", brandDataToSend);

      serviceCall.then((response) => {
        if (response && response.status === 200) {
          authSuccessCallback(token);
          handleAddEditClickClose();
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else {
          console.error("Error response:", response);
          props.viewStatus.DisplayOperationStatus(response.status || 500);
        }
        setIsSaving(false);
      }).catch((error) => {
        console.error("Error during save operation:", error);
        setIsSaving(false);
      });
    });
  };

  const handleSaveDebounced = debounce(handleSave, 300);

  const handleDelete = () => {
    const updatedBrands = brands.filter(brand => brand.BrandCode !== brandToDelete.BrandCode);
    setBrands(updatedBrands);

    props.acquireToken((token) => {
      SettingsService.DeleteBrand(token, brandToDelete.BrandId)
        .then((response) => {
          if (response && response.status === 200) {
            setDataState({ ...dataState, data: updatedBrands });
            handleDeleteClose();
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } else {
            setBrands(brands);
            props.viewStatus.DisplayOperationStatus(response.status || 500);
          }
        })
        .catch(() => {
          setBrands(brands);
        });
    });
  };

  // Memoized processed data
  const processedData = useMemo(() => process(brands, dataState), [brands, dataState]);

  return isLoaded ? (
    <div className="row h-100">
      <div className="col-md-12 h-100">
        <Card className="card-border bg-white h-100" fluid>
          <CardHeader className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="f-size-18">Brands Analysis</div>
              {props.user?.Permissions?.some((p) => p === "Settings.ReadWrite" || p === "Settings.Configuration.ReadWrite") && (
                <Button
                  icon={<AddIcon />}
                  iconPosition="before"
                  content="Add Brand"
                  primary
                  onClick={() => handleAddEditClickOpen(null, false)}
                  className="ml-auto"
                />
              )}
            </div>
          </CardHeader>
          <CardBody>
            <div className="tbl-search float-right text-right mb-2">
              <Input
                onChange={(e, { value }) => {
                  filterGridData(value);
                }}
                inverted
                icon={<SearchIcon />}
                placeholder="Search..."
                className="border-gray"
              />
            </div>
            <div className="grid-with-search">
              <Grid
                style={{
                  height: "90%",
                }}
                pageable={{
                  buttonCount: 3
                }}
                sortable={true}
                data={processedData} // Use the memoized processed data
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                }}
                {...dataState}
              >
                <Column field="BrandCode" title="Brand Code" width="200px" />
                <Column field="ProductSuiteName" title="Product Suite Name" width="200px" />
                <Column
                  field="IsMicrosoftPartnerLogo"
                  title="Microsoft Partner"
                  width="100px"
                  cell={(props) => (
                    <td style={{ textAlign: "center" }}>
                      <Checkbox
                        toggle
                        checked={props.dataItem.IsMicrosoftPartnerLogo}
                        onChange={() => handleToggleChange(props.dataItem)}
                      />
                    </td>
                  )}
                />
                {props.user?.Permissions?.some((p) => p === "Settings.ReadWrite" || p === "Settings.Configuration.ReadWrite") && (
                  <Column
                    field=""
                    title="Actions"
                    style={{ textAlign: "inherit" }}
                    width={70}
                    cell={(props) => (
                      <td style={{ textAlign: "inherit" }}>
                        <Button
                          styles={{ height: 'auto' }}
                          iconOnly
                          size="large"
                          icon={<EditIcon />}
                          text
                          onClick={() => handleAddEditClickOpen(props.dataItem, true)}
                        />
                        <Button
                          styles={{ height: 'auto' }}
                          iconOnly
                          size="large"
                          icon={<TrashCanIcon />}
                          text
                          onClick={() => handleDeleteClickOpen(props.dataItem)}
                        />
                      </td>
                    )}
                  />
                )}
              </Grid>
            </div>
          </CardBody>
        </Card>
        {showModal && (
          <Dialog
            className="k-dialog-md"
            title={showEditModal ? "Edit Brand" : "Add Brand"}
            onClose={handleAddEditClickClose}
          >
            <div className="p-4">
              <Form>
                <FormInput
                  label="Brand Code"
                  required
                  id="alertId-inline"
                  fluid
                  value={brandData?.BrandCode || ""}
                  onChange={(e) => handleInputChange(e, 'BrandCode')}
                  errorMessage={errors.BrandCode}
                  placeholder="Analytics 365"
                />
                <FormInput
                  label="Product Suite Name"
                  required
                  id="alertId-inline"
                  fluid
                  value={brandData?.ProductSuiteName || ""}
                  onChange={(e) => handleInputChange(e, 'ProductSuiteName')}
                  errorMessage={errors.ProductSuiteName}
                  placeholder="Teams Calling"
                />
                <div>
                  <label style={{ display: "inline" }} >Microsoft Partner Logo</label>
                  <Checkbox style={{ display: "inline" }}
                    toggle
                    checked={brandData?.IsMicrosoftPartnerLogo}
                    onChange={() => setBrandData({ ...brandData, IsMicrosoftPartnerLogo: !brandData.IsMicrosoftPartnerLogo })}
                  />
                </div>
                <DialogActionsBar>
                  <Button
                    primary
                    content={isSaving ? "Saving..." : "Save"}
                    onClick={handleSaveDebounced}
                    disabled={isSaving}
                  />
                  <Button
                    secondary
                    content="Cancel"
                    onClick={handleAddEditClickClose}
                  />
                </DialogActionsBar>
              </Form>
            </div>
          </Dialog>
        )}
        {showDeleteConfirm && (
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={handleDeleteClose}
            onConfirm={handleDelete}
            open={showDeleteConfirm}
            backdrop
            header="Confirm Deletion"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                handleDelete(true)
              },
            }}
            content={`Are you sure you want to delete brand "${brandToDelete?.BrandCode}" ?`}
          />
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export { SettingsBrandsAnalysis };
