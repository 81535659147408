import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, RadioGroup, Label, Datepicker } from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellEmailLogs, CellEmail} from '../../shared/appcomponents/custom-grid-cells';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsEmailLogs = (props) => {

	const [EmailLogs, setEmailLogs] = React.useState(null);
	// const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());

	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "EmailSentDate",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});

	const emailDaysItems = [
		{
			name: '7 days',
			key: '7days',
			label: '7 days',
			value: '7',
		},
		{
			name: 'Select Date Range',
			key: 'all',
			label: 'Select Date Range:',
			value: '-1',
		},
	];

	const [emailDaysCheckedValue, setEmailDaysCheckedValue] = React.useState('7')

	const emailDaysChange = (e, data) => {
		
		setEmailDaysCheckedValue(data.value);
		// if(data.value != '-1'){ 
		// 	SetLoaded(false);
		// 	props.acquireToken((token) => {
		// 		if (token) {
		// 			let _params = {
		// 				EmailDays: data.value,
		// 			};
		// 			SettingsService.GetEmailLogs(token, _params).then((response) => {
		// 				if(response && response.status==200){
		// 					response=response.data;
		// 					setEmailLogs(response);
		// 				}
		// 				else if(response && response.status==401){
		// 				props.viewStatus.InformChildPageStatus(response.status);
		// 				}
		// 				else {
		// 				props.viewStatus.InformChildPageStatus(500);
		// 				}        
		// 				SetLoaded(true);
		// 			});
		// 		}
		// 	});
		// }
	};

	const applyDateFilter = () => {
		SetLoaded(false);
		setActive("false");

		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() +1) + '/' + sd.getDate();
		let ed = addDays(new Date(selectedEndDate), 1) ;
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() +1) + '/' + ed.getDate();
		
		props.acquireToken((token) => {
			if (token) {
				let _params = {
					EmailDays: emailDaysCheckedValue,
					startDate: startDate,
					endDate: endDate
				};
				SettingsService.GetEmailLogs(token, _params).then((response) => {
					if(response && response.status==200){
						response=response.data;
						setEmailLogs(response);
                    }
                    else if(response && response.status==401){
					props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
					props.viewStatus.InformChildPageStatus(500);
					}        
					SetLoaded(true);
				});
			}
		});
	}
	
	React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
			let _params = {
				EmailDays: emailDaysCheckedValue,
			};
      SettingsService.GetEmailLogs(token, _params).then((response) => {
		if(response && response.status==200){
            response=response.data;
          setEmailLogs(response);
		}
		else if(response && response.status==401){
			props.viewStatus.InformChildPageStatus(response.status);
		  }
		  else {
			props.viewStatus.InformChildPageStatus(500);
		  }        
		  SetLoaded(true);
      });
    }
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "Subject", operator: "contains", value: value },
								{ field: "Body", operator: "contains", value: value },
								{ field: "EmailTo", operator: "contains", value: value },
								{ field: "EmailCC", operator: "contains", value: value },
								{ field: "EmailBCC", operator: "contains", value: value },
								{ field: "InstanceName", operator: "contains", value: value },
								{ field: "AlertDetails", operator: "contains", value: value },
								{ field: "EmailSentDate", operator: "contains", value: value },
								{ field: "Status", operator: "contains", value: value },
								],
    };
    setDataState({ ...dataState, filter: filter });
  };

	const ActionIconVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        {/* <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/> */}
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            // setDeleteAlertObj(props.dataItem);
            // setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>
					
				</FluentGrid>
			</section>
		);
	};

    const expandChange = (event) => {
      let newData = EmailLogs.map((item) => {
        if (item.alertId === event.dataItem.alertId) {
          item.expanded = !event.dataItem.expanded;
        }

        return item;
      });
      setEmailLogs(newData);
    };

	const  addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	const CellEmailCol = (prop) => {
    return (
        <CellEmail {...prop} acquireToken={props.acquireToken} />
    );
 }

 const [isActive, setActive] = useState("false");
 const ToggleClass = () => {
   setActive(!isActive); 
  };

	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between position-relative">
								<div className="f-size-18">Email Logs</div>
										
								<Button styles={{padding:'0 12px'}} icon={<FilterIcon />} content={"Filters"} title="Filters" onClick={ToggleClass} className="filetr-icon" circular />
										
								<Flex gap="gap.small" className={isActive ? "email-log-filter" : "active email-log-filter"}>
									<RadioGroup
										checkedValue={emailDaysCheckedValue}
										items={emailDaysItems}
										onCheckedValueChange={emailDaysChange}
										// value={emailDaysSelected}
									/>
									<Text content="Start Date" className="date-filter-text"  size="medium" weight="regular"
									 disabled={emailDaysCheckedValue == "-1" ? false:true} />
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedStartDate} disabled={emailDaysCheckedValue == "-1" ? false:true} 
										maxDate={selectedEndDate}
										onDateChange={ (e, data) => {
												setSelectedStartDate(data.value);
											}} />
									<Text content="End Date"  className="date-filter-text" size="medium" weight="regular"
										disabled={emailDaysCheckedValue == "-1" ? false:true}/>
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedEndDate}  disabled={emailDaysCheckedValue == "-1" ? false:true} 
										minDate={selectedStartDate}
										onDateChange={(e, data) => {
												setSelectedEndDate(data.value);
											}}/>

									<Flex gap="gap.small" className="mt-2">
										<Button content="Apply" 
											onClick = {applyDateFilter}/>
										<Button content="Cancel" className="aws-applyFilter" 
											onClick = {() => setActive("false")}/>
									</Flex>
									
								</Flex>
							</div>
							
						</CardHeader>
						<CardBody>
							<div className="tbl-search float-right text-right mb-2">
								<Input
									onChange={(e, { name, value }) => {
										filterGridData(value);
									}}
									inverted
									icon={<SearchIcon />}
									placeholder="Search..."
									className="border-gray"
								/>
							</div>
							<div className='grid-with-search'>
								<Grid
									style={{
										height: "100%",
									}}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
									data={process(EmailLogs, dataState)}
									{...dataState}
									onDataStateChange={(e) => {
										setDataState(e.dataState);
									}}
									 detail={context.mediaCategory.sm ? DetailComponentSm : null}
									 expandField="expanded"
									 onExpandChange={expandChange}
								>
									<Column field="Subject"  title="Email Subject"  cell={CellEmailLogs} width={150}/>
									<Column field="AWSmessageId"  title="Email Body" cell={CellEmailCol} width={100}/>
									<Column field="EmailTo" title="To Address"   cell={CellEmailLogs}  width={220}/>
									{/* <Column field="EmailCC"  title="CC Address"  cell={CellEmailLogs}  width={200}/>
									<Column field="EmailBCC"  title="BCC Address"  cell={CellEmailLogs} width={200}/> */}
									<Column field="InstanceName"  title="Instance" width={150}/>
									<Column field="AlertDetails"  title="Alert" cell={CellEmailLogs} width={200}/>
									<Column field="EmailSentDate" title="Email Sent Date"  width={200} />
									<Column field="Status" title="Status" width={100}/>
								</Grid>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { SettingsEmailLogs };
