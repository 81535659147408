import React, { useState } from 'react'
import { Flex, Box, Loader } from '@fluentui/react-northstar'

const Home = (props) => {
    return (
    <>
      <Box className="settings pt-3 pb-1">
        <Flex fill className="error" hAlign="center" vAlign="center">
            <div className="notfound">
              <div className="notfound-section welcome">
                <h1>Welcome!</h1>
                <h2>to Tollring Hub</h2>
              </div>
            </div>
          </Flex>
      </Box>
    </>
  )
};

export { Home }