import React from 'react';
import { NavMenu } from './NavMenu';
import { Flex } from '@fluentui/react-northstar';

export const Layout = (props) => {
  return (
    <Flex column gap="gap.medium" className="w-100">
      <Flex.Item size="size.quarter" align="stretch">
        {
          !props.inTeams ? 
          <NavMenu {...props} />
          : <></>
        }
      </Flex.Item>
      <Flex.Item className='w-100' align="stretch" >
      <div className='container'>
        {props.children}
      </div>
      </Flex.Item>
    </Flex>
  );
}
