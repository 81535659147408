import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { BusinessPriorities } from './businesspriority';
import './tools.scss';

export const Tools = (props) => {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate(path);
    }
    React.useEffect(() => {
        routeChange('/tools/businesspriorities');
    },[])
    return(
        <Outlet />
    )
}