import React, {useState} from 'react';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, Text, Flex, Dialog } from '@fluentui/react-northstar';
import { useSearchParams, useParams } from 'react-router-dom';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellIncidentHistory, CellIncidentLevel, CellInstanceOverview, CellAccessSPPortal, CellCountGroup, CellTenantStatus, CellDateTime, CellComments, CellIncidentStatus, CellIncidentOwner, CellInstanceOverviewLabel , CellColTitle, rowRender } from '../../../../shared/appcomponents/custom-grid-cells';
import { INCIDENTLEVELS ,OPERATIONSTATUS} from '../../../../shared/appcomponents/enums';
import MonitoringService from '../../../../services/monitoringservice';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from '@progress/kendo-data-query';
import { CloseIcon } from "@fluentui/react-icons-northstar";
import { WindowContext } from "../../../../shared/contexts/Context";

const PortalAlarm = (props) => {
  const { id } = useParams();
  const [incidents, setIncidents] = useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [spDialogDataItem, setSPDialogDataItem] = React.useState(null);
  const [spDialogOpen, setSPDialogOpen] = React.useState(false);
  const [isUpdating, setUpdating] = React.useState(false);
  const [IncidentStatuses, setIncidentStatuses] = useState(null);
  const [AppUsers, setAppUsers] = useState(null);
  const [searchParams] = useSearchParams();
  // React.useEffect(() => {
  //   setIncidents(props.incidents?.Table);
  //   setLoaded(true); 
  // }, [props.incidents]);    
  React.useEffect(() => {
    props.acquireToken((token) => {
        setLoaded(false);
        let _alarmType = searchParams.get('type');
        let _state = INCIDENTLEVELS[_alarmType];
        if(_state == null){
            _state = -1;
        }

        if(_alarmType == "down"){
          _state = 0;
        }

        let _params = {
            instanceId: id ? id : -1,
            incidentLevelId : _state,
            appliedFilters:  id
              ? [`InstanceId IN (${id})`]
              : [],
        }
        if (token) {
          MonitoringService.GetDataIncidents(token, _params).then((response) => {
            if(response && response.status==200){
              response=response.data;
      response.Table?.map(m => m.IsUpdating = false);
              setIncidents(response.Table);
              setIncidentStatuses(response.IncidentStatuses);
              setAppUsers(response.AppUsers);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
              }
              else {
                props.viewStatus.InformChildPageStatus(500);
              }

            setLoaded(true);
          });
        }
    });
  }, [id, props.incidents]);

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "IncidentTimestampUTC",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const CellAccessServiceProvider = (prop) => {
  return <CellAccessSPPortal onClick={() => {props.acquireToken((token) => {
      setSPDialogOpen(true); 
      getSPUrl(token, prop.dataItem);
  })}} text= {prop.text}/>
  }

   const getSPUrl = (token, dataItem) => {
      let _host = dataItem.InstancePortalURL.split("/")[2];
      let _url = "http://" + _host + "/serviceprovider/token.aspx";
      if (_host.toString().lastIndexOf('call-analytics.co.uk') > 0)
          _url = "https://" + _host + "/serviceprovider/token.aspx";

      MonitoringService.GetServiceProviderToken(token, {host: _host}).then((response)=>{
        if(response && response.status==200){
          response=response.data;
              let t = JSON.stringify(response);
              t = t.replace('"', '');
              t = t.replace('"', '');
              if (response.length > 5 && response.length < 50){
                  dataItem.SPPortalLoginUrl = _url + "?token=" + t;
              }
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if(response && response.status==401){
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
              }
              else {
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
              }

          setSPDialogDataItem(dataItem); 
      });        
  }

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);    
    setIncidents(null);    
    setIncidentStatuses(null);    
    setAppUsers(null);    
    props.acquireToken((token) => {
        let _params = {
            instanceId: id ? id : -1,
            incidentLevelId : INCIDENTLEVELS[props.alarmType],
            appliedFilters: appliedFilters
        }
        if (token) {
          MonitoringService.GetDataIncidents(token, _params).then((response) => {
            if(response && response.status==200){
              response=response.data;
              response.Table?.map(m => m.IsUpdating = false);
              setIncidents(response.Table);
              setIncidentStatuses(response.IncidentStatuses);
              setAppUsers(response.AppUsers);
            }
            else if(response && response.status==401){
              props.viewStatus.InformChildPageStatus(response.status);
              }
              else {
                props.viewStatus.InformChildPageStatus(500);
              }

            setLoaded(true);
          });
        }
    })
}

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'IncidentId', operator: 'contains', value: value },
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'Platform', operator: 'contains', value: value },
        { field: 'IncidentOwner', operator: 'contains', value: value }
      ],
    };
    setDataState({ ...dataState, filter: filter });
};

const CellIncidentStatusCol = (prop) => {
  return <CellIncidentStatus {...prop}   user={props.user} incidentStatus={IncidentStatuses}
    onChange={() => {props.acquireToken((token) => {
        setUpdating(true); 
        prop.dataItem.IncidentOwner = props.account.username;
        UpdateIncedent(token, prop.dataItem);
      })}}
  />
}

const CellIncidentOwnerCol = (prop) => {
  return <CellIncidentOwner {...prop}  user={props.user} appUsers={AppUsers}
    onChange={() => {props.acquireToken((token) => {
        setUpdating(true); 
        UpdateIncedent(token, prop.dataItem);
      })}}
  />
}

const UpdateIncedent = (token, dataItem) => {
  let _params = {
    IncidentSection: 2,
    IncidentId: dataItem.IncidentId,
    IncidentOwner: dataItem.IncidentOwner,
    IncidentStatusId: dataItem.IncidentStatusId
  }

  // props.updateData();


  MonitoringService.UpdateIncident(token, _params).then((response)=>{
    if(response && response.status==200){
      response=response.data;
      setUpdating(false); 
      if(dataItem.IncidentStatusId===3){
        let array = [...incidents];
          let index= array.findIndex(element => element.IncidentId === dataItem.IncidentId)
          if (index !== -1) {
              array.splice(index, 1);
              setIncidents(array);
            }
      }
     // props.routeChange('/dashboards/monitoring/portal/alarms?type=critical', 'critical', 1);
      // RefeshData(null);
      props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
    }
    else if(response && response.status==401){
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
      }
      else {
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
      }

    dataItem.IsUpdating=false;    
      if(dataItem.IncidentStatusId!==3){
      let arr = [...incidents];
      setIncidents(arr);  }
  });  
}

const RefeshData = (appliedFilters) => {
  setLoaded(false);    
  setIncidents(null);    
  setIncidentStatuses(null);    
  setAppUsers(null);    
  props.acquireToken((token) => {
      let _params = {
          instanceId: -1,
          incidentLevelId : INCIDENTLEVELS[props.alarmType],
          appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetDataIncidents(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            response.Table?.map(m => m.IsUpdating = false);
            setIncidents(response.Table);
            setIncidentStatuses(response.IncidentStatuses);
            setAppUsers(response.AppUsers);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
            }
            else {
              props.viewStatus.InformChildPageStatus(500);
            }

          setLoaded(true);
        });
      }
  })
}
const CellIncidentHistoryCol = (prop) => {
   return (
    //  <td  style={{ display:"flex", justifyContent:"center", borderBottomWidth:"0px !important" } }>
    //    {prop.text && <span>{prop.text}&nbsp;</span>}
       <CellIncidentHistory {...prop} IncidentSection={2} acquireToken={props.acquireToken} />
    //  </td>
   );
}

const CellCommentsCol = (prop) => {
   return (
   
       <CellComments  user={props.user} {...prop} IncidentSection={2} acquireToken={props.acquireToken} />
     
   );
}

const expandChange = (event) => {
  let newData = incidents.map((item) => {
    if (item.IncidentId === event.dataItem.IncidentId) {
      item.expanded = !event.dataItem.expanded;
    }
    return item;
  });
  setIncidents(newData);
};

const DetailComponentSm = (props1) => {
  const dataItem = props1.dataItem;
  return (
    <table>
      <tbody>
        <tr>
          <td>&nbsp;&nbsp;IncidentId: {dataItem.IncidentId}</td>
          <td>
            <CellInstanceOverviewLabel
              {...props1}
              field={"InstanceName"}
              text={"Instance:"}
            />
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;Instance Time: {dataItem.IncidentId}</td>
          <td>
            <CellInstanceOverviewLabel
              {...props1}
              field={"InstanceTime"}
              text={"InstanceTimeUTC:"}
              cell={CellDateTime}
            />
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;Platform: {dataItem.Platform}</td>
          <td>&nbsp;&nbsp;Data Rule Breached: {dataItem.DataRuleBreached}</td>
        </tr>
        <tr>
          <td>
            <CellTenantStatus
              {...props1}
              field={"TenantStatus"}
              text={"Tenant Status:"}
            />
          </td>
          <td>
            <CellAccessServiceProvider
              {...props1}
              field={"InstancePortalURL"}
              text={"Access SP Portal:"}
            />
          </td>
        </tr>
        <tr>
          <td>
            <CellInstanceOverview
              {...props1}
              field={"InstanceOverview"}
              text={"Instance Overview:"}
            />
          </td>
          <td>
            <CellCommentsCol
              {...props1}
              field={"IncidentId"}
              text={"Comments:"}
            />
          </td>
        </tr>
        <tr>
          <td>
            <CellIncidentHistoryCol
              {...props1}
              field={"IncidentId"}
              text={"History:"}
            />
          </td>
          <td>
            <CellIncidentOwnerCol
              {...props1}
              field={"IncidentOwner"}
              text={"Owner:"}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const DetailComponentLg = (props1) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <CellInstanceOverview
              {...props1}
              field={"InstanceOverview"}
              text={"Instance Overview:"}
            />
          </td>
          <td>
            <CellCommentsCol
              {...props1}
              field={"IncidentId"}
              text={"Comments:"}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

  return <WindowContext.Consumer>
      {(context) => (
    <>
 <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
          <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
           defaultAppliedFilter={
             {filter: 'Instance', operator: '==', value: id}
          }
          //defaultAppliedFilter={{filter: 'Instance', operator: '==', value: id}}
         elegibleFilters={['Instance', 'Period', 'Platform', 'Owner', 'IncidentStatus']} />
        <Flex.Item push>
          <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
          <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
          </div>
        </Flex.Item>
      </Flex>
      <div className='w-100 no-bg'>
        {isLoaded && incidents ? (
          <KendoGrid
            style={{
              height: "456px",
            }}
            // pageable={true}
            pageable={{
              buttonCount: 3
            }}
            sortable={true}
            data={process(incidents, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            // detail={
            //   context.mediaCategory.sm ? DetailComponentSm : DetailComponentLg
            // }
            expandField="expanded"
            onExpandChange={expandChange}
            rowRender={rowRender}
          >
            
            <Column field="IncidentId" title="Ref#"   locked={true} width={100}/>
            <Column field="IncidentTimestampUTC" title={<CellColTitle title="Incident Timestamp" />}  locked={true}  width={180} cell={CellDateTime}/> 
           
            <Column field="InstanceTime" title="Instance Time"  locked={true} width={140} cell={CellDateTime}/>
            <Column field="InstanceName" title="Instance"  width={150} cell={CellInstanceOverviewLabel}/>
            
            <Column field="Platform" title="Platform"   width={150}/>
           
            <Column field="DataRuleBreached" title="Rule Breached"  width={200} />
           
            <Column field="TenantStatus" title="Tenant Status" width={130}  cell={CellTenantStatus} filterable={false} sortable={false} />
           
            <Column field="InstancePortalURL" title={<CellColTitle title="SP Portal" />} cell={CellAccessServiceProvider} width={85} filterable={false} sortable={false} />
            {/* {!context.mediaCategory.sm && (
            <Column field="InstanceOverview" title="Instance Overview" cell={CellInstanceOverview} width={160} />)}
            {!context.mediaCategory.sm && (
            <Column field="IncidentId" title="Comments" cell={CellCommentsCol} filterable={false} sortable={false}  />)} */}
           
            <Column field="IncidentId" title="History" cell={CellIncidentHistoryCol} width={70} filterable={false} sortable={false} />
            <Column field="IncidentStatusId" title="Status" cell={CellIncidentStatusCol}  width={130}/>
           
            <Column field="IncidentOwner"   title="Owner"  width={200} cell={CellIncidentOwnerCol}  />

            <Column field="InstanceOverview" title={<CellColTitle title="Instance Overview" />} width={160} cell={CellInstanceOverview} filterable={false} sortable={false} />
            <Column field="IncidentId" title={<CellColTitle title="Comments" />} width={110} cell={CellCommentsCol} filterable={false} sortable={false}  />

          </KendoGrid>
        ) : (
          <Loader />
        )}
      </div>
      <Dialog
        open={spDialogOpen}
        onCancel={() => {
          setSPDialogOpen(false);
          setSPDialogDataItem(null);
              }}
        onClose={() => { setSPDialogOpen(false); setSPDialogDataItem(null); }}
        onConfirm={() => {
          window.open(spDialogDataItem?.SPPortalLoginUrl, "_blank");
          setSPDialogDataItem(null);
        }}
        confirmButton={
          spDialogDataItem && spDialogDataItem.SPPortalLoginUrl
            ? "Visit Page"
            : null
        }
        cancelButton="Close"
        content={
          <Text
            content={
              spDialogDataItem
                ? spDialogDataItem.SPPortalLoginUrl
                  ? `Please click on Visit button to access ${spDialogDataItem?.InstanceName}`
                  : `Could not retrieve token for ${spDialogDataItem?.InstanceName}`
                : "Retrieveing token..."
            }
          />
        }
        header="Service Provider Access"
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setSPDialogOpen(false);
            setSPDialogDataItem(null);
          },
        }}
      />
    </>
  )}
  </WindowContext.Consumer>
};


export { PortalAlarm }