import * as React from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { PanelBar, PanelBarItem, Stepper } from '@progress/kendo-react-layout';
import { Divider, AddIcon, Flex, Button as FluentButton, RadioGroup, Input, Text, TextArea, TrashCanIcon, Card, CardBody, ChevronEndMediumIcon, Grid, ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon, ArrowRightIcon, Segment, Header } from '@fluentui/react-northstar'
import { ProgressBar } from '@progress/kendo-react-progressbars';
import Button from '@progress/kendo-react-buttons/dist/npm/Button';
import ToolsService from '../../services/toolsservice';
import { formatDate } from '@progress/kendo-intl';

export const sortHelper = (a, b) => {
  return b.PriorityCount - a.PriorityCount;
}

export const getPriorityLevel = (totalItems, priorityCount) => {
  if(priorityCount >= Math.round(totalItems * 0.7)){
    return 'HIGH';
  }
  else if(priorityCount >= Math.round(totalItems * 0.3)) {
    return 'MEDIUM';
  }
  else {
    return 'LOW';
  }
}

export const shuffleArray = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const combinations = (n, k) => {
  let result= [];
  let combos = [];
  const recurse = start => {
    if (combos.length + (n - start + 1) < k) { return }
    recurse(start + 1);
    combos.push(start);
    if(combos.length === k) {     
       result.push(combos.slice());
    }else if(combos.length + (n - start + 2) >= k){
       recurse(start + 1);
    }
    combos.pop();     
  }
  recurse(1, combos);
  return shuffleArray(result);
}

export const BusinessPriorityForm = (props) => {
  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = React.useState({});
  const [steps, setSteps] = React.useState([{
    label: 'Enter Priorities',
    icon: 'k-i-search',
    isValid: undefined
  }, {
    label: 'Compare Priority',
    icon: 'k-i-arrows-swap',
    isValid: undefined
  }, {
    label: 'Way to Go!',
    icon: 'k-i-sort-desc',
    isValid: undefined
  }]);
  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid = steps.slice(0, step).findIndex(currentStep => currentStep.isValid === false) === -1;
  const onPrevClick = React.useCallback(event => {
    event.preventDefault();
    setStep(() => Math.max(step - 1, 0));
  }, [step, setStep]);

  const [totalPriorities, setTotalPriorities] = React.useState(2);
  const [businessPriority, setBusinessPriority] = React.useState({
    "BusinessPriorityId": 0,
    "BusinessDesc": '',
    "CreatorUserEmail": '',
    "DateAddedHub": null,
    "DateUpdatedHub": null,
    "Priorities": [{
      "PriorityId": 1,
      "PriorityDesc": '',
      "PriorityCount": 0,
      "PriorityLevel": '',
      "BusinessPriorityId": 0,
      "DateAddedHub": null,
      "DateUpdatedHub": null
    }, {
      "PriorityId": 2,
      "PriorityDesc": '',
      "PriorityCount": 0,
      "PriorityLevel": '',
      "BusinessPriorityId": 0,
      "DateAddedHub": null,
      "DateUpdatedHub": null
    }]
  });
  const [compareCombinations, setCompareCombinations] = React.useState(null);
  const [comparedIndex, setComparedIndex] = React.useState(0);
  const [compareItems, setCompareItems] = React.useState([]);
  const [selectedPriorityIndex, setSelectedPriorityIndex] = React.useState(-1);

  const onStepSubmit = React.useCallback(event => {
    const {
      isValid,
      values
    } = event;
    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));
    setSteps(currentSteps);

    if(step == 0){
      //Get Combinations of two
      let combos = combinations(businessPriority.Priorities.length, 2);
      setCompareCombinations(combos);
      getCompareItems(combos);
    }
    if(step == 1){
      if(step === 1 && compareCombinations.length == comparedIndex){
        updatePriorityCount();
      }
      businessPriority.Priorities.sort(sortHelper);
      let totalItems = businessPriority.Priorities.length;
      let newPriorityArray = businessPriority.Priorities.map((p) => (
        {...p, PriorityLevel : getPriorityLevel(totalItems, p.PriorityCount)}
      ));
      setBusinessPriority({...businessPriority, Priorities : newPriorityArray});
    }
    setStep(() => Math.min(step + 1, lastStepIndex));
    setFormState(values);

    if (isLastStep && isPreviousStepsValid && isValid) {
      addBusinessPriority(businessPriority);
    }
  }, [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex, businessPriority, comparedIndex, selectedPriorityIndex]);

  const addBusinessPriority = (_businessPriority) => {
    props.acquireToken((token) => {
        ToolsService.AddBusinessPriority(token, _businessPriority).then((response) => {
            if(response){
                //debugger
            }
            else{

            }
            props.handlePrioritySave(response);
        })
    })
  }

  const updatePriority = (priorityId, value) => {
    const newPriorityArray = businessPriority.Priorities.map((item, i) => {
      if (item.PriorityId == priorityId) {
        return { ...item, PriorityDesc: value };
      } else {
        return item;
      }
    });
    setBusinessPriority({...businessPriority, Priorities : newPriorityArray});
  };
  const removePriority = (priorityId) => {
    const newPriorityArray = businessPriority.Priorities.filter((item) => item.PriorityId != priorityId);
    setBusinessPriority({...businessPriority, Priorities : newPriorityArray});
  };

  const getCompareItems = (_compareCombinations) => {
    let _comparedIndex = comparedIndex;
    setCompareItems([
      {
        name: "rdCompare",
        key: businessPriority.Priorities[_compareCombinations[_comparedIndex][0] - 1]?.PriorityId,
        label: businessPriority.Priorities[_compareCombinations[_comparedIndex][0] - 1]?.PriorityDesc,
        value: 0,
      },
      {
        name: "rdCompare",
        key: businessPriority.Priorities[_compareCombinations[_comparedIndex][1] - 1]?.PriorityId,
        label: businessPriority.Priorities[_compareCombinations[_comparedIndex][1] - 1]?.PriorityDesc,
        value: 1,
      }
    ]); 
    setComparedIndex(_comparedIndex + 1);
    setSelectedPriorityIndex(-1);   
  }

  const updatePriorityCount = () => {   
    let _comparedIndex = comparedIndex - 1; 
    businessPriority.Priorities[compareCombinations[_comparedIndex][selectedPriorityIndex] - 1].PriorityCount += 1;
  }

  const PrioritiesInputs = <>
  <div className="p-3 m-3 mb-0">
  <Input label="Business Description" className="business-desc" value={businessPriority.BusinessDesc} onChange={(e, {value}) => {setBusinessPriority({...businessPriority, BusinessDesc: value})}} fluid required inverted placeholder='Please enter business description...' />
  <Divider className='mb-3 mt-3' content="Priorities" />
    {
      businessPriority?.Priorities?.map((p, i) => {
        return (
        <Flex className='my-2' gap="gap.large" hAlign="center" vAlign="center">
          <Text content={i + 1} size="larger" />
          <TextArea className="priority-input" value={p.PriorityDesc} onChange={(e, {value}) => {updatePriority(p.PriorityId, value)}} clearable fluid required showSuccessIndicator inverted placeholder='Please enter a priority...' />
          <FluentButton onClick={(e) => {e.preventDefault(); removePriority(p.PriorityId);}} text icon={<TrashCanIcon size="larger" />} iconOnly title="Delete" />
        </Flex>
      )})
    }
    
    <Flex style={{height:'60px'}} className='my-2' gap="gap.large" hAlign="end" vAlign="center">
      <FluentButton style={{minWidth:'36px'}} className="p-0" onClick={(e) => {
        e.preventDefault(); 
        setBusinessPriority({...businessPriority, Priorities: [...businessPriority.Priorities, {
          "PriorityId": totalPriorities + 1,
          "PriorityDesc": '',
          "PriorityCount": 0,
          "PriorityLevel": '',
          "BusinessPriorityId": 0,
          "DateAddedHub": null,
          "DateUpdatedHub": null
        }]});
        setTotalPriorities(totalPriorities + 1);
        }} text icon={<AddIcon size="larger" />} circular title="Add Priority" />
    </Flex>
  </div>
</>;

const SelectOne = <>
<div className="p-3 m-3 mb-0">
  <Flex hAlign='center' className="mb-3"><Text size='large' content="Which of these has the highest priority. Once you’ve clicked next you can’t go back so think about it carefully…" /></Flex> 
  {
    compareCombinations ? <>
    <RadioGroup className='rdCompare' name="rdCompare" checkedValue={selectedPriorityIndex} onCheckedValueChange={(e, props) => {
      setSelectedPriorityIndex(props.value);
    }}
    items={compareItems}/>
    <Flex column className='mt-5' gap="gap.large" hAlign="center" vAlign="center">
      <ProgressBar value={comparedIndex - 1} max={compareCombinations?.length} className="comp-progress" />
      <Text size='large' content={`Remaining Comparisons ${compareCombinations?.length - comparedIndex + 1} of ${compareCombinations?.length}`} />
    </Flex>
    </>
  :<></>
  }
  
</div>
</>;

const PriorityOrder = <>
<Flex column className='mt-4 pl-5 pr-5' gap="gap.large" hAlign="center" vAlign="center">
    <Flex hAlign='center' className="mb-3"><Text size='large' content="Based on your answers, your priorities rank in the following sequence" /></Flex> 
  <PanelBar className='prioritylevels' expandMode="multiple" style={{ width: "100%" }}>
    <PanelBarItem className='high' title={<><ArrowUpIcon bordered circular /><Text className="ml-2" size="large" content="High"/></>} expanded={true}>
      <Grid columns={1}>
        {
          businessPriority.Priorities.filter((f) => f.PriorityLevel === 'HIGH').map((p) => 
          <Card elevated fluid>
            <CardBody className='m-0'>
              <Flex space='between' fluid>
                <Text content={p.PriorityDesc} />
                <Text content={p.PriorityCount} />
              </Flex>
            </CardBody>
          </Card>
          )
        }
      </Grid>
    </PanelBarItem>
    <PanelBarItem className='medium' title={<><ArrowRightIcon bordered circular /><Text className="ml-2" size="large" content="Medium"/></>}>
      <Grid columns={1}>
      {
          businessPriority.Priorities.filter((f) => f.PriorityLevel === 'MEDIUM').map((p) => 
          <Card elevated fluid>
            <CardBody className='m-0'>
              <Flex space='between' fluid>
                <Text content={p.PriorityDesc} />
                <Text content={p.PriorityCount} />
              </Flex>
            </CardBody>
          </Card>
          )
        }
      </Grid>
    </PanelBarItem>
    <PanelBarItem className='low' title={<><ArrowDownIcon bordered circular /><Text className="ml-2" size="large" content="Low"/></>}>
      <Grid columns={1}>
      {
          businessPriority.Priorities.filter((f) => f.PriorityLevel === 'LOW').map((p) => 
          <Card elevated fluid>
            <CardBody className='m-0'>
              <Flex space='between' fluid>
                <Text content={p.PriorityDesc} />
                <Text content={p.PriorityCount} />
              </Flex>
            </CardBody>
          </Card>
          )
        }
      </Grid>
    </PanelBarItem>
  </PanelBar>
</Flex>
</>;

const stepPages = [PrioritiesInputs, SelectOne, PriorityOrder];

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '45px',
    paddingBottom: '45px',
    background:'rgba(221, 221, 221, 0.533)'
  }}>
    <Stepper value={step} items={steps} />
    <Form initialValues={formState} onSubmitClick={onStepSubmit} render={formRenderProps => <div style={{
      alignSelf: 'center',
      width: "100%"
    }}>
      <FormElement style={{
        width: "100%",
        minWidth: 480
      }}>
        {stepPages[step]}
        <span style={{
          marginTop: '40px'
        }} className={'k-form-separator'} />
        <div style={{
          justifyContent: 'center',
          alignContent: 'center'
        }} className={'k-form-buttons k-buttons-end'}>
          <div>
            {step === 1 && compareCombinations.length > comparedIndex ? <FluentButton
            size="medium"
            iconPosition="after"
            icon={<ChevronEndMediumIcon />}
            disabled={selectedPriorityIndex === -1} onClick={(e) => { e.preventDefault(); updatePriorityCount(); getCompareItems(compareCombinations); }}
              content="Next"/> : undefined}
            {
              (step === 1 && compareCombinations.length == comparedIndex) || step !== 1
              ?
              <FluentButton
              size="medium"
              iconPosition="after"
              icon={<ChevronEndMediumIcon />}
              primary={true} 
              disabled={businessPriority.Priorities.some(s => s.PriorityDesc == "" || s.PriorityDesc == null) || (isLastStep ? !isPreviousStepsValid : (step === 1 && compareCombinations.length == comparedIndex + 1 && selectedPriorityIndex === -1))} 
              onClick={formRenderProps.onSubmit}
                content={isLastStep ? 'Submit' : 'Next'}/>
              : <></>
            }            
          </div>
        </div>
      </FormElement>
    </div>} />
  </div>;
};

export const BusinessPriorities = (props) => {
    const [isShown, setIsShown] = React.useState(false);
    const [isHome, setIsHome] = React.useState(true);
    const [businessPriorities, setBusinessPriorities] = React.useState([]);

    React.useEffect(() => {
      props.acquireToken((token) => {
        ToolsService.GetBusinessPriorities(token).then((response) => {
          if(response){
            setBusinessPriorities(response);
          }
        })
      });
    }, []);

    const handleClick = event => {
        setIsShown(current => !current);
        setIsHome(current => !current);
    };

    const handlePrioritySave = (addedBusinessPriority) => {
      if(addedBusinessPriority){
        setBusinessPriorities([addedBusinessPriority, ...businessPriorities]);
      }
      setIsShown(current => !current);
      setIsHome(current => !current);
    };

    const deleteBusinessPriority = (_businessPriorityId) => {
      props.acquireToken((token) => {
          ToolsService.DeleteBusinessPriority(token, {BusinessPriorityId: _businessPriorityId}).then((response) => {
              if(response){
                  if(response > 0){
                    setBusinessPriorities(businessPriorities.filter((f) => f.BusinessPriorityId != _businessPriorityId));
                  }
              }
          })
      })
    }

    return <div className='priorities pt-1'>
        <Segment style={{minHeight:'calc(100vh - 60px)'}}>
            <Flex column fluid vAlign='center'>
                <Header content="Business Priorities" />
                <Flex className="p-2" column hAlign='end'>  
                    {!isShown && isHome && (
                        <Button onClick={handleClick} className="buttons-container-button test btn-npx" icon="sort-asc btn-icon">
                            New Business Priority
                        </Button>
                    )}
                    {isShown && !isHome && (<>
                        <Button onClick={handleClick} className="buttons-container-button" icon="aggregate-fields">
                            My Business Priorities
                        </Button>
                    </>)}
                </Flex>
                <Flex column className="p-2" fluid>
                    {isShown && !isHome && (<>
                    <BusinessPriorityForm {...props} handlePrioritySave={handlePrioritySave} />
                    </>)}
                    {
                      !isShown && isHome ?
                      <>
                      <Header as='h3' content="My Business Priorities" />
                      <PanelBar className='prioritylevels' expandMode="multiple" style={{ width: "100%" }}>
                      {
                        businessPriorities.map((m) => 
                        <PanelBarItem className='businesspriorities' title={<>
                          <Flex fluid vAlign='center'>
                            <Text className="ml-2" size="large" content={m.BusinessDesc}/>
                            <Text className="ml-2" size="medium" content={`(${formatDate(new Date(m.DateAddedHub), "dd-MMM-yyyy HH:mm:ss")})`}/>
                            <Flex.Item push>
                              <FluentButton onClick={() => deleteBusinessPriority(m.BusinessPriorityId) } icon={<TrashCanIcon />} iconOnly />
                            </Flex.Item>
                          </Flex>
                          </>} expanded={true}>
                          <Flex column style={{width: '100%', alignItems:'center', padding:'15px 0'}}>
                            {
                              m.Priorities.map((p) => 
                              <Card elevated className={p.PriorityLevel} style={{paddingLeft: '25px', width: '100%', justifyContent:'center'}}>
                                <CardBody className='m-0'>
                                  <Flex space='between' fluid>
                                    <Text style={{width: '120px'}} content={p.PriorityLevel} />
                                    <Text content={p.PriorityDesc} />
                                    <Flex.Item push>
                                      <Text content={p.PriorityCount} />
                                    </Flex.Item>
                                  </Flex>
                                </CardBody>
                              </Card>
                              )
                            }
                          </Flex>
                        </PanelBarItem>
                        )
                      }
                    </PanelBar>
                      </>
                      : <></>
                    }
                    
                </Flex>
            </Flex>
        </Segment>
    </div>
};
