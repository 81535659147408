import React, { useState, useEffect } from 'react';

function EditableScriptTextarea({ scriptText, onSave, index }) {
  const parts = scriptText.split(/(@[^ ]+)/g);
  const [editableText, setEditableText] = useState(parts.map(part => part.startsWith('@') ? part.substring(1) : ''));

  const handleEditChange = (e, i) => {
    const newText = [...editableText];
    newText[i] = e.target.value;
    setEditableText(newText);

    const updatedText = parts.map((part, j) => (part.startsWith('@') ? `@${newText[j]}` : part)).join('');
    onSave(updatedText, index);
  };

  useEffect(() => {
    setEditableText(parts.map(part => part.startsWith('@') ? part.substring(1) : ''));
  }, [scriptText]);

  useEffect(() => {
    const hasInputBoxes = parts.some(part => part.startsWith('@'));
    if (!hasInputBoxes) {
      onSave(scriptText, index);
    }
  }, [scriptText, index, onSave]);

  return (
    <div>
      {parts.map((part, i) => (
        part.startsWith('@') ? (
          <input
            key={i}
            type="text"
            value={editableText[i]}
            onChange={(e) => handleEditChange(e, i)}
            style={{ backgroundColor: 'yellow' }}
          />
        ) : (
          <span key={i}>{part}</span>
        )
      ))}
    </div>
  );
}

export default EditableScriptTextarea;
