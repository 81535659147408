import React, { useState , useEffect} from 'react'
import { Grid as FluentGrid, Text, Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Checkbox, Alert, Dialog as FluentDialog } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, CloseIcon } from '@fluentui/react-icons-northstar'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import { CellConfigurationState, CellDropDownListUser , CellUserPermissions} from "../../shared/appcomponents/custom-grid-cells";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { BrowserRouter , useNavigate} from 'react-router-dom';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsUsers = (props) => {
  const [users, setUsers] = React.useState(null);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [userData, SetUserData] = useState(null);
  const [visible, setVisible] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteVersionObj, setDeleteVersionObj] = useState(null);
  const [updateUserPermissionLoading, setupdateUserPermissionLoading] = useState(false);
  const [permissionGroups, setPermissionGroups] = React.useState(null);
  const [serviceProviders, setServiceProviders] = React.useState(null);
  const [restrictedAccess, setRestrictedAccess] = React.useState(false);
  // const [permissionData, setPermissionData] = React.useState(null);
  let navigate = useNavigate(); 

  useEffect(() => checkIsValid(), [userData]);

  const editSubmitClickHandler = () => {
    props.acquireToken(authSuccessEditUserCallback);
    setVisible(false);
    setIsValid(false);
  };

  const updateAppPermissions = () => {
    props.updateUser();
    // navigate("/");
  }

  const expandChange = (event) => {
    let newData = users.map((item) => {
      if (item.AppUserId === event.dataItem.AppUserId) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setUsers(newData);
  };

  const authSuccessEditUserCallback = (token) => {
    if (token) {
      SettingsService.UpdateUser(token, userData).then((response) => {
        if(response && response.status==200){
          response=response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          if(props.user.AppUserId == userData.AppUserId){
            //user updating own permissions
            setTimeout(updateAppPermissions, 2000);
          }

        } 
        else if(response && response.status==401){
          debugger;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    }
  };

  const authSuccessUpdateUserPermissionsCallback = (token, userData) => {
    if (token) {
      setupdateUserPermissionLoading(true);
      SettingsService.UpdateUser(token, userData).then((response) => {
        if(response && response.status==200){
          response=response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if(response && response.status==401){
          debugger;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
      setupdateUserPermissionLoading(false);
    }
  };

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "DisplayName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "DisplayName", operator: "contains", value: value },
      { field: "AppUserEmail", operator: "contains", value: value },
      { field: "PrimaryContact", operator: "contains", value: value },
      { field: "SecondaryContact", operator: "contains", value: value },
      { field: "GroupName", operator: "contains", value: value }],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const authSuccessRemoveUserCallback = (token, AppUserId) => {
    if (token) {
      let _params = {
        AppUserId: AppUserId,
      };
      SettingsService.RemoveUser(token, _params).then((response) => {
        if(response && response.status==200){
          response=response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }  else if(response && response.status==401){
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handleRoleChange = (alwaysNull, data) => {
    SetUserData({ ...userData, Role: data.value });
  };

  const handleIsAdminChange = (checked) => {
    SetUserData({ ...userData, IsAdmin: checked });
  };

  const handleIsAnalyticsUserChange = (checked) => {
    SetUserData({ ...userData, IsAnalyticsUser: checked });
  };

  const handleIsExternalUserChange = (checked) => {
    SetUserData({ ...userData, IsExternal: checked });
  };

  const handleIsAccessBlockedChange = (checked) => {
    SetUserData({ ...userData, IsAccessBlocked: checked });
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetUserData(token).then((response) => {
        if(response && response.status==200){
          response=response.data;
          setUsers(response.Users);
          setPermissionGroups(response?.PermissionGroups?.map((m) => ({header: m.Value, value: m.Key})));
          setServiceProviders(response?.ServiceProviders?.map((m) => ({header: m.Value, value: m.Key})));
        }
        else if(response && response.status==401){
          props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
          props.viewStatus.InformChildPageStatus(500);
          }  
          SetLoaded(true);    
      });
    }
  };

  const ActionIconUsers = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
            <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleEditClickOpen(props1.dataItem, true)}/>
            {
              props1.dataItem.GroupName != "System Admin" ||
              (props1.dataItem.GroupName == "System Admin" && props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite"))) ? 
              <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<TrashCanIcon />} text onClick={() => {
                setDeleteVersionObj(props1.dataItem);
                setDeleteConfirmationDialog(true);
              }} />
              :<></>
            }
      </td>
    );
  }

  const CellCheckbox = (props1) => {
    return updateUserPermissionLoading ? (
      <Loader size="small" inline />
    ) : (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          onChange={(e, { checked }) => {
            switch (props1.field) {
              case "IsAnalyticsUser":
                props1.dataItem.IsAnalyticsUser = checked;
                break;
              case "IsAdmin":
                props1.dataItem.IsAdmin = checked;
                break;
              case "IsExternal":
                props1.dataItem.IsExternal = checked;
                break;
              case "IsAccessBlocked":
                props1.dataItem.IsAccessBlocked = checked;
                break;
              default:
                console.log("Invalid");
            }
            props.acquireToken((token) =>
              authSuccessUpdateUserPermissionsCallback(token, props1.dataItem)
            );
          }}
          checked={
            props1.field == "IsAnalyticsUser"
              ? props1.dataItem.IsAnalyticsUser
              : props1.field == "IsAdmin"
              ? props1.dataItem.IsAdmin
              : props1.field == "IsExternal"
              ? props1.dataItem.IsExternal
              : props1.field == "IsAccessBlocked"
              ? props1.dataItem.IsAccessBlocked
              : false
          }
          disabled={props1.dataItem.GroupName == "System Admin" ? 
          !props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite")): false}
        />
      </td>
    );
  };

  const CellCheckboxDisabled = (props1) => {
    return updateUserPermissionLoading ? (
      <Loader size="small" inline />
    ) : (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled
          checked={
            props1.field == "IsAnalyticsUser"
              ? props1.dataItem.IsAnalyticsUser
              : props1.field == "IsAdmin"
              ? props1.dataItem.IsAdmin
              : props1.field == "IsExternal"
              ? props1.dataItem.IsExternal
              : props1.field == "IsAccessBlocked"
              ? props1.dataItem.IsAccessBlocked
              : false
          }
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteVersionObj != null)  {
      props.acquireToken((token) =>
        authSuccessRemoveUserCallback(token, deleteVersionObj.AppUserId)
      );
    }
  };

  const handleEditClickOpen = (dataItem, isEdit) => {
    let data = [];
    let names = [];
    let ids = [];
    setVisible(true);    
    // SetUserData(dataItem);    
    if(dataItem.RestrictedResellers && dataItem.RestrictedResellers.length > 0 
        && dataItem.RestrictedResellersId && dataItem.RestrictedResellersId.length > 0 ){
       names = dataItem.RestrictedResellers.split('$$');
       ids = dataItem.RestrictedResellersId.split('$$');
       if(names && names.length > 0 && ids && ids.length > 0){
        for(let i = 0; i < ids.length; i++){
          data.push({
            header: names[i],
            value: ids[i]+ ",1"
          })
        }
       }
    }
    if(dataItem.RestrictedInstances && dataItem.RestrictedInstances.length > 0 
      && dataItem.RestrictedInstancesId && dataItem.RestrictedInstancesId.length > 0 ){
      names = dataItem.RestrictedInstances.split('$$');
      ids = dataItem.RestrictedInstancesId.split('$$');
      if(names && names.length > 0 && ids && ids.length > 0){
        for(let i = 0; i < ids.length; i++){
          data.push({
            header: names[i],
            value: ids[i] + ",0"
          })
        }
      }
    }

    dataItem.SelectedSP = data;
    
    SetUserData(dataItem);   
    checkIsValid();
  };

  const handleEditClickClose = () => {
    setVisible(false);
    SetUserData(null);
    setIsValid(false);
  };

  const checkIsValid = () => {
    if (
      userData?.AppUserEmail !== undefined &&
      userData?.AppUserEmail?.length > 0 &&
      userData?.DisplayName !== undefined &&
      userData?.DisplayName?.length > 0 &&
      userData?.PrimaryContact !== undefined &&
      userData?.PrimaryContact?.length > 0
    ) {
      setIsValid(true);
    } else setIsValid(false);
  };

  const handlePermissionGroupChange = (alwaysNull, data) => {
    SetUserData({ ...userData, PermissionGroupId: data.value.value, GroupName: data.value.header });
  };

  const restrictedAccessChange = () => {
    if(restrictedAccess == false){
      setRestrictedAccess(true);
    }
    else{
      setRestrictedAccess(false);
    }
  }

  const handleSPChange = (e, data) => {
    SetUserData({ ...userData, SelectedSP: data.value });
  }

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <FluentGrid columns={2}>
          <Text weight="bold" content="Email:" />
          <Text content={dataItem.AppUserEmail} />   
          <Text weight="bold" content="Primary Contact:" />
          <Text content={dataItem.PrimaryContact} />          
          <Text weight="bold" content="Secondary Contact:" />
          <Text content={dataItem.SecondaryContact} />
        </FluentGrid>
        {
          props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.ManageLogin.ReadWrite" )) ?
          <Checkbox
          styles={{ marginRight: "10px" }}
          toggle
          labelPosition="start"
          label="Access Blocked:"
          disabled={dataItem.GroupName == "System Admin" ? 
          !props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite")): false}
          checked={dataItem.IsAccessBlocked}
          onClick={(event, isChecked) => {
            dataItem.IsAccessBlocked = isChecked.checked;
            props.acquireToken((token) =>
              authSuccessUpdateUserPermissionsCallback(token, dataItem)
            );
          }}
        />
            :
            props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ManageLogin.Read" )) ?
            <Checkbox
          styles={{ marginRight: "10px" }}
          toggle
          labelPosition="start"
          label="Access Blocked:"
          checked={dataItem.IsAccessBlocked}
          disabled
        />
            : <></>
        }
      </section>
    );
  };

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="f-size-18">USERS</div>
                <div>
                  {visible && (
                    <Dialog
                    className='mid-wide restricted-width'
                      title={"Edit User"}
                      onClose={handleEditClickClose}
                    >
                      <Form onSubmit={submitHandler}>
                        <FormInput
                          label="User Name"
                          name="UserName"
                          id="UserName-inline"
                          fluid
                          required
                          disabled
                          value={userData?.DisplayName}
                          onChange={(value) =>
                            SetUserData({
                              ...userData,
                              DisplayName: value.target.value,
                            })
                          }
                        />
                        <FormInput
                          label="User Email"
                          name="AppUserEmail"
                          id="AppUserEmail-inline"
                          fluid
                          required
                          disabled
                          value={userData?.AppUserEmail}
                          onChange={(value) =>
                            SetUserData({
                              ...userData,
                              AppUserEmail: value.target.value,
                            })
                          }
                        />
                        <FormInput
                          label="Primary Contact"
                          name="PrimaryContact"
                          id="PrimaryContact-inline"
                          fluid
                          required
                          value={userData?.PrimaryContact}
                          onChange={(value) =>
                            SetUserData({
                              ...userData,
                              PrimaryContact: value.target.value,
                            })
                          }
                        />
                        <FormInput
                          label="Secondary Contact"
                          name="SecondaryContact"
                          id="SecondaryContact-inline"
                          fluid
                          value={userData?.SecondaryContact}
                          onChange={(value) =>
                            SetUserData({
                              ...userData,
                              SecondaryContact: value.target.value,
                            })
                          }
                        />
                        <FormDropdown
                          label={{
                            content: `Permission Group`,
                            id: "permission-inline",
                          }}
                          disabled={userData?.GroupName == "System Admin" ? 
                          !props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite")): false}
                          items={props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite")) ? permissionGroups 
                          : permissionGroups.filter((p) => p.header != "System Admin")}
                          aria-labelledby={"permission-inline"}
                          placeholder="Choose Permission Group"
                          checkable
                          autosize="true"
                          fluid
                          value={
                            userData != null && userData.PermissionGroupId > -1
                              ? {
                                  header: userData?.GroupName,
                                  value: userData?.PermissionGroupId,
                                }
                              : null
                          }
                          onChange={handlePermissionGroupChange}
                        />

                        <Checkbox 
                          // onChange={restrictedAccessChange} 
                          checked={userData?.RestrictedAccess}
                          onChange={(value, dt) => {
                              SetUserData({
                                ...userData,
                                RestrictedAccess: dt.checked,
                              })
                            }
                          }
                          label="Restricted Access (Applicable for Billing Dashboard Only)"
                          className="permission-checkbox">
                        </Checkbox>

                        <FormDropdown
                          search
                          multiple
                          label={{
                            content: `Service Providers`,
                            id: "sp-inline",
                          }}
                          disabled={!userData?.RestrictedAccess}
                          items={serviceProviders}
                          aria-labelledby={"sp-inline"}
                          placeholder="Choose Service Providers"
                          checkable
                          autosize="true"
                          fluid
                          value={
                            userData.SelectedSP
                          } 
                          onChange={handleSPChange}
                          className='checknow'
                        />

                        {/* <FormCheckbox
                          label="Admin Access"
                          id="IsAdmin"
                          toggle
                          value={userData?.IsAdmin}
                          checked={userData?.IsAdmin}
                          onChange={(e, { checked }) => {
                            handleIsAdminChange(checked);
                          }}
                        /> */}
                        {/* <FormCheckbox
                          label="Analytics Access"
                          id="IsAnalyticsUser"
                          toggle
                          value={userData?.IsAnalyticsUser}
                          checked={userData?.IsAnalyticsUser}
                          onChange={(e, { checked }) => {
                            handleIsAnalyticsUserChange(checked);
                          }}
                        /> */}
                        {/* <FormCheckbox
                          label="External User"
                          id="IsExternal"
                          toggle
                          value={userData?.IsExternal}
                          checked={userData?.IsExternal}
                          onChange={(e, { checked }) => {
                            handleIsExternalUserChange(checked);
                          }}
                        /> */}
                        {/* <FormCheckbox
                          label="Access Blocked"
                          id="IsAcessBlocked"
                          toggle
                          value={userData?.IsAccessBlocked}
                          checked={userData?.IsAccessBlocked}
                          onChange={(e, { checked }) => {
                            handleIsAccessBlockedChange(checked);
                          }}
                        /> */}
                        {/* <FormDropdown
                          label={{
                            content: `Role`,
                            id: "Type-inline",
                          }}
                          items={["Sales", "Admin", "Management Team", "R&D"]}
                          aria-labelledby={"Type-inline"}
                          search={true}
                          placeholder="Choose Role"
                          required
                          autosize="true"
                          value={userData?.Role}
                          onChange={handleRoleChange}
                        /> */}

                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editSubmitClickHandler}
                            disabled={isValid === false}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className='grid-with-search'>
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(users, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column field="DisplayName" title="User Name"  width={250}/>
                    {!context.mediaCategory.sm && (
                    <Column field="AppUserEmail" title="Email" width={250}/>
                    )}
                    {!context.mediaCategory.sm && (
                    <Column field="PrimaryContact" width={150} title="Primary Contact" />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column field="SecondaryContact" width={200} title="Secondary Contact" />
                    )}
                    
                    {!context.mediaCategory.sm && (
                      <Column
                        field="GroupName"
                        title="Permission Group"
                        width={170}
                        cell={CellUserPermissions}
                      />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="RestrictedAccess"
                        title="Restricted Access"
                        width={170}
                        cell={CellConfigurationState}
                      />
                    )}
                    {/* {!context.mediaCategory.sm && (
                    <Column
                      field="IsExternal"
                      title="External User"
                      cell={CellCheckbox}
                      width="120px"
                    />)} */}
                

                    {!context.mediaCategory.sm ?
                      props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.ManageLogin.ReadWrite" )) ?
                      <Column
                      field="IsAccessBlocked"
                      title="Access blocked"
                      cell={CellCheckbox}
                      width={150} filterable={false} sortable={false}
                    />
                        :
                        props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ManageLogin.Read" )) ?
                        <Column
                      field="IsAccessBlocked"
                      title="Access blocked"
                      cell={CellCheckboxDisabled}
                      width={150} filterable={false} sortable={false}
                    />
                        : <></> : <></>
                      }
								 {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.ManageLogin.ReadWrite" )) &&

                      <Column
                        field=""
                        title="Actions"
                        style={{ textAlign: "center" }}
                        cell={ActionIconUsers}
                        width={100}
                      />}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
           <FluentDialog
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setDeleteConfirmationDialog(false);
                setDeleteVersionObj(null);
              }}
              onConfirm={() => removeData()}
              open={openDeleteConfirmationDialog}
              backdrop
              header="Action confirmation"
              headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => {
                  setDeleteConfirmationDialog(false);
                  setDeleteVersionObj(null);
                },
              }}
              content={`Are you sure to delete user: ${deleteVersionObj?.DisplayName}?`}
            />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsUsers };
